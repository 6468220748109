import styled from 'styled-components';
import Button from '@mui/material/Button';

const LoadMoreButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 40px;
`;

export default function LoadMoreButton(props) {
	return (
		<LoadMoreButtonWrapper>
			<Button variant="contained" {...props}>
				Last flere
			</Button>
		</LoadMoreButtonWrapper>
	);
}
