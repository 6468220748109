import { gql } from '@apollo/client';

export const PAGINATE_COMMENTS = gql`
	query paginateComments(
		$docId: ID!
		$order: Int
		$orderBy: String
		$limit: Int
		$offset: Int
	) {
		comments: paginateComments(
			docId: $docId
			order: $order
			orderBy: $orderBy
			limit: $limit
			skip: $offset
		) {
			count
			items {
				_id
				message
				createdAt
				createdBy
				createdByDisplayName
			}
		}
	}
`;
