import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { dropdownYears } from 'utils/dates';

import ModalLayout from 'components/modals/ModalLayout';
import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import AutocompleteField from 'components/form/AutocompleteField';
import SubmitButton from 'components/form/SubmitButton';

import { GET_COMPANY_CONTROL_TYPES } from 'api/queries/companyControlQueries';
import { INSERT_ONE_COMPANY_CONTROL } from 'api/mutations/companyControlMutations';

export default function CompanyControlsCreateModal({ companyId, onClose }) {
	const navigate = useNavigate();

	const {
		data: { controlTypes } = { controlTypes: [] },
		loading: getCompanyControlTypesLoading,
		error: getCompanyControlTypesError,
	} = useQuery(GET_COMPANY_CONTROL_TYPES);

	const [
		insertCompanyControl,
		{
			loading: insertCompanyControlLoading,
			error: insertCompanyControlError,
		},
	] = useMutation(INSERT_ONE_COMPANY_CONTROL, {
		refetchQueries: ['getCompanyControls'],
	});

	const year = new Date().getFullYear();
	const previousYear = year - 1;
	const years = dropdownYears(null, year);

	return (
		<ModalLayout
			title="Opprett ny kontroll"
			onClose={onClose}
			maxWidth="800px"
		>
			<ErrorMessage
				errors={[
					getCompanyControlTypesError,
					insertCompanyControlError,
				]}
			/>

			<Form
				values={{ year: previousYear }}
				isLoading={
					getCompanyControlTypesLoading || insertCompanyControlLoading
				}
				isDisabled={
					getCompanyControlTypesLoading || insertCompanyControlLoading
				}
				onSubmit={async ({ type, year }) => {
					try {
						const { data } = await insertCompanyControl({
							variables: {
								companyId,
								type,
								year,
							},
						});

						if (data) {
							onClose();

							navigate(
								`/bedrifter/${data.control.companyId}/kontroller/${data.control._id}/rediger/`
							);
						}
					} catch (e) {
						console.warn(e);
					}
				}}
			>
				{controlTypes && (
					<AutocompleteField
						label="Velg type kontroll"
						name="type"
						options={controlTypes.map(control => {
							return {
								value: control.value,
								label: control.label,
							};
						})}
						required
						multiple={false}
					/>
				)}

				<AutocompleteField
					label="Gjelder for år"
					name="year"
					options={years}
					required
					multiple={false}
				/>

				<p>Du kan kun opprette en av hver type kontroll per år.</p>

				<SubmitButton variant="contained">
					Opprett kontroll
				</SubmitButton>
			</Form>
		</ModalLayout>
	);
}
