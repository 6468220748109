import { useQuery } from '@apollo/client';

import Filter from 'components/filters/Filter';

import { PAGINATE_USERS_FOR_FILTER_SELECT } from 'api/queries/userQueries';

const allOption = { value: '', label: 'Alle' };

export default function UsersFilterSelect({
	title,
	value,
	onChange,
	roles,
	disabled,
	size,
}) {
	const {
		data: { users = { count: 0, items: [] } } = {
			users: { count: 0, items: [] },
		},
		loading,
	} = useQuery(PAGINATE_USERS_FOR_FILTER_SELECT, {
		variables: {
			roles,
			order: 1,
			orderBy: 'name',
		},
	});

	return (
		<Filter
			name="users-filter"
			label={title}
			value={value || allOption}
			options={[
				allOption,
				...users.items.map(accountant => ({
					value: accountant._id,
					label: accountant.name,
				})),
			]}
			onChange={onChange}
			loading={loading}
			disabled={disabled}
			size={size}
		/>
	);
}
