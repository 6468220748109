import { Controller, useFormContext } from 'react-hook-form';
import MUITextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

export default function TextField({
	name,
	label = null,
	textAlign = 'left',
	prefix = null,
	suffix = null,
	...rest
}) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			defaultValue=""
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<MUITextField
					label={label}
					variant="outlined"
					value={value ?? ''}
					onChange={event => {
						onChange(event);
					}}
					error={!!error}
					helperText={error?.message}
					slotProps={{
						input: {
							inputProps: {
								style: { textAlign },
							},
							startAdornment: prefix && (
								<InputAdornment position="start">
									{prefix}
								</InputAdornment>
							),
							endAdornment: suffix && (
								<InputAdornment position="start">
									{suffix}
								</InputAdornment>
							),
						},
					}}
					{...rest}
				/>
			)}
		/>
	);
}
