import { Controller, useFormContext } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

export default function AutoComplete({
	name,
	label,
	defaultValue = null,
	options = [],
	loading = false,
	...rest
}) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue ?? ''}
			render={({ field: { onChange, value } }) => (
				<Autocomplete
					loading={loading}
					value={
						value
							? (options.find(option => option.value === value) ??
								null)
							: null
					}
					options={options}
					onChange={(_event, item) => onChange(item?.value ?? null)}
					renderInput={params => (
						<TextField
							{...params}
							label={label}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{loading ? (
											<CircularProgress
												color="inherit"
												size={20}
											/>
										) : null}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					)}
					noOptionsText="Ingen alternativer"
					{...rest}
				/>
			)}
		/>
	);
}
