import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

import departments from 'components/utils/departments';

import { useUser } from 'web/contexts/UserContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorAlert from 'components/ErrorAlert';
import DeleteButton from 'components/buttons/DeleteButton';

import ManuallyVerifiedUsersPanel from 'web/components/panels/ManuallyVerifiedUsersPanel';
import { Grid, GridItem } from 'web/components/company/GridLayout';
import { StickyAction } from 'web/components/Layout';
import CreatedInfo from 'web/components/CreatedInfo';
import UpdatedInfo from 'web/components/UpdatedInfo';
import GeneralDeviations from 'web/components/GeneralDeviations';

import Form from 'components/form/Form';
import AutocompleteField from 'components/form/AutocompleteField';
import CheckboxField from 'components/form/CheckboxField';
import DualFields from 'components/form/DualFields';
import SelectField from 'components/form/SelectField';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import { ARCHIVE_COMPANY, UPDATE_COMPANY } from 'api/mutations/tenantMutations';
import { PAGINATE_TENANT_CATEGORIES } from 'api/queries/tenantCategoryQueries';
import { PAGINATE_USERS } from 'api/queries/userQueries';

const FormGrid = styled.div`
	margin-bottom: 32px;

	@media (min-width: 1640px) {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
		grid-column-gap: 32px;
	}
`;

const FormGridCol = styled.div``;

export default function DetailsTab({
	company,
	companyLoading,
	setCreateUserModal,
}) {
	const navigate = useNavigate();
	const { userHasMinRole } = useUser();
	const { notify } = useSnackbar();

	const {
		data: { users } = { users: { count: 0, items: [] } },
		loading: usersLoading,
		error: usersError,
	} = useQuery(PAGINATE_USERS, {
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		variables: { orderBy: 'name', order: 1 },
	});

	const {
		data: { categories } = { categories: { count: 0, items: [] } },
		loading: categoriesLoading,
		error: categoriesError,
	} = useQuery(PAGINATE_TENANT_CATEGORIES, {
		errorPolicy: 'all',
	});

	const [
		updateCompany,
		{ loading: updateCompanyLoading, error: updateCompanyError },
	] = useMutation(UPDATE_COMPANY, {
		refetchQueries: ['findOneTenant'],
	});

	const [archiveCompany, { error: archiveCompanyError }] =
		useMutation(ARCHIVE_COMPANY);

	const adminsAndAccountants = users?.items.filter(
		u => u.role === 'admin' || u.role === 'accountant'
	);

	const auditors = users?.items.filter(u => u.role === 'auditor');

	return (
		<>
			<ErrorAlert error={updateCompanyError} />
			<ErrorAlert error={usersError} />
			<ErrorAlert error={categoriesError} />
			<ErrorAlert error={archiveCompanyError} />

			<Form
				key={company && company._id}
				values={company}
				isLoading={
					companyLoading ||
					usersLoading ||
					categoriesLoading ||
					updateCompanyLoading
				}
				isDisabled={
					companyLoading ||
					usersLoading ||
					categoriesLoading ||
					updateCompanyLoading
				}
				onSubmit={async variables => {
					if (variables.userRoles) {
						variables.userRoles = variables.userRoles.map(
							({ id, role }) => ({
								id,
								role,
							})
						);
					}

					delete variables.proffData;

					const { data } = await updateCompany({
						variables: {
							...variables,
							orgNo: variables?.orgNo?.replaceAll(' ', ''),
						},
					});

					if (data) {
						notify('Bedriften ble oppdatert!');
					}
				}}
			>
				<FormGrid>
					<FormGridCol>
						<TextField name="name" label="Navn" required />

						<AutocompleteField
							name="accountTypes"
							label="Kontotyper"
							options={[
								{
									value: 'accounting',
									label: 'Regnskapskunde',
								},
								{
									value: 'counseling',
									label: 'Rådgivningskunde',
								},
								{
									value: 'lead',
									label: 'Potensiell kunde',
								},
							]}
							multiple
							required
						/>

						<TextField name="clientNo" label="Kundenummer" />

						<TextField
							name="orgNo"
							label="Organisasjonsnr"
							pattern="^\s*(\d{3}\s*\d{3}\s*\d{3}|\d{9})\s*$"
						/>

						<TextField name="email" type="email" label="E-post" />

						<TextField name="phone" type="tel" label="Telefon" />

						<SelectField
							name="departmentId"
							label="Avdeling"
							options={departments}
						/>

						{categories && (
							<SelectField
								name="categoryIds"
								label="Kategorier"
								options={categories.items.map(item => ({
									label: item.name,
									value: item._id,
								}))}
								multiple
							/>
						)}

						{userHasMinRole('admin') && (
							<CheckboxField
								name="isInactive"
								label="Ikke aktiv"
							/>
						)}
					</FormGridCol>

					<FormGridCol>
						{adminsAndAccountants && (
							<AutocompleteField
								name="accountantIds"
								label="Regnskapsfører"
								options={adminsAndAccountants.map(item => ({
									label: item.name,
									value: item._id,
								}))}
								multiple
							/>
						)}

						{adminsAndAccountants && (
							<AutocompleteField
								name="accountManagerIds"
								label="Kundeansvarlig"
								options={adminsAndAccountants.map(item => ({
									label: item.name,
									value: item._id,
								}))}
								multiple
							/>
						)}

						{adminsAndAccountants && (
							<AutocompleteField
								name="payrollManagerIds"
								label="Lønnsansvarlig regnskapsfører"
								options={adminsAndAccountants.map(item => ({
									label: item.name,
									value: item._id,
								}))}
								multiple
							/>
						)}

						{adminsAndAccountants && (
							<AutocompleteField
								name="annualAccountsManagerIds"
								label="Årsoppgjørsansvarlig"
								options={adminsAndAccountants.map(item => ({
									label: item.name,
									value: item._id,
								}))}
								multiple
							/>
						)}

						{adminsAndAccountants && (
							<AutocompleteField
								name="advisorIds"
								label="Rådgiver"
								options={adminsAndAccountants.map(item => ({
									label: item.name,
									value: item._id,
								}))}
								multiple
							/>
						)}

						{adminsAndAccountants && (
							<AutocompleteField
								name="controllerIds"
								label="Oppdragskontrollør"
								options={adminsAndAccountants.map(item => ({
									label: item.name,
									value: item._id,
								}))}
								multiple
							/>
						)}

						{auditors && (
							<AutocompleteField
								name="auditorIds"
								label="Revisor"
								options={auditors.map(item => ({
									label: item.name,
									value: item._id,
								}))}
								multiple
							/>
						)}

						<TextField
							name="freeText"
							label="Fritekst"
							maxRows="5"
							multiline={true}
						/>
					</FormGridCol>

					{(company?.dataDeviations?.generalDeviations
						?.hasDeviations ||
						company?.dataDeviations?.proffDeviations
							?.hasDeviations) && (
						<FormGridCol>
							<GeneralDeviations
								deviations={
									company.dataDeviations.generalDeviations
								}
								proffDeviations={
									company.dataDeviations.proffDeviations
								}
							/>
						</FormGridCol>
					)}
				</FormGrid>

				<Grid>
					{users && (
						<GridItem>
							<DualFields
								title="Bedriftsroller"
								name="userRoles"
								handleCreateUser={() =>
									setCreateUserModal(true)
								}
								leftSideOptions={users.items.map(user => {
									return {
										label: user.name,
										value: user._id,
										subdued:
											user.role === undefined ||
											user.role === null ||
											user.role == 'silent',
										tooltip: [
											<Stack
												key="1"
												direction="row"
												alignItems="center"
												gap={1}
											>
												<MailIcon />
												{user.email || 'Mangler e-post'}
											</Stack>,
											<Stack
												key="2"
												direction="row"
												alignItems="center"
												gap={1}
											>
												<PhoneIcon />
												{user.phone ||
													'Mangler telefon'}
											</Stack>,
										],
										action: () =>
											navigate(`/brukere/${user._id}/`),
									};
								})}
							/>
						</GridItem>
					)}
				</Grid>

				<StickyAction>
					<DeleteButton
						onClick={async () => {
							const { data } = await archiveCompany({
								variables: {
									_id: company._id,
								},
							});

							if (!data.deleted) {
								throw new Error(
									'Det oppstod en feil ved sletting!'
								);
							}
						}}
						redirect="/bedrifter/"
					/>

					<SubmitButton
						variant="contained"
						size="large"
						icon={<SaveIcon />}
					>
						Lagre
					</SubmitButton>
				</StickyAction>
			</Form>

			<Grid>
				{company && (
					<GridItem>
						<ManuallyVerifiedUsersPanel companyId={company._id} />
					</GridItem>
				)}
			</Grid>

			<CreatedInfo
				createdAt={company?.createdAt}
				createdBy={company?.createdByDisplayName}
			/>

			<UpdatedInfo
				updatedAt={company?.updatedAt}
				updatedBy={company?.updatedByDisplayName}
			/>
		</>
	);
}
