import { Controller, useFormContext } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MUISelect from '@mui/material/Select';

export default function Select({
	name,
	label,
	defaultValue = null,
	options = [],
	fullWidth = false,
	...rest
}) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue ?? ''}
			render={({ field: { onChange, value } }) => (
				<div>
					<FormControl fullWidth={fullWidth}>
						<InputLabel id={`${name}-select-label`}>
							{label}
						</InputLabel>

						<MUISelect
							labelId={`${name}-select-label`}
							id={`${name}-select`}
							value={value ?? ''}
							label={label}
							onChange={onChange}
							sx={{ minWidth: '400px' }}
							{...rest}
						>
							{options.map((option, index) => (
								<MenuItem key={index} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</MUISelect>
					</FormControl>
				</div>
			)}
		/>
	);
}
