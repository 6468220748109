import { useState } from 'react';
import { useQuery } from '@apollo/client';

import EditAssignmentEntryDialog from 'web/screens/dialogs/assignmentTaskEntry/EditAssignmentEntryDialog';

import { TaskDeadlineEntryIcon } from 'web/components/AssignmentTasksCalendarTimeline';

import { GET_TASK_DEADLINE_ENTRY_IN_PERIOD } from 'api/queries/companyAssignmentTaskQueries';

export default function TaskDeadlineEntryInPeriod({
	companyId,
	taskName,
	month,
	year,
}) {
	const [showEntryDialog, setShowEntryDialog] = useState(false);

	const {
		data: { deadlineEntry } = { deadlineEntry: null },
		loading,
		error,
	} = useQuery(GET_TASK_DEADLINE_ENTRY_IN_PERIOD, {
		variables: {
			companyId,
			taskName,
			month,
			year,
		},
	});

	if (error) return <div>Feil ved lasting</div>;

	if (loading || !deadlineEntry) {
		return <div />;
	}

	return (
		<>
			<TaskDeadlineEntryIcon
				deadlineEntry={deadlineEntry}
				onClick={() => setShowEntryDialog(true)}
			/>

			<EditAssignmentEntryDialog
				deadlineEntry={deadlineEntry}
				open={showEntryDialog}
				onClose={() => setShowEntryDialog(false)}
			/>
		</>
	);
}
