import { useQuery, useMutation } from '@apollo/client';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogCloseButton,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';
import ErrorAlert from 'components/ErrorAlert';

import Form from 'components/form/Form';
import AutocompleteField from 'components/form/AutocompleteField';
import SubmitButton from 'components/form/SubmitButton';

import { GET_COMPANY_ROUTINE_TYPES } from 'api/queries/companyRoutineQueries';
import { INSERT_ONE_COMPANY_ROUTINE } from 'api/mutations/companyRoutineMutations';

export default function CompanyRoutinesCreateDialog({
	companyId,
	activeRoutines = [],
	open,
	onClose,
}) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="xs"
			aria-labelledby="dialog-title"
			aria-describedby="dialog-description"
		>
			<DialogTitle id="dialog-title">Opprett rutine</DialogTitle>

			<DialogCloseButton onClick={onClose} />

			<CompanyRoutinesCreateForm
				companyId={companyId}
				activeRoutines={activeRoutines}
				onClose={onClose}
			/>
		</Dialog>
	);
}

function CompanyRoutinesCreateForm({ companyId, activeRoutines, onClose }) {
	const { notify } = useSnackbar();

	const {
		data: { routineTypes } = { routineTypes: [] },
		loading: getCompanyRoutineTypesLoading,
		error: getCompanyRoutineTypesError,
	} = useQuery(GET_COMPANY_ROUTINE_TYPES);

	const [
		createRoutine,
		{ loading: createRoutineLoading, error: createRoutineError },
	] = useMutation(INSERT_ONE_COMPANY_ROUTINE, {
		refetchQueries: ['getCompanyRoutines'],
	});

	return (
		<Form
			isLoading={getCompanyRoutineTypesLoading || createRoutineLoading}
			isDisabled={getCompanyRoutineTypesLoading || createRoutineLoading}
			onSubmit={async ({ routineType }) => {
				try {
					const { data } = await createRoutine({
						variables: {
							companyId,
							type: routineType,
						},
					});

					if (data?.routine?._id) {
						notify('Bedriftsrutinen ble opprettet');

						onClose();
					}
				} catch (e) {
					console.warn(e);
				}
			}}
		>
			<DialogContent>
				<ErrorAlert error={getCompanyRoutineTypesError} />
				<ErrorAlert error={createRoutineError} />

				{routineTypes && (
					<AutocompleteField
						label="Velg type rutine"
						name="routineType"
						options={routineTypes.map(routine => {
							return {
								value: routine.value,
								label: routine.label,
								disabled: activeRoutines.some(
									el => el.type === routine.value
								),
							};
						})}
						required
						multiple={false}
					/>
				)}

				<p>Du kan kun opprette en av hver type rutine.</p>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Avbryt</Button>

				<SubmitButton variant="contained">Opprett rutine</SubmitButton>
			</DialogActions>
		</Form>
	);
}
