import { useState, createContext, useContext } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import Drawer from '@mui/material/Drawer';

import {
	MenuWrapper,
	MenuHeader,
	CloseButton,
} from 'app/components/BottomMenu';
import Icon from 'components/Icon';
import { ListItemElement } from 'app/components/List';

import { UPDATE_DOCUMENT_NODE } from 'api/mutations/documentMutations';
import { GET_VIABLE_PARENTS } from 'api/queries/documentQueries';

const DocumentNodeList = styled.div`
	margin: 0;
	padding: 25px 15px;
	width: 100%;
	list-style: none;
	max-height: 220px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
`;
const Pusher = styled.div`
	width: 100%;
	clear: both;
	display: inline-block;
`;

const MoveDocumentNodeContext = createContext({
	isOpen: false,
	documentNodeId: null,
});

export function useMoveDocumentNode() {
	const context = useContext(MoveDocumentNodeContext);

	if (context === undefined) {
		throw new Error(
			'useMoveDocumentNode must be used within a MoveDocumentNodeContextMenuProvider'
		);
	}

	return context;
}

export default function MoveDocumentNodeContextMenuProvider({ children }) {
	let [isOpen, setIsOpen] = useState(false);
	let [documentNode, setDocumentNode] = useState(null);
	const { onRemoveDocumentNode = null } = documentNode || {};

	return (
		<MoveDocumentNodeContext.Provider
			value={{
				isOpen,
				setIsOpen,
				documentNode,
				setDocumentNode,
				move: documentNode => {
					setDocumentNode(documentNode);
					setIsOpen(true);
				},
			}}
		>
			{children}

			{documentNode && (
				<MoveDocumentNodeContextMenu
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					documentNode={documentNode}
					setDocumentNode={setDocumentNode}
					onRemoveDocumentNode={onRemoveDocumentNode}
				/>
			)}
		</MoveDocumentNodeContext.Provider>
	);
}

function MoveDocumentNodeContextMenu({
	documentNode,
	setDocumentNode,
	isOpen,
	setIsOpen,
	onRemoveDocumentNode,
}) {
	const { data, error } = useQuery(GET_VIABLE_PARENTS, {
		variables: {
			documentNodeId: documentNode._id,
			tenantId: documentNode.tenantId,
		},
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
	});

	const [updateDocumentNode] = useMutation(UPDATE_DOCUMENT_NODE, {
		refetchQueries: ['documentNodes', 'paginateDocumentNodes'],
	});

	return (
		<Drawer anchor="bottom" open={isOpen} onClose={() => setIsOpen(false)}>
			<div>
				<MenuWrapper>
					<MenuHeader>
						Flytt {documentNode.name}
						<CloseButton onClick={() => setIsOpen(false)}>
							<Icon icon="times" />
						</CloseButton>
					</MenuHeader>

					<p>{error && error.toString()}</p>

					<DocumentNodeList>
						{documentNode.parentId !== 'root' && (
							<ListItemElement
								name="Topp mappen"
								type="folder"
								onClick={async () => {
									await updateDocumentNode({
										variables: {
											_id: documentNode._id,
											parentId: 'root',
										},
									});
									setIsOpen(false);

									onRemoveDocumentNode &&
										onRemoveDocumentNode();
									setDocumentNode(null);
								}}
							/>
						)}

						{(data &&
							data.documentNodes &&
							data.documentNodes.items
								.filter(
									item => item._id != documentNode.parentId
								)
								.map(item => (
									<ListItemElement
										key={item._id}
										{...item}
										onClick={async () => {
											await updateDocumentNode({
												variables: {
													_id: documentNode._id,
													parentId: item._id,
												},
											});
											setIsOpen(false);

											onRemoveDocumentNode &&
												onRemoveDocumentNode();
											setDocumentNode(null);
										}}
									/>
								))) || <div />}

						<Pusher />
					</DocumentNodeList>
				</MenuWrapper>
			</div>
		</Drawer>
	);
}
