import { gql } from '@apollo/client';

export const FIND_ONE_FILE = gql`
	query findOneFile($_id: ID!) {
		file: findOneFile(_id: $_id) {
			_id
			fileName
			fileType
			url
		}
	}
`;

export const GET_FILES = gql`
	query getFiles($docId: ID!) {
		files: getFiles(docId: $docId) {
			_id
			docId
			fileName
			fileType
			url
			createdAt
			createdByDisplayName
		}
	}
`;
