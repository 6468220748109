import React, { useState, Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import AssignmentRisksCreateDialog from 'web/components/panels/dialogs/AssignmentRisksCreateDialog';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';
import { RevisionText } from 'components/Revision';
import { UnderlineLink } from 'web/components/Link';

import Panel, { EmptyPanel } from 'web/components/panels/Panel';

import { GET_ASSIGNMENT_RISKS } from 'api/queries/assignmentRiskQueries';
import { DELETE_ONE_ASSIGNMENT_RISK } from 'api/mutations/assignmentRiskMutations';

export default function AssignmentRisksPanel({ companyId }) {
	const [showDialog, setShowDialog] = useState(false);

	const {
		data: { risks } = { risks: [] },
		loading,
		error,
	} = useQuery(GET_ASSIGNMENT_RISKS, {
		variables: {
			companyId,
		},
	});

	return (
		<>
			<Panel
				title="Oppdragsrisikoanalyser"
				action={
					<Button
						variant="contained"
						color="primary"
						disabled={showDialog}
						onClick={() => {
							setShowDialog(true);
						}}
						endIcon={<AddIcon size="small" />}
					>
						Opprett ny
					</Button>
				}
			>
				<ErrorMessage errors={[error]} />

				{loading ? (
					<InlineLoading />
				) : (
					<AssignmentRisksList risks={risks} />
				)}
			</Panel>

			<AssignmentRisksCreateDialog
				existingRisks={risks}
				companyId={companyId}
				open={showDialog}
				onClose={() => setShowDialog(false)}
			/>
		</>
	);
}

function AssignmentRisksList({ risks = [] }) {
	if (!risks || risks.length === 0) {
		return (
			<EmptyPanel>Bedriften har ingen oppdragsrisikoanalyser.</EmptyPanel>
		);
	}

	return (
		<List>
			{risks.map(risk => (
				<AssignmentRiskItem key={risk._id} risk={risk} />
			))}
		</List>
	);
}

function AssignmentRiskItem({ risk }) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [deleteRisk, { loading, error }] = useMutation(
		DELETE_ONE_ASSIGNMENT_RISK,
		{
			refetchQueries: ['getAssignmentRisks'],
		}
	);

	return (
		<>
			<ErrorMessage errors={[error]} />

			<ListItem
				secondaryAction={
					<IconButton
						edge="end"
						aria-label="Slett"
						role="button"
						disabled={loading}
						onClick={async () => {
							try {
								if (
									!(await verify({
										title: 'Slett oppdragsrisikoanalyse',
										text: `Er du sikker på at du vil slette «${risk.name}»?`,
									}))
								) {
									return;
								}
								const { data } = await deleteRisk({
									variables: {
										_id: risk._id,
									},
								});

								if (data.deleted) {
									notify(`${risk.name} ble slettet`);
								}
							} catch (err) {
								console.error(err);
							}
						}}
					>
						<DeleteIcon />
					</IconButton>
				}
			>
				<ListItemText>
					<UnderlineLink
						to={`/bedrifter/${risk.companyId}/oppdragsrisiko/${risk._id}/rediger/`}
					>
						{risk.name}
					</UnderlineLink>

					<RevisionText
						needsRevision={risk?.needsRevision}
						date={risk?.revision?.date}
						by={risk?.revision?.byDisplayName}
					/>
				</ListItemText>
			</ListItem>

			<Divider component="li" />
		</>
	);
}
