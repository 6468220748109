import { gql } from '@apollo/client';

export const INSERT_ONE_COMPANY_RISK_ACTION = gql`
	mutation insertOneCompanyRiskAction(
		$questionId: ID!
		$description: String!
		$dueDate: DateTime!
	) {
		action: insertOneCompanyRiskAction(
			questionId: $questionId
			description: $description
			dueDate: $dueDate
		) {
			_id
		}
	}
`;

export const MARK_COMPANY_RISK_ACTION_AS_DONE = gql`
	mutation markActionAsDone($_id: ID!) {
		done: markActionAsDone(_id: $_id)
	}
`;

export const DELETE_ONE_COMPANY_RISK_ACTION = gql`
	mutation deleteOneCompanyRisk($_id: ID!) {
		deleted: deleteOneCompanyRiskAction(_id: $_id)
	}
`;
