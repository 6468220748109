import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

import { useWebApp } from 'web/contexts/WebContexts';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import LoginLayout from 'web/components/LoginLayout';
import { UnderlineLink } from 'web/components/Link';

import { EMAIL_AND_PASSWORD_LOGIN } from 'api/mutations/userMutations';

export default function AdminLogin() {
	const { set } = useWebApp();
	const navigate = useNavigate();

	const [login, { loading, error }] = useMutation(EMAIL_AND_PASSWORD_LOGIN);

	return (
		<LoginLayout errors={error}>
			<Form
				isLoading={loading}
				isDisabled={loading}
				onSubmit={async ({ email, password }) => {
					try {
						const {
							data: { token },
						} = await login({
							variables: { email, password },
						});

						if (token) {
							set({ apiToken: token });
							navigate('/');
						}
					} catch (err) {
						console.warn(err);
					}
				}}
			>
				<TextField name="email" type="email" label="E-post" required />

				<TextField
					name="password"
					type="password"
					label="Passord"
					required
				/>

				<SubmitButton
					variant="contained"
					size="large"
					icon={<FingerprintIcon />}
				>
					Logg inn
				</SubmitButton>
			</Form>

			<UnderlineLink to="/glemt-passord">Glemt passord</UnderlineLink>

			<UnderlineLink to="/">Gå tilbake</UnderlineLink>
		</LoginLayout>
	);
}
