import { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';

import ErrorMessage from 'components/ErrorMessage';
import Panel from 'web/components/panels/Panel';
import NotVerifiedUserInfoItem from 'web/components/panels/NotVerifiedUserInfoItem';
import UserInfoItem from 'web/components/panels/UserInfoItem';

import { GET_COMPANY_USERS_MANUALLY_VERIFIED } from 'api/queries/tenantQueries';

export default function ManuallyVerifiedUsersPanel({ companyId }) {
	const {
		data: { users } = { users: null },
		loading,
		error,
	} = useQuery(GET_COMPANY_USERS_MANUALLY_VERIFIED, {
		variables: {
			companyId,
		},
	});

	if (loading || !users) return null;

	return (
		<Panel title="Manuelt verifiserte brukere">
			<ErrorMessage errors={error} />

			<List>
				{users.map(user => {
					if (user.role === 'silent') {
						return (
							<Fragment key={user._id}>
								<ListItem>
									<ListItemText>
										<NotVerifiedUserInfoItem user={user} />
									</ListItemText>
								</ListItem>

								<Divider component="li" />
							</Fragment>
						);
					}

					return (
						<Fragment key={user._id}>
							<ListItem>
								<UserInfoItem user={user} />
							</ListItem>
							<Divider component="li" />
						</Fragment>
					);
				})}
			</List>
		</Panel>
	);
}
