import LoadingButton from '@mui/lab/LoadingButton';

import { useForm } from 'components/form/Form';

export default function SubmitButton({
	disabled,
	icon = null,
	label,
	children,
	...rest
}) {
	const { isDisabled, isLoading, canSubmit, fields, values } = useForm();

	return (
		<LoadingButton
			disabled={disabled || isDisabled || !canSubmit(fields, values)}
			type="submit"
			startIcon={icon}
			loadingPosition={icon ? 'start' : 'center'}
			loading={isLoading}
			{...rest}
		>
			{children}
		</LoadingButton>
	);
}
