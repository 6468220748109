import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import LockResetIcon from '@mui/icons-material/LockReset';
import SendIcon from '@mui/icons-material/Send';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

import { formatDate } from 'utils/dates';

import { roles } from 'server/context/roles';
import { countries } from 'utils/countries';
import { useUser } from 'web/contexts/UserContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';

import NotFound from 'web/screens/NotFound';

import NewPasswordDialog from 'web/screens/dialogs/users/NewPasswordDialog';

import ErrorAlert from 'components/ErrorAlert';
import DeleteButton from 'components/buttons/DeleteButton';

import DesktopLayout, { Content, StickyAction } from 'web/components/Layout';

import ProfileImage from 'web/components/ProfileImage';
import IdentityVerification from 'web/components/IdentityVerification';
import CreatedInfo from 'web/components/CreatedInfo';
import UpdatedInfo from 'web/components/UpdatedInfo';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import SelectField from 'components/form/SelectField';
import SwitchField from 'components/form/SwitchField';
import DualFields from 'components/form/DualFields';
import DynamicPEPFields from 'components/form/DynamicPEPFields';
import AutocompleteField from 'components/form/AutocompleteField';
import SubmitButton from 'components/form/SubmitButton';

import TenantRoles from 'web/components/user/TenantRoles';

import { FIND_USER_WITH_USER_ROLES } from 'api/queries/userQueries';
import { FIND_TENANTS } from 'api/queries/tenantQueries';
import { PAGINATE_AUDITING_COMPANIES } from 'api/queries/auditingCompanyQueries';
import {
	UPDATE_ONE_USER,
	DELETE_ONE_USER,
	INVITE_ONE_USER,
	RESET_FAILED_LOGIN_ATTEMPTS,
	SEND_USER_CONTACT_INFO_UPDATE_NOTIFICATION,
} from 'api/mutations/userMutations';

const Actions = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;
	width: 100%;
	max-width: 400px;
	button {
		margin-bottom: 16px;
	}
`;

const Invite = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;

	& > *:not(:last-child) {
		margin-right: 50px;
		flex: 1 1 auto;
	}

	& > *:last-child {
		width: 75%;
		flex: 1 1 75%;
	}
`;

const FormGrid = styled.div`
	@media (min-width: 1500px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 32px;
		margin-bottom: 32px;
	}
`;

const FormGridCol = styled.div``;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 16px;
	padding: 32px 0;

	@media (min-width: 1200px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: 1800px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const SaveInfo = styled.div`
	margin-top: 40px;
`;

export default function UsersEdit() {
	const { userId } = useParams();
	const navigate = useNavigate();
	const { user: currentUser } = useUser();
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [isUserAuditor, setIsUserAuditor] = useState(false);
	const [inviteSent, setInviteSent] = useState(false);
	const [isPEP, setIsPEP] = useState(false);
	const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);

	const [lastVerifiedEmail, setLastVerifiedEmail] = useState(null);
	const [lastVerifiedPhone, setLastVerifiedPhone] = useState(null);

	const {
		data: { user } = { user: {} },
		loading: userLoading,
		error: userError,
		refetch: userRefetch,
	} = useQuery(FIND_USER_WITH_USER_ROLES, {
		variables: {
			_id: userId,
		},
	});

	const [
		updateOneUser,
		{ loading: updateOneUserLoading, error: updateOneUserError },
	] = useMutation(UPDATE_ONE_USER, {
		refetchQueries: ['findOneUser'],
	});

	const {
		data: { companies } = { companies: [] },
		loading: companiesLoading,
	} = useQuery(FIND_TENANTS, { variables: { orderBy: 'name', order: 1 } });

	const {
		data: { auditingCompanies } = { auditingCompanies: [] },
		loading: auditingCompaniesLoading,
		error: auditingCompaniesError,
	} = useQuery(PAGINATE_AUDITING_COMPANIES);

	const [
		inviteOneUser,
		{ loading: inviteOneUserLoading, error: inviteOneUserError },
	] = useMutation(INVITE_ONE_USER, {
		refetchQueries: ['findOneUser'],
	});

	const [
		deleteOneUser,
		{ loading: deleteOneUserLoading, error: deleteOneUserError },
	] = useMutation(DELETE_ONE_USER);

	const [
		resetFailedLoginAttempts,
		{
			loading: resetFailedLoginAttemptsLoading,
			error: resetFailedLoginAttemptsError,
		},
	] = useMutation(RESET_FAILED_LOGIN_ATTEMPTS, {
		refetchQueries: ['findOneUser'],
	});

	const [
		sendContactInfoUpdateNotification,
		{
			loading: sendContactInfoUpdateNotificationLoading,
			error: sendContactInfoUpdateNotificationError,
		},
	] = useMutation(SEND_USER_CONTACT_INFO_UPDATE_NOTIFICATION, {
		refetchQueries: ['findOneUser'],
	});

	useEffect(() => {
		if (!user?.role || userLoading) return;

		setIsPEP(user.isPoliticallyExposedPerson);
		setIsUserAuditor(user.role === 'auditor');
	}, [user?.role, user?.isPoliticallyExposedPerson]);

	useEffect(() => {
		if (currentUser?._id === user?._id) {
			setLastVerifiedEmail(user?.email);
			setLastVerifiedPhone(user?.phone);
		}
	}, [currentUser?._id && user?._id]);

	if (!user) {
		return (
			<NotFound
				label="Bruker ikke funnet"
				title="Denne brukeren ble ikke funnet!"
			/>
		);
	}

	const userInvite =
		(user.userVerifications &&
			user.userVerifications.find(
				verification => verification.type == 'invite'
			)) ||
		null;

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Brukere',
					to: '/brukere/',
				},
				{
					label: user.name || 'Laster…',
				},
			]}
			title={user?.name ? `Bruker: ${user.name}` : 'Bruker'}
		>
			<Content>
				<ErrorAlert error={userError} />
				<ErrorAlert error={deleteOneUserError} />
				<ErrorAlert error={updateOneUserError} />
				<ErrorAlert error={resetFailedLoginAttemptsError} />
				<ErrorAlert error={sendContactInfoUpdateNotificationError} />
				<ErrorAlert error={inviteOneUserError} />

				{!auditingCompaniesLoading && isUserAuditor && (
					<ErrorAlert error={auditingCompaniesError} />
				)}

				<Form
					values={{ ...user, ...user.address }}
					key={user._id}
					isLoading={
						userLoading ||
						updateOneUserLoading ||
						companiesLoading ||
						auditingCompaniesLoading ||
						inviteOneUserLoading
					}
					onSubmit={async values => {
						try {
							const verifyFields = [];
							if (
								lastVerifiedEmail &&
								values.email !== lastVerifiedEmail
							) {
								verifyFields.push('e-post');
							}
							if (
								lastVerifiedPhone &&
								values.phone !== lastVerifiedPhone
							) {
								verifyFields.push('telefon');
							}

							if (verifyFields.length > 0) {
								const verified = await verify({
									title: 'Bekreft endringer',
									text: `Du har endret felt som trenger verifisering: ${verifyFields.join(
										', '
									)}. Er du sikker på at du vil lagre og gjennomføre verifiseringen?`,
								});

								if (!verified) {
									notify(
										'Endringer ble ikke lagret.',
										'warning'
									);
									return;
								}
							}

							if (values.userRoles) {
								const userRolesValue = values.userRoles.map(
									r => ({ id: r.id, role: r.role })
								);

								values.userRoles = userRolesValue;
							}

							if (values.role !== 'auditor') {
								delete values.auditingCompanyId;
							}

							values.address = {
								street_address:
									values.street_address || undefined,
								postal_code: values.postal_code || undefined,
								locality: values.locality || undefined,
								country: values.country || undefined,
							};

							values.isPoliticallyExposedPerson =
								values.isPoliticallyExposedPerson ?? false;

							values.userPoliticallyExposedPersonRoles =
								Array.isArray(
									values.userPoliticallyExposedPersonRoles
								)
									? values.userPoliticallyExposedPersonRoles
									: undefined;

							if (!values.isPoliticallyExposedPerson) {
								values.userPoliticallyExposedPersonRoles = [];
							}

							if (
								values.isPoliticallyExposedPerson &&
								values.userPoliticallyExposedPersonRoles
									?.length === 0
							) {
								notify(
									'Vennligst legg til roller for politisk eksponert person',
									'warning'
								);
								return;
							}

							const { data } = await updateOneUser({
								variables: {
									_id: userId,
									...values,
								},
							});

							if (data.user) {
								notify('Brukeren ble oppdatert!');
							}
						} catch (err) {
							console.error(err);
						}
					}}
				>
					<FormGrid>
						<FormGridCol>
							<SelectField
								label="Brukertype"
								name="role"
								options={roles.map(r => ({
									value: r._id,
									label: r.name,
								}))}
								onChange={option => {
									setIsUserAuditor(
										option.value === 'auditor'
									);
								}}
								disabled={user.role === 'silent'}
							/>

							<TextField
								name="name"
								type="text"
								label="Navn"
								required
							/>

							<TextField
								name="position"
								type="text"
								label="Stilling"
							/>

							<TextField
								name="email"
								type="email"
								label="E-post"
							/>

							<TextField
								name="phone"
								type="tel"
								label="Telefon"
							/>

							{!auditingCompaniesLoading && isUserAuditor && (
								<AutocompleteField
									label="Revisorfirma"
									name="auditingCompanyId"
									options={auditingCompanies.items.map(
										auditingCompany => ({
											value: auditingCompany._id,
											label: auditingCompany.name,
										})
									)}
								/>
							)}

							<TextField
								name="socialno"
								type="number"
								min="10000000000"
								max="31129999999"
								label="Personnummer"
							/>

							<TextField
								name="dNumber"
								type="number"
								min="10000000000"
								max="31129999999"
								label="D-nummer"
							/>
						</FormGridCol>

						<FormGridCol>
							<TextField
								name="street_address"
								label="Gateadresse"
							/>

							<Row>
								<TextField
									name="postal_code"
									label="Postnummer"
									fullWidth
								/>

								<TextField
									name="locality"
									label="Sted"
									fullWidth
								/>
							</Row>

							<AutocompleteField
								label="Bosatt i land"
								name="country"
								options={countries.map(country => ({
									label: country,
									value: country,
								}))}
							/>

							<AutocompleteField
								label="Statsborger i land"
								name="citizenship"
								options={countries.map(country => ({
									label: country,
									value: country,
								}))}
							/>

							<SwitchField
								name="isPoliticallyExposedPerson"
								checked={isPEP}
								label={`Ja, ${user.name} er en politisk eksponert person`}
								onChange={e => {
									setIsPEP(e);
								}}
							/>

							{isPEP && (
								<DynamicPEPFields
									name="userPoliticallyExposedPersonRoles"
									existingRelations={
										user.userPoliticallyExposedPersonRoles ||
										[]
									}
								/>
							)}

							{user._id && <ProfileImage userId={user._id} />}

							<IdentityVerification
								userId={user._id}
								fileName={user.identificationFile}
								onCompleted={userRefetch}
							/>
						</FormGridCol>
					</FormGrid>

					<Grid>
						<TenantRoles
							title="Regnskapsfører"
							tenants={user.accountantTenants}
						/>

						<TenantRoles
							title="Kundenasvarlig"
							tenants={user.accountManagerTenants}
						/>

						<TenantRoles
							title="Revisor"
							tenants={user.auditorTenants}
						/>

						<TenantRoles
							title="Oppdragskontrollør"
							tenants={user.controllerTenants}
						/>

						<TenantRoles
							title="Reell rettighesthaver"
							tenants={user.ownerTenants}
							showOwnerShare={true}
							userId={user._id}
						/>

						{!userLoading &&
							!updateOneUserLoading &&
							!companiesLoading && (
								<DualFields
									title="Bedriftsroller"
									name="userRoles"
									leftSideLabel="Bedrift"
									leftSideOptions={companies?.map(
										company => ({
											value: company._id,
											label: company.name,
											action: () =>
												navigate(
													`/bedrifter/${company._id}/rediger`
												),
										})
									)}
									userRoles={user.userRoles}
									onChange={async updatedUserRoles => {
										await updateOneUser({
											variables: {
												_id: userId,
												userRoles: updatedUserRoles.map(
													ur => {
														return {
															id: ur.id,
															role: ur.role,
														};
													}
												),
											},
										});
									}}
								/>
							)}
					</Grid>

					<div>
						<h3>Innstillinger</h3>

						<Actions>
							{currentUser._id === user._id && (
								<Button
									variant="outlined"
									color="primary"
									size="large"
									type="button"
									onClick={() => {
										setShowNewPasswordModal(true);
									}}
								>
									Endre passord
								</Button>
							)}

							{currentUser?.role === 'admin' && (
								<Button
									variant="outlined"
									color="primary"
									size="large"
									type="button"
									startIcon={<LockResetIcon />}
									disabled={resetFailedLoginAttemptsLoading}
									onClick={async () => {
										const { data } =
											await resetFailedLoginAttempts({
												variables: {
													_id: user._id,
												},
											});

										if (data.resetFailedLoginAttempts) {
											notify(
												'For mange påloggingsforsøk ble nullstilt'
											);
										}
									}}
								>
									Nullstill for mange pålogginsforsøk
								</Button>
							)}

							<Button
								variant="contained"
								size="large"
								to="tilganger"
								component={Link}
								startIcon={<FolderOutlinedIcon />}
							>
								Mappetilganger
							</Button>
						</Actions>
					</div>

					{user.role !== 'silent' && (
						<Invite>
							<Button
								variant="outlined"
								color="primary"
								size="large"
								type="button"
								startIcon={<BadgeOutlinedIcon />}
								disabled={
									sendContactInfoUpdateNotificationLoading
								}
								onClick={async () => {
									try {
										if (
											!(await verify({
												title: 'Oppdatering av brukerinformasjon',
												text: 'Vil du sende en notifikasjon til brukeren om å oppdatere kontaktinformasjonen sin?',
											}))
										) {
											return;
										}

										const { data } =
											await sendContactInfoUpdateNotification(
												{
													variables: {
														userId: user._id,
													},
												}
											);

										if (data.sent) {
											notify(
												'Notifikasjon sendt til bruker'
											);
										}
									} catch (err) {
										console.error(err);
									}
								}}
							>
								Be om oppdatering av brukerinfo
							</Button>

							<div>
								{user.forceUserVerificationDate && (
									<div>
										<strong>Siste notifikasjon: </strong>

										{formatDate(
											user.forceUserVerificationDate,
											'DD.MM.YYYY [kl] HH:mm'
										)}
									</div>
								)}

								{user.userLastVerificationDate && (
									<div>
										<strong>Sist verifisert: </strong>

										{formatDate(
											user.userLastVerificationDate,
											'DD.MM.YYYY [kl] HH:mm'
										)}
									</div>
								)}
							</div>
						</Invite>
					)}

					{!user.hasLoggedIn && user.email && (
						<Invite>
							<Button
								variant="outlined"
								size="large"
								disabled={inviteOneUserLoading || inviteSent}
								onClick={async () => {
									try {
										const { data } = await inviteOneUser({
											variables: {
												_id: user._id,
											},
										});

										if (data.inviteOneUser) {
											setInviteSent(true);

											notify('Bruker ble invitert');
										}
									} catch (err) {
										console.error(err);
									}
								}}
								startIcon={<SendIcon />}
							>
								{inviteOneUserLoading
									? 'Inviterer…'
									: 'Inviter bruker'}
							</Button>

							{userInvite && userInvite.lastSent && (
								<div>
									<strong>Sist invitert: </strong>

									{formatDate(
										userInvite.lastSent,
										'DD.MM.YYYY [kl] HH:mm'
									)}
								</div>
							)}
						</Invite>
					)}

					<SaveInfo>
						<CreatedInfo
							createdAt={user?.createdAt}
							createdBy={user?.createdByDisplayName}
						/>

						<UpdatedInfo
							updatedAt={user?.updatedAt}
							updatedBy={user?.updatedByDisplayName}
						/>
					</SaveInfo>

					<StickyAction>
						{currentUser?.role === 'admin' &&
							currentUser?._id !== user?._id && (
								<DeleteButton
									disabled={deleteOneUserLoading}
									onClick={async () => {
										const { data } = await deleteOneUser({
											variables: { _id: user._id },
										});

										if (!data.deleted) {
											throw new Error(
												'Det oppstod en feil ved sletting!'
											);
										}
									}}
									redirect="/brukere/"
								/>
							)}

						<SubmitButton
							variant="contained"
							size="large"
							icon={<SaveIcon />}
						>
							Oppdater bruker
						</SubmitButton>
					</StickyAction>
				</Form>
			</Content>

			<NewPasswordDialog
				userId={user._id}
				open={showNewPasswordModal}
				onClose={() => setShowNewPasswordModal(false)}
			/>
		</DesktopLayout>
	);
}
