import { useState } from 'react';

import DocumentsDialog from 'web/components/widgets/dialogs/DocumentsDialog';

import {
	Wrapper,
	Document,
	StyledIcon,
	StyledButton,
	SubTitle,
	NoDocumentsMessage,
} from 'web/components/widgets/components/lists/common/ListComponents';

export default function DocumentsList({ documents }) {
	const [activeDocuments, setActiveDocuments] = useState(null);

	return (
		<Wrapper>
			{documents.length > 0 ? (
				<div>
					{documents.map(doc => (
						<Document key={doc._id}>
							<StyledIcon icon="file-signature" />

							<div>
								<StyledButton
									onClick={() => setActiveDocuments([doc])}
								>
									{doc.name}
								</StyledButton>

								<SubTitle>{doc.tenant.name}</SubTitle>
							</div>
						</Document>
					))}
				</div>
			) : (
				<NoDocumentsMessage>
					Ingen aktive signaturerforespørsler
				</NoDocumentsMessage>
			)}

			<DocumentsDialog
				documents={activeDocuments}
				open={!!activeDocuments}
				onClose={() => setActiveDocuments(null)}
			/>
		</Wrapper>
	);
}
