import { Controller, useFormContext } from 'react-hook-form';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MUICheckbox from '@mui/material/Checkbox';

export default function Checkbox({
	name,
	label,
	defaultChecked = false,
	...rest
}) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultChecked}
			render={({ field: { onChange, value } }) => (
				<FormGroup>
					<FormControlLabel
						control={
							<MUICheckbox
								checked={!!value}
								onChange={event =>
									onChange(event.target.checked)
								}
								{...rest}
							/>
						}
						label={label}
					/>
				</FormGroup>
			)}
		/>
	);
}
