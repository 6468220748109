import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';
import ErrorAlert from 'components/ErrorAlert';

import ProffDataDeviationsPanel from 'web/components/panels/ProffDataDeviationsPanel';
import ProffDataPanel from 'web/components/panels/ProffDataPanel';

import { UPDATE_COMPANY_PROFFDATA } from 'api/mutations/tenantMutations';

const StyledButtonWrapper = styled.div`
	margin-top: 32px;
	text-align: center;
`;

export default function ProffTab({
	companyId,
	company,
	companyLoading,
	setCreateUserModal,
	refetch,
}) {
	const { notify } = useSnackbar();

	const [updateProffData, { error: proffDataError }] = useMutation(
		UPDATE_COMPANY_PROFFDATA,
		{
			onCompleted: () => {
				notify('Data fra proff.no ble oppdatert');
			},
		}
	);

	async function handleUpdateProffData() {
		try {
			await updateProffData({
				variables: {
					_id: companyId,
				},
			});

			refetch();
		} catch (err) {
			console.warn(err);
		}
	}

	return (
		<>
			{!companyLoading && !company?.orgNo && (
				<ErrorMessage
					title="Bedriften mangler organisasjonsnummer!"
					errors={
						'Du må legge inn dette før du kan hente data fra proff.no.'
					}
				/>
			)}

			{company && company.orgNo && (
				<ProffDataDeviationsPanel
					companyId={company._id}
					deviations={company.dataDeviations.proffDeviations}
					deviationExceptions={company.proffDataDeviationExceptions}
					setCreateUserModal={setCreateUserModal}
				/>
			)}

			<ErrorAlert error={proffDataError} />

			{company?.proffData && <ProffDataPanel data={company.proffData} />}

			{company?.orgNo && (
				<StyledButtonWrapper>
					<Button
						startIcon={<CloudDownloadIcon />}
						size="large"
						variant="contained"
						color="primary"
						onClick={async e => {
							e.preventDefault();
							e.stopPropagation();
							handleUpdateProffData();
						}}
					>
						{company.proffData
							? 'Oppdater data fra proff.no'
							: 'Hent data fra proff.no'}
					</Button>
				</StyledButtonWrapper>
			)}
		</>
	);
}
