import { useMutation } from '@apollo/client';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import ModalLayout from 'components/modals/ModalLayout';
import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import DateField from 'components/form/DateField';
import SubmitButton from 'components/form/SubmitButton';

import { INSERT_ONE_COMPANY_RISK_ACTION } from 'api/mutations/companyRiskActionMutations';

export default function CompanyRiskCreateActionModal({ questionId, onClose }) {
	const [insertAction, { loading, error }] = useMutation(
		INSERT_ONE_COMPANY_RISK_ACTION,
		{ refetchQueries: ['findCompanyRiskActions'] }
	);

	return (
		<ModalLayout title="Legg til tiltak" onClose={onClose} width="800px">
			<ErrorMessage errors={error} />

			<Form
				isLoading={loading}
				isDisabled={loading}
				onSubmit={async ({ description, dueDate }) => {
					try {
						const { data } = await insertAction({
							variables: {
								questionId,
								description,
								dueDate,
							},
						});

						if (data) {
							onClose();
						}
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<TextField
					name="description"
					type="text"
					label="Tiltak"
					maxRows="5"
					multiline={true}
					required
				/>

				<DateField
					name="dueDate"
					label="Frist"
					defaultValue={new Date()}
					required
				/>

				<SubmitButton variant="contained" icon={<AccessTimeIcon />}>
					Legg til
				</SubmitButton>
			</Form>
		</ModalLayout>
	);
}
