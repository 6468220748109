import styled from 'styled-components';

import Icon from 'components/Icon';

const StyledIcon = styled(Icon)`
	margin-left: 10px;
	vertical-align: middle;
`;

export default function SortIcon({ field, orderBy, order }) {
	if (field !== orderBy) return null;

	return (
		<StyledIcon
			icon={order === 1 ? 'sort-amount-up' : 'sort-amount-down'}
		/>
	);
}
