import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import UnsignedDocumentsList from 'web/components/widgets/components/UnsignedDocumentsList';

export default function DocumentsDialog({ documents, open, onClose }) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="sm"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Dokumenter som venter på signering
			</DialogTitle>

			<DialogContent>
				{documents && (
					<UnsignedDocumentsList
						documents={documents}
						showSignedSignees={true}
						w
					/>
				)}
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose} variant="cotained">
					Lukk
				</Button>
			</DialogActions>
		</Dialog>
	);
}
