import { useQuery } from '@apollo/client';

import useSortOrder from 'hooks/useSortOrder';
import { useUser } from 'web/contexts/UserContext';
import { useAppFilters } from 'web/contexts/AppFiltersContext';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';

import SearchFilter from 'components/filters/SearchFilter';

import DesktopLayout, {
	ArchiveHeader,
	ArchiveHeaderRow,
	Content,
} from 'web/components/Layout';
import Table, { TableRow, TableCell } from 'web/components/Table';
import Link from 'web/components/Link';
import SortIcon from 'web/components/SortIcon';
import CreateFabButton from 'web/components/CreateFabButton';

import { PAGINATE_AUDITING_COMPANIES } from 'api/queries/auditingCompanyQueries';

export default function AuditingCompaniesArchive() {
	const { userHasMinRole } = useUser();
	const { search } = useAppFilters();

	const [{ orderBy, order }, setNewOrder] = useSortOrder('name');

	const {
		data: { auditingCompanies = { count: 0, items: [] } } = {
			auditingCompanies: { count: 0, items: [] },
		},
		loading,
		error,
	} = useQuery(PAGINATE_AUDITING_COMPANIES, {
		variables: {
			order,
			orderBy,
			search,
		},
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		notifyOnNetworkStatusChange: true,
	});

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Revisorfirma',
				},
			]}
		>
			<Content>
				<ErrorMessage errors={error} />

				<ArchiveHeader>
					<ArchiveHeaderRow>
						<SearchFilter label="Søk etter revisorfirma" />
					</ArchiveHeaderRow>
				</ArchiveHeader>

				<Table columns="8">
					<TableRow header>
						<TableCell
							start="1"
							end="8"
							onClick={() => setNewOrder('name')}
						>
							<span>Navn</span>

							<SortIcon
								field="name"
								orderBy={orderBy}
								order={order}
							/>
						</TableCell>

						<TableCell>Revisorer</TableCell>
					</TableRow>

					{!loading && auditingCompanies?.items?.length === 0 && (
						<TableRow transparent>
							<TableCell start="1" end="-1" center>
								Ingen revisorfirma ble funnet
							</TableCell>
						</TableRow>
					)}

					{!error &&
						auditingCompanies?.items.map(auditingCompany => (
							<Link
								to={`/revisorfirma/${auditingCompany._id}/rediger/`}
								key={auditingCompany._id}
							>
								<TableRow hover>
									<TableCell start="1" end="8">
										{auditingCompany.name}
									</TableCell>

									<TableCell>
										{auditingCompany?.auditors?.length ?? 0}
									</TableCell>
								</TableRow>
							</Link>
						))}

					{loading && (
						<TableRow transparent>
							<TableCell start="1" end="-1">
								<InlineLoading />
							</TableCell>
						</TableRow>
					)}

					{!error &&
						!loading &&
						auditingCompanies?.items?.length !== 0 && (
							<TableRow footer>
								<TableCell start="1" end="-1" right>
									Viser {auditingCompanies.items.length} av{' '}
									{auditingCompanies.count} revisorfirma
								</TableCell>
							</TableRow>
						)}
				</Table>
			</Content>

			{userHasMinRole('accountant') && <CreateFabButton to="ny/" />}
		</DesktopLayout>
	);
}
