import { useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';

import ErrorAlert from 'components/ErrorAlert';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import CheckboxField from 'components/form/CheckboxField';
import SubmitButton from 'components/form/SubmitButton';

import DesktopLayout, { NarrowContent } from 'web/components/Layout';

import { GET_COMPANY_DOCUMENTS } from 'api/queries/documentQueries';
import { RENAME_ONE_DOCUMENT_NODE } from 'api/mutations/documentMutations';

export default function DocumentEdit({ companyId }) {
	const { documentNodeId } = useParams();
	const { verify } = useConfirmDialog();
	const navigate = useNavigate();

	const {
		data: { documentNode, parentDocumentNodes = [] } = {},
		loading: documentNodeLoading,
		error: documentNodeError,
	} = useQuery(GET_COMPANY_DOCUMENTS, {
		variables: {
			tenantId: companyId,
			parentId: documentNodeId,
		},
	});
	const [
		renameDocumentNode,
		{ loading: renameDocumentNodeLoading, error: renameDocumentNodeError },
	] = useMutation(RENAME_ONE_DOCUMENT_NODE, {
		refetchQueries: ['paginateDocumentNodes'],
	});

	const parentList = parentDocumentNodes.map(parent => ({
		to: `/dokumenter/${parent?._id}/`,
		label: parent?.name || '...',
	}));

	return (
		<DesktopLayout
			loading={documentNodeLoading}
			breadcrumbs={[
				{
					to: '/dokumenter/',
					label: 'Dokumenter',
				},
				...parentList,
				{
					to: `/dokumenter/${documentNodeId}`,
					label: documentNode?.name || '...',
				},
				{
					label: 'Rediger',
				},
			]}
		>
			<NarrowContent>
				{!documentNodeLoading && documentNode && (
					<Form
						isLoading={renameDocumentNodeLoading}
						isDisabled={renameDocumentNodeLoading}
						values={{
							name: documentNode.name,
							manuallySigned:
								documentNode.manuallySigned ||
								documentNode.isSigningComplete,
						}}
						onSubmit={async ({ name, manuallySigned }) => {
							try {
								if (
									!documentNode.manuallySigned &&
									!documentNode.isSigningComplete &&
									manuallySigned
								) {
									if (
										!(await verify({
											title: 'Bekreft signering',
											text: "Er du sikker på at du ønsker å endre dokumentets status til 'Manuelt signert'? Eventuelle aktive signaturforespørsler vil bli kansellert.",
										}))
									) {
										return;
									}
								}

								const { data } = await renameDocumentNode({
									variables: {
										_id: documentNodeId,
										name,
										manuallySigned,
									},
								});

								if (data?.file?._id) {
									navigate('../');
								}
							} catch (error) {
								console.error(error);
							}
						}}
					>
						<ErrorAlert error={documentNodeError} />
						<ErrorAlert error={renameDocumentNodeError} />

						<TextField required name="name" label="Navngi filen" />

						<CheckboxField
							name="manuallySigned"
							label="Er dette dokumentet allerede signert?"
							disabled={
								documentNode.manuallySigned ||
								documentNode.isSigningComplete
							}
						/>

						<SubmitButton
							variant="contained"
							size="large"
							icon={<SaveIcon />}
						>
							Oppdater
						</SubmitButton>
					</Form>
				)}
			</NarrowContent>
		</DesktopLayout>
	);
}
