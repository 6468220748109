import { Fragment } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { useUser } from 'web/contexts/UserContext';

import { InlineLoading } from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import { UnderlineLink } from 'web/components/Link';

import { FIND_HELP_PAGE } from 'api/queries/helpPageQueries';
import { GET_FILES } from 'api/queries/fileQueries';

export const Section = styled.div`
	font-size: 1.2rem;
	line-height: 1.5rem;
	margin-bottom: 40px;
`;

const TitleWrapper = styled.div`
	margin: 0 0 24px 0;
	display: flex;
	align-items: center;
	gap: 20px;
`;

export const Title = styled.h1`
	margin: 0;
`;

const EditLink = styled(UnderlineLink)`
	font-size: 0.9em;
`;

const NoDataMessage = styled.div`
	color: ${p => p.theme.palette.status.error};
	font-style: italic;
	padding: 20px 0;
`;

export default function HelpSingle() {
	const { helpPageId } = useParams();
	const { userHasMinRole } = useUser();

	const {
		data: { helpPage } = { helpPage: null },
		error,
		loading,
	} = useQuery(FIND_HELP_PAGE, {
		variables: {
			_id: helpPageId,
		},
	});

	if (loading) {
		return <InlineLoading />;
	}

	return (
		<div>
			<ErrorMessage errors={[error]} />

			<TitleWrapper>
				<Title>{helpPage?.name}</Title>

				{userHasMinRole('admin') && (
					<EditLink to={`/hjelpesider/${helpPageId}/rediger/`}>
						(rediger)
					</EditLink>
				)}
			</TitleWrapper>

			<HelpPageSections sections={helpPage?.sections} />
		</div>
	);
}

function HelpPageSections({ sections }) {
	if (!sections || sections.length === 0) {
		return (
			<NoDataMessage>
				Det er ikke opprett noen avsnitt for denne hjelpesiden!
			</NoDataMessage>
		);
	}

	return (
		<>
			{sections.map(section => (
				<HelpPageAccordionSection
					key={section._id}
					section={section}
					defaultExpanded={sections.length === 1}
				/>
			))}
		</>
	);
}

function HelpPageAccordionSection({ section, defaultExpanded = false }) {
	return (
		<Accordion defaultExpanded={defaultExpanded}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel-content"
				id="panel-header"
			>
				<Typography>{section.title}</Typography>
			</AccordionSummary>

			<AccordionDetails>
				<HelpPageSectionFileList sectionId={section._id} />
			</AccordionDetails>
		</Accordion>
	);
}

function HelpPageSectionFileList({ sectionId }) {
	const { data: { files = [] } = { files: [] }, error: filesError } =
		useQuery(GET_FILES, { variables: { docId: sectionId } });

	return (
		<List>
			<ErrorMessage errors={[filesError]} />

			<HelpPageSectionFiles files={files} />
		</List>
	);
}

function HelpPageSectionFiles({ files }) {
	if (!files || files.length === 0) {
		return (
			<NoDataMessage>
				Det har ikke blitt lastet opp noen filer til dette avsnittet!
			</NoDataMessage>
		);
	}
	return (
		<>
			{files &&
				files.map(file => (
					<Fragment key={file._id}>
						<ListItem dense={true} divider={true}>
							<ListItemButton
								component="a"
								href={file.url}
								title={`Last ned ${file.fileName}`}
								target="_blank"
								rel="noreferrer"
							>
								<ListItemIcon>
									<AttachFileIcon fontSize="small" />
								</ListItemIcon>

								<ListItemText primary={file.fileName} />
							</ListItemButton>
						</ListItem>
					</Fragment>
				))}
		</>
	);
}
