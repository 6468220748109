import TokenVerifyModal from 'web/components/verifications/TokenVerifyModal';

export default function PhoneVerifyModal({ _id, verificationPhone }) {
	return (
		<TokenVerifyModal
			type="phone"
			userId={_id}
			verificationValue={verificationPhone}
			title="Du må verifisere telefonnummeret ditt"
			firstStepContent="For å kunne motta meldinger fra oss, må du registrere et mobilnummer du har tilgang til. Sjekk at nummeret stemmer, så sender vi en verifiseringskode til det."
			lastStepContent="Fyll ut verifiseringskoden du har fått tilsendt på SMS."
			changeValueText="Endre telefonnummer"
		/>
	);
}
