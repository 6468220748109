import { createContext, useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const StyledButton = styled.button`
	background: none;
	border: none;
	outline: none;
	padding: 0;
	margin: 0;
`;

const AnimationHolder = styled.div`
	.from-right-enter {
		transform: translateX(100%);
	}

	.from-right-enter.from-right-enter-active {
		transform: translateX(0);
		transition: all 250ms cubic-bezier(0, 0, 0.2, 1);
	}

	.from-right-exit {
		transform: translateX(0);
	}

	.from-right-exit-active {
		transform: translateX(100%);
		transition: all 250ms cubic-bezier(0.4, 0, 1, 1);
	}

	.from-left-enter {
		transform: translateX(-100%);
	}

	.from-left-enter.from-left-enter-active {
		transform: translateX(0);
		transition: all 250ms cubic-bezier(0, 0, 0.2, 1);
	}

	.from-left-exit {
		transform: translateX(0);
	}

	.from-left-exit-active {
		transform: translateX(-100%);
		transition: all 250ms cubic-bezier(0.4, 0, 1, 1);
	}

	.fade-enter {
		opacity: 0;
	}

	.fade-enter.fade-enter-active {
		opacity: 1;
		transition: all 250ms cubic-bezier(0, 0, 0.2, 1);
	}

	.fade-exit {
		opacity: 1;
	}

	.fade-exit-active {
		opacity: 0;
		transition: all 250ms cubic-bezier(0.4, 0, 1, 1);
	}
`;

const NavigatorContext = createContext({
	currentScreen: {},
	history: {},
	_stack: [],
	_screens: {},
});

export function useNavigation() {
	const context = useContext(NavigatorContext);

	if (context === undefined) {
		throw new Error('useNavigation must be used within a NavigatorContext');
	}

	return context;
}

export function Link({
	to,
	pop,
	replace,
	onTopOfRoot,
	reset,
	className,
	...props
}) {
	const { history, defaultPush, currentScreen } = useNavigation();
	let active = to && to.screen === currentScreen.screen;

	if (reset) {
		active = currentScreen.screen === defaultPush.screen;
	}

	return (
		<StyledButton
			type="button"
			className={classnames(active && 'self--active', className)}
			onClick={() => {
				if (pop) {
					return history.pop();
				}

				if (reset) {
					return history.reset(to);
				}

				if (!to) {
					console.warn('Missing to or pop in link');

					return;
				}

				if (onTopOfRoot) {
					return history.set([defaultPush, to]);
				}

				!replace && history.push(to);
				replace && history.replace(to);
			}}
			{...props}
		/>
	);
}

function layerToElement(
	{ screen, ...props },
	{ screens, globalData, context, index }
) {
	const Layer = screens[screen];
	const { transition, currentScreen, defaultTransitionTimeout } = context;

	return (
		<CSSTransition
			key={screen + '-' + index}
			classNames={transition}
			timeout={{
				enter:
					currentScreen.transitionTimeout || defaultTransitionTimeout,
				exit:
					currentScreen.transitionTimeout || defaultTransitionTimeout,
			}}
		>
			<Layer
				{...props}
				globalData={globalData}
				history={context.history}
			/>
		</CSSTransition>
	);
}

export function NavigatorProvider({ children }) {
	const [stack, setStack] = useState([]);
	const [defaultPush, setDefaultPush] = useState({});
	const [onPopLast, setOnPopLast] = useState(null);
	const [screens, setScreens] = useState({});
	const [transition, setTransition] = useState(
		defaultPush.transition || 'from-right'
	);
	const currentScreen = stack[stack.length - 1] || {};
	const defaultTransitionTimeout = 300;

	const context = {
		stack,
		currentScreen,
		defaultPush,
		transition,
		defaultTransitionTimeout,
		screens,
		init: ({ screens, defaultPush, onPopLast }) => {
			setScreens(screens);
			setDefaultPush(defaultPush);
			setOnPopLast(onPopLast);
			setStack([defaultPush]);
			setTransition(defaultPush.transition || 'from-right');
		},
		history: {
			push: layer => {
				stack.push(layer);
				history.pushState({}, '', '');

				setTransition(layer.transition || 'from-right');
				setStack([...stack]);
			},
			pop: () => {
				if (stack.length === 1) {
					// TODO: Add intent back navigation. Or other handle
					if (!onPopLast) {
						return console.warn('Stack navigaion out of bounds.');
					}

					return onPopLast();
				}
				setTransition(
					stack[stack.length - 1]?.transition || 'from-right'
				);

				stack.pop();

				setStack([...stack]);
			},
			set: layers => {
				setTransition(
					layers[layers.length - 1]?.transition || 'from-right'
				);
				setStack(layers);

				history.pushState({}, '', '');
			},
			replace: layer => {
				stack.pop();
				stack.push(layer);

				setTransition(
					stack[stack.length - 1]?.transition || 'from-right'
				);
				setStack([...stack]);
				history.pushState({}, '', '');
			},
			reset: layer => {
				setTransition(
					(layer || defaultPush).transition || 'from-right'
				);
				setStack([layer || defaultPush]);
			},
		},
	};

	window.onpopstate = e => {
		e.preventDefault();

		context.history.pop();
	};

	return (
		<NavigatorContext.Provider value={context}>
			{children}
		</NavigatorContext.Provider>
	);
}

export default function Navigator({
	screens,
	globalData,
	defaultPush,
	onPopLast,
}) {
	const context = useNavigation();
	const { stack, screens: registeredScreens, init } = context;

	useEffect(() => {
		init({ screens, defaultPush, onPopLast });
	}, []);

	return (
		<AnimationHolder>
			<TransitionGroup>
				{stack.map((layer, index) =>
					layerToElement(layer, {
						screens: registeredScreens,
						globalData,
						context,
						index,
					})
				)}
			</TransitionGroup>
		</AnimationHolder>
	);
}
