import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatePicker({ name, label }) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={new Date()}
			render={({ field: { onChange, value } }) => (
				<MUIDatePicker
					label={label}
					control={control}
					format="DD/MM/YYYY"
					value={dayjs(value)}
					onChange={date => {
						if (!date || !date.isValid()) return;
						// TODO: Quick fix to handle time offset wrong on live server.
						const newDate = date
							.set('hour', 12)
							.set('minute', 0)
							.set('second', 0)
							.toDate();
						onChange(newDate);
					}}
					slotProps={{ textField: { variant: 'outlined' } }}
				/>
			)}
		/>
	);
}
