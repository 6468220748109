import { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';

import ErrorMessage from 'components/ErrorMessage';

import Layout from 'app/components/Layout';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';

import {
	UPDATE_ONE_USER_INPUT_WITH_VERIFICATION,
	VERIFY_ONE_USER_INPUT_WITH_TOKEN,
} from 'api/mutations/userMutations';

const Title = styled.h3`
	margin: 0 0 20px 0;
`;

const Content = styled.div`
	margin-bottom: 20px;
`;

const Text = styled.div`
	color: ${p => p.theme.palette.text.secondary};
	margin-bottom: 20px;
`;

const ButtonsWrapper = styled.div`
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 15px;
`;

export default function UserVerifyToken({
	type,
	userId,
	verificationValue,
	pageTitle,
	title,
	firstStepContent,
	lastStepContent,
	changeValueText,
}) {
	const [errors, setErrors] = useState(null);
	const [inputHasChanged, setInputHasChanged] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);

	const [updateUserInputWithVerification] = useMutation(
		UPDATE_ONE_USER_INPUT_WITH_VERIFICATION,
		{
			onError: error => setErrors(error),
			refetchQueries: ['currentUser'],
			errorPolicy: 'all',
		}
	);

	const [verifyUserInputWithToken] = useMutation(
		VERIFY_ONE_USER_INPUT_WITH_TOKEN,
		{
			onError: error => setErrors(error),
			refetchQueries: ['currentUser'],
			errorPolicy: 'all',
		}
	);

	return (
		<Layout pageTitle={pageTitle}>
			<Title>{title}</Title>

			<ErrorMessage errors={errors} />

			{currentStep === 0 && (
				<Form
					onSubmit={async ({ input }) => {
						if (!input) {
							setErrors(['Vennligst skriv inn en verdi!']);
							return;
						}

						setErrors(null);

						try {
							await updateUserInputWithVerification({
								variables: {
									_id: userId,
									field: type,
									value: input,
								},
							});

							setCurrentStep(1);
						} catch (err) {
							console.warn(err);
						}
					}}
				>
					<Content>
						<Text>{firstStepContent}</Text>

						<TextField
							name="input"
							type={type}
							required={true}
							defaultValue={verificationValue}
							fullWidth
							onChange={e => {
								setInputHasChanged(
									e.target.value !== verificationValue
								);
							}}
						/>
					</Content>

					<ButtonsWrapper>
						<Button
							type="button"
							color="primary"
							variant="outlined"
							onClick={() => {
								setErrors(null);
								setCurrentStep(1);
							}}
						>
							Jeg har allerede en kode
						</Button>

						<Button
							type="submit"
							variant="contained"
							color="primary"
						>
							{inputHasChanged
								? 'Oppdater og send kode'
								: 'Send kode'}
						</Button>
					</ButtonsWrapper>
				</Form>
			)}

			{currentStep === 1 && (
				<Form
					onSubmit={async ({ token }) => {
						if (!token) {
							setErrors(['Vennligst skriv inn en kode!']);
							return;
						}

						setErrors(null);

						try {
							await verifyUserInputWithToken({
								variables: {
									_id: userId,
									type,
									value: verificationValue,
									token,
								},
							});
						} catch (err) {
							console.warn(err);
						}
					}}
				>
					<Content>
						<Text>{lastStepContent}</Text>

						<TextField name="token" required={true} fullWidth />
					</Content>

					<ButtonsWrapper>
						<Button
							type="button"
							color="primary"
							variant="outlined"
							onClick={() => {
								setErrors(null);
								setCurrentStep(0);
							}}
						>
							{changeValueText}
						</Button>

						<Button
							type="submit"
							variant="contained"
							color="primary"
						>
							Registrer kode
						</Button>
					</ButtonsWrapper>
				</Form>
			)}
		</Layout>
	);
}
