import { gql } from '@apollo/client';

export const INSERT_ONE_TODO = gql`
	mutation insertOneTodo(
		$title: String!
		$date: DateTime!
		$companyId: ID
		$description: String
	) {
		todo: insertOneTodo(
			title: $title
			date: $date
			companyId: $companyId
			description: $description
		) {
			_id
		}
	}
`;

export const UPDATE_ONE_TODO = gql`
	mutation updateOneTodo(
		$_id: ID!
		$title: String
		$date: DateTime
		$completed: Boolean
		$companyId: ID
		$description: String
	) {
		todo: updateOneTodo(
			_id: $_id
			title: $title
			date: $date
			completed: $completed
			companyId: $companyId
			description: $description
		) {
			_id
		}
	}
`;

export const DELETE_ONE_TODO = gql`
	mutation deleteOneTodo($_id: ID!) {
		deleted: deleteOneTodo(_id: $_id)
	}
`;
