import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { useWebApp } from 'web/contexts/WebContexts';

import LoginLayout from 'web/components/LoginLayout';
import { UnderlineLink } from 'web/components/Link';

import Form from 'components/form/Form';
import SubmitButton from 'components/form/SubmitButton';
import TextField from 'components/form/TextField';

import { VERIFY_USER_INVITE } from 'api/mutations/userMutations';

const Link = styled(UnderlineLink)`
	display: block;
	margin-top: 30px;
`;

export default function Invite() {
	const navigate = useNavigate();
	const { set } = useWebApp();

	const [verifyUserInvite, { loading, error }] =
		useMutation(VERIFY_USER_INVITE);

	return (
		<LoginLayout errors={error}>
			<Form
				isLoading={loading}
				isDisabled={loading}
				onSubmit={async ({ code }) => {
					try {
						const {
							data: { id },
						} = await verifyUserInvite({
							variables: { code },
						});

						if (id) {
							set({ invitedUser: id });

							navigate('/');
						}
					} catch (err) {
						console.warn(err);
					}
				}}
			>
				<TextField name="code" label="Invitasjonskode" required />

				<SubmitButton variant="contained" size="large">
					Bruk kode
				</SubmitButton>

				<Link to="/">Gå tilbake</Link>
			</Form>
		</LoginLayout>
	);
}
