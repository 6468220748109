import { formatDate } from 'utils/dates';

import Message from 'components/messages/Message';

export default function UpdatedCreatedAtAlert({
	createdAt,
	createdBy,
	updatedAt,
	updatedBy,
}) {
	if (updatedAt && updatedBy) {
		return (
			<Message type="success">
				<MessageText
					message="Sist oppdatert"
					date={updatedAt}
					by={updatedBy}
				/>
			</Message>
		);
	}

	return (
		<Message type="success">
			<MessageText message="Opprettet" date={createdAt} by={createdBy} />
		</Message>
	);
}

export function UpdatedCreatedAtText({
	createdAt,
	createdBy,
	updatedAt,
	updatedBy,
}) {
	if (updatedAt && updatedBy) {
		return (
			<MessageText
				message="Sist oppdatert"
				date={updatedAt}
				by={updatedBy}
			/>
		);
	}

	return <MessageText message="Opprettet" date={createdAt} by={createdBy} />;
}

function MessageText({ message, date, by }) {
	const formatedDate = formatDate(date, 'DD.MM.YY [kl] HH:mm');

	return `${message} ${formatedDate} av ${by}`;
}
