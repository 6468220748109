import { useState } from 'react';
import { useMutation } from '@apollo/client';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import FormIntroText from 'components/form/FormIntroText';
import SwitchField from 'components/form/SwitchField';
import DynamicPEPFields from 'components/form/DynamicPEPFields';
import SubmitButton from 'components/form/SubmitButton';

import { VERIFY_ONE_USER_PEP } from 'api/mutations/userMutations';

export default function PEPVerifyModal({ _id }) {
	const [error, setError] = useState(null);
	const [showPepRolesFields, setShowPepRolesFields] = useState(false);

	const [verifyPEP, { loading: verifyPepLoading, error: verifyPEPError }] =
		useMutation(VERIFY_ONE_USER_PEP, {
			refetchQueries: ['currentUser'],
		});

	return (
		<>
			<DialogTitle id="user-verification-dialog-title">
				Du må oppgi om du er en politisk eksponert person
			</DialogTitle>

			<ErrorMessage errors={[error, verifyPEPError]} />

			<Form
				values={{ isPEP: false }}
				isLoading={verifyPepLoading}
				onSubmit={async ({ isPEP, roles }) => {
					try {
						setError(null);

						if (isPEP && roles.length === 0) {
							setError(
								'Mangler relasjoner for politisk eksponert person'
							);
						}

						await verifyPEP({
							variables: {
								_id,
								isPEP,
								roles: isPEP ? roles : null,
							},
						});
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<DialogContent sx={{ paddingTop: 0 }}>
					<FormIntroText>
						For å kunne benytte tjenesten vår, må du avgi en
						erklæring på om du, eller noen i din nærmeste krets, er
						en politisk eksponert person.
					</FormIntroText>

					<SwitchField
						name="isPEP"
						label="Ja, jeg er en politisk eksponert person"
						onChange={value => {
							setShowPepRolesFields(value);
						}}
					/>

					{showPepRolesFields && <DynamicPEPFields name="roles" />}
				</DialogContent>

				<DialogActions>
					<SubmitButton variant="contained" color="primary">
						Bekreft
					</SubmitButton>
				</DialogActions>
			</Form>
		</>
	);
}
