import { useState } from 'react';
import { useMutation } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import { upload } from 'utils/fileHandling';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import FileField from 'components/form/FileField';
import SubmitButton from 'components/form/SubmitButton';

import { GET_FILES } from 'api/queries/fileQueries';
import {
	INSERT_ONE_FILE,
	REQUEST_FILE_UPLOAD,
} from 'api/mutations/fileMutations';

export default function ImageUploadDialog({
	docId,
	uploadContext,
	open,
	onClose,
}) {
	const { notify } = useSnackbar();

	const [fileUploadLoading, setFileUploadLoading] = useState(false);
	const [fileUploadError, setFileUploadError] = useState(null);

	const [
		requestFileUpload,
		{ loading: requestFileUploadLoading, error: requestFileUploadError },
	] = useMutation(REQUEST_FILE_UPLOAD);
	const [
		insertOneFile,
		{ loading: insertOneFileLoading, error: insertOneFileError },
	] = useMutation(INSERT_ONE_FILE, {
		refetchQueries: [
			{
				query: GET_FILES,
				variables: { docId: docId },
			},
		],
	});

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="xs"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Last opp bilde</DialogTitle>

			<Form
				isLoading={
					fileUploadLoading ||
					requestFileUploadLoading ||
					insertOneFileLoading
				}
				isDisabled={
					fileUploadLoading ||
					requestFileUploadLoading ||
					insertOneFileLoading
				}
				onSubmit={async ({ file }) => {
					try {
						setFileUploadError(null);
						setFileUploadLoading(true);

						const { data: { uploadUrl = null } = {} } =
							await requestFileUpload({
								variables: {
									uploadContext,
									fileName: file.name,
									contentType: file.type,
								},
							});

						if (!uploadUrl) {
							setFileUploadError(
								'Feil ved opplasting til server!'
							);
							return;
						}

						await upload(uploadUrl, file, {
							'Content-Type': file.type,
						});

						const { data } = await insertOneFile({
							variables: {
								fileName: file.name,
								fileType: file.type,
								docId,
								url: uploadUrl.split('?')[0],
							},
						});

						if (data?.file) {
							notify('Bildet ble lastet opp!');

							onClose();
						} else {
							setFileUploadError(
								'Feil ved opplasting av bilde til databasen!'
							);
						}
					} catch (err) {
						console.error(err);

						setFileUploadError('Feil ved opplasting av bilde!');
					} finally {
						setFileUploadLoading(false);
					}
				}}
			>
				<DialogContent>
					<ErrorMessage
						errors={[
							fileUploadError,
							requestFileUploadError,
							insertOneFileError,
						]}
					/>

					<FileField
						required
						name="file"
						label="Velg bilde"
						helperText="Filen kan maksimalt være 10MB"
						accept=".jpg,.jpeg,.png,.gif,.svg,.webp"
						maxSize="10000000"
					/>
				</DialogContent>

				<DialogActions>
					<Button onClick={onClose}>Avbryt</Button>

					<SubmitButton icon={<CameraAltIcon />} variant="contained">
						Last opp bilde
					</SubmitButton>
				</DialogActions>
			</Form>
		</Dialog>
	);
}
