import { useEffect } from 'react';
import styled from 'styled-components';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import Icon from 'components/Icon';

import { useForm } from 'components/form/Form';

export const CheckboxGroup = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const Wrapper = styled.div`
	margin-bottom: 16px;
	position: relative;

	.MuiCheckbox-colorSecondary.Mui-checked {
		color: ${p => p.theme.palette.primary.main};
	}
`;
const TooltipIconWrapper = styled.span`
	position: absolute;
	right: 0;
	top: 0;
	height: 56px;
	width: 56px;
	text-align: center;
	line-height: 56px;
`;

export default function CheckboxField(props) {
	const { name, disabled, tooltip, label, defaultValue = false } = props;

	const { registerField, updateField, isDisabled, values, errors } =
		useForm();

	const error = errors[name];

	useEffect(() => {
		registerField(props);
	});

	return (
		<Wrapper>
			<FormControlLabel
				control={
					<Checkbox
						{...props}
						disabled={disabled || isDisabled}
						error={errors[name]}
						value={
							values[name] !== null && values[name] !== undefined
								? !!values[name]
								: defaultValue
						}
						name={name}
						onChange={(e, checked) => {
							updateField({
								name,
								value: checked,
							});
						}}
						checked={
							values[name] !== null && values[name] !== undefined
								? !!values[name]
								: defaultValue
						}
					/>
				}
				label={label}
			/>
			{tooltip && (
				<Tooltip title={tooltip} placement="left">
					<TooltipIconWrapper>
						<Icon icon="question-circle" />
					</TooltipIconWrapper>
				</Tooltip>
			)}
			{!!error && <span>{error}</span>}
		</Wrapper>
	);
}
