import { gql } from '@apollo/client';

export const PAGINATE_AUDITING_COMPANIES = gql`
	query paginateAuditingCompanies(
		$order: Int
		$orderBy: String
		$limit: Int
		$offset: Int
		$search: String
	) {
		auditingCompanies: paginateAuditingCompanies(
			order: $order
			orderBy: $orderBy
			limit: $limit
			skip: $offset
			search: $search
		) {
			count
			items {
				_id
				name
				auditors {
					_id
				}
			}
		}
	}
`;

export const FIND_AUDITING_COMPANY = gql`
	query findOneAuditingCompany($_id: ID!) {
		auditingCompany: findOneAuditingCompany(_id: $_id) {
			_id
			name
			orgNo
			email
			phone
			auditors {
				_id
				name
			}
		}
	}
`;
