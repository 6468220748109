import styled from 'styled-components';
import Button from '@mui/material/Button';

import LoginLayout from 'web/components/LoginLayout';
import Link from 'web/components/Link';

const Title = styled.h2`
	font-size: 1.375rem;
	text-align: center;
	margin: 0 0 30px 0;
`;

export default function UnauthorizedNotFound({
	title = 'Denne siden ble ikke funnet!',
}) {
	return (
		<LoginLayout>
			<Title>{title}</Title>

			<Link to={'/'}>
				<Button variant="contained" color="primary" size="large">
					Logg inn
				</Button>
			</Link>
		</LoginLayout>
	);
}
