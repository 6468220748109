import { useState } from 'react';
import { useMutation } from '@apollo/client';
import KeyIcon from '@mui/icons-material/Key';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import LoginLayout from 'web/components/LoginLayout';
import { UnderlineLink } from 'web/components/Link';

import { REQUEST_PASSWORD_RESET } from 'api/mutations/userMutations';

export default function ForgotPassword() {
	const [message, setMessage] = useState('');

	const [restPassword, { loading, error }] = useMutation(
		REQUEST_PASSWORD_RESET
	);

	return (
		<LoginLayout errors={error}>
			{!message && (
				<>
					<Form
						isLoading={loading}
						isDisabled={loading}
						onSubmit={async ({ email }) => {
							try {
								const {
									data: { ok },
								} = await restPassword({
									variables: { email },
								});

								if (ok) {
									setMessage(
										'Du vil om kort tid motta en e-post med instruksjoner for å nullstille passordet!'
									);
								}
							} catch (err) {
								console.warn(err);
							}
						}}
					>
						<TextField
							name="email"
							type="email"
							label="E-post"
							required
						/>

						<SubmitButton
							variant="contained"
							size="large"
							icon={<KeyIcon />}
						>
							Nullstill passord
						</SubmitButton>
					</Form>

					<UnderlineLink to="/admin-innlogging">
						Gå tilbake
					</UnderlineLink>
				</>
			)}

			{message && (
				<>
					<p>{message}</p>

					<UnderlineLink to="/nytt-passord">
						Sett nytt passord
					</UnderlineLink>
				</>
			)}
		</LoginLayout>
	);
}
