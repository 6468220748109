import { useState, createContext, useContext } from 'react';
import { createPortal } from 'react-dom';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import Button from '@mui/material/Button';

import ModalLayout from 'components/modals/ModalLayout';
import ErrorMessage from 'components/ErrorMessage';
import DocumentIcon from 'components/DocumentIcon';

import { GET_COMPANY_FOLDERS } from 'api/queries/documentQueries';
import { UPDATE_DOCUMENT_NODE } from 'api/mutations/documentMutations';

const CurrentFolder = styled.div`
	margin-bottom: 10px;
`;

const Folder = styled.button`
	background-color: #fff;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	line-height: 1;
	margin-bottom: 5px;
	gap: 10px;
	padding: 0;
	width: 100%;
	transition: all 0.5s;

	&:hover {
		color: ${p => p.theme.palette.primary.main};
	}
`;

const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
	margin-top: 20px;
`;

const MoveDocumentNodeModalContext = createContext({
	isOpen: false,
	documentNodeId: null,
});

export function useMoveDocumentNodeModal() {
	const context = useContext(MoveDocumentNodeModalContext);

	if (context === undefined) {
		throw new Error(
			'useMoveDocumentNodeModal must be used within a MoveDocumentNodeModalProvider'
		);
	}

	return context;
}

export default function MoveDocumentNodeModalProvider({ children }) {
	let [isOpen, setIsOpen] = useState(false);
	let [documentNode, setDocumentNode] = useState(null);

	return (
		<MoveDocumentNodeModalContext.Provider
			value={{
				isOpen,
				setIsOpen,
				documentNode,
				setDocumentNode,
				move: documentNode => {
					setDocumentNode(documentNode);
					setIsOpen(true);
				},
			}}
		>
			{children}

			{isOpen &&
				documentNode &&
				createPortal(
					<MoveDocumentModal
						setIsOpen={setIsOpen}
						documentNode={documentNode}
					/>,
					document.getElementById('modal-root')
				)}
		</MoveDocumentNodeModalContext.Provider>
	);
}

function MoveDocumentModal({ documentNode, setIsOpen }) {
	const [folderId, setFolderId] = useState('root');

	const {
		data: { folder: { currentFolder, subFolders } } = {
			folder: { currentFolder: null, subFolders: [] },
		},
		error,
	} = useQuery(GET_COMPANY_FOLDERS, {
		variables: {
			_id: folderId,
			tenantId: documentNode.tenantId,
		},
	});

	const [updateDocumentNode] = useMutation(UPDATE_DOCUMENT_NODE, {
		refetchQueries: ['documentNodes', 'paginateDocumentNodes'],
	});

	return (
		<ModalLayout
			title={`Flytt ${documentNode.name}`}
			onClose={() => setIsOpen(false)}
			maxWidth="800px"
		>
			<ErrorMessage errors={error} />

			{currentFolder && (
				<>
					<CurrentFolder>
						<strong>Gjeldende mappe:</strong> {currentFolder.name}
					</CurrentFolder>
				</>
			)}

			{subFolders.map(({ _id, name }) => (
				<Folder key={_id} onClick={() => setFolderId(_id)}>
					<DocumentIcon type="folder" />

					<span>{name}</span>
				</Folder>
			))}

			<Actions>
				{currentFolder && (
					<Button onClick={() => setFolderId(currentFolder.parentId)}>
						« Tilbake
					</Button>
				)}

				<Button
					variant="contained"
					color="primary"
					type="button"
					disabled={!currentFolder}
					onClick={async () => {
						if (!currentFolder) return;

						try {
							await updateDocumentNode({
								variables: {
									_id: documentNode._id,
									parentId: currentFolder._id,
								},
							});

							setIsOpen(false);
						} catch (err) {
							console.error(err);
						}
					}}
				>
					Flytt hit
				</Button>
			</Actions>
		</ModalLayout>
	);
}
