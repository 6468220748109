import { gql } from '@apollo/client';

export const GET_GOVERNMENT_DEADLINES = gql`
	query getGovernmentDeadlines($startDate: DateTime, $endDate: DateTime) {
		deadlines: getGovernmentDeadlines(
			startDate: $startDate
			endDate: $endDate
		) {
			_id
			title
			description
			link
			date
		}
	}
`;
