import { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';

import ErrorMessage from 'components/ErrorMessage';

import Layout from 'app/components/Layout';

import Form from 'components/form/Form';
import SwitchField from 'components/form/SwitchField';
import DynamicPEPFields from 'components/form/DynamicPEPFields';

import { VERIFY_ONE_USER_PEP } from 'api/mutations/userMutations';

const Title = styled.h3`
	margin: 0 0 20px 0;
`;

const Text = styled.div`
	color: ${p => p.theme.palette.text.secondary};
	margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
	margin-top: 20px;
	text-align: right;
`;

export default function UserVerifyCitizenship({ _id }) {
	const [showPepRolesFields, setShowPepRolesFields] = useState(false);

	const [verifyPEP, { error }] = useMutation(VERIFY_ONE_USER_PEP, {
		refetchQueries: ['currentUser'],
		errorPolicy: 'all',
	});

	return (
		<Layout pageTitle="Politisk person">
			<Title>Du må oppgi om du er en politisk eksponert person</Title>
			<ErrorMessage errors={error} />

			<Form
				values={{ isPEP: false }}
				onSubmit={async ({ isPEP, roles }) => {
					await verifyPEP({
						variables: {
							_id,
							isPEP,
							roles,
						},
					});
				}}
			>
				<div>
					<Text>
						For å kunne benytte tjenesten vår, må du avgi en
						erklæring på om du, eller noen i din nærmeste krets, er
						en politisk eksponert person.
					</Text>

					<SwitchField
						name="isPEP"
						label="Ja, jeg er en politisk eksponert person"
						onChange={value => {
							setShowPepRolesFields(value);
						}}
					/>

					{showPepRolesFields && <DynamicPEPFields name="roles" />}
				</div>

				<ButtonWrapper>
					<Button type="submit" variant="contained" color="primary">
						Bekreft
					</Button>
				</ButtonWrapper>
			</Form>
		</Layout>
	);
}
