import { Controller, useFormContext } from 'react-hook-form';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function ButtonGroup({ name, label, options = [], ...rest }) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, value } }) => (
				<ToggleButtonGroup
					value={value}
					onChange={(_event, value) => onChange(value)}
					aria-label={label}
					color="primary"
					{...rest}
				>
					{options.map(({ value, label }) => (
						<ToggleButton key={value} value={value} size="small">
							{label}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			)}
		/>
	);
}
