import { useMutation } from '@apollo/client';
import { formatDate } from 'utils/dates';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';

import {
	OuterInfoWrapper,
	InfoLineWrapper,
	InfoLine,
	InfoValue,
	SubduedValue,
	PersonIcon,
	Button,
} from 'web/components/panels/PanelItems';
import { UnderlineLink } from 'web/components/Link';

import { INVITE_ONE_USER } from 'api/mutations/userMutations';

export default function NotVerifiedUserInfoItem({ user }) {
	const { notify } = useSnackbar();

	const [inviteOneUser, { data, loading, error }] =
		useMutation(INVITE_ONE_USER);

	const userInvite =
		(user.userVerifications &&
			user.userVerifications.find(
				verification => verification.type == 'invite'
			)) ||
		null;

	return (
		<OuterInfoWrapper>
			<ErrorMessage errors={error} />

			<InfoLineWrapper>
				<InfoLine>
					<PersonIcon />

					<InfoValue>
						<div>
							<UnderlineLink to={`/brukere/${user._id}/`}>
								{user.name}
							</UnderlineLink>
						</div>

						{userInvite && userInvite.lastSent && (
							<SubduedValue>
								<span>Sist invitert: </span>
								{formatDate(
									userInvite.lastSent,
									'DD.MM.YYYY [kl] HH:mm'
								)}
							</SubduedValue>
						)}
					</InfoValue>
				</InfoLine>

				<Button
					disabled={loading || !!data}
					type="button"
					onClick={async e => {
						try {
							e.preventDefault();

							const invite = await inviteOneUser({
								variables: {
									_id: user._id,
								},
							});

							if (invite?.data?.inviteOneUser) {
								notify(
									`Invitasjon til ${user.name} er sendt`,
									'success'
								);
							} else {
								notify(
									`Kunne ikke sende invitasjon til ${user.name}`,
									'error'
								);
							}
						} catch (err) {
							console.error(err);
						}
					}}
				>
					{data ? 'invitert' : 'Inviter'}
				</Button>
			</InfoLineWrapper>
		</OuterInfoWrapper>
	);
}
