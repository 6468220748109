import styled from 'styled-components';

export const DocumentTitleWrapper = styled.div`
	text-align: center;
	margin-bottom: 34px;
	padding: 0 16px 16px;
`;
export const DocumentTitle = styled.span`
	font-size: 18px;
	font-weight: 600;
`;
export const ParentTitle = styled.span`
	font-size: 13px;
	color: #919191;
	display: block;
	width: 100%;
`;
export const DocumentParagraph = styled.p`
	margin-bottom: 0;
	text-align: ${p => p.$align || 'left'};
	font-size: 18px;
	line-height: 30px;
`;
