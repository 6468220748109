import styled from 'styled-components';

import Icon from 'components/Icon';

const StyledStatusIcon = styled(Icon)`
	color: ${p =>
		p.$error
			? p.theme.palette.status.error
			: p.theme.palette.status.success};
	vertical-align: middle;
`;

export default function StatusIcon({
	error,
	errorTitle = '',
	successTitle = '',
}) {
	return (
		<StyledStatusIcon
			icon={error ? 'xmark' : 'check'}
			title={error ? errorTitle : successTitle}
			$error={error}
		/>
	);
}
