export const countries = [
	'Norge',
	'Sverige',
	'Danmark',
	'Finland',
	'Afghanistan',
	'Albania',
	'Algerie',
	'Amerikansk Samoa',
	'Andorra',
	'Angola',
	'Anguilla',
	'Antarktis',
	'Antigua og Barbuda',
	'Argentina',
	'Armenia',
	'Aruba',
	'Aserbajdsjan',
	'Australia',
	'Bahamas',
	'Bahrain',
	'Bangladesh',
	'Barbados',
	'Belgia',
	'Belize',
	'Benin',
	'Bermuda',
	'Bhutan',
	'Bolivia',
	'Bosnia-Hercegovina',
	'Botswana',
	'Bouvetøya',
	'Brasil',
	'Brunei',
	'Bulgaria',
	'Burkina Faso',
	'Burundi',
	'Canada',
	'Caymanøyene',
	'Chile',
	'Christmasøya',
	'Colombia',
	'Cookøyene',
	'Costa Rica',
	'Cuba',
	'Curaçao',
	'De amerikanske jomfruøyene',
	'De britiske jomfruøyene',
	'De forente arabiske emirater',
	'De franske sørterritorier',
	'Den dominikanske republikk',
	'Den sentralafrikanske republikk',
	'Det britiske territoriet i Indiahavet',
	'Djibouti',
	'Dominica',
	'Ecuador',
	'Egypt',
	'Ekvatorial-Guinea',
	'El Salvador',
	'Elfenbenskysten',
	'Eritrea',
	'Estland',
	'Eswatini',
	'Etiopia',
	'Falklandsøyene',
	'Fiji',
	'Filippinene',
	'Frankrike',
	'Fransk Guyana',
	'Fransk Polynesia',
	'Færøyene',
	'Gabon',
	'Gambia',
	'Georgia',
	'Ghana',
	'Gibraltar',
	'Grenada',
	'Grønland',
	'Guadeloupe',
	'Guam',
	'Guatemala',
	'Guernsey',
	'Guinea',
	'Guinea-Bissau',
	'Guyana',
	'Haiti',
	'Heard- og McDonaldøyene',
	'Hellas',
	'Honduras',
	'Hongkong',
	'Hviterussland',
	'India',
	'Indonesia',
	'Irak',
	'Iran',
	'Irland',
	'Island',
	'Israel',
	'Italia',
	'Jamaica',
	'Japan',
	'Jemen',
	'Jersey',
	'Jordan',
	'Kambodsja',
	'Kamerun',
	'Kapp Verde',
	'Karibisk Nederland',
	'Kasakhstan',
	'Kenya',
	'Kina',
	'Kirgisistan',
	'Kiribati',
	'Kokosøyene',
	'Komorene',
	'Kongo',
	'Kongo-Brazzaville',
	'Kosovo',
	'Kroatia',
	'Kuwait',
	'Kypros',
	'Laos',
	'Latvia',
	'Lesotho',
	'Libanon',
	'Liberia',
	'Libya',
	'Liechtenstein',
	'Litauen',
	'Luxemburg',
	'Macao',
	'Madagaskar',
	'Malawi',
	'Malaysia',
	'Maldivene',
	'Mali',
	'Malta',
	'Man',
	'Marokko',
	'Marshalløyene',
	'Martinique',
	'Mauritania',
	'Mauritius',
	'Mayotte',
	'Mexico',
	'Mikronesiaføderasjonen',
	'Moldova',
	'Monaco',
	'Mongolia',
	'Montenegro',
	'Montserrat',
	'Mosambik',
	'Myanmar (Burma)',
	'Namibia',
	'Nauru',
	'Nederland',
	'Nepal',
	'New Zealand',
	'Nicaragua',
	'Niger',
	'Nigeria',
	'Niue',
	'Nord-Korea',
	'Nord-Makedonia',
	'Nord-Marianene',
	'Norfolkøya',
	'Ny-Caledonia',
	'Oman',
	'Pakistan',
	'Palau',
	'Palestina',
	'Panama',
	'Papua Ny-Guinea',
	'Paraguay',
	'Peru',
	'Pitcairnøyene',
	'Polen',
	'Portugal',
	'Puerto Rico',
	'Qatar',
	'Réunion',
	'Romania',
	'Russland',
	'Rwanda',
	'Saint Kitts og Nevis',
	'Saint-Barthélemy',
	'Saint-Martin',
	'Saint-Pierre-et-Miquelon',
	'Salomonøyene',
	'Samoa',
	'San Marino',
	'São Tomé og Príncipe',
	'Saudi-Arabia',
	'Senegal',
	'Serbia',
	'Seychellene',
	'Sierra Leone',
	'Singapore',
	'Sint Maarten',
	'Slovakia',
	'Slovenia',
	'Somalia',
	'Spania',
	'Sri Lanka',
	'St. Helena',
	'St. Lucia',
	'St. Vincent og Grenadinene',
	'Storbritannia',
	'Sudan',
	'Surinam',
	'Svalbard og Jan Mayen',
	'Sveits',
	'Syria',
	'Sør-Afrika',
	'Sør-Georgia og Sør-Sandwichøyene',
	'Sør-Korea',
	'Sør-Sudan',
	'Tadsjikistan',
	'Taiwan',
	'Tanzania',
	'Thailand',
	'Togo',
	'Tokelau',
	'Tonga',
	'Trinidad og Tobago',
	'Tsjad',
	'Tsjekkia',
	'Tunisia',
	'Turkmenistan',
	'Turks- og Caicosøyene',
	'Tuvalu',
	'Tyrkia',
	'Tyskland',
	'Uganda',
	'Ukraina',
	'Ungarn',
	'Uruguay',
	'USA',
	'USAs ytre øyer',
	'Usbekistan',
	'Vanuatu',
	'Vatikanstaten',
	'Venezuela',
	'Vest-Sahara',
	'Vietnam',
	'Wallis og Futuna',
	'Zambia',
	'Zimbabwe',
	'Øst-Timor',
	'Østerrike',
	'Åland',
];
