import { gql } from '@apollo/client';

export const PAGINATE_COMPANY_APPLICATIONS = gql`
	query paginateCompanyApplications(
		$order: Int
		$orderBy: String
		$limit: Int
		$offset: Int
		$search: String
		$isArchived: Boolean
	) {
		companyApplications: paginateCompanyApplications(
			order: $order
			orderBy: $orderBy
			limit: $limit
			skip: $offset
			search: $search
			isArchived: $isArchived
		) {
			count
			items {
				_id
				companyName
				companyFoundationDate
				createdAt
			}
		}
	}
`;

export const GET_ONE_COMPANY_APPLICATION = gql`
	query findOneCompanyApplication($_id: ID!) {
		companyApplication: findOneCompanyApplication(_id: $_id) {
			_id
			createdAt
			updatedAt
			companyFoundationDate
			accountingOffice
			registrationFee
			auditorFee
			companyName
			companyAddress
			companyPostalCode
			companyCounty
			companyPurpose
			stockCapital
			stockAmount
			stockPremium
			stockItemDeposit
			auditor
			signatories
			procura
			bylaws
			companyIsEmployer
			contact {
				_id
				name
				address
				postalCode
				county
				phone
				email
				identifier
			}
			primaryContact {
				_id
				role
				name
				address
				postalCode
				county
				phone
				email
				identifier
			}
			boardMembers {
				_id
				name
				address
				postalCode
				county
				phone
				email
				identifier
				role
			}
			stockholders {
				_id
				name
				address
				postalCode
				county
				phone
				email
				identifier
				shares
			}
		}
	}
`;
