import { assigmentTaskDeadlineEntryStatusColor } from 'utils/colors';

import useChildTotal from 'hooks/useChildTotal';

import { taskEntryStatuses } from 'server/templates/assignments';

import { useAppFilters } from 'web/contexts/AppFiltersContext';

import {
	WidgetList,
	WidgetListItem,
	WidgetListLabel,
} from 'web/components/widgets/components/WidgetList';
import WidgetListData from 'web/components/widgets/components/WidgetListData';

import Widget from 'web/components/widgets/Widget';

import {
	COMPANIES_WITH_TASK_DEADLINE_ENTRIES,
	COMPANIES_WITH_OVERDUE_TASK_DEADLINE_ENTRIES,
} from 'api/queries/widgetQueries';

export default function TaskDeadlineWidget({ title, type }) {
	const { year } = useAppFilters();
	const [total, updateTotal] = useChildTotal();

	return (
		<Widget title={`${title} ${year.value - 1}`} titleIcon="calendar-clock">
			<WidgetList>
				<WidgetListData
					name="not-started"
					label={
						<WidgetListLabel
							label="Ikke startet"
							chipColor={assigmentTaskDeadlineEntryStatusColor({
								paletteType: 'dark',
							})}
						/>
					}
					query={COMPANIES_WITH_TASK_DEADLINE_ENTRIES}
					variables={{
						type,
						status: 'Ikke startet',
					}}
					link={id => `/bedrifter/${id}/rediger/?tab=planlegging`}
					total={total}
					onLoaded={updateTotal}
				/>

				{taskEntryStatuses[type].map(status => (
					<WidgetListData
						key={status}
						name={status}
						label={
							<WidgetListLabel
								label={status}
								chipColor={assigmentTaskDeadlineEntryStatusColor(
									{
										status,
										paletteType: 'dark',
									}
								)}
							/>
						}
						query={COMPANIES_WITH_TASK_DEADLINE_ENTRIES}
						variables={{
							type,
							status,
						}}
						link={id => `/bedrifter/${id}/rediger/?tab=planlegging`}
						total={total}
						onLoaded={updateTotal}
					/>
				))}
			</WidgetList>

			<WidgetList>
				<WidgetListItem label="Totalt" value={total} />
			</WidgetList>

			<WidgetList>
				<WidgetListData
					name="postponed"
					label={<WidgetListLabel label="Ikke søkt utsatt frist" />}
					query={COMPANIES_WITH_TASK_DEADLINE_ENTRIES}
					variables={{
						type,
						excludeCompleted: true,
						excludePostponed: true,
						isPostponed: false,
					}}
					link={id => `/bedrifter/${id}/rediger/?tab=planlegging`}
					total={total}
				/>

				<WidgetListData
					name="postponed"
					label={<WidgetListLabel label="Søkt utsatt frist" />}
					query={COMPANIES_WITH_TASK_DEADLINE_ENTRIES}
					variables={{
						type,
						isPostponed: true,
					}}
					link={id => `/bedrifter/${id}/rediger/?tab=planlegging`}
					total={total}
				/>

				<WidgetListData
					name="overdue"
					label={<WidgetListLabel label="Forfalt" />}
					query={COMPANIES_WITH_OVERDUE_TASK_DEADLINE_ENTRIES}
					variables={{
						type,
					}}
					link={id => `/bedrifter/${id}/rediger/?tab=planlegging`}
					total={total}
				/>
			</WidgetList>
		</Widget>
	);
}
