import styled from 'styled-components';

import { pluralize } from 'utils/strings';
import { orgNoFormat } from 'utils/numbers';

import { getDepartmentLabel } from 'components/utils/departments';

import { useUser } from 'web/contexts/UserContext';

import { EmailLink, PhoneLink } from 'web/components/Link';

import Widget from 'web/components/widgets/Widget';

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
`;

const Column = styled.div``;

const Table = styled.table`
	border-spacing: 0 3px;
`;

const Item = styled.tr`
	margin: 0;
`;

const Label = styled.td`
	vertical-align: top;
	font-weight: 600;
	padding-right: 15px;
`;

const Value = styled.td`
	vertical-align: top;
`;

export default function CompanyInfoWidget({ company }) {
	const { user } = useUser();

	if (!company) {
		return null;
	}

	return (
		<Widget title={`Velkommen ${user.name}!`}>
			<Grid>
				<Column>
					<Table>
						<tbody>
							<Item>
								<Label>Firmanavn:</Label>

								<Value>{company.name}</Value>
							</Item>

							{company.orgNo && (
								<Item>
									<Label>Organisasjonsnr:</Label>

									<Value>{orgNoFormat(company.orgNo)}</Value>
								</Item>
							)}

							{company.clientNo && (
								<Item>
									<Label>Kundenummer:</Label>

									<Value>{company.clientNo}</Value>
								</Item>
							)}

							{company.departmentId && (
								<Item>
									<Label>Avdeling: </Label>

									<Value>
										{getDepartmentLabel(
											company.departmentId
										)}
									</Value>
								</Item>
							)}
						</tbody>
					</Table>
				</Column>

				<Column>
					<Table>
						<tbody>
							{company.accountants &&
								company.accountants.length > 0 && (
									<Item>
										<Label>
											{pluralize(
												'Regnskapsfører',
												'Regnskapsførere',
												company.accountants
											)}
											:
										</Label>

										<Value>
											{company.accountants.map(
												({ name, phone, email }) => (
													<Person
														key={name}
														name={name}
														email={email}
														phone={phone}
													/>
												)
											)}
										</Value>
									</Item>
								)}

							{company.auditors &&
								company.auditors.length > 0 && (
									<Item>
										<Label>
											{pluralize(
												'Revisor',
												'Revisorer',
												company.auditors
											)}
											:
										</Label>

										<Value>
											{company.auditors.map(
												({ name, phone, email }) => (
													<Person
														key={name}
														name={name}
														email={email}
														phone={phone}
													/>
												)
											)}
										</Value>
									</Item>
								)}
						</tbody>
					</Table>
				</Column>
			</Grid>
		</Widget>
	);
}

const StyledPerson = styled.div`
	margin-bottom: 10px;
`;

const PersonInfo = styled.div`
	margin-bottom: 3px;
`;

function Person({ name, email, phone }) {
	return (
		<StyledPerson>
			<PersonInfo>{name}</PersonInfo>

			<PersonInfo>
				<EmailLink email={email} />
			</PersonInfo>

			<PersonInfo>
				<PhoneLink phone={phone} />
			</PersonInfo>
		</StyledPerson>
	);
}
