import { useQuery } from '@apollo/client';

import { formatDate } from 'utils/dates';

import { TableCell } from 'web/components/Table';

import { CompanyControlStatusPill } from 'components/Pill';

import { GET_COMPANY_CONTROL_FOR_COMPANY } from 'api/queries/companyControlQueries';

export default function QualityControlControlStatus({ companyId }) {
	const { data: { control } = { control: null }, loading } = useQuery(
		GET_COMPANY_CONTROL_FOR_COMPANY,
		{
			variables: {
				companyId,
				controlTypeValue: 'kvalitet',
			},
		}
	);

	if (loading || !control) {
		return <div />;
	}

	return (
		<TableCell
			to={`/bedrifter/${companyId}/kontroller/${control._id}/rediger/`}
			center
		>
			<CompanyControlStatusPill
				$statusCode={control.status.code}
				title={`Oppdatert ${formatDate(
					control.status.updatedAt,
					'DD.MM.YY [kl] HH:mm'
				)}`}
			>
				{`${control.status.label} '${String(control.year).slice(-2)}`}
			</CompanyControlStatusPill>
		</TableCell>
	);
}
