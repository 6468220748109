import { useQuery, useMutation } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorAlert from 'components/ErrorAlert';

import Form from 'components/form/Form';
import AutocompleteField from 'components/form/AutocompleteField';
import TextField from 'components/form/TextField';

import { PAGINATE_USERS } from 'api/queries/userQueries';
import { ADD_OWNER_TO_COMPANY } from 'api/mutations/tenantMutations';

export default function AddOwnerDialog({ companyId, open, onClose }) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="sm"
			aria-labelledby="dialog-title"
			aria-describedby="dialog-description"
		>
			<DialogTitle id="dialog-title">
				Legg til reell rettighetshaver
			</DialogTitle>

			<IconButton
				aria-label="close"
				onClick={onClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: theme => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>

			<AddOwnerForm companyId={companyId} onClose={onClose} />
		</Dialog>
	);
}

function AddOwnerForm({ companyId, onClose }) {
	const { notify } = useSnackbar();

	const {
		data: { users } = { users: { count: 0, items: [] } },
		loading: userLoading,
		error: userError,
	} = useQuery(PAGINATE_USERS, {
		variables: { orderBy: 'name', order: 1 },
	});

	const [addOwner, { loading: addOwnerLoading, error: addOwnerError }] =
		useMutation(ADD_OWNER_TO_COMPANY, {
			refetchQueries: ['findOneTenant'],
		});

	return (
		<Form
			isLoading={userLoading || addOwnerLoading}
			onSubmit={async ({ userId, note }) => {
				try {
					if (!userId) {
						return;
					}

					const { data } = await addOwner({
						variables: {
							_id: companyId,
							userId,
							note,
						},
					});

					if (data && data.added) {
						notify('Rettighetshaver ble lagt til');

						onClose();
					}
				} catch (err) {
					console.error(err);
				}
			}}
		>
			<DialogContent>
				<ErrorAlert error={userError} />
				<ErrorAlert error={addOwnerError} />

				<AutocompleteField
					name="userId"
					label="Reell rettighetshavere"
					options={users.items.map(user => ({
						value: user._id,
						label: user.name,
						subdued:
							user.role === undefined ||
							user.role === null ||
							user.role == 'silent',
						tooltip: [
							<Stack
								key="1"
								direction="row"
								alignItems="center"
								gap={1}
							>
								<MailIcon />
								{user.email || 'Mangler e-post'}
							</Stack>,
							<Stack
								key="2"
								direction="row"
								alignItems="center"
								gap={1}
							>
								<PhoneIcon />
								{user.phone || 'Mangler telefon'}
							</Stack>,
						],
					}))}
				/>

				<TextField
					name="note"
					label="Notat"
					maxRows="5"
					multiline={true}
				/>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Avbryt</Button>

				<Button
					type="submit"
					variant="contained"
					disabled={userLoading || addOwnerLoading}
				>
					Legg til
				</Button>
			</DialogActions>
		</Form>
	);
}
