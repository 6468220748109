import styled from 'styled-components';

import { formatDate } from 'utils/dates';

import { UnderlineLink } from 'web/components/Link';

const Item = styled.div`
	margin-bottom: 10px;
`;

const PrimaryText = styled.div``;

const SecondaryText = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-style: italic;
`;

export default function AnnualSettlementControlStatusDialogContent({
	items,
	link,
}) {
	return (
		<div>
			{items?.length > 0 ? (
				<div>
					{items.map(entry => {
						const annualFinancialStatus =
							entry.annualFinancialStatus[0] || null;

						return (
							<Item key={entry._id}>
								<PrimaryText>
									<UnderlineLink to={link(entry._id)}>
										{entry.name}
									</UnderlineLink>
								</PrimaryText>

								{annualFinancialStatus && (
									<SecondaryText>
										{`Satt klar ${formatDate(
											annualFinancialStatus.reviewedAt,
											'DD.MM.YY [kl] HH:mm'
										)} av ${
											annualFinancialStatus.reviewedByDisplayName
										}`}
									</SecondaryText>
								)}
							</Item>
						);
					})}
				</div>
			) : (
				<div>Ingen oppføringer ble funnet</div>
			)}
		</div>
	);
}
