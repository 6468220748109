import { gql } from '@apollo/client';

/**
 * Used for public access in the signing app. Access with an accessKey.
 */
export const GET_SIGNATURE_REQUESTS = gql`
	query getSignatureRequest($documentId: ID!, $accessKey: String!) {
		getSignatureRequest(documentId: $documentId, accessKey: $accessKey) {
			_id
			userHasSigned
			userHasSignedAt
			textToSign
			fileHash
			documentNode {
				_id
				name
				url {
					url
					name
				}
			}
		}
	}
`;

export const GET_ACTIVE_SIGNATURE_REQUEST = gql`
	query getActiveSignatureRequest($documentId: ID!) {
		getActiveSignatureRequest(documentId: $documentId) {
			_id
			fileHash
			textToSign
			documentId
			signees {
				email
				phone
				userId
				userName
				signed
			}
			type
			status
			signUrl
		}
	}
`;
