import { useAppFilters } from 'web/contexts/AppFiltersContext';

import UsersFilterSelect from 'components/filters/components/UsersFilterSelect';

export default function AnnualAccountManagerFilter({
	disabled = false,
	size = 'medium',
}) {
	const { annualAccountsManager, setAnnualAccountsManager } = useAppFilters();

	return (
		<UsersFilterSelect
			title="Vis årsoppgjøransvarlig"
			roles={['admin', 'accountant']}
			value={annualAccountsManager}
			onChange={setAnnualAccountsManager}
			disabled={disabled}
			size={size}
		/>
	);
}
