import styled from 'styled-components';
import Button from '@mui/material/Button';

import DesktopLayout, { NarrowContent } from 'web/components/Layout';
import Link from 'web/components/Link';

const Title = styled.h1`
	text-align: center;
`;

const Center = styled.div`
	text-align: center;
`;

export default function NotFound({
	label = '404 – Side ikke funnet',
	title = 'Denne siden ble ikke funnet!',
}) {
	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label,
				},
			]}
		>
			<NarrowContent>
				<Center>
					<Title>{title}</Title>

					<Link to={'/'}>
						<Button
							variant="contained"
							color="primary"
							size="large"
						>
							Tilbake til framsiden
						</Button>
					</Link>
				</Center>
			</NarrowContent>
		</DesktopLayout>
	);
}
