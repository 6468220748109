import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';

import { InlineLoading } from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import { RiskScorePill } from 'components/Pill';
import { RevisionText } from 'components/Revision';

import { UnderlineLink } from 'web/components/Link';

import Panel, { EmptyPanel } from 'web/components/panels/Panel';

import { GET_COMPANY_RISKS } from 'api/queries/companyRiskQueries';
import {
	INSERT_ONE_COMPANY_RISK,
	DELETE_ONE_COMPANY_RISK,
} from 'api/mutations/companyRiskMutations';

export default function CompanyRisksPanel({ companyId }) {
	const navigate = useNavigate();

	const {
		data: { companyRisks } = { companyRisks: [] },
		loading: companyRisksLoading,
		error: companyRisksError,
	} = useQuery(GET_COMPANY_RISKS, {
		variables: {
			companyId,
		},
	});

	const [createCompanyRisk, { error: createCompanyRiskError }] = useMutation(
		INSERT_ONE_COMPANY_RISK
	);

	return (
		<Panel
			title="Hvitvasking"
			action={
				<Button
					size="medium"
					variant="contained"
					color="primary"
					disabled={companyRisks?.length > 0}
					onClick={async () => {
						try {
							const { data } = await createCompanyRisk({
								variables: { companyId },
							});

							if (data) {
								navigate(
									`/bedrifter/${companyId}/hvitvasking/${data.risk._id}/rediger/`
								);
							}
						} catch (err) {
							console.error(err);
						}
					}}
					endIcon={<AddIcon size="small" />}
				>
					Opprett
				</Button>
			}
		>
			<ErrorMessage
				errors={[companyRisksError, createCompanyRiskError]}
			/>

			{companyRisksLoading ? (
				<InlineLoading />
			) : (
				<CompanyRisksList companyRisks={companyRisks} />
			)}
		</Panel>
	);
}

function CompanyRisksList({ companyRisks = [] }) {
	if (!companyRisks || companyRisks.length === 0) {
		return (
			<EmptyPanel>Bedriften har ingen skjema for hvitvasking.</EmptyPanel>
		);
	}

	return (
		<List>
			{companyRisks.map(companyRisk => (
				<CompanyRisksListItem
					key={companyRisk._id}
					companyRisk={companyRisk}
				/>
			))}
		</List>
	);
}

function CompanyRisksListItem({ companyRisk }) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [deleteCompanyRisk, { loading, error }] = useMutation(
		DELETE_ONE_COMPANY_RISK,
		{
			refetchQueries: ['findCompanyRisks'],
		}
	);

	return (
		<>
			<ErrorMessage errors={error} />

			<ListItem
				secondaryAction={
					<div>
						<RiskScorePill $score={companyRisk.totalScore}>
							{companyRisk.totalScore ?? 'Ikke vurdert'}
						</RiskScorePill>

						<IconButton
							edge="end"
							aria-label="Slett"
							role="button"
							disabled={loading}
							onClick={async () => {
								try {
									if (
										!(await verify({
											title: 'Slett risikovudering',
											text: `Er du sikker på at du vil slette ${companyRisk.name}?`,
										}))
									) {
										return;
									}

									const { data } = await deleteCompanyRisk({
										variables: {
											_id: companyRisk._id,
										},
									});

									if (data?.deleted) {
										notify('Risikovudering ble slettet!');
									}
								} catch (err) {
									console.error(err);
								}
							}}
						>
							<DeleteIcon />
						</IconButton>
					</div>
				}
			>
				<ListItemText>
					<UnderlineLink
						to={`/bedrifter/${companyRisk.companyId}/hvitvasking/${companyRisk._id}/rediger/`}
					>
						{companyRisk.name}
					</UnderlineLink>

					<RevisionText
						needsRevision={companyRisk.needsRevision}
						date={companyRisk?.revision?.date}
						by={companyRisk?.revision?.byDisplayName}
					/>
				</ListItemText>
			</ListItem>

			<Divider component="li" />
		</>
	);
}
