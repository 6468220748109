import { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { useForm } from 'components/form/Form';

const relationTypes = [
	{
		label: 'Meg selv',
		value: 'myself',
		restricted: true,
	},
	{ label: 'Familiemedlem', value: 'family' },
	{
		label: 'Nær forbindelse',
		value: 'closeConnection',
	},
];

const Title = styled.h3`
	margin: 0 0 20px 0;
`;

const Row = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 10px;
	margin: 6px 0px 0px;
`;

const StyledIconButton = styled(IconButton)`
	transform: translateY(-10px);
`;

export default function DynamicPEPFields({ name, existingRelations = [] }) {
	const [relations, setRelations] = useState(existingRelations);
	const { registerField, updateField } = useForm();

	useEffect(() => {
		registerField({ name, value: relations });
	}, []);

	useEffect(() => {
		updateField({ name, value: relations });
	}, [relations]);

	const handleAddRelation = () => {
		setRelations([
			...relations,
			{
				type: '',
				name: '',
				address: '',
			},
		]);
	};

	const handleModifyRelation = (index, param, value) => {
		let currentRelations = [...relations];
		currentRelations[index][param] = value;
		setRelations(currentRelations);
		updateField({ name, value: relations });
	};

	useEffect(() => {
		updateField({ name, value: relations });
	}, [relations]);

	const handleDeleteRelation = e => {
		setRelations([...relations].filter((_, i) => i !== e));
	};

	return (
		<div>
			<Title>Relasjoner</Title>

			<List>
				{relations &&
					relations.map((relation, index) => {
						const restricted =
							relationTypes.find(
								rel => rel.value === relation.type
							)?.restricted || false;

						return (
							<Fragment key={index}>
								<ListItem
									secondaryAction={
										<StyledIconButton
											edge="end"
											onClick={() => {
												handleDeleteRelation(index);
											}}
										>
											<DeleteIcon />
										</StyledIconButton>
									}
								>
									<ListItemText>
										<Row>
											<FormControl fullWidth size="small">
												<Select
													displayEmpty
													fullWidth={true}
													value={relation.type}
													name={`type[${index}]`}
													size="large"
													onChange={e => {
														const currentKey =
															e.target.name.replace(
																`[${index}]`,
																''
															);

														handleModifyRelation(
															index,
															currentKey,
															e.target.value
														);

														if (
															relationTypes.find(
																rel =>
																	rel.value ==
																	e.target
																		.value
															).restricted
														) {
															Object.keys(
																relations[index]
															)
																.filter(
																	key =>
																		key !==
																		currentKey
																)
																.map(key => {
																	handleModifyRelation(
																		index,
																		key,
																		''
																	);
																});
														}
													}}
												>
													{relationTypes &&
														relationTypes.map(
															(rel, relIndex) => (
																<MenuItem
																	value={
																		rel.value
																	}
																	key={`relation_${index}_${relIndex}`}
																>
																	<ListItemText
																		primary={
																			rel.label
																		}
																	/>
																</MenuItem>
															)
														)}
												</Select>

												<FormHelperText>
													Relasjon
												</FormHelperText>
											</FormControl>

											{!restricted && (
												<FormControl>
													<OutlinedInput
														type="text"
														name={`name[${index}]`}
														value={relation.name}
														size="large"
														aria-describedby={`name-helper-text-${index}`}
														onChange={e => {
															handleModifyRelation(
																index,
																e.target.name.replace(
																	`[${index}]`,
																	''
																),
																e.target.value
															);
														}}
													/>
													<FormHelperText
														id={`name-helper-text-${index}`}
													>
														Navn
													</FormHelperText>
												</FormControl>
											)}

											{!restricted && (
												<FormControl>
													<OutlinedInput
														fullWidth={true}
														name={`address[${index}]`}
														value={relation.address}
														size="large"
														aria-describedby={`address-helper-text-${index}`}
														onChange={e => {
															handleModifyRelation(
																index,
																e.target.name.replace(
																	`[${index}]`,
																	''
																),
																e.target.value
															);
														}}
													/>

													<FormHelperText
														id={`address-helper-text-${index}`}
													>
														Adresse
													</FormHelperText>
												</FormControl>
											)}
										</Row>
									</ListItemText>
								</ListItem>

								<Divider component="li" />
							</Fragment>
						);
					})}
			</List>

			<Button
				variant="contained"
				color="primary"
				onClick={handleAddRelation}
				endIcon={<AddIcon />}
			>
				Legg til
			</Button>
		</div>
	);
}
