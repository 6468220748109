import { useMutation } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import FrequencyFields from 'web/screens/dialogs/assignmentTask/FrequencyFields';
import SubTasksField from 'web/screens/dialogs/assignmentTask/SubTasksField';

import ErrorMessage from 'components/ErrorMessage';

import Form, { FormGroup, TextField, DatePicker, Switch } from 'web/form/Form';

import { INSERT_ONE_COMPANY_ASSIGNMENT_TASK } from 'api/mutations/companyAssignmentTaskMutations';

//TODO: Needs to be implement again in the timeline
export default function NewAssignmentTaskDialog({
	companyId,
	category,
	open,
	onClose,
}) {
	const { notify } = useSnackbar();

	const [insertTask, { loading, error }] = useMutation(
		INSERT_ONE_COMPANY_ASSIGNMENT_TASK,
		{
			refetchQueries: ['getTasks'],
		}
	);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Opprett en ny oppgave i {category}
			</DialogTitle>

			<Form
				onSubmit={async values => {
					try {
						//TODO: Cleanup the deadline object before save?
						const { data } = await insertTask({
							variables: {
								companyId,
								input: {
									...values,
									category,
								},
							},
						});

						if (data?.task?._id) {
							notify('Oppgave opprettet!');

							onClose();
						}
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<DialogContent>
					<ErrorMessage errors={[error]} />

					<FormGroup>
						<TextField
							name="name"
							label="Oppgavenavn"
							fullWidth
							required
						/>
					</FormGroup>

					<FormGroup>
						<DatePicker
							name="startDate"
							label="Startdato"
							required
						/>
					</FormGroup>

					<FrequencyFields />

					<FormGroup>
						<TextField
							name="routine"
							label="Oppgaverutine"
							fullWidth
							multiline
							rows={4}
						/>
					</FormGroup>

					<FormGroup>
						<Switch
							name="notifications"
							label="Send en påminnelse på e-post til regnskapsfører og kundeansvarlig ved utløp av tidsfrist."
						/>
					</FormGroup>

					<SubTasksField />
				</DialogContent>

				<DialogActions>
					<Button onClick={onClose}>Avbryt</Button>

					<Button
						type="submit"
						variant="contained"
						disabled={loading}
						autoFocus
					>
						Opprett
					</Button>
				</DialogActions>
			</Form>
		</Dialog>
	);
}
