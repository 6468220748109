import { gql } from '@apollo/client';

export const INSERT_ONE_COMPANY_ROUTINE = gql`
	mutation insertOneCompanyRoutine($companyId: ID!, $type: String!) {
		routine: insertOneCompanyRoutine(companyId: $companyId, type: $type) {
			_id
		}
	}
`;

export const UPDATE_COMPANY_ROUTINE = gql`
	mutation updateOneCompanyRoutine(
		$_id: ID!
		$name: String!
		$folderPlacements: [String]
		$values: [RoutineValuesInput!]
	) {
		routine: updateOneCompanyRoutine(
			_id: $_id
			name: $name
			folderPlacements: $folderPlacements
			values: $values
		) {
			_id
		}
	}
`;

export const DELETE_COMPANY_ROUTINE = gql`
	mutation deleteOneCompanyRoutine($_id: ID!) {
		deleted: deleteOneCompanyRoutine(_id: $_id)
	}
`;
