import { gql } from '@apollo/client';

export const INSERT_AUDITING_COMPANY = gql`
	mutation insertOneAuditingCompany(
		$name: String!
		$orgNo: String!
		$email: String
		$phone: String
	) {
		auditingCompany: insertOneAuditingCompany(
			name: $name
			orgNo: $orgNo
			email: $email
			phone: $phone
		) {
			_id
			name
			orgNo
			email
			phone
		}
	}
`;

export const UPDATE_AUDITING_COMPANY = gql`
	mutation updateOneAuditingCompany(
		$_id: ID!
		$name: String
		$orgNo: String
		$email: String
		$phone: String
	) {
		auditingCompany: updateOneAuditingCompany(
			_id: $_id
			name: $name
			orgNo: $orgNo
			email: $email
			phone: $phone
		) {
			_id
			name
			orgNo
			email
			phone
		}
	}
`;

export const DELETE_AUDITING_COMPANY = gql`
	mutation deteleOneAuditingCompany($_id: ID!) {
		deleted: deleteOneAuditingCompany(_id: $_id)
	}
`;
