import styled from 'styled-components';
import { useWatch, useFormContext } from 'react-hook-form';
import { FormGroup, Select } from 'web/form/Form';

// TODO: Don't duplicate. Also in CompanyAssignmentTaskCreate and on the server
const frequencies = [
	{ value: 'weekly', label: 'Ukentlig' }, // Should this one be removed?
	{ value: 'monthly', label: 'Månedlig' },
	{ value: 'biMonthly', label: '2-månedlig' },
	{ value: 'quarterly', label: 'Kvartalsvis' },
	{ value: 'tertiary', label: 'Tertialvis' },
	{ value: 'halfYearly', label: 'Halvårlig' },
	{ value: 'yearly', label: 'Årlig' },
	{ value: 'custom', label: 'Egendefinert' },
];

const weekdays = [
	{ value: 1, label: 'Mandag' },
	{ value: 2, label: 'Tirsdag' },
	{ value: 3, label: 'Onsdag' },
	{ value: 4, label: 'Torsdag' },
	{ value: 5, label: 'Fredag' },
];
const monthDays = Array.from({ length: 31 }, (_, i) => ({
	value: i + 1,
	label: i + 1,
}));

const months = [
	{ value: 0, label: 'Januar' },
	{ value: 1, label: 'Februar' },
	{ value: 2, label: 'Mars' },
	{ value: 3, label: 'April' },
	{ value: 4, label: 'Mai' },
	{ value: 5, label: 'Juni' },
	{ value: 6, label: 'Juli' },
	{ value: 7, label: 'August' },
	{ value: 8, label: 'September' },
	{ value: 9, label: 'Oktober' },
	{ value: 10, label: 'November' },
	{ value: 11, label: 'Desember' },
];

const relativeMonths = [
	{ value: 0, label: 'Samme mnd.' },
	{ value: 1, label: 'Neste mnd.' },
	{ value: 2, label: 'Om 2 mnd.' },
	{ value: 3, label: 'Om 3 mnd.' },
	{ value: 4, label: 'Om 4 mnd.' },
	{ value: 5, label: 'Om 5 mnd.' },
	{ value: 6, label: 'Om 6 mnd.' },
	{ value: 7, label: 'Om 7 mnd.' },
	{ value: 8, label: 'Om 8 mnd.' },
	{ value: 9, label: 'Om 9 mnd.' },
	{ value: 10, label: 'Om 10 mnd.' },
	{ value: 11, label: 'Om 11 mnd.' },
	{ value: 12, label: 'Om 12 mnd.' },
];

export default function FrequencyFields() {
	const { control } = useFormContext();

	const frequencyValue = useWatch({
		name: 'frequency',
		control,
	});

	return (
		<div>
			<FormGroup>
				<Select
					name="frequency"
					label="Frekvens"
					options={frequencies}
					fullWidth
					required
				/>
			</FormGroup>

			<FrequencyDeadlineFields frequency={frequencyValue} />
		</div>
	);
}

// TODO: Duplicate. Also in CompanyAssignmentTasksCreate
function FrequencyDeadlineFields({ frequency }) {
	if (frequency === 'weekly') {
		return (
			<FormGroup>
				<Select
					name="deadline.weekday"
					label="Ukedag"
					options={weekdays}
					fullWidth
					required
				/>
			</FormGroup>
		);
	} else if (frequency === 'monthly') {
		return (
			<FormGroup>
				<Select
					name="deadline.day"
					label="Dag i måneden"
					options={monthDays}
					fullWidth
					required
				/>
			</FormGroup>
		);
	} else if (
		frequency === 'biMonthly' ||
		frequency === 'quarterly' ||
		frequency === 'tertiary' ||
		frequency === 'halfYearly'
	) {
		return (
			<>
				<FormGroup>
					<Select
						name="deadline.day"
						label="Dag i måneden"
						options={monthDays}
						fullWidth
						required
					/>
				</FormGroup>

				<FormGroup>
					<Select
						name="deadline.relativeMonth"
						label="Relativ måned"
						options={relativeMonths}
						fullWidth
						required
					/>
				</FormGroup>
			</>
		);
	} else if (frequency === 'yearly') {
		return (
			<>
				<FormGroup>
					<Select
						name="deadline.day"
						label="Dag i måneden"
						options={monthDays}
						fullWidth
						required
					/>
				</FormGroup>

				<FormGroup>
					<Select
						name="deadline.month"
						label="Måned"
						options={months}
						fullWidth
						required
					/>
				</FormGroup>
			</>
		);
	} else if (frequency === 'custom') {
		return <CustomDeadlineDates />;
	}

	return null;
}

const CustomDeadlineDatesWrapper = styled.div`
	margin-bottom: 30px;
`;

const CustomDeadlineDatesItem = styled.div`
	margin-bottom: 5px;
`;

function CustomDeadlineDates() {
	const { getValues } = useFormContext();
	const customDeadlineDates = getValues('deadline.dates');

	return (
		<CustomDeadlineDatesWrapper>
			{customDeadlineDates ? (
				<div>
					{customDeadlineDates.map(({ day, month }) => {
						const monthLabel =
							months.find(({ value }) => value === month)
								?.label ?? 'Ukjent måned';

						return (
							<CustomDeadlineDatesItem
								key={`${day}-${month}`}
							>{`${day}. ${monthLabel}`}</CustomDeadlineDatesItem>
						);
					})}
				</div>
			) : (
				<div>Ingen egendefinerte datoer</div>
			)}
		</CustomDeadlineDatesWrapper>
	);
}
