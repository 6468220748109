import styled from 'styled-components';
import { getRoleLabel } from 'server/libs/companyRoles';

const Wrapper = styled.div`
	margin-bottom: 16px;
`;
const Row = styled.div`
	margin: 0 -5px;
	@media (min-width: 640px) {
		display: flex;
	}
`;
const Column = styled.div`
	flex: 1;
	padding: 5px;
`;
const Label = styled.div`
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.26);
	padding: 18.5px 14px;
`;

export default function UserRolesList({ roles }) {
	return (
		<Wrapper>
			{roles &&
				roles.map(role => (
					<Row key={`${role.id}-${role.role}`}>
						<Column>
							<Label>{role.companyName}</Label>
						</Column>
						<Column>
							<Label>{getRoleLabel(role.role)}</Label>
						</Column>
					</Row>
				))}
		</Wrapper>
	);
}
