import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import { TOGGLE_FAVORITE } from 'api/mutations/tenantMutations';

const Button = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
`;

const ActiveIcon = styled(StarIcon)`
	font-size: 30px;
	color: ${p => p.theme.palette.common.yellow};
`;

const InactiveIcon = styled(StarBorderIcon)`
	font-size: 30px;
`;

const Error = styled.div`
	color: ${p => p.theme.palette.status.error};
`;

export default function FavoriteButton({ company }) {
	const [toggleFavorite, { data, error }] = useMutation(TOGGLE_FAVORITE, {});

	if (!company) {
		return null;
	}

	if (error) {
		return <Error>Det oppstod en feil!</Error>;
	}

	const isFavorite = data ? data.isFavorite : company.isFavorite;

	return (
		<Button
			title={
				isFavorite
					? 'Fjern bedrift som favoritt'
					: 'Legg til bedrift som favoritt'
			}
			onClick={async () => {
				try {
					toggleFavorite({ variables: { _id: company._id } });
				} catch (err) {
					console.error(err);
				}
			}}
		>
			{isFavorite ? <ActiveIcon /> : <InactiveIcon />}
		</Button>
	);
}
