import { createContext, useState, useContext } from 'react';

import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';

export const ConfirmContext = createContext({ confirm: async () => {} });

let onConfirm = () => {};

export function useConfirmDialog() {
	const context = useContext(ConfirmContext);

	if (context === undefined) {
		throw new Error(
			'useConfirmDialog must be used within a ConfirmProvider'
		);
	}

	return context;
}

export default function ConfirmProvider({ children }) {
	const [isOpen, setIsOpen] = useState(false);
	const [message, setMessage] = useState({});

	return (
		<ConfirmContext.Provider
			value={{
				verify: message => {
					setMessage(message);
					setIsOpen(true);

					return new Promise(resolve => {
						onConfirm = value => {
							setIsOpen(false);
							resolve(value);
						};
					});
				},
			}}
		>
			{children}

			<Dialog
				open={isOpen}
				onClose={() => onConfirm(false)}
				aria-labelledby="confirm-dialog-title"
				aria-describedby="confirm-dialog-title"
			>
				<DialogTitle id="confirm-dialog-title">
					{message.title}
				</DialogTitle>

				{message.text && (
					<DialogContent id="confirm-dialog-title">
						{message.text}
					</DialogContent>
				)}

				<DialogActions>
					<Button onClick={() => onConfirm(false)}>Nei</Button>

					<Button onClick={() => onConfirm(true)}>Ja</Button>
				</DialogActions>
			</Dialog>
		</ConfirmContext.Provider>
	);
}
