import styled from 'styled-components';

export const List = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;
export const ListItem = styled.li`
	flex: 1 1 auto;
	min-width: 0px;
	padding: 4px 0px;
	margin-top: 6px;
	margin-bottom: 6px;
	font-size: 1.1rem;
`;
export const ListItemLabel = styled.div`
	font-weight: 300;
	font-size: 0.9rem;
	line-height: 1;
	margin-bottom: 5px;
`;
export const ListItemValue = styled.div`
	line-height: 1.5;
`;

export function ListItemElement({ label, value }) {
	return (
		<ListItem>
			<ListItemLabel>{label}</ListItemLabel>
			<ListItemValue>{value}</ListItemValue>
		</ListItem>
	);
}
