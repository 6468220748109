import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import DesktopLayout, { NarrowContent } from 'web/components/Layout';
import SigneeForm from 'web/components/SigneeForm';

import { GET_COMPANY_DOCUMENTS } from 'api/queries/documentQueries';
import { ADD_SIGNEES_TO_DOCUMENT } from 'api/mutations/signatureMutations';
import { GET_ONE_COMPANY } from 'api/queries/tenantQueries';

export default function SendSigningRequest() {
	const navigate = useNavigate();
	const { companyId, documentNodeId } = useParams();
	const { notify } = useSnackbar();

	const {
		data: { documentNode, parentDocumentNodes = [] } = {},
		loading,
		error,
	} = useQuery(GET_COMPANY_DOCUMENTS, {
		variables: {
			tenantId: companyId,
			parentId: documentNodeId,
		},
		errorPolicy: 'all',
	});

	const { data: { company } = {} } = useQuery(GET_ONE_COMPANY, {
		variables: {
			_id: companyId,
		},
	});

	const [
		sendSigningRequest,
		{ loading: signingLoading, error: signingError },
	] = useMutation(ADD_SIGNEES_TO_DOCUMENT);

	const parentList = parentDocumentNodes.map(parent => ({
		to: '/bedrifter/' + (company && company._id) + '/' + parent._id + '/',
		label: parent.name,
	}));

	return (
		<DesktopLayout
			pageTitle="Send til signering"
			breadcrumbs={[
				{
					to: '/bedrifter/',
					label: 'Bedrifter',
				},
				{
					to: '/bedrifter/' + ((company && company._id + '/') || ''),
					label: (company && company.name) || '...',
				},
				...parentList,
				{
					label: (documentNode && documentNode.name) || '...',
					to:
						'/bedrifter/' +
						(company && company._id) +
						'/' +
						documentNodeId +
						'/',
				},
				{
					label: 'Signer',
				},
			]}
			title="Send til signering"
		>
			<NarrowContent>
				<SigneeForm
					loading={loading || signingLoading}
					description={'Send til signering'}
					errors={[error, signingError]}
					documentId={documentNodeId}
					companyId={companyId}
					onSubmit={async variables => {
						try {
							const data = await sendSigningRequest({
								variables,
								refetchQueries: ['paginateDocumentNodes'],
							});

							if (!data.error) {
								notify('Dokument sendt til signering');

								navigate(
									`/bedrifter/${company._id}/${documentNodeId}/`
								);
							}
						} catch (err) {
							console.error(err);
						}
					}}
					{...documentNode}
				/>
			</NarrowContent>
		</DesktopLayout>
	);
}
