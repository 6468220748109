import styled from 'styled-components';

const FormGroupWrapper = styled.div`
	width: 100%;
	margin-bottom: 20px;
`;

export default function FormGroup({ children }) {
	return <FormGroupWrapper>{children}</FormGroupWrapper>;
}
