import { useAppFilters } from 'web/contexts/AppFiltersContext';

import UsersFilterSelect from 'components/filters/components/UsersFilterSelect';

export default function AuditorFilter({ disabled = false, size = 'medium' }) {
	const { auditor, setAuditor } = useAppFilters();

	return (
		<UsersFilterSelect
			title="Vis revisor"
			roles={['auditor']}
			value={auditor}
			onChange={setAuditor}
			disabled={disabled}
			size={size}
		/>
	);
}
