import { createContext, useState, useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const validAlertTypes = ['success', 'info', 'warning', 'error'];

export const SnackbarContext = createContext();

export function useSnackbar() {
	const context = useContext(SnackbarContext);

	if (context === undefined) {
		throw new Error('useSnackbar must be used within a SnackbarProvider');
	}

	return context;
}

export default function SnackbarProvider({ children }) {
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [alertType, setAlertType] = useState('success');

	const handleOpen = (message, type) => {
		if (!validAlertTypes.includes(type)) {
			throw new Error(
				`Must be one of the valid alert types: ${validAlertTypes}.`
			);
		}

		setAlertType(type);
		setMessage(message);
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<SnackbarContext.Provider
			value={{
				notify: (message, type = 'success') =>
					handleOpen(message, type),
			}}
		>
			{children}

			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={open}
				autoHideDuration={3000}
				onClose={handleClose}
			>
				<MuiAlert
					elevation={6}
					variant="filled"
					onClose={handleClose}
					severity={alertType}
				>
					{message}
				</MuiAlert>
			</Snackbar>
		</SnackbarContext.Provider>
	);
}
