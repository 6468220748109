import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { calculationFunctions } from 'server/templates/assignmentRisks';

import { formatDate } from 'utils/dates';
import { sanitizeHtml } from 'utils/sanitizers';

import Message from 'components/messages/Message';
import { InlineLoading } from 'components/Loading';
import Revision from 'components/Revision';
import ErrorMessage from 'components/ErrorMessage';

import DesktopLayout, {
	MediumContent,
	StickyAction,
} from 'web/components/Layout';

import {
	FIND_ONE_ASSIGNMENT_RISK,
	GET_ASSIGNMENT_RISKS_HISTORY,
} from 'api/queries/assignmentRiskQueries';

const Title = styled.h1`
	margin: 0 0 30px 0;
`;

const Description = styled.div`
	margin: 30px 0;
`;

const Section = styled.div`
	margin-bottom: 20px;

	@media print {
		page-break-inside: avoid;
	}
`;

const SectionTitle = styled.h3`
	margin: 0 0 10px 0;
`;

const SectionContent = styled.div`
	background-color: ${p => p.theme.palette.background.default};
	border: 1px solid ${p => p.theme.palette.background.paper};
	padding: 20px 40px;
`;

export default function AssignmentRiskSignle() {
	const { companyId, riskId } = useParams();

	const {
		data: { risk } = { risk: null },
		loading: riskLoading,
		error: riskError,
	} = useQuery(FIND_ONE_ASSIGNMENT_RISK, {
		variables: {
			_id: riskId,
		},
	});

	return (
		<DesktopLayout
			title={`Oppdragsrisiko ${risk?.name}`}
			loading={riskLoading}
			breadcrumbs={[
				{
					to: '/bedrifter/',
					label: 'Bedrifter',
				},
				{
					to: `/bedrifter/${companyId}/rediger/?tab=oppdrag`,
					label: risk?.company?.name || '…',
				},
				{
					label: risk?.name || '...',
				},
			]}
		>
			<ErrorMessage errors={[riskError]} />

			{riskLoading ? (
				<InlineLoading />
			) : (
				<AssignmentRiskViewContent risk={risk} />
			)}
		</DesktopLayout>
	);
}

function AssignmentRiskViewContent({ risk }) {
	if (!risk) {
		return <MediumContent>Fant ikke risiko</MediumContent>;
	}

	return (
		<MediumContent>
			<Title>{risk.name}</Title>

			<AssignmentRiskChangesMessage hasChanges={risk?.hasChanges} />

			<Revision
				needsRevision={risk.needsRevision}
				date={risk?.revision?.date}
				by={risk?.revision?.byDisplayName}
			/>

			<AssignmentRisksHistory riskId={risk._id} />

			<AssignmentRiskDescription description={risk?.description} />

			<AssignmentRiskCategories
				assignmentRiskId={risk._id}
				categories={risk?.categories}
			/>

			<StickyAction>
				<Button
					to={`/bedrifter/${risk.companyId}/rediger/?tab=oppdrag`}
					component={Link}
					startIcon={<ArrowBackIcon />}
				>
					Tilbake
				</Button>

				<Button
					variant="contained"
					size="large"
					to={`/bedrifter/${risk.companyId}/oppdragsrisiko/${risk._id}/rediger`}
					component={Link}
					startIcon={<EditIcon />}
				>
					Rediger
				</Button>
			</StickyAction>
		</MediumContent>
	);
}

function AssignmentRiskChangesMessage({ hasChanges }) {
	if (!hasChanges) return null;

	return (
		<Message type="warning" title="NB! Skjemaet har endringer">
			Skjemaet inneholder endringer som ikke er revidert!
		</Message>
	);
}

function AssignmentRiskDescription({ description }) {
	if (!description) return null;

	return (
		<Description
			dangerouslySetInnerHTML={{
				__html: sanitizeHtml(description),
			}}
		/>
	);
}

function AssignmentRiskCategories({ assignmentRiskId, categories = [] }) {
	if (categories.length === 0) return <div>Mangler kategorier!</div>;

	return categories.map(category => {
		const noQuestionsWithValues = category.questions.every(
			question => !question.value
		);

		if (noQuestionsWithValues) {
			return null;
		}

		return (
			<Section key={category.title}>
				<SectionTitle>{category.title}</SectionTitle>

				<SectionContent>
					<Questions
						assignmentRiskId={assignmentRiskId}
						questions={category.questions}
					/>
				</SectionContent>
			</Section>
		);
	});
}

function Questions({ questions }) {
	return questions.map(question => {
		if (question.dependsOn) {
			try {
				const dependsOnQuestion = questions.find(
					q => q.id === question.dependsOn
				);

				if (!dependsOnQuestion) {
					throw new Error(
						`Depens on question with id ${question.dependsOn} not found`
					);
				}

				if (dependsOnQuestion.value !== question.dependsOnValue) {
					return null;
				}
			} catch (err) {
				console.error(err);

				return null;
			}
		}

		return (
			<QuestionField
				key={question.id}
				allQuestions={questions}
				question={question}
			/>
		);
	});
}

const QuestionWrapper = styled.div`
	margin-bottom: 20px;

	@media print {
		page-break-inside: avoid;
	}
`;

const QuestionTitleWrapper = styled.div`
	margin: 0 0 10px 0;
	position: relative;
	display: flex;
	align-items: center;
	gap: 5px;
`;

const QuestionTitle = styled.h4`
	margin: 0;
`;

const QuestionFieldTypes = {
	title: TitleQuestionField,
	html: HtmlQuestionField,
	boolean: BooleanQuestionField,
	describe: DescribeQuestionField,
	negativeDescribe: DescribeQuestionField,
	text: TextQuestionField,
	textarea: TextAreaQuestionField,
	number: NumberQuestionField,
	currency: CurrencyQuestionField,
	choice: ChoiceQuestionField,
	suggestion: SuggestionQuestionField,
	risk: RiskQuestionField,
	calculation: CalculationQuestionField,
};

function QuestionField({ allQuestions, question }) {
	const QuestionComponent =
		QuestionFieldTypes[question.type] || NotImplementedQuestionField;

	return (
		<QuestionComponent allQuestions={allQuestions} question={question} />
	);
}

function Question({ title = null, description = null, children }) {
	return (
		<QuestionWrapper>
			{title && (
				<QuestionTitleWrapper>
					<QuestionTitle>{title}</QuestionTitle>
				</QuestionTitleWrapper>
			)}

			{description && (
				<RiskQuestionDescription>
					<div
						dangerouslySetInnerHTML={{
							__html: sanitizeHtml(description),
						}}
					/>
				</RiskQuestionDescription>
			)}

			<div>{children}</div>
		</QuestionWrapper>
	);
}

const NotImplementedMessage = styled.div`
	border: 1px solid ${p => p.theme.palette.error.main};
	color: ${p => p.theme.palette.error.main};
	padding: 20px;
`;

function NotImplementedQuestionField({ question }) {
	return (
		<NotImplementedMessage>
			Spørsmålstype «{question.type}» er ikke implementert!
		</NotImplementedMessage>
	);
}

function TitleQuestionField({ question }) {
	return (
		<Question
			title={question.title}
			helpText={question.helpText}
			description={question.description}
		/>
	);
}

function HtmlQuestionField({ question }) {
	return (
		<Question>
			<div
				dangerouslySetInnerHTML={{
					__html: sanitizeHtml(question.value),
				}}
			/>
		</Question>
	);
}

function BooleanQuestionField({ question }) {
	return (
		<Question
			title={question.title}
			helpText={question.helpText}
			description={question.description}
		>
			{question.value === 'nei' ? 'Nei' : 'Ja'}
		</Question>
	);
}

function DescribeQuestionField({ question }) {
	if (!question.value) {
		return null;
	}

	return (
		<Question
			title={question.title}
			helpText={question.helpText}
			description={question.description}
		>
			{question.value}
		</Question>
	);
}

function TextQuestionField({ question }) {
	if (!question.value) {
		return null;
	}

	return (
		<Question
			title={question.title}
			helpText={question.helpText}
			description={question.description}
		>
			{question.value}
		</Question>
	);
}

function TextAreaQuestionField({ question }) {
	if (!question.value) {
		return null;
	}

	return (
		<Question title={question.title} description={question.description}>
			<div style={{ whiteSpace: 'pre-line' }}>{question.value}</div>
		</Question>
	);
}

function NumberQuestionField({ question }) {
	if (!question.value) {
		return null;
	}

	return (
		<Question title={question.title} description={question.description}>
			{question.value}
		</Question>
	);
}

function CurrencyQuestionField({ question }) {
	if (!question.value) {
		return null;
	}

	return (
		<Question title={question.title} description={question.description}>
			{question.value} kr
		</Question>
	);
}

function ChoiceQuestionField({ question }) {
	if (!question.value) {
		return null;
	}

	return (
		<Question title={question.title} description={question.description}>
			{question.value}
		</Question>
	);
}

function SuggestionQuestionField({ question }) {
	if (!question.value) {
		return null;
	}

	return (
		<Question title={question.title} description={question.description}>
			{question.value}
		</Question>
	);
}

const RiskQuestionFieldWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	position: relative;
	margin-bottom: 5px;
`;

const RiskQuestionTitle = styled(QuestionTitle)`
	opacity: ${p => (p.$subdued ? 0.6 : 1)};
	flex: 1;
`;

const RiskQuestionDescription = styled.div`
	margin-bottom: 20px;
`;

const riskColors = {
	Høy: 'error',
	Middels: 'warning',
	Lav: 'success',
	IA: 'info',
};

const riskLabels = {
	Høy: 'Høy risiko',
	Middels: 'Middels risiko',
	Lav: 'Lav risiko',
	IA: 'Ikke aktuelt',
};

const RiskQuestionFieldValue = styled.div``;

function RiskQuestionField({ question }) {
	if (!question.value) {
		return null;
	}

	return (
		<RiskQuestionFieldWrapper>
			<RiskQuestionTitle>{question.title}</RiskQuestionTitle>

			<RiskQuestionFieldValue>
				<Chip
					title={riskLabels[question.value]}
					color={riskColors[question.value]}
					label={question.value}
					sx={{ width: '100px' }}
				/>
			</RiskQuestionFieldValue>
		</RiskQuestionFieldWrapper>
	);
}

function CalculationQuestionField({ allQuestions, question }) {
	const calculationFunction = calculationFunctions[question.id];

	if (!calculationFunction) {
		return (
			<NotImplementedMessage>
				Missing calculation function for question
			</NotImplementedMessage>
		);
	}

	const calculatedValue = calculationFunction(allQuestions);

	return <Question title={question.title}>{calculatedValue}</Question>;
}

const AssignmentRisksHistoryWrapper = styled.div`
	margin-bottom: 20px;

	@media print {
		display: none;
	}
`;

const AssignmentRisksHistoryActions = styled.div`
	display: flex;
	gap: 10px;
	justify-content: flex-end;
	margin-bottom: 20px;
`;

function AssignmentRisksHistory({ riskId }) {
	const [showHistory, setShowHistory] = useState(false);

	return (
		<AssignmentRisksHistoryWrapper>
			<AssignmentRisksHistoryActions>
				<Button
					onClick={() => setShowHistory(prevHistory => !prevHistory)}
				>
					Revisjonshistorikk
				</Button>
			</AssignmentRisksHistoryActions>

			<Collapse in={showHistory}>
				<AssignmentRisksHistoryList riskId={riskId} />
			</Collapse>
		</AssignmentRisksHistoryWrapper>
	);
}

function AssignmentRisksHistoryList({ riskId }) {
	const {
		data: { risks } = { risks: [] },
		loading,
		error,
	} = useQuery(GET_ASSIGNMENT_RISKS_HISTORY, {
		variables: {
			_id: riskId,
		},
	});

	return (
		<Section>
			<SectionContent>
				<ErrorMessage errors={[error]} />

				{loading && <div>Laster...</div>}

				{risks.length === 0 ? (
					<div>Skjemaet har ingen revisjoner.</div>
				) : (
					<ul>
						{risks.map(risk => (
							<li key={risk._id}>
								Revidert{' '}
								<i>
									{formatDate(
										risk.revision.date,
										'DD.MM.YY [kl] HH:mm'
									)}
								</i>{' '}
								av <i>{risk.revision.byDisplayName}</i>
							</li>
						))}
					</ul>
				)}
			</SectionContent>
		</Section>
	);
}
