import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { useAppFilters } from 'web/contexts/AppFiltersContext';

import {
	types,
	postponedStatuses,
	createData,
} from 'web/screens/companies/postponedDeadlines/helpers';

import DataTable from 'web/screens/companies/postponedDeadlines/DataTable';

import ErrorAlert from 'components/ErrorAlert';

import DesktopLayout, { WideContent } from 'web/components/Layout';
import Filter, { FiltersWrapper } from 'web/components/inputs/Filter';
import YearFilter from 'components/filters/YearFilter';
import DepartmentFilter from 'components/filters/DepartmentFilter';

import { GET_COMPANIES_WITH_POSTPONED_TASK_DEADLINE_ENTRY } from 'api/queries/companyAssignmentTaskQueries';

export default function CompaniesWithPostponedTaskDeadline() {
	const [type, setType] = useState(types[0]);
	const [postponedStatus, setPostponedStatus] = useState(
		postponedStatuses[0]
	);

	const { year, department } = useAppFilters();

	const {
		data: { entries } = {
			entries: [],
		},
		loading,
		error,
	} = useQuery(GET_COMPANIES_WITH_POSTPONED_TASK_DEADLINE_ENTRY, {
		variables: {
			year: year.value,
			departmentId: department?.value,
			type: type.value,
			postponedStatus: postponedStatus.value,
		},
	});

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					to: '/bedrifter/',
					label: 'Bedrifter',
				},
				{
					label: 'Utsatte oppgaver',
				},
			]}
		>
			<WideContent>
				<FiltersWrapper>
					<YearFilter size="small" />

					<DepartmentFilter size="small" />

					<Filter
						name="type"
						label="Velg oppgavetype"
						value={type}
						options={types}
						onChange={value => setType(value)}
					/>

					<Filter
						name="postponedStatus"
						label="Hvem søker?"
						value={postponedStatus}
						options={postponedStatuses}
						onChange={value => setPostponedStatus(value)}
					/>
				</FiltersWrapper>
				<ErrorAlert error={error} />

				<DataTable
					key={
						`${year.value}-${type.value}-${postponedStatus.value}` /*Resetting state when filters change */
					}
					data={entries.map(entry => createData(entry))}
					year={year.value}
					type={type.value}
					loading={loading}
				/>
			</WideContent>
		</DesktopLayout>
	);
}
