import styled from 'styled-components';

const Wrapper = styled.div`
	font-size: 14px;
	padding-bottom: ${p => (p.$show ? '32px' : 0)};
	height: ${p => (p.$show ? '64px' : 0)};
	overflow: hidden;
	transition: all 0.2s ease-in-out;
`;

const Progress = styled.div`
	background-color: ${p => p.theme.palette.common.gray};
	height: 4px;
	overflow: hidden;
	position: relative;
	margin-bottom: 6px;
`;

const Bar = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	transition: transform 0.2s linear;
	transform-origin: left;
	background-color: ${p =>
		(p.$value <= 3 && p.theme.palette.status.error) ||
		(p.$value === 4 && 'orange') ||
		(p.$value === 5 && p.theme.palette.status.success)};
	transition: transform 0.4s linear;
	transform: translateX(-${p => 100 - (p.value / 5) * 100}%);
`;

export default function PasswordStrength({ password = '' }) {
	const passwordTracker = {
		uppercase: password.match(/[A-Z]/g),
		lowercase: password.match(/[a-z]/g),
		number: password.match(/[0-9]/g),
		specialChar: password.match(/[#?!@$%^&*-]/g),
		eightCharsOrGreater: password.match(/.{8,}/g),
	};

	const passwordStrength = Object.values(passwordTracker).filter(
		value => value
	).length;

	const strengthLabel = ['dårlig', 'dårlig', 'middels', 'bra', 'sterkt'];

	return (
		<Wrapper $show={passwordStrength > 0}>
			<Progress>
				<Bar $value={passwordStrength} />
			</Progress>
			<div>Passordstyrke: {strengthLabel[passwordStrength - 1]}</div>
		</Wrapper>
	);
}
