import { StyledALink } from 'web/components/Link';

import {
	Wrapper,
	Document,
	StyledIcon,
	SubTitle,
	NoDocumentsMessage,
} from 'web/components/widgets/components/lists/common/ListComponents';

export default function SignatureRequestsList({ signatureRequests }) {
	return (
		<Wrapper>
			{signatureRequests.length > 0 ? (
				<div>
					{signatureRequests.map(signatureRequest => (
						<Document key={signatureRequest._id}>
							<StyledIcon icon="file-signature" />

							<div>
								<StyledALink
									href={signatureRequest.signUrl}
									target="_blank"
									rel="noopener"
								>
									{signatureRequest.documentNode.name}
								</StyledALink>

								<SubTitle>
									{signatureRequest.documentNode.tenant.name}
								</SubTitle>
							</div>
						</Document>
					))}
				</div>
			) : (
				<NoDocumentsMessage>
					Ingen dokumenter som avventer signering
				</NoDocumentsMessage>
			)}
		</Wrapper>
	);
}
