import styled from 'styled-components';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

import { sanitizeHtml } from 'utils/sanitizers';

const StyledTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: 500,
	},
});

const TooltipTextWrapper = styled.div`
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: ${p => p.theme.palette.text.white};
		margin-top: 0;
	}

	a {
		color: ${p => p.theme.palette.text.white};
	}
`;

const TooltipIconWrapper = styled.span`
	color: ${p => p.theme.palette.common.darkGray};
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	position: ${p => (p.$absolutePosition ? 'absolute' : 'relative')};
	right: ${p => (p.$absolutePosition ? '-30px' : 0)};
	height: 30px;
	width: 30px;
	text-align: center;
	line-height: 56px;
`;

export default function HelpText({ text, absolutePosition = false }) {
	if (!text) return null;

	return (
		<StyledTooltip
			title={
				<TooltipTextWrapper
					dangerouslySetInnerHTML={{
						__html: sanitizeHtml(text),
					}}
				/>
			}
			placement="right"
		>
			<TooltipIconWrapper $absolutePosition={absolutePosition}>
				<HelpIcon />
			</TooltipIconWrapper>
		</StyledTooltip>
	);
}
