import styled from 'styled-components';
import MuiButton from '@mui/material/Button';
import MuiPersonIcon from '@mui/icons-material/Person';

export const InfoSection = styled.section``;

export const InfoTitle = styled.h4`
	margin: 0 0 15px 0;
	font-size: 16px;
`;

export const OuterInfoWrapper = styled.div`
	width: 100%;
`;

export const InfoLineWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export const InfoLine = styled.div`
	display: flex;
	align-items: center;
	flex: 1 1 0%;
`;

export const InfoValue = styled.div`
	margin-left: 10px;
	line-height: 1;
`;

export const SubduedValue = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-style: italic;
	font-size: 0.9rem;
	margin-top: 5px;
`;

export const PersonIcon = MuiPersonIcon;

export function Button(props) {
	return (
		<MuiButton
			size="small"
			variant="outlined"
			color="primary"
			type="button"
			{...props}
		/>
	);
}
