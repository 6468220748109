import { companyControlStatusColor } from 'utils/colors';

import useChildTotal from 'hooks/useChildTotal';

import { useAppFilters } from 'web/contexts/AppFiltersContext';

import {
	WidgetList,
	WidgetListItem,
	WidgetListLabel,
} from 'web/components/widgets/components/WidgetList';
import WidgetListData from 'web/components/widgets/components/WidgetListData';
import AnnualSettlementControlStatusDialogContent from 'web/components/widgets/dialogs/AnnualSettlementControlStatusDialogContent';

import Widget from 'web/components/widgets/Widget';

import {
	COMPANIES_ANNUAL_SETTLEMENT_CONTROL_STATUS_READY,
	COMPANIES_CONTROLLED,
} from 'api/queries/widgetQueries';

export default function CompanyControlsWidget() {
	const { year } = useAppFilters();

	return (
		<Widget title="Kontroller" titleIcon="circle-check">
			<CompanyControlWidgetList
				title={`Årsregnskap- og skattemeldingskontroll ${
					year.value - 1
				}`}
				type="arsregnskap-skattemelding"
			/>

			<CompanyControlWidgetList
				title={`Kvalitetskontroll ${year.value}`}
				type="kvalitet"
			/>
		</Widget>
	);
}

function CompanyControlWidgetList({ title, type }) {
	const [total, updateTotal] = useChildTotal();

	return (
		<>
			<WidgetList title={title}>
				{type === 'arsregnskap-skattemelding' && (
					<WidgetListData
						name="ready"
						label={
							<WidgetListLabel
								label="Klart til kontroll"
								chipColor={companyControlStatusColor('READY')}
							/>
						}
						query={COMPANIES_ANNUAL_SETTLEMENT_CONTROL_STATUS_READY}
						link={id => `/bedrifter/${id}/rediger/?tab=skjema`}
						total={total}
						onLoaded={updateTotal}
						DialogContent={({ items, link }) => (
							<AnnualSettlementControlStatusDialogContent
								items={items}
								link={link}
							/>
						)}
					/>
				)}

				<WidgetListData
					name="review"
					label={
						<WidgetListLabel
							label="Revisjon"
							chipColor={companyControlStatusColor('REVIEW')}
						/>
					}
					query={COMPANIES_CONTROLLED}
					variables={{
						type,
						statusCode: 'REVIEW',
					}}
					link={id => `/bedrifter/${id}/rediger/?tab=skjema`}
					total={total}
					onLoaded={updateTotal}
				/>

				<WidgetListData
					name="control"
					label={
						<WidgetListLabel
							label="Avviksbehandling"
							chipColor={companyControlStatusColor('CONTROL')}
						/>
					}
					query={COMPANIES_CONTROLLED}
					variables={{
						type,
						statusCode: 'CONTROL',
					}}
					link={id => `/bedrifter/${id}/rediger/?tab=skjema`}
					total={total}
					onLoaded={updateTotal}
				/>

				<WidgetListData
					name="approval"
					label={
						<WidgetListLabel
							label="Godkjenning"
							chipColor={companyControlStatusColor('APPROVAL')}
						/>
					}
					query={COMPANIES_CONTROLLED}
					variables={{
						type,
						statusCode: 'APPROVAL',
					}}
					link={id => `/bedrifter/${id}/rediger/?tab=skjema`}
					total={total}
					onLoaded={updateTotal}
				/>

				<WidgetListData
					name="approved"
					label={
						<WidgetListLabel
							label="Godkjent"
							chipColor={companyControlStatusColor('APPROVED')}
						/>
					}
					query={COMPANIES_CONTROLLED}
					variables={{
						type,
						statusCode: 'APPROVED',
					}}
					link={id => `/bedrifter/${id}/rediger/?tab=skjema`}
					total={total}
					onLoaded={updateTotal}
				/>
			</WidgetList>

			<WidgetList>
				<WidgetListItem label="Totalt" value={total} />
			</WidgetList>
		</>
	);
}
