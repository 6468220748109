import { useState } from 'react';
import { useMutation } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorAlert from 'components/ErrorAlert';

import Form from 'components/form/Form';
import FileField from 'components/form/FileField';

import { REQUEST_ID_DOCUMENT_UPLOAD } from 'api/mutations/userMutations';

export default function IDDocumentUploadDialog({ userId, open, onClose }) {
	const { notify } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const [requestIDDocumentUpload, { error: requestIDDocumentUploadErrror }] =
		useMutation(REQUEST_ID_DOCUMENT_UPLOAD);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="xs"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Last opp identifikasjonsdokument
			</DialogTitle>

			<Form
				isLoading={isLoading}
				isDisabled={isLoading}
				onSubmit={async ({ file }) => {
					try {
						setIsLoading(true);

						const {
							data: { uploadUrl },
						} = await requestIDDocumentUpload({
							variables: {
								userId,
								fileName: file.name,
								contentType: file.type,
							},
						});

						if (!uploadUrl) return;

						const response = await fetch(uploadUrl, {
							method: 'PUT',
							headers: { 'Content-Type': file.type },
							body: file,
						});

						if (!response.ok) {
							setError('Feil ved opplasting av dokument!');
							setIsLoading(false);
							return;
						}

						setIsLoading(false);
						notify('ID-dokumentet ble lastet opp!');
						onClose();
					} catch (err) {
						setIsLoading(false);
						console.warn(err);
					}
				}}
			>
				<DialogContent>
					<ErrorAlert error={requestIDDocumentUploadErrror} />
					<ErrorAlert error={error} />

					<FileField
						required
						name="file"
						label="Velg fil"
						helperText="Filen må være en PDF og mindre enn 15 MB."
						maxSize="15000000"
						accept="application/pdf"
					/>
				</DialogContent>

				<DialogActions>
					<Button onClick={onClose}>Avbryt</Button>

					<Button
						type="submit"
						variant="contained"
						disabled={isLoading}
						startIcon={<SaveIcon />}
						autoFocus
					>
						Last opp fil
					</Button>
				</DialogActions>
			</Form>
		</Dialog>
	);
}
