import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const FiltersWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 20px;
	margin-bottom: 20px;
`;

export default function Filter({ name, label, value, options, onChange }) {
	return (
		<FormControl sx={{ minWidth: 120 }} size="small">
			<InputLabel id={`filter-select-label-${name}`}>{label}</InputLabel>

			<Select
				labelId={`filter-select-label-${name}`}
				id={`filter-select-${name}`}
				label={label}
				value={value.value}
				onChange={event => {
					const value = event.target.value;

					onChange({
						value,
						label: options.find(option => option.value === value)
							.label,
					});
				}}
			>
				{options.map(option => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
