import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { formatDate } from 'utils/dates';

import ErrorAlert from 'components/ErrorAlert';
import { InlineLoading } from 'components/Loading';

import { DeadlineItem } from 'web/components/widgets/GovernmentDeadlinesWidget';

import { GET_GOVERNMENT_DEADLINES } from 'api/queries/governmentDeadlineQueries';

const Section = styled.div`
	margin-bottom: 30px;
`;

const SectionTitle = styled.h3`
	margin: 0 0 15px 0;
	text-transform: capitalize;
`;

export default function GovernmentDeadlinesDialog({ open, onClose }) {
	const {
		data: { deadlines } = {
			deadlines: [],
		},
		loading,
		error,
	} = useQuery(GET_GOVERNMENT_DEADLINES);

	const deadlinesGroupedByMonth = groupBy(deadlines, item =>
		formatDate(item.date, 'YYYY-M-MMMM')
	);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="sm"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Offentlige frister
			</DialogTitle>

			<DialogContent>
				<ErrorAlert error={error} />

				{loading ? (
					<InlineLoading />
				) : (
					<div>
						{map(
							deadlinesGroupedByMonth,
							(deadlinesInMonth, monthStr) => {
								const [year, monthKey, monthName] =
									monthStr.split('-');

								return (
									<Section key={`${year}-${monthKey}`}>
										<SectionTitle>
											{monthName} {year}
										</SectionTitle>

										{deadlinesInMonth.map(deadline => (
											<DeadlineItem
												key={deadline._id}
												deadline={deadline}
											/>
										))}
									</Section>
								);
							}
						)}
					</div>
				)}
			</DialogContent>

			<DialogActions>
				<Button variant="contained" onClick={onClose}>
					Lukk
				</Button>
			</DialogActions>
		</Dialog>
	);
}
