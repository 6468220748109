import styled, { css } from 'styled-components';
import Icon from 'components/Icon';
import Link from 'web/components/Link';

import { formatDate } from 'utils/dates';

const sharedLinkStyles = css`
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	color: ${p => p.theme.palette.text.primary};
	text-decoration: none;
	transition: all 0.5s;

	&:hover {
		color: ${p => p.theme.palette.text.primary};
	}
`;
const DocumentLink = styled(Link)`
	${sharedLinkStyles}
`;
const DocumentA = styled.a`
	${sharedLinkStyles}
`;

const Name = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.2rem;
	flex: 1;
	margin-bottom: 5px;
	transition: all 0.5s;

	&:hover {
		color: ${p => p.theme.palette.primary.main};
		text-decoration: underline;
	}
`;

const StyledIcon = styled(Icon)`
	margin-right: 10px;
	width: 20px;
	text-align: center;

	&.fa-file-check {
		color: ${p => p.theme.palette.status.success};
	}

	&.fa-file-signature {
		color: ${p => p.theme.palette.status.error};
	}
`;

const File = styled.div``;

const Byline = styled.div`
	color: ${p => p.theme.palette.text.secondary};
	font-style: italic;
`;

export default function DocumentItem({ doc, href = null, to = null }) {
	if (href) {
		return (
			<DocumentA href={href} target="_blank">
				<ItemContent doc={doc} />
			</DocumentA>
		);
	} else if (to) {
		return (
			<DocumentLink to={to}>
				<ItemContent doc={doc} />
			</DocumentLink>
		);
	} else {
		return null;
	}
}

function ItemContent({ doc }) {
	function getIcon() {
		if (doc.type === 'folder') return 'folder';
		if (doc.isSigningComplete) return 'file-check';
		if (doc.isSigning) return 'file-signature';

		return 'file';
	}

	return (
		<>
			<StyledIcon icon={getIcon()} />
			<File>
				<Name>{doc.name}</Name>

				<Byline>
					<span>{doc.createdBy}</span>
					<span> – </span>

					<span>
						{formatDate(doc.createdAt, 'DD.MM.YY [kl] HH:mm')}
					</span>
				</Byline>
			</File>
		</>
	);
}
