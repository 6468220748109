import { useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';

import { defaultFolders } from 'server/libs/defaultFolders';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';
import Revision from 'components/Revision';
import DeleteButton from 'components/buttons/DeleteButton';

import DesktopLayout, {
	NarrowContent,
	StickyAction,
} from 'web/components/Layout';
import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import AutocompleteField from 'components/form/AutocompleteField';
import CompanyRoutineFields from 'components/form/CompanyRoutineFields';
import SubmitButton from 'components/form/SubmitButton';

import { GET_ONE_COMPANY } from 'api/queries/tenantQueries';
import { FIND_ONE_COMPANY_ROUTINE } from 'api/queries/companyRoutineQueries';
import {
	UPDATE_COMPANY_ROUTINE,
	DELETE_COMPANY_ROUTINE,
} from 'api/mutations/companyRoutineMutations';

export default function CompanyRoutinesEdit() {
	const { notify } = useSnackbar();
	const navigate = useNavigate();
	const { companyId, routineId } = useParams();

	const {
		data: { company } = { company: null },
		loading: companyLoading,
		error: companyError,
	} = useQuery(GET_ONE_COMPANY, {
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		variables: {
			_id: companyId,
		},
	});

	const {
		data: { routine } = { routine: null },
		loading: routineLoading,
		error: routineError,
	} = useQuery(FIND_ONE_COMPANY_ROUTINE, {
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
		variables: {
			_id: routineId,
		},
	});

	const [
		updateRoutine,
		{ loading: updateRoutineLoading, error: updateRoutineError },
	] = useMutation(UPDATE_COMPANY_ROUTINE);

	const [
		deleteRoutine,
		{ loading: deleteRoutineLoading, error: deleteRoutineError },
	] = useMutation(DELETE_COMPANY_ROUTINE);

	return (
		<DesktopLayout
			title="Rediger rutine"
			breadcrumbs={[
				{
					to: '/bedrifter/',
					label: 'Bedrifter',
				},
				{
					to: `/bedrifter/${company?._id}/rediger/`,
					label: company?.name || '...',
				},
				{
					to: `/bedrifter/${company?._id}/rediger/?tab=skjema`,
					label: 'Rutiner',
				},
				{
					label: `Rediger ${routine?.name ?? 'rutine'}`,
				},
			]}
		>
			<NarrowContent>
				<ErrorMessage
					errors={[
						companyError,
						routineError,
						updateRoutineError,
						deleteRoutineError,
					]}
				/>

				<Revision
					needsRevision={routine?.needsRevision}
					date={routine?.revision?.date}
					by={routine?.revision?.byDisplayName}
				/>

				<Form
					key={routine && routine._id}
					values={routine}
					isLoading={
						companyLoading ||
						routineLoading ||
						updateRoutineLoading ||
						deleteRoutineLoading
					}
					isDisabled={
						companyLoading ||
						routineLoading ||
						updateRoutineLoading ||
						deleteRoutineLoading
					}
					onSubmit={async values => {
						const valuesArray = Object.keys(values.values).map(
							key => ({
								name: key,
								value: values.values[key],
							})
						);

						const variables = {
							_id: values._id,
							name: values.name,
							folderPlacements: values.folderPlacements,
							values: valuesArray,
						};

						const res = await updateRoutine({ variables });

						notify('Rutinen ble oppdatert!');

						if (res) {
							navigate(
								`/bedrifter/${companyId}/rediger/?tab=skjema`
							);
						}
					}}
				>
					<TextField name="name" label="Navn på rutine" required />

					<AutocompleteField
						name="folderPlacements"
						label="Vis i mappe(r)"
						options={defaultFolders.map(({ slug, name }) => ({
							value: slug,
							label: name,
						}))}
						multiple
					/>

					{routine && (
						<CompanyRoutineFields
							fields={routine.fields}
							companyId={routine.companyId}
						/>
					)}

					<StickyAction>
						<DeleteButton
							disabled={deleteRoutineLoading}
							onClick={async () => {
								const { data } = await deleteRoutine({
									variables: {
										_id: routine._id,
									},
								});

								if (!data.deleted) {
									throw new Error(
										'Det oppstod en feil ved sletting!'
									);
								}
							}}
							redirect={`/bedrifter/${companyId}/rediger/?tab=skjema`}
						/>

						<SubmitButton
							variant="contained"
							size="large"
							icon={<SaveIcon />}
						>
							Revider
						</SubmitButton>
					</StickyAction>
				</Form>
			</NarrowContent>
		</DesktopLayout>
	);
}
