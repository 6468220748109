import { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import MUITextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchBar({ label, value = '', updateSearch }) {
	const handleChange = debounce(value => updateSearch(value), 750);
	const inputRef = useRef();

	useEffect(() => {
		// Listen for changes in other components and update the value
		inputRef.current.value = value;
	}, [value]);

	return (
		<MUITextField
			label={label}
			inputRef={inputRef}
			variant="outlined"
			fullWidth={true}
			onChange={({ target }) => handleChange(target.value)}
			slotProps={{
				input: {
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
				},
			}}
		/>
	);
}
