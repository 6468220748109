import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import { UnderlineLink } from 'web/components/Link';
import Signers from 'web/components/Signers';

const UnsignedDocument = styled.div`
	border-bottom: 1px solid ${p => p.theme.palette.common.gray};
	margin-bottom: 20px;
	padding-bottom: 20px;

	&:last-of-type {
		border: none;
	}
`;

const Document = styled.div`
	margin-bottom: 10px;
	font-size: 1.2rem;
`;

const DocumentLabel = styled.strong`
	margin-right: 10px;
`;

export default function UnsignedDocumentsDialog({
	unsignedDocuments = [],
	linkBase,
	isOpen,
	close,
}) {
	return (
		<Dialog
			open={isOpen}
			fullWidth={true}
			maxWidth="md"
			onClose={close}
			aria-labelledby="documents-with-missing-signees-modal-title"
			aria-describedby="documents-with-missing-signees-modal-description"
		>
			<DialogTitle id="documents-with-missing-signees-modal-title">
				Dokumenter som mangler signering
			</DialogTitle>

			<DialogContent id="documents-with-missing-signees-modal-description">
				{unsignedDocuments?.map(({ _id, name }) => (
					<UnsignedDocument key={_id}>
						<Document>
							<DocumentLabel>Dokument:</DocumentLabel>

							<UnderlineLink to={`${linkBase}${_id}/`}>
								{name}
							</UnderlineLink>
						</Document>

						<Signers documentId={_id} showSignedSignees={false} />
					</UnsignedDocument>
				))}
			</DialogContent>

			<DialogActions>
				<Button
					type="submit"
					color="primary"
					variant="contained"
					onClick={close}
				>
					Lukk
				</Button>
			</DialogActions>
		</Dialog>
	);
}
