import { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import Button from '@mui/material/Button';

import GovernmentDeadlinesDialog from 'web/components/widgets/dialogs/GovernmentDeadlinesDialog';

import ErrorAlert from 'components/ErrorAlert';

import Widget from 'web/components/widgets/Widget';
import CalendarIcon from 'web/components/widgets/components/CalendarIcon';

import { StyledALink } from 'web/components/Link';

import { GET_GOVERNMENT_DEADLINES } from 'api/queries/governmentDeadlineQueries';

const ActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
`;

const ALink = styled.a`
	color: ${p => p.theme.palette.primary.main};
	text-decoration: none;
`;

// Set startDate one week back in time.
const startDate = new Date();
startDate.setDate(startDate.getDate() - 7);
// Set endDate to the first day of the month two months from now.
const endDate = new Date();
endDate.setMonth(endDate.getMonth() + 2);
endDate.setDate(1);

export default function GovernmentDeadlinesWidget() {
	const [open, setOpen] = useState(false);

	const {
		data: { deadlines } = {
			deadlines: [],
		},
		loading,
		error,
	} = useQuery(GET_GOVERNMENT_DEADLINES, {
		variables: {
			startDate,
			endDate,
		},
	});

	return (
		<Widget title="Offentlige frister" titleIcon="clock" loading={loading}>
			<ErrorAlert error={error} />

			{deadlines.map(deadline => (
				<DeadlineItem key={deadline._id} deadline={deadline} />
			))}

			<ActionWrapper>
				<Button onClick={() => setOpen(true)}>Se alle</Button>
			</ActionWrapper>

			<GovernmentDeadlinesDialog
				open={open}
				onClose={() => setOpen(false)}
			/>
		</Widget>
	);
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 10px;
`;

const Title = styled.div`
	margin-bottom: 5px;
`;

const Description = styled.div`
	color: ${p => p.theme.palette.common.darkGray};
	font-size: 14px;
`;

export function DeadlineItem({ deadline }) {
	return (
		<Wrapper>
			<ALink href={deadline.link} target="_blank" rel="noreferrer">
				<CalendarIcon date={deadline.date} />
			</ALink>

			<Title>
				<StyledALink
					href={deadline.link}
					target="_blank"
					rel="noreferrer"
				>
					{deadline.title}
				</StyledALink>

				{deadline.description && (
					<Description>{deadline.description}.</Description>
				)}
			</Title>
		</Wrapper>
	);
}
