import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';

import { validateEmail, validatePhone } from 'utils/strings';

import {
	DocumentTitleWrapper,
	DocumentTitle,
	ParentTitle,
	DocumentParagraph,
} from 'components/DocumentLayout';

import Card from 'components/Card';
import DocumentIcon from 'components/DocumentIcon';
import SigneeEditor from 'components/SigneeEditor';
import ErrorMessage from 'components/ErrorMessage';

import { FIND_TENANT_BOARD_MEMBER_USERS } from 'api/queries/userQueries';

export default function SigneeForm({
	documentId,
	companyId,
	onSubmit,
	loading,
	errors,
	singleLabel = 'Signatur #',
	description = 'Her kan vi komme med en kort beskjed om hvordan signering fungerer..',
	...doc
}) {
	const [formData, setFormData] = useState({
		sent: false,
		signees: [],
	});
	const { signees } = formData;

	const [
		findTenantBoardMembers,
		{
			data: { users: tenantBoardMemberUsers } = { users: null },
			loading: tenantBoardMemberUsersLoading,
			error: tenantBoardMemberUsersError,
		},
	] = useLazyQuery(FIND_TENANT_BOARD_MEMBER_USERS, {
		variables: {
			tenantId: companyId,
		},
		errorPolicy: 'all',
	});

	useEffect(() => {
		if (!tenantBoardMemberUsers || tenantBoardMemberUsersError) return;

		setFormData({
			...formData,
			signees: [
				...signees,
				...tenantBoardMemberUsers.items.map(user => {
					return {
						key: uuid(),
						email: '',
						phone: '',
						userId: user._id,
					};
				}),
			],
		});
	}, [tenantBoardMemberUsers]);

	function isSigneesDataValid(signees) {
		if (signees.length < 1) {
			return false;
		}

		const validSignees = signees.filter(
			signee =>
				validateEmail(signee.email) ||
				validatePhone(signee.phone) ||
				signee.userId
		);

		return validSignees.length === signees.length;
	}

	function addSigneesToDocument({ documentId, signees }) {
		if (!isSigneesDataValid(signees)) {
			setFormData({
				...formData,
				loading: false,
				error: 'Mangler informasjon',
				sent: false,
				signees,
			});

			return;
		}

		setFormData({
			...formData,
			loading: true,
			error: null,
			sent: false,
			signees,
		});

		onSubmit({
			signees,
			documentId,
		});
	}

	return (
		<Card
			key="document"
			icon={
				<DocumentIcon
					needsSignature={false}
					hasSignature={false}
					fontSize="large"
				/>
			}
		>
			<DocumentTitleWrapper>
				<DocumentTitle>{doc.name}</DocumentTitle>

				{doc.parent && doc.parent.name && (
					<ParentTitle>
						{' / '}
						{doc.parent.name}
					</ParentTitle>
				)}

				<DocumentParagraph $align="center">
					{description}
				</DocumentParagraph>
			</DocumentTitleWrapper>

			<ErrorMessage errors={[...errors, tenantBoardMemberUsersError]} />

			{signees.map((s, i) => (
				<SigneeEditor
					key={s.key}
					signee={s}
					allSignees={signees}
					companyId={companyId}
					label={`${singleLabel}${i + 1}`}
					disabled={loading || !!tenantBoardMemberUsersLoading}
					onRemove={() => {
						const newSignees = signees.filter(
							(item, index) => index !== i
						);

						setFormData({
							...formData,
							signees: newSignees,
						});
					}}
					onChange={signee => {
						signees[i] = signee;
						setFormData({
							...formData,
							signees: [...signees],
						});
					}}
				/>
			))}

			<DocumentParagraph $align="center">
				<Button
					type="button"
					color="primary"
					disabled={loading}
					endIcon={<AddIcon />}
					onClick={() => {
						findTenantBoardMembers();
					}}
				>
					LEGG TIL ALLE I STYRE
				</Button>

				<Button
					type="button"
					color="primary"
					disabled={loading}
					endIcon={<AddIcon />}
					onClick={() => {
						setFormData({
							...formData,
							signees: [
								...signees,
								{
									key: uuid(),
									email: '',
									phone: '',
									userId: '',
								},
							],
						});
					}}
				>
					LEGG TIL ENKELTPERSON
				</Button>
			</DocumentParagraph>

			<DocumentParagraph $align="center">
				<Button
					variant="contained"
					size="large"
					disabled={!isSigneesDataValid(signees) || loading}
					onClick={() =>
						addSigneesToDocument({ documentId, signees })
					}
					startIcon={<SendIcon />}
				>
					Send
				</Button>
			</DocumentParagraph>
		</Card>
	);
}
