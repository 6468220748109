import { useMutation } from '@apollo/client';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { countries } from 'utils/countries';

import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import AutocompleteField from 'components/form/AutocompleteField';
import SubmitButton from 'components/form/SubmitButton';

import { VERIFY_ONE_USER_CITIZENSHIP } from 'api/mutations/userMutations';

export default function CitizenshipVerifyModal({
	_id,
	verificationCitizenship,
}) {
	const [verifyUserCitizenship, { loading, error }] = useMutation(
		VERIFY_ONE_USER_CITIZENSHIP,
		{
			refetchQueries: ['currentUser'],
		}
	);

	return (
		<>
			<DialogTitle id="user-verification-dialog-title">
				Vennligst bekreft ditt statsborgerskap
			</DialogTitle>

			<ErrorMessage errors={error} />

			<Form
				values={{ citizenship: verificationCitizenship }}
				isLoading={loading}
				onSubmit={async ({ citizenship }) => {
					try {
						verifyUserCitizenship({
							variables: {
								_id,
								citizenship,
							},
						});
					} catch (err) {
						console.warn(err);
					}
				}}
			>
				<DialogContent>
					<AutocompleteField
						label="Statsborger i land"
						name="citizenship"
						options={countries.map(country => ({
							label: country,
							value: country,
						}))}
					/>
				</DialogContent>

				<DialogActions>
					<SubmitButton variant="contained" color="primary">
						Bekreft statsborgerskap
					</SubmitButton>
				</DialogActions>
			</Form>
		</>
	);
}
