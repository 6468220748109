import { useAppFilters } from 'web/contexts/AppFiltersContext';

import UsersFilterSelect from 'components/filters/components/UsersFilterSelect';

export default function ControllerFilter({
	disabled = false,
	size = 'medium',
}) {
	const { controller, setController } = useAppFilters();

	return (
		<UsersFilterSelect
			title="Vis oppdragskontrollør"
			roles={['admin', 'accountant']}
			value={controller}
			onChange={setController}
			disabled={disabled}
			size={size}
		/>
	);
}
