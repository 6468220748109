import styled from 'styled-components';
import Icon from 'components/Icon';

export const ErrorIcon = styled(Icon)`
	color: ${p => p.theme.palette.status.error};
`;
export const SuccessIcon = styled(Icon)`
	color: ${p => p.theme.palette.status.success};
`;

export default function DocumentIcon({
	className,
	needsSignature = false,
	hasSignature = false,
	type,
	...rest
}) {
	return (
		<>
			{needsSignature ? (
				hasSignature ? (
					<SuccessIcon
						icon="file-check"
						className={className}
						{...rest}
					/>
				) : (
					<ErrorIcon
						icon="file-signature"
						solid
						className={className}
						{...rest}
					/>
				)
			) : (
				<Icon
					icon={type === 'folder' ? 'folder' : 'file'}
					solid
					className={className}
					{...rest}
				/>
			)}
		</>
	);
}
