import { useMutation } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import { GET_COMPANY_DOCUMENTS } from 'api/queries/documentQueries';
import { CREATE_FOLDER } from 'api/mutations/documentMutations';

export default function FolderCreateDialog({
	parentId,
	tenantId,
	isOpen,
	order,
	orderBy,
	includeHidden,
	close,
}) {
	return (
		<Dialog
			open={isOpen}
			fullWidth={true}
			maxWidth="xs"
			onClose={close}
			aria-labelledby="folder-create-dialog-title"
		>
			<DialogTitle id="folder-create-dialog-title">Ny mappe</DialogTitle>

			<FolderCreateForm
				parentId={parentId}
				tenantId={tenantId}
				order={order}
				orderBy={orderBy}
				includeHidden={includeHidden}
				close={close}
			/>
		</Dialog>
	);
}

function FolderCreateForm({
	parentId,
	tenantId,
	order,
	orderBy,
	includeHidden,
	close,
}) {
	const { notify } = useSnackbar();

	const [createFolder, { loading, error }] = useMutation(CREATE_FOLDER, {
		refetchQueries: [
			{
				query: GET_COMPANY_DOCUMENTS,
				variables: {
					parentId,
					tenantId,
					order,
					orderBy,
					includeHidden,
				},
			},
		],
	});

	return (
		<Form
			isLoading={loading}
			onSubmit={async folder => {
				try {
					const { data } = await createFolder({
						variables: {
							parentId: parentId,
							tenantId,
							...folder,
						},
					});

					if (data?.folder._id) {
						notify('Mappen ble opprettet');

						close();
					}
				} catch (err) {
					console.error(err);
				}
			}}
		>
			<DialogContent>
				<ErrorMessage errors={[error]} />

				<TextField name="name" label="Mappenavn" />
			</DialogContent>

			<DialogActions>
				<Button onClick={close}>Avbryt</Button>

				<SubmitButton
					variant="contained"
					icon={<CreateNewFolderIcon />}
				>
					Opprett mappe
				</SubmitButton>
			</DialogActions>
		</Form>
	);
}
