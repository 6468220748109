import { useState } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import DocumentCreateDialog from 'web/screens/dialogs/documents/DocumentCreateDialog';
import FolderCreateDialog from 'web/screens/dialogs/documents/FolderCreateDialog';

export default function CreateMenu({
	parentId,
	tenantId,
	order,
	orderBy,
	includeHidden,
}) {
	const [currentModal, setCurrentModal] = useState(null);

	return (
		<>
			<SpeedDial
				ariaLabel="Ny fil eller mappe"
				icon={<SpeedDialIcon />}
				sx={{ position: 'fixed', bottom: '5vw', right: '5vw' }}
			>
				<SpeedDialAction
					icon={<FolderOpenIcon />}
					tooltipTitle="Ny mappe"
					onClick={() => setCurrentModal('folder')}
				/>

				<SpeedDialAction
					icon={<UploadFileIcon />}
					tooltipTitle="Ny fil"
					onClick={() => setCurrentModal('document')}
				/>
			</SpeedDial>

			<DocumentCreateDialog
				parentId={parentId}
				tenantId={tenantId}
				order={order}
				orderBy={orderBy}
				includeHidden={includeHidden}
				isOpen={currentModal === 'document'}
				close={() => setCurrentModal(null)}
			/>

			<FolderCreateDialog
				parentId={parentId}
				tenantId={tenantId}
				order={order}
				orderBy={orderBy}
				includeHidden={includeHidden}
				isOpen={currentModal === 'folder'}
				close={() => setCurrentModal(null)}
			/>
		</>
	);
}
