import dayjs from 'utils/dates';

/**
 * Pluralize a word.
 */
export function pluralize(singular, plural, array) {
	if (!Array.isArray(array) || array.length === 1) return singular;

	return plural;
}

/**
 * Checks if the given phone number is valid.
 *
 * @param {string} phone - The phone number to validate.
 * @returns {boolean} - Returns true if the phone number is valid, otherwise false.
 */
export function validatePhone(phone) {
	const phoneRegex = /^\+?[1-9]\d{1,14}$/;

	return phoneRegex.test(phone);
}

/**
 * Checks if the given email address is valid.
 *
 * @param {string} email - The email address to validate.
 * @returns {boolean} - Returns true if the email address is valid, otherwise false.
 */
export function validateEmail(email) {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	return emailRegex.test(email);
}

/**
 * Capitalizes the first letter of the given string.
 *
 * @param {string} str - The string to be capitalized.
 * @return {string} The string with the first letter capitalized.
 */
export function capitalize(str) {
	if (!str) return str; // Return the original string if it's falsy (e.g., "", null, undefined)

	return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Removes the file extension from a given filename.
 *
 * @param {string} filename - The filename from which to remove the extension.
 * @returns {string} The filename without the extension.
 */
export function removeFileExtension(filename) {
	const parts = filename.split('.');

	if (parts.length > 1) {
		return parts.slice(0, -1).join('.');
	} else {
		return filename;
	}
}

/**
 * Returns the file extension from a given filename.
 *
 * @param {string} filename - The filename from which to extract the extension.
 * @returns {string} The file extension (without the dot).
 */
export function getFileExtension(filename) {
	const parts = filename.split('.');

	if (parts.length > 1) {
		return parts.pop();
	} else {
		return '';
	}
}

/**
 * Converts a string to a slug.
 *
 * @param {string|string[]} filename - The input string or array of strings to convert to a slug.
 * @param {string|null} fileExtension - Optional file extension to append to the slug.
 * @param {Object} options - Additional options for slug generation.
 * @param {string} [options.separator='-'] - The separator used to replace spaces.
 * @param {boolean} [options.includeDate=true] - Whether to include the current date in the slug.
 * @param {string} [options.dateFormat='YYYY-MM-DD'] - The format of the date to include in the slug.
 * @param {string} [options.dateSeperator='-'] - The separator between the date and the rest of the slug.
 * @param {Date} [options.date=new Date()] - The date to include in the slug.
 *  @param {boolean} [options.lowercase=true] - Whether to use all lowercase or not.
 * @returns {string} The slugified string.
 */
export function slugify(filename = '', fileExtension = null, options = {}) {
	// Destructure options with default values
	const {
		separator = '-',
		includeDate = false,
		dateFormat = 'YYYY-MM-DD',
		dateSeperator = '-',
		date = new Date(),
		lowercase = true,
	} = options;

	let slug = Array.isArray(filename) ? filename.join(' ') : String(filename);

	if (lowercase) {
		slug = slug.toLowerCase();
	}

	slug = slug
		.replace(/[^\p{L}\p{N}._\s-]+/gu, '') // Remove all non-word characters except for letters, numbers, periods, underscores, dashes, and spaces
		.replace(/\s+/g, separator) // Replace spaces with separator
		.replace(new RegExp(`^${separator}+`), '') // Trim separator from start of text
		.replace(new RegExp(`${separator}+$`), ''); // Trim separator from end of text

	if (includeDate) {
		slug = `${dayjs(date).format(dateFormat)}${dateSeperator}${slug}`;
	}

	return fileExtension ? `${slug}.${fileExtension}` : slug;
}
