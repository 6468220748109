import DOMPurify from 'dompurify';

/**
 * Add a hook to make all links open a new window if target="_blank".
 * Also add rel="noopener" to prevent tabnabbing.
 */
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
	if ('target' in node) {
		node.setAttribute('target', '_blank');
		node.setAttribute('rel', 'noopener');
	}
});

export default DOMPurify;

export function sanitizeHtml(html) {
	return DOMPurify.sanitize(html);
}
