import { useState } from 'react';

export default function useChildTotal() {
	const [totalValues, setTotalValues] = useState({});

	const updateTotal = (key, value) => {
		setTotalValues(prevValues => ({
			...prevValues,
			[key]: value,
		}));
	};

	const total = Object.values(totalValues).reduce(
		(acc, value) => acc + value,
		0
	);

	return [total, updateTotal];
}
