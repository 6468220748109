import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import { formatDate } from 'utils/dates';

import ErrorMessage from 'components/ErrorMessage';
import { UpdatedCreatedAtText } from 'components/UpdatedCreatedAtAlert';

import { StyledALink } from 'web/components/Link';
import { ContractStatus } from 'web/components/panels/CompanyContractsPanel';

import { PAGINATE_COMPANY_CONTRACTS } from 'api/queries/companyContractQueries';

const Wrapper = styled.div`
	margin: 30px 0 20px 0;
`;

const Panel = styled.div`
	background-color: ${p => p.theme.palette.background.default};
	border: 1px solid ${p => p.theme.palette.background.paper};
	padding: 20px;
	margin-bottom: 20px;
`;

const Title = styled.h3`
	margin: 0 0 20px 0;
`;

const Text = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-size: 0.9rem;
	margin-top: 5px;
`;

const TextWrapper = styled.div`
	font-size: 14px;
	text-align: center;
`;

export default function FolderCompanyContracts({ companyId }) {
	const {
		data: { contracts = { count: 0, items: [] } } = {
			contracts: { count: 0, items: [] },
		},
		error,
	} = useQuery(PAGINATE_COMPANY_CONTRACTS, {
		variables: {
			companyId,
			isSentToSigning: true,
			orderBy: 'createdAt',
			order: -1,
		},
	});

	return (
		<Wrapper>
			<ErrorMessage errors={[error]} />

			<Panel>
				<Title>Oppdragsavtaler</Title>

				<CompanyContractsList contracts={contracts?.items} />
			</Panel>
		</Wrapper>
	);
}

function CompanyContractsList({ contracts = [] }) {
	if (!contracts || contracts.length === 0) {
		return (
			<div>
				Bedriften har ingen digitale oppdragsavtaler.
				<br /> Se nedenfor for evt. manuelt opplastede avtaler (pdf).
			</div>
		);
	}

	return (
		<List>
			{contracts.map(contract => (
				<CompanyContractsListItem
					key={contract._id}
					contract={contract}
				/>
			))}
		</List>
	);
}

function CompanyContractsListItem({ contract }) {
	return (
		<>
			<ListItem
				secondaryAction={
					<TextWrapper>
						<div>{formatDate(contract.createdAt, 'DD.MM.YY')}</div>

						<ContractStatus
							isSentToSigning={contract.isSentToSigning}
							isFullySigned={contract.isFullySigned}
						/>
					</TextWrapper>
				}
			>
				<ListItemText>
					<StyledALink
						href={`${process.env.REACT_APP_CONTRACT_SIGN_URL}/avtaler/${contract._id}`}
						target="_blank"
						rel="noreferrer"
					>
						{contract.typeLabel}
					</StyledALink>

					<Text>
						<UpdatedCreatedAtText
							createdAt={contract.createdAt}
							createdBy={contract.createdByDisplayName}
							updatedAt={contract.updatedAt}
							updatedBy={contract.updatedByDisplayName}
						/>
					</Text>
				</ListItemText>
			</ListItem>

			<Divider component="li" />
		</>
	);
}
