/**
 * Formats an array of elements into a language-specific string using conjunctions.
 *
 * If the Intl.ListFormat API is available, it will use it to format the array
 * into a more human-readable string with conjunctions (e.g., "A, B, og C").
 * If not, it will fall back to a simple comma-separated string.
 *
 * @param {Array} array - The array of elements to format.
 * @param {string|null} [field=null] - Optional. The key of the property to use for
 *                                     formatting when the array elements are objects.
 *                                     If null, the array elements themselves are used.
 * @returns {string} The formatted string.
 *
 * @example
 * conjunctionFormat(['apple', 'banana', 'cherry']);
 * // Returns "apple, banana, og cherry" if Intl.ListFormat is supported.
 *
 * @example
 * conjunctionFormat([{name: 'apple'}, {name: 'banana'}, {name: 'cherry'}], 'name');
 * // Returns "apple, banana, og cherry" if Intl.ListFormat is supported.
 */
export function conjunctionFormat(array, field = null) {
	if ('ListFormat' in Intl) {
		const formatter = new Intl.ListFormat('nb', {
			style: 'long',
			type: 'conjunction',
		});

		const list = field ? array.map(item => item[field]) : array;

		return formatter.format(list);
	}

	return field ? array.map(item => item[field]).join(', ') : array.join(', ');
}
