export const defaultFolders = [
	{
		name: 'Fastopplysninger',
		slug: 'fastopplysninger',
		order: 0,
		children: [
			{ name: 'Firmaattest', order: 0 },
			{ name: 'Stiftelsesdokument', order: 1 },
			{ name: 'Vedtekter', order: 2 },
			{ name: 'Aksjeeierbok', order: 3 },
		],
	},
	{
		name: 'Oppdragsopplysninger',
		slug: 'oppdragsopplysninger',
		order: 1,
		children: [
			{ name: 'Oppdragsavtaler', order: 0 },
			{ name: 'Fullmakter', order: 1 },
			{ name: 'Hvitvaskingskontroll', order: 2 },
		],
	},
	{
		name: 'Årsoppgjør',
		slug: 'arsoppgjor',
		order: 2,
		children: [
			{ name: 'Årsregnskap', order: 0 },
			{ name: 'Revisjonsberetning', order: 1 },
			{ name: 'Nummerert brev revisor', order: 2 },
			{ name: 'Skattemelding', order: 3 },
		],
	},
	{
		name: 'Protokoller',
		slug: 'protokoller',
		order: 3,
		children: [
			{ name: 'Innkalling', order: 0 },
			{ name: 'Styremøter', order: 1 },
			{ name: 'Generalforsamling', order: 2 },
		],
	},
	{
		name: 'Regnskap',
		slug: 'regnskap',
		order: 4,
		children: [
			{ name: 'Regnskapsrapporter', order: 0 },
			{ name: 'Andre dokumenter', order: 1 },
		],
	},
	{
		name: 'Lønn',
		slug: 'lonn',
		order: 5,
		children: [
			{ name: 'Lønnsgrunnlag', order: 0 },
			{ name: 'Lønnstrekk', order: 1 },
			{ name: 'Egenmelding/Sykmelding', order: 2 },
		],
	},
	{
		name: 'Rådgivning',
		slug: 'radgivning',
		order: 6,
		children: [
			{ name: 'Analyser', order: 0 },
			{ name: 'Maler', order: 1 },
			{ name: 'Andre dokumenter', order: 2 },
		],
	},
	{
		name: 'Avtaler',
		slug: 'avtaler',
		order: 7,
		children: [
			{ name: 'Leieavtaler', order: 0 },
			{ name: 'Kjøpsavtaler', order: 1 },
			{ name: 'Salgsavtaler', order: 2 },
			{ name: 'Leasingavtaler', order: 3 },
			{ name: 'Låneavtaler', order: 4 },
			{ name: 'Andre avtaler', order: 5 },
		],
	},
	{
		name: 'Korrespondanse',
		slug: 'korrespondanse',
		order: 8,
		children: [
			{ name: 'Regnskap', order: 0 },
			{ name: 'Revisor', order: 1 },
			{ name: 'Skatteetaten', order: 2 },
			{ name: 'NAV', order: 3 },
			{ name: 'Andre', order: 4 },
		],
	},
	{
		name: 'Mine dokumenter',
		slug: 'mine-dokumenter',
		order: 9,
		excludeFromAccess: true, // 'Mine dokumenter' is a special folder that is not a part of the folder access logic. We need to set excludeFromAccess because we use the defaultFolders sturcutre to list folder access in UserEditAccess.
	},
];

export function getFolderNameBySlug(slug) {
	return defaultFolders.find(folder => folder.slug === slug)?.name;
}
