import { Fragment } from 'react';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

import { Card, CardContent } from 'components/GridCardLayout';

import NotVerifiedUserInfoItem from 'web/components/panels/NotVerifiedUserInfoItem';
import { InfoTitle } from 'web/components/panels/PanelItems';
import Panel from 'web/components/panels/Panel';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

const Message = styled.div`
	font-size: 1rem;
`;

const StyledUl = styled.ul`
	padding-left: 1.5em;
	font-size: 16px;
`;

const StyledList = styled(List)`
	padding: 0;
	margin: 0;

	a {
		color: inherit;
	}

	li[role='separator']:last-child {
		display: none;
	}
`;

export default function GeneralDeviations({ deviations, proffDeviations }) {
	const hasListDeviations =
		deviations.missingProffData ||
		deviations.missingClientNumber ||
		deviations.missingOrgNumber ||
		deviations.missingDepartment ||
		deviations.missingAccountants ||
		deviations.missingControllers ||
		deviations.missingAccountManagers ||
		deviations.missingAdvisors ||
		deviations.missingSignedContract ||
		deviations.missingCompanyAssignmentTasks ||
		deviations.routinesNeedsRevision ||
		deviations.assignmentRisksNeedsRevision;

	return (
		<Panel title="Avvik" background={false}>
			<Wrapper>
				{proffDeviations?.hasDeviations && (
					<Alert severity="warning">
						<div>
							<InfoTitle>Avvik fra Proff</InfoTitle>

							<Message>
								Se egen fane for avvik fra Proff.no
							</Message>
						</div>
					</Alert>
				)}

				{deviations.hasDeviations && (
					<>
						{hasListDeviations && (
							<Alert severity="warning">
								<div>
									<InfoTitle>Bedriften Mangler</InfoTitle>

									<StyledUl>
										{deviations.missingClientNumber && (
											<li>Kundenummer</li>
										)}

										{deviations.missingOrgNumber && (
											<li>Organisasjonsnummer</li>
										)}

										{deviations.missingProffData && (
											<li>Mangler data fra proff</li>
										)}

										{deviations.missingDepartment && (
											<li>Avdeling</li>
										)}

										{deviations.missingAccountants && (
											<li>Regnskapsfører</li>
										)}

										{deviations.missingAccountManagers && (
											<li>Kundeansvarlig</li>
										)}

										{deviations.missingControllers && (
											<li>Oppdragskontrollør</li>
										)}

										{deviations.missingAdvisors && (
											<li>Rådgiver</li>
										)}

										{deviations.missingSignedContract && (
											<li>Oppdragsavtale mangler</li>
										)}

										{deviations.missingCompanyAssignmentTasks && (
											<li>Oppdrag ikke planlagt</li>
										)}

										{deviations.routinesNeedsRevision && (
											<li>
												Bedriftsrutiner trenger revisjon
											</li>
										)}

										{deviations.assignmentRisksNeedsRevision && (
											<li>
												Oppdragsrisiko trenger revisjon
											</li>
										)}
									</StyledUl>
								</div>
							</Alert>
						)}

						{deviations.companyUsersAndOwnersNotVerified && (
							<Card type="warning">
								<CardContent>
									<InfoTitle>
										Brukere som ikke er verifisert
									</InfoTitle>

									<StyledList>
										{deviations.companyUsersAndOwnersNotVerified.map(
											user => (
												<Fragment
													key={`company-user-not-verified-${user._id}`}
												>
													<ListItem>
														<NotVerifiedUserInfoItem
															user={user}
														/>
													</ListItem>
													<Divider component="li" />
												</Fragment>
											)
										)}
									</StyledList>
								</CardContent>
							</Card>
						)}
					</>
				)}
			</Wrapper>
		</Panel>
	);
}
