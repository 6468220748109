import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import useSortOrder from 'hooks/useSortOrder';

import { useUser } from 'web/contexts/UserContext';
import { useAppFilters } from 'web/contexts/AppFiltersContext';

import UsersCreateDialog from 'web/screens/dialogs/users/UsersCreateDialog';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';
import ErrorText from 'components/ErrorText';

import AppFiltersWithActiveState from 'components/filters/AppFiltersWithActiveState';

import DesktopLayout, { ArchiveHeader, Content } from 'web/components/Layout';
import Table, { TableRow, TableCell } from 'web/components/Table';
import Link from 'web/components/Link';
import SortIcon from 'web/components/SortIcon';
import CreateFabButton from 'web/components/CreateFabButton';

import { PAGINATE_AUDITORS } from 'api/queries/userQueries';

export default function AuditorsArchive() {
	const navigate = useNavigate();
	const { userHasMinRole } = useUser();
	const [{ orderBy, order }, setNewOrder] = useSortOrder('name');

	const { search, auditingCompany } = useAppFilters();

	const [showUserCreateDialog, setShowUserCreateDialog] = useState(false);

	const {
		data: { auditors = { count: 0, items: [] } } = {
			auditors: { count: 0, items: [] },
		},
		loading,
		error,
	} = useQuery(PAGINATE_AUDITORS, {
		variables: {
			order,
			orderBy,
			search,
			filterById: auditingCompany?.value ? auditingCompany.value : null,
		},
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		notifyOnNetworkStatusChange: true,
	});

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Revisorer',
				},
			]}
		>
			<Content>
				<ErrorMessage errors={error} />

				<ArchiveHeader>
					<AppFiltersWithActiveState
						searchLabel="Søk etter revisor"
						displayFilters={['auditingCompany']}
					/>
				</ArchiveHeader>

				<Table columns="6">
					<TableRow header>
						<TableCell
							start="1"
							end="5"
							onClick={() => setNewOrder('name')}
						>
							<span>Navn</span>

							<SortIcon
								field="name"
								orderBy={orderBy}
								order={order}
							/>
						</TableCell>

						<TableCell
							onClick={() => setNewOrder('auditingCompany.name')}
						>
							<span>Revisorfirma</span>

							<SortIcon
								field="auditingCompany.name"
								orderBy={orderBy}
								order={order}
							/>
						</TableCell>

						<TableCell>Tilknyttet bedrifter</TableCell>
					</TableRow>

					{!loading && auditors?.items?.length === 0 && (
						<TableRow transparent>
							<TableCell start="1" end="-1" center>
								Ingen revisorer ble funnet
							</TableCell>
						</TableRow>
					)}

					{!error &&
						auditors?.items.map(auditor => (
							<Link
								to={`/brukere/${auditor._id}`}
								key={auditor._id}
							>
								<TableRow hover>
									<TableCell start="1" end="5">
										{auditor.name}
									</TableCell>

									<TableCell>
										{auditor?.auditingCompany?.name || (
											<ErrorText>
												Revisorfirma mangler
											</ErrorText>
										)}
									</TableCell>

									<TableCell>
										{auditor?.auditorTenants?.length ?? 0}
									</TableCell>
								</TableRow>
							</Link>
						))}

					{loading && (
						<TableRow transparent>
							<TableCell start="1" end="-1">
								<InlineLoading />
							</TableCell>
						</TableRow>
					)}

					{!error && !loading && auditors?.items?.length !== 0 && (
						<TableRow footer>
							<TableCell start="1" end="-1" right>
								Viser {auditors.items.length} av{' '}
								{auditors.count} revisorer
							</TableCell>
						</TableRow>
					)}
				</Table>
			</Content>

			{userHasMinRole('accountant') && (
				<CreateFabButton
					onClick={() => setShowUserCreateDialog(true)}
				/>
			)}

			<UsersCreateDialog
				title="Opprett revisor"
				defaultRole="auditor"
				open={showUserCreateDialog}
				onClose={() => setShowUserCreateDialog(false)}
				onSubmitSuccess={user => {
					setShowUserCreateDialog(false);

					navigate(`/brukere/${user._id}/`);
				}}
			/>
		</DesktopLayout>
	);
}
