import { useMutation } from '@apollo/client';

import ModalLayout from 'components/modals/ModalLayout';
import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import { INSERT_ONE_HELP_PAGE } from 'api/mutations/helpPageMutations';

export default function HelpPageCreateModal({ onClose }) {
	const [insertOneHelpPage, { loading, error }] = useMutation(
		INSERT_ONE_HELP_PAGE,
		{
			refetchQueries: ['paginateHelpPages'],
		}
	);

	return (
		<ModalLayout
			title="Opprett hjelpeside"
			onClose={onClose}
			maxWidth="800px"
		>
			<ErrorMessage errors={[error]} />

			<Form
				isLoading={loading}
				isDisabled={loading}
				onSubmit={async values => {
					try {
						const { data } = await insertOneHelpPage({
							variables: {
								...values,
							},
						});

						onClose(data.newHelpPage);
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<TextField name="name" label="Navn" required />

				<SubmitButton variant="contained">
					Opprett hjelpeside
				</SubmitButton>
			</Form>
		</ModalLayout>
	);
}
