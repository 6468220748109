import { customAlphabet } from 'nanoid';

/**
 * Generates a unique verification token.
 *
 * This function generates a unique token using a custom alphabet and checks for its uniqueness
 * against an existing UsersModel if provided. If a UsersModel is passed, it will ensure that the
 * generated token does not already exist in the database by checking against the 'userVerifications.token' field.
 *
 * @param {Object} [UsersModel=null] - The Mongoose model to check for existing tokens. If not provided, the function will simply return a generated token.
 * @returns {Promise<string>} - A promise that resolves to a unique verification token.
 */
export async function generateToken(UsersModel = null) {
	const token = customAlphabet('1234567890abcdef', 6);

	if (!UsersModel) return token();

	let verificationToken = '';
	let existingToken = '';

	do {
		verificationToken = token();
		existingToken = await UsersModel.findOne({
			['userVerifications.token']: verificationToken,
		});
	} while (existingToken);

	return verificationToken;
}

/**
 * Generates a unique and secure verification token.
 *
 * This function generates a unique and secure token using a custom alphabet consisting of URL safe characters.
 * The token is 15 characters long. If a UsersModel is provided, it ensures that the generated token does not
 * already exist in the database by checking against the 'userVerifications.token' field.
 *
 * @param {Object} [UsersModel=null] - The Mongoose model to check for existing tokens. If not provided, the function will simply return a generated token.
 * @returns {Promise<string>} - A promise that resolves to a unique verification token.
 */
export async function generateSecureToken(UsersModel = null) {
	const token = customAlphabet(
		'1234567890abcdefghijklmnopqrstuvwqyzABCDEFGHIJKLMNOPQRSTUVWXYZ!*@-', //URL safe chars only
		15
	);

	if (!UsersModel) return token();

	let verificationToken = '';
	let existingToken = '';

	do {
		verificationToken = token();
		existingToken = await UsersModel.findOne({
			['userVerifications.token']: verificationToken,
		});
	} while (existingToken);

	return verificationToken;
}
