import { useAppFilters } from 'web/contexts/AppFiltersContext';

import TaskDeadlineEntryInPeriod from 'web/components/companiesArchive/TaskDeadlineEntryInPeriod';

export default function TaskDeadlineEntryStatusInPeriod({ companyId }) {
	const { year, month, task } = useAppFilters();

	if (
		Number.isNaN(!year?.value) ||
		Number.isNaN(!month?.value) ||
		!task?.value
	) {
		return null;
	}

	return (
		<TaskDeadlineEntryInPeriod
			companyId={companyId}
			taskName={task.value}
			month={month.value}
			year={year.value}
		/>
	);
}
