import { gql } from '@apollo/client';

export const INSERT_ONE_HELP_PAGE = gql`
	mutation insertOneHelpPage($name: String!) {
		newHelpPage: insertOneHelpPage(name: $name) {
			_id
			name
		}
	}
`;

export const UPDATE_HELP_PAGE = gql`
	mutation updateOneHelpPage($_id: ID!, $name: String!) {
		helpPage: updateOneHelpPage(_id: $_id, name: $name) {
			_id
			name
		}
	}
`;

export const DELETE_HELP_PAGE = gql`
	mutation deleteOneHelpPage($_id: ID!) {
		deleted: deleteOneHelpPage(_id: $_id)
	}
`;

export const INSERT_ONE_HELP_PAGE_SECTION = gql`
	mutation insertOneHelpPageSection($helpPageId: ID!, $title: String!) {
		helpPage: insertOneHelpPageSection(
			helpPageId: $helpPageId
			title: $title
		) {
			_id
		}
	}
`;

export const UPDATE_ONE_HELP_PAGE_SECTION = gql`
	mutation updateOneHelpPageSection(
		$helpPageId: ID!
		$sectionId: ID!
		$title: String
	) {
		updated: updateOneHelpPageSection(
			helpPageId: $helpPageId
			sectionId: $sectionId
			title: $title
		)
	}
`;

export const DELETE_ONE_HELP_PAGE_SECTION = gql`
	mutation deleteOneHelpPageSection($helpPageId: ID!, $sectionId: ID!) {
		deleted: deleteOneHelpPageSection(
			helpPageId: $helpPageId
			sectionId: $sectionId
		)
	}
`;
