import { companyRoles } from 'server/libs/companyRoles';

import { Section, Title } from 'web/screens/HelpSingle';

export default function HelpSingleUserManual() {
	return (
		<div>
			<Title>Brukerveiledning</Title>

			<Section>
				<h2>Brukerroller og mappetilganger</h2>

				<p>
					Når man legger til en bruker på en bedrift, så vil de få
					tilgang til ulike mapper basert på hvilken rolle de blir
					tildelt. I etterkant kan man overstyre denne mappetilgangen
					på brukernivå per tilknyttet bedrift.
				</p>

				<p>
					Oversikten nedefor viser standard mappetilganger for de
					ulike brukerrollene:
				</p>

				{companyRoles.map(role => (
					<div key={role.value}>
						<h3>{role.label}</h3>

						<ul>
							{Object.values(role?.defaultFolderAccess).length >
							0 ? (
								Object.values(role.defaultFolderAccess).map(
									label => <li key={label}>{label}</li>
								)
							) : (
								<li>Har ingen tilganger som standard</li>
							)}
						</ul>
					</div>
				))}
			</Section>

			<Section>
				<h2>Signering av dokument</h2>

				<h3>Varsler</h3>

				<p>
					Når man sender et dokument til signering, så vil det gå ut
					varseler til mottakerne på e-post og sms. Hvis brukeren har
					installert appen, så vil det også gå ut en push-varsling til
					denne.
				</p>

				<h3>Purringer</h3>

				<p>
					Hvis brukeren ikke signerer, så vil det gå ut nye varsler på
					e-post og sms, samt push-notifikasjon etter henholdsvis 2 og
					4 dager.
				</p>

				<p>
					Hvis dokumentet ikke har blitt fullstendig signert etter 6
					dager, så vil det gå ut et varsel til den brukeren som
					forespurte signeringen.
				</p>
			</Section>
		</div>
	);
}
