import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import 'dayjs/locale/nb';

dayjs.locale('nb');
dayjs.extend(isSameOrBefore);
dayjs.extend(weekOfYear);

export default dayjs;
export { dayjs as date };
export const thisYear = new Date().getFullYear();

/**
 * Formats a given date/time string or object into the specified format.
 *
 * @param {string|Date} datetime - The date/time to format. This can be a date string or a Date object.
 * @param {string} format - The format string to use for the output.
 * @param {string|null} [parseFormat=null] - The format string to use for parsing the input date/time.
 *                                           If null, the input is assumed to be in a standard recognizable format.
 * @returns {string} - The formatted date/time string.
 *
 * @example
 * // Format a date string to 'YYYY-MM-DD'
 * formatDate('2023-07-04', 'YYYY-MM-DD');
 *
 * @example
 * // Format a date string with a custom parse format
 * formatDate('04-07-2023', 'YYYY-MM-DD', 'DD-MM-YYYY');
 */
export function formatDate(datetime, format, parseFormat = null) {
	if (parseFormat) {
		dayjs.extend(customParseFormat);

		return dayjs(datetime, parseFormat).format(format);
	}

	return dayjs(datetime).format(format);
}

/**
 * Generates an array of objects representing years for a dropdown menu.
 *
 * @param {number} startYear - The starting year for the dropdown.
 *                             Defaults to three years before the current year if not provided.
 * @param {number} endYear - The ending year for the dropdown.
 *                           Defaults to one year after the current year if not provided.
 * @returns {Array<{label: string, value: number}>} An array of objects where each object has a `label` as a
 *                                                  string representation of the year and a `value` as the
 *                                                  numerical year.
 */
export function dropdownYears(startYear, endYear) {
	if (!startYear) startYear = thisYear - 3;
	if (!endYear) endYear = thisYear + 1;

	const years = [];

	for (let i = startYear; i <= endYear; i++) {
		years.push({
			label: i.toString(),
			value: i,
		});
	}

	return years;
}

/**
 * Checks if a given date is today's date.
 *
 * @param {Date} date - The date to be compared with today's date.
 * @returns {boolean} True if the given date is the same as today's date, otherwise false.
 */
export function isToday(date) {
	const today = new Date();
	return (
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
	);
}

/**
 * Determines if a given date is before the current date.
 *
 * This function accepts a date string and checks if it represents a date
 * that is earlier than the current date. The comparison is done based
 * on the day, ignoring the time component. The function returns true if
 * the input date is before today and false otherwise.
 *
 * Note: The date string should be in a format that is compatible with
 * dayjs parsing. The function does not perform any validation on the
 * input date string format.
 *
 * @param {string} dateStr - The date string to be evaluated. It should be in a
 *                           format that dayjs can parse.
 * @returns {boolean} True if the input date is before today, false otherwise.
 */
export function isDateBeforeToday(dateStr) {
	const inputDate = dayjs(dateStr);

	return inputDate.isBefore(dayjs(), 'day');
}

/**
 * Checks if a given date is the same as or before the current date.
 *
 * This function takes a date string as an argument and compares it
 * to the current date, considering only the day part of the dates.
 * It returns true if the passed date is either the same day or a
 * date before today, and false otherwise.
 *
 * @param {string} dateStr - The date string to be compared with the current date.
 *                            Expected in a format that can be parsed by dayjs.
 * @returns {boolean} True if the input date is the same as or before the current date, false otherwise.
 */
export function isDateSameOrBeforeToday(dateStr) {
	const inputDate = dayjs(dateStr);

	return inputDate.isSameOrBefore(dayjs(), 'day');
}

/**
 * Returns the week number for a given date.
 *
 * @param {Date|string|number} date - The date for which to get the week number. Can be a Date object, a string, or a timestamp.
 * @returns {number} The week number for the provided date.
 */
export function getWeekNumber(date) {
	const dayjsDate = dayjs(date);

	return dayjsDate.week();
}

/**
 * Returns the start date of the current week (Monday).
 *
 * @returns {Date} A Date object representing the start of the current week (Monday) with time set to 00:00:00.
 */
export function getStartDateOfCurrentWeek() {
	const today = new Date();
	const dayOfWeek = today.getDay();
	const differenceToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

	today.setDate(today.getDate() - differenceToMonday);
	today.setHours(0, 0, 0, 0);

	return today;
}

/**
 * Checks if a date is valid.
 *
 * @param {number} year - The year (e.g., 2023).
 * @param {number} month - The month (1-based index, e.g., 1 for January, 2 for February).
 * @param {number} day - The day of the month (e.g., 15).
 * @returns {boolean} True if the date is valid, false otherwise.
 */
export function isDateValid(date) {
	if (!date) return false;

	return !isNaN(new Date(date));
}
