import { Fragment } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import Panel from 'web/components/panels/Panel';
import { UnderlineLink } from 'web/components/Link';

function getOwnerShare(tenant, userId) {
	const owner = tenant.owners.find(owner => owner.user._id === userId);

	if (!owner?.shareInPercent || isNaN(owner.shareInPercent)) {
		return null;
	}

	return Number(owner.shareInPercent);
}

function OwnerShareInPercent({ tenant, userId }) {
	const ownerShare = getOwnerShare(tenant, userId);

	if (!ownerShare) return <em>Mangler info om eierandel</em>;

	const formatedOwnerShare = ownerShare.toLocaleString('nb-NO', {
		minimumFractionDigits: 0,
		maximumFractionDigits: 3,
	});

	return <em>Eierandel: {formatedOwnerShare}%</em>;
}

export default function TenantRoles({
	title,
	tenants,
	showOwnerShare = false,
	userId = null,
}) {
	if (!tenants) return null;

	return (
		<Panel title={title}>
			<List>
				{tenants.map(tenant => (
					<Fragment key={tenant._id}>
						<ListItem>
							<ListItemText
								primary={
									<UnderlineLink
										to={`/bedrifter/${tenant._id}/rediger/`}
									>
										{tenant.name}
									</UnderlineLink>
								}
								secondary={
									showOwnerShare && (
										<OwnerShareInPercent
											tenant={tenant}
											userId={userId}
										/>
									)
								}
							/>
						</ListItem>

						<Divider component="li" />
					</Fragment>
				))}
			</List>
		</Panel>
	);
}
