import { useState } from 'react';
import { useMutation } from '@apollo/client';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';

import FormIntroText from 'components/form/FormIntroText';
import TextField from 'components/form/TextField';
import Form from 'components/form/Form';
import SubmitButton from 'components/form/SubmitButton';

import {
	UPDATE_ONE_USER_INPUT_WITH_VERIFICATION,
	VERIFY_ONE_USER_INPUT_WITH_TOKEN,
} from 'api/mutations/userMutations';

export default function TokenVerifyModal({
	type,
	userId,
	verificationValue,
	title,
	firstStepContent,
	lastStepContent,
	changeValueText,
}) {
	const { notify } = useSnackbar();

	const [inputHasChanged, setInputHasChanged] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);

	const [
		updateVerification,
		{ loading: updateVerificationLoading, error: updateVerificationError },
	] = useMutation(UPDATE_ONE_USER_INPUT_WITH_VERIFICATION, {
		refetchQueries: ['currentUser'],
		errorPolicy: 'all',
	});

	const [
		verifyUserInputWithToken,
		{
			loading: verifyUserInputWithTokenLoading,
			error: verifyUserInputWithTokenError,
		},
	] = useMutation(VERIFY_ONE_USER_INPUT_WITH_TOKEN, {
		refetchQueries: ['currentUser'],
		errorPolicy: 'all',
	});

	return (
		<>
			{title && (
				<DialogTitle id="user-verification-dialog-title">
					{title}
				</DialogTitle>
			)}

			<ErrorMessage
				errors={[
					updateVerificationError,
					verifyUserInputWithTokenError,
				]}
			/>

			{currentStep === 0 && (
				<Form
					values={{ input: verificationValue }}
					isLoading={updateVerificationLoading}
					onSubmit={async ({ input }) => {
						try {
							const { data } = await updateVerification({
								variables: {
									_id: userId,
									field: type,
									value: input,
								},
							});

							if (data.updatedValue) {
								setCurrentStep(1);
							}
						} catch (err) {
							console.error(err);
						}
					}}
				>
					<DialogContent sx={{ paddingTop: 0 }}>
						<FormIntroText>{firstStepContent}</FormIntroText>

						<TextField
							name="input"
							type={type}
							onChange={() => setInputHasChanged(true)}
							required
							fullWidth
						/>
					</DialogContent>

					<DialogActions>
						<Button
							type="Mui"
							color="primary"
							variant="outlined"
							onClick={() => setCurrentStep(1)}
						>
							Jeg har allerede en kode
						</Button>

						<Button
							type="submit"
							variant="contained"
							color="primary"
						>
							{inputHasChanged
								? 'Oppdater og send kode'
								: 'Send kode'}
						</Button>
					</DialogActions>
				</Form>
			)}

			{currentStep === 1 && (
				<Form
					isLoading={verifyUserInputWithTokenLoading}
					onSubmit={async ({ token }) => {
						try {
							const { data } = await verifyUserInputWithToken({
								variables: {
									_id: userId,
									type,
									value: verificationValue,
									token,
								},
							});

							if (data.updated) {
								notify('Verifiseringen var vellykket!');
							}
						} catch (err) {
							console.error(err);
						}
					}}
				>
					<DialogContent sx={{ paddingTop: 0 }}>
						<FormIntroText>{lastStepContent}</FormIntroText>

						<TextField name="token" required={true} fullWidth />
					</DialogContent>

					<DialogActions>
						<Button
							type="button"
							color="primary"
							variant="outlined"
							onClick={() => setCurrentStep(0)}
						>
							{changeValueText}
						</Button>

						<SubmitButton
							type="submit"
							variant="contained"
							color="primary"
						>
							Registrer kode
						</SubmitButton>
					</DialogActions>
				</Form>
			)}
		</>
	);
}
