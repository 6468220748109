import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Icon from 'components/Icon';

import { pluralize } from 'utils/strings';

import { useAppFilters } from 'web/contexts/AppFiltersContext';
import { useUser } from 'web/contexts/UserContext';

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const FilterTitle = styled.div`
	font-size: 14px;
`;

const FiltersWrapper = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
`;

export default function AppActiveFilters({ displayFilters = [] }) {
	const { userHasMinRole } = useUser();

	const {
		year,
		month,
		department,
		setDepartment,
		accountant,
		setAccountant,
		accountManager,
		setAccountManager,
		payrollManager,
		setPayrollManager,
		annualAccountsManager,
		setAnnualAccountsManager,
		controller,
		setController,
		auditor,
		setAuditor,
		accountantLegacy,
		setAccountantLegacy,
		accountantRole,
		setAccountantRole,
		accountantRoleLegacy,
		setAccountantRoleLegacy,
		userRole,
		setUserRole,
		task,
		setTask,
		company,
		setCompany,
		accountType,
		setAccountType,
		auditingCompany,
		setAuditingCompany,
	} = useAppFilters();

	const allFilters = [
		{
			id: 'year',
			name: 'År',
			value: year?.value,
			label: year?.label,
		},
		{
			id: 'month',
			name: 'Måned',
			value: month?.value,
			label: month?.label,
		},
		{
			id: 'task',
			name: 'Oppgave',
			value: task?.value,
			label: task?.label,
			onDelete: () => setTask(null),
		},
		{
			id: 'department',
			name: 'Avdeling',
			value: department?.value,
			label: department?.label,
			onDelete: () => setDepartment(null),
		},
		{
			id: 'accountant',
			name: 'Regnskapsfører',
			value: accountant?.value,
			label: accountant?.label,
			onDelete: () => setAccountant(null),
		},
		{
			id: 'accountManager',
			name: 'Kundeansvarlig',
			value: accountManager?.value,
			label: accountManager?.label,
			onDelete: () => setAccountManager(null),
		},
		{
			id: 'payrollManager',
			name: 'Lønnsansvarlig',
			value: payrollManager?.value,
			label: payrollManager?.label,
			onDelete: () => setPayrollManager(null),
		},
		{
			id: 'annualAccountsManager',
			name: 'Årsoppgjøransvarlig',
			value: annualAccountsManager?.value,
			label: annualAccountsManager?.label,
			onDelete: () => setAnnualAccountsManager(null),
		},
		{
			id: 'controller',
			name: 'Oppdragskontrollør',
			value: controller?.value,
			label: controller?.label,
			onDelete: () => setController(null),
		},
		{
			id: 'auditor',
			name: 'Revisor',
			value: auditor?.value,
			label: auditor?.label,
			onDelete: () => setAuditor(null),
		},
		userHasMinRole('admin')
			? {
					id: 'adminsAndAccountants',
					name: 'Regnskapsfører',
					value: accountantLegacy?.value,
					label: accountantLegacy?.label,
					onDelete: () => setAccountantLegacy(null),
				}
			: null,
		{
			id: 'accountantRole',
			name: 'Rolle',
			value: accountantRole?.value,
			label: accountantRole?.label,
			onDelete: () => setAccountantRole(null),
		},
		{
			id: 'accountantRoleLegacy',
			name: 'Rolle',
			value: accountantRoleLegacy?.value,
			label: accountantRoleLegacy?.label,
			onDelete: () => setAccountantRoleLegacy(null),
		},
		{
			id: 'userRole',
			name: 'Brukerrolle',
			value: userRole?.value,
			label: userRole?.label,
			onDelete: () => setUserRole(null),
		},
		{
			id: 'company',
			name: 'Bedrift',
			value: company?.value,
			label: company?.label,
			onDelete: () => setCompany(null),
		},
		{
			id: 'accountType',
			name: 'Kontotype',
			value: accountType?.value,
			label: accountType?.label,
			onDelete: () => setAccountType(null),
		},
		{
			id: 'auditingCompany',
			name: 'Revisorfirma',
			value: auditingCompany?.value,
			label: auditingCompany?.label,
			onDelete: () => setAuditingCompany(null),
		},
	];

	const activeFilters = allFilters.filter(filter => {
		if (displayFilters.length === 0)
			return (
				filter?.value !== null &&
				filter?.value !== undefined &&
				filter?.value !== ''
			);

		return (
			displayFilters.includes(filter?.id) &&
			filter?.value !== null &&
			filter?.value !== undefined &&
			filter?.value !== ''
		);
	});

	if (activeFilters.length === 0) return null;

	return (
		<Wrapper>
			<Tooltip
				title={pluralize(
					'Aktivt filter',
					'Aktive filtre',
					activeFilters
				)}
				placement="top-end"
			>
				<FilterTitle id="active-filters-title">
					<Icon icon="filter-list" />
				</FilterTitle>
			</Tooltip>

			<FiltersWrapper aria-labelledby="active-filters-title">
				{activeFilters.map((filter, index) => {
					const chipLabel = (
						<span>
							<strong>{filter.name}:</strong> {filter.label}
						</span>
					);

					return (
						<li key={`active-filter-${index}`}>
							<Chip
								label={chipLabel}
								onDelete={filter?.onDelete}
							/>
						</li>
					);
				})}
			</FiltersWrapper>
		</Wrapper>
	);
}
