import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export default function DialogBox({
	title,
	open,
	onCancel,
	cancelLabel = 'Avbryt',
	onSave,
	saveLabel = 'Lagre',
	fullWidth = false,
	children,
	...props
}) {
	return (
		<Dialog
			open={open}
			onClose={onCancel}
			fullWidth={fullWidth}
			aria-labelledby="dialog-box-title"
			aria-describedby="dialog-box-description"
			{...props}
		>
			<DialogTitle id="dialog-box-title" x={{ m: 0, p: 2 }}>
				<span>{title}</span>

				{onCancel && <DialogCloseButton onClick={onCancel} />}
			</DialogTitle>

			<DialogContent id="dialog-box-description" dividers>
				{children}
			</DialogContent>

			<DialogActions>
				<Button onClick={onCancel}>{cancelLabel}</Button>

				<Button onClick={onSave} variant="contained" autoFocus>
					{saveLabel}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export function DialogCloseButton(props) {
	return (
		<IconButton
			aria-label="close"
			sx={{
				position: 'absolute',
				right: 8,
				top: 8,
			}}
			{...props}
		>
			<CloseIcon />
		</IconButton>
	);
}

export { Dialog, DialogTitle, DialogContent, DialogActions, Button };
