import { useState, useLayoutEffect, useRef } from 'react';

export default function useScrollToT() {
	const ref = useRef(null);
	const [shouldScrollTo, setShouldScrollTo] = useState(false);

	useLayoutEffect(() => {
		if (ref.current && shouldScrollTo) {
			ref.current.scrollIntoView({ behavior: 'smooth' });
			setShouldScrollTo(false);
		}
	}, [shouldScrollTo]);

	function scrollToElement() {
		setShouldScrollTo(true);
	}

	return [ref, scrollToElement];
}
