export const companyRoles = [
	{
		label: 'Innehaver',
		value: 'innehaver',
		boardMember: false,
		proffTitle: 'Innehaver',
		defaultFolderAccess: {
			fastopplysninger: 'Fastopplysninger',
			oppdragsopplysninger: 'Oppdragsopplysninger',
			arsoppgjor: 'Årsoppgjør',
			protokoller: 'Protokoller',
			regnskap: 'Regnskap',
			lonn: 'Lønn',
			radgivning: 'Rådgivning',
			avtaler: 'Avtaler',
			korrespondanse: 'Korrespondanse',
		},
	},
	{
		label: 'Daglig leder',
		value: 'dagligleder',
		boardMember: false,
		proffTitle: 'Daglig leder',
		defaultFolderAccess: {
			fastopplysninger: 'Fastopplysninger',
			oppdragsopplysninger: 'Oppdragsopplysninger',
			arsoppgjor: 'Årsoppgjør',
			protokoller: 'Protokoller',
			regnskap: 'Regnskap',
			lonn: 'Lønn',
			radgivning: 'Rådgivning',
			avtaler: 'Avtaler',
			korrespondanse: 'Korrespondanse',
		},
	},
	{
		label: 'Nestleder',
		value: 'nestleder',
		boardMember: true,
		proffTitle: 'Nestleder',
		defaultFolderAccess: {
			fastopplysninger: 'Fastopplysninger',
			oppdragsopplysninger: 'Oppdragsopplysninger',
			arsoppgjor: 'Årsoppgjør',
			protokoller: 'Protokoller',
		},
	},
	{
		label: 'Styreleder',
		value: 'styreleder',
		boardMember: true,
		proffTitle: 'Styrets leder',
		defaultFolderAccess: {
			fastopplysninger: 'Fastopplysninger',
			oppdragsopplysninger: 'Oppdragsopplysninger',
			arsoppgjor: 'Årsoppgjør',
			protokoller: 'Protokoller',
		},
	},
	{
		label: 'Styremedlem',
		value: 'styremedlem',
		boardMember: true,
		proffTitle: 'Styremedlem',
		defaultFolderAccess: {
			fastopplysninger: 'Fastopplysninger',
			oppdragsopplysninger: 'Oppdragsopplysninger',
			arsoppgjor: 'Årsoppgjør',
			protokoller: 'Protokoller',
		},
	},
	{
		label: 'Lønnsansvarlig (Bedrift)',
		value: 'lønnsansvarlig',
		boardMember: false,
		proffTitle: 'Lønnsansvarlig',
		defaultFolderAccess: {
			lonn: 'Lønn',
		},
	},
	{
		label: 'Varamedlem',
		value: 'varamedlem',
		boardMember: false,
		proffTitle: 'Varamedlem',
		defaultFolderAccess: {},
	},
	{
		label: 'Kontaktperson',
		value: 'kontaktperson',
		boardMember: false,
		proffTitle: 'Kontaktperson',
		defaultFolderAccess: {},
	},
	{
		label: 'Administrasjon',
		value: 'administrasjon',
		boardMember: false,
		proffTitle: 'Administrasjon',
		defaultFolderAccess: {},
	},
];

export const adminCompanyRoles = [
	{
		value: 'accountantIds',
		label: 'Regnskapsfører',
	},
	{
		value: 'accountManagerIds',
		label: 'Kundeansvarlig',
	},
	{
		value: 'payrollManagerIds',
		label: 'Lønnsansvarlig regnskapsfører',
	},
	{
		value: 'annualAccountsManagerIds',
		label: 'Årsoppgjørsansvarlig',
	},
	{
		value: 'controllerIds',
		label: 'Oppdragskontrollør',
	},
	{
		value: 'auditorIds',
		label: 'Revisor',
	},
];

export function getRoleLabel(value) {
	const role = companyRoles.find(r => r.value === value);

	if (!role) return '';

	return role.label;
}

export function getRoleByProffTitle(title) {
	const role = companyRoles.find(r => r.proffTitle === title);

	return role ? role.value : '';
}

export function isBoardMember(role) {
	const foundRole = companyRoles.find(r => r.value === role);

	return foundRole ? foundRole.boardMember : false;
}
