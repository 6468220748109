import { Controller, useFormContext } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MUISelect from '@mui/material/Select';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

export default function MultiSelect({
	name,
	label,
	defaultValue = null,
	options = [],
	...rest
}) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue ?? []}
			render={({ field: { onChange, value } }) => (
				<div>
					<FormControl sx={{ minWidth: '400px' }}>
						<InputLabel id={`${name}-multiple-select-label`}>
							{label}
						</InputLabel>

						<MUISelect
							labelId={`${name}-multiple-select-label`}
							id={`${name}-multiple-select`}
							value={value ?? []}
							label={label}
							onChange={onChange}
							renderValue={selected => (
								<Box
									sx={{
										display: 'flex',
										flexWrap: 'wrap',
										gap: 0.5,
									}}
								>
									{selected.map(value => (
										<Chip
											key={value}
											label={
												options.find(
													item => item.value === value
												).label
											}
										/>
									))}
								</Box>
							)}
							multiple
							{...rest}
						>
							{options.map((option, index) => (
								<MenuItem key={index} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</MUISelect>
					</FormControl>
				</div>
			)}
		/>
	);
}
