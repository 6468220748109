import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AuditorHome from 'web/screens/auditor/AuditorHome';
import AuditorCompaniesArchive from 'web/screens/auditor/AuditorCompaniesArchive';

import CompaniesSingle from 'web/screens/CompaniesSingle';
import CompaniesDocumentsSingle from 'web/screens/CompaniesDocumentsSingle';
import DocumentEdit from 'web/screens/DocumentEdit';
import DocumentShare from 'web/screens/DocumentShare';
import SendSigningRequest from 'web/screens/SendSigningRequest';

import UserProfile from 'web/screens/UserProfile';
import NotFound from 'web/screens/NotFound';

import RouteRedirect from 'web/routes/components/RouteRedirect';

export default function AuditorRoutes() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<AuditorHome />} />

				<Route path="bedrifter">
					<Route index element={<AuditorCompaniesArchive />} />

					<Route path=":companyId">
						<Route index element={<CompaniesSingle />} />

						<Route path=":documentNodeId">
							<Route
								index
								element={<CompaniesDocumentsSingle />}
							/>

							<Route path="rediger" element={<DocumentEdit />} />

							<Route path="del" element={<DocumentShare />} />

							<Route
								path="signer"
								element={<SendSigningRequest />}
							/>
						</Route>
					</Route>
				</Route>

				<Route path="profil" element={<UserProfile />} />

				<Route path="404" element={<NotFound />} />

				<Route path="*" element={<RouteRedirect redirect="/404" />} />
			</Routes>
		</BrowserRouter>
	);
}
