import styled from 'styled-components';
import Icon from 'components/Icon';

import { useMainMenu } from 'app/menus/MainMenu';
import { Link } from 'app/components/Navigator';
import { useApp } from 'app/contexts/AppProviders';

const Header = styled.header`
	background: ${p => p.theme.palette.background.default};
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
	display: flex;
	align-items: center;
	min-height: 56px;
	position: relative;
	z-index: 9;
	padding-top: env(safe-area-inset-top);
`;
const Heading = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1 1 0%;
	color: #1a1a1a;
	line-height: 37px;
	font-size: 20px;
	font-weight: 700;
	width: 100%;
	max-width: calc(100% - 70px);
	padding-left: ${props => (props.showLogo ? '35px' : 'inherit')};
`;
const Title = styled.h1`
	font-size: 20px;
	line-height: 1;
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: ${props => (props.showLogo ? 'calc(100% - 55px)' : '100%')};
	width: ${props => (props.showLogo ? 'auto' : '100%')};
`;
export const IconHeaderButton = styled(Link)`
	width: auto;
	height: 24px;
	text-align: center;
	padding: 16px;
	margin: 0 0 0 auto;
	box-sizing: content-box;
	font-size: 20px;
	.material-icons {
		font-size: 20px;
	}
`;
export const BackButton = styled(Link)`
	&& {
		width: auto;
		height: 24px;
		text-align: center;
		padding: 16px;
		margin: -16px;
		box-sizing: content-box;
		font-size: 15px;
		.material-icons {
			font-size: 15px;
		}
	}
`;
const CurrentTenant = styled.button`
	background: #636363;
	color: white;
	border: none;
	width: 100%;
	padding: 4px;
	font-weight: 700;
`;

export default function PageHeader({
	title,
	leftButton,
	hideMainMenuButton,
	hideSearchButton,
	showLogo,
}) {
	const { setIsOpen } = useMainMenu();
	const {
		data: { user, tenant = null },
	} = useApp();

	return (
		<div>
			<Header>
				{leftButton}

				{!leftButton && (
					<BackButton aria-label="Gå tilbake" type="button" pop>
						<Icon icon="chevron-left" />
					</BackButton>
				)}

				<Heading showLogo={showLogo}>
					<Title showLogo={showLogo}>
						{(!showLogo && title) || (
							<svg
								style={{ display: 'block' }}
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_101_2)">
									<path
										d="M12.2949 28.3234C11.7626 28.752 11.2912 29.1163 10.86 29.418C12.2072 29.8071 13.6029 30.003 15.0052 30C17.3253 29.9994 19.6137 29.4606 21.6904 28.426C23.7671 27.3913 25.5754 25.8891 26.9733 24.0373C28.3711 22.1855 29.3204 20.0347 29.7464 17.754C30.1724 15.4733 30.0635 13.1249 29.4283 10.8934L18.408 22.4897C16.4873 24.5533 14.446 26.5012 12.2949 28.3234V28.3234Z"
										fill="#9D0028"
									/>
									<path
										d="M2.81057 16.9714C2.82555 16.317 2.9719 15.6722 3.24091 15.0754C3.50993 14.4787 3.89612 13.942 4.37657 13.4974C4.81282 13.0073 5.34556 12.6126 5.94143 12.338C6.5373 12.0634 7.18345 11.9147 7.83943 11.9014C9.04371 11.9014 9.75686 13.0054 9.98743 15.2048C10.0071 15.456 10.0277 15.6463 10.038 15.7671C10.133 16.9661 10.3586 18.1511 10.7109 19.3011C10.962 19.974 11.3109 20.3048 11.7446 20.3048C12.1294 20.195 12.481 19.9915 12.768 19.7126C13.3928 19.2071 13.9891 18.6674 14.5543 18.096L26.1257 6.63685C26.3966 6.36599 26.6477 6.12256 26.9083 5.8937C25.7161 4.28328 24.2134 2.92802 22.4888 1.90795C20.7642 0.887886 18.8527 0.223675 16.8671 -0.045463C14.8816 -0.314601 12.8623 -0.183213 10.9284 0.340946C8.99446 0.865105 7.18516 1.77141 5.60729 3.00635C4.02942 4.2413 2.71496 5.77986 1.74151 7.53119C0.76806 9.28252 0.155356 11.2111 -0.0604324 13.2032C-0.276221 15.1952 -0.0907198 17.2103 0.485119 19.1294C1.06096 21.0486 2.01546 22.8329 3.29228 24.3771C3.252 24.066 3.20228 23.7248 3.162 23.3631C2.9205 21.2412 2.80316 19.107 2.81057 16.9714V16.9714Z"
										fill="#9D0028"
									/>
								</g>
								<defs>
									<clipPath id="clip0_101_2">
										<rect
											width="30"
											height="30"
											fill="white"
										/>
									</clipPath>
								</defs>
							</svg>
						)}
					</Title>
				</Heading>

				{user && user.email && user.phone && !hideSearchButton && (
					<IconHeaderButton
						to={{
							screen: 'Search',
						}}
						aria-label="Åpne/lukk meny"
						style={{ marginRight: '0px' }}
					>
						<Icon icon="search" style={{ fontSize: '20px' }} />
					</IconHeaderButton>
				)}

				{!hideMainMenuButton && (
					<IconHeaderButton
						aria-label="Åpne/lukk meny"
						onClick={() => setIsOpen(true)}
					>
						<Icon icon="bars" style={{ fontSize: '24px' }} />
					</IconHeaderButton>
				)}
			</Header>

			{tenant && <CurrentTenant>{tenant.name}</CurrentTenant>}
		</div>
	);
}
