import { gql } from '@apollo/client';

export const GET_COMPANY_RISK_ACTIONS = gql`
	query findCompanyRiskActions($questionId: ID!) {
		actions: findCompanyRiskActions(questionId: $questionId) {
			_id
			description
			dueDate
			isOverdue
			done
		}
	}
`;
