import styled from 'styled-components';

import { UnderlineLink } from 'web/components/Link';
import Signers from 'web/components/Signers';

export default function UnsignedDocumentsList({
	documents,
	showSignedSignees = false,
}) {
	return (
		<div>
			{documents.length > 0 ? (
				<div>
					{documents.map(doc => (
						<UnsignedDocument
							key={doc._id}
							document={doc}
							showSignedSignees={showSignedSignees}
						/>
					))}
				</div>
			) : (
				<div>Ingen dokumenter mangler signering!</div>
			)}
		</div>
	);
}

const DocumentWrapper = styled.div`
	border-bottom: 1px solid ${p => p.theme.palette.common.gray};
	margin-bottom: 30px;
	padding-bottom: 20px;

	&:last-of-type {
		border: none;
	}
`;

const DocumentHeader = styled.div`
	margin-bottom: 10px;
	font-size: 1.2rem;
`;

const DocumentInfo = styled.div`
	color: ${p => p.theme.palette.text.secondary};
	font-size: 0.9rem;
`;

function UnsignedDocument({ document, showSignedSignees }) {
	return (
		<DocumentWrapper>
			<DocumentHeader>
				<div>
					<UnderlineLink
						to={`/bedrifter/${document.tenant._id}/${document._id}/`}
					>
						{document.name}
					</UnderlineLink>
				</div>

				<DocumentInfo>{document.tenant.name}</DocumentInfo>
			</DocumentHeader>

			<Signers
				documentId={document._id}
				showSignedSignees={showSignedSignees}
			/>
		</DocumentWrapper>
	);
}
