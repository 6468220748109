import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';

import { MarkdownEditor } from 'web/components/MarkdownEditor';

const MarkdownFieldWrapper = styled.div``;

export default function MarkdownField({ name, label = null }) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, value }, fieldState: { error } }) => {
				return (
					<MarkdownFieldWrapper>
						{label && <div>{label}</div>}

						<MarkdownEditor value={value} onChange={onChange} />

						{error && <div>{error}</div>}
					</MarkdownFieldWrapper>
				);
			}}
		/>
	);
}
