import { useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';

import { FormGroup } from 'web/form/Form';

const SubTasksFieldTitle = styled.h4`
	margin: 0 0 10px 0;
`;

const SubTasksAdd = styled.div`
	margin: 20px 0 0 0;
	display: flex;
	gap: 15px;
	align-items: center;
`;

const SubTasksAddInput = styled.input`
	width: 100%;
	max-width: 300px;
	padding: 10px;
`;

export default function SubTasksField() {
	const { verify } = useConfirmDialog();
	const { control } = useFormContext();
	const [newSubTask, setNewSubTask] = useState('');

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'subTasks',
	});

	const handleSubmit = () => {
		if (!newSubTask || newSubTask.trim() === '') return;

		append({
			name: newSubTask,
		});

		setNewSubTask('');
	};

	return (
		<FormGroup>
			<SubTasksFieldTitle>Deloppgaver</SubTasksFieldTitle>

			{fields.length > 0 && (
				<List>
					{fields.map((field, index) => (
						<ListItem
							key={index}
							secondaryAction={
								<IconButton
									edge="end"
									aria-label="Fjern deloppgave"
									title="Fjern deloppgave"
									onClick={async () => {
										if (
											await verify({
												title: 'Fjern deloppgave',
												text: 'Er du sikker på at du vil fjerne deloppgaven?',
											})
										) {
											remove(index);
										}
									}}
								>
									<DeleteIcon />
								</IconButton>
							}
						>
							{field.name}
						</ListItem>
					))}
				</List>
			)}

			<SubTasksAdd>
				<SubTasksAddInput
					name="addSubTask"
					placeholder="Legg til deloppgave"
					value={newSubTask}
					onChange={({ target }) => setNewSubTask(target.value)}
					onKeyDown={e => {
						if (e.key === 'Enter') {
							e.preventDefault();
							handleSubmit();
						}
					}}
				/>

				<Button
					type="button"
					variant="outlined"
					disabled={!newSubTask || newSubTask.trim() === ''}
					onClick={handleSubmit}
				>
					Legg til
				</Button>
			</SubTasksAdd>
		</FormGroup>
	);
}
