import { saveAs } from 'file-saver';

// TODO: Update when we drop the app
export async function download(url, name) {
	saveAs(await (await fetch(url)).blob(), name);
}

export async function upload(url, file, headers) {
	return await fetch(url, {
		method: 'PUT',
		body: file,
		headers,
	});
}
