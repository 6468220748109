import { Controller, useFormContext } from 'react-hook-form';
import MUIFormGroup from '@mui/material/FormGroup';
import MUIFormControlLabel from '@mui/material/FormControlLabel';
import MUISwitch from '@mui/material/Switch';

export default function Switch({
	name,
	label,
	defaultChecked = false,
	...rest
}) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultChecked}
			render={({ field: { onChange, value } }) => (
				<MUIFormGroup>
					<MUIFormControlLabel
						control={
							<MUISwitch
								checked={!!value}
								onChange={event =>
									onChange(event.target.checked)
								}
								{...rest}
							/>
						}
						label={label}
						{...rest}
					/>
				</MUIFormGroup>
			)}
		/>
	);
}
