import { useQuery } from '@apollo/client';

import { WidgetList } from 'web/components/widgets/components/WidgetList';
import WidgetListData from 'web/components/widgets/components/WidgetListData';

import ErrorAlert from 'components/ErrorAlert';

import Widget from 'web/components/widgets/Widget';

import { PAGINATE_TENANT_CATEGORIES } from 'api/queries/tenantCategoryQueries';
import { COMPANIES_WITH_CATEGORY } from 'api/queries/widgetQueries';

export default function CompanyCategoryWidget() {
	const {
		data: { categories } = {
			categories: { count: 0, items: [] },
		},
		error,
	} = useQuery(PAGINATE_TENANT_CATEGORIES);

	return (
		<Widget title="System" titleIcon="laptop">
			<ErrorAlert error={error} />

			<WidgetList>
				{categories.items.map(({ _id, name }) => (
					<WidgetListData
						key={_id}
						label={name}
						query={COMPANIES_WITH_CATEGORY}
						variables={{
							categoryId: _id,
						}}
						link={id => `/bedrifter/${id}/rediger/`}
					/>
				))}
			</WidgetList>
		</Widget>
	);
}
