import { useAppFilters } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

const options = [
	{ value: '', label: 'Alle typer' },
	{ value: 'accountant', label: 'Regnskapsfører' },
	{ value: 'accountManager', label: 'Kundeansvarlig' },
	{ value: 'controller', label: 'Oppdragskontrollør' },
];

export default function AccountantRoleFilterLegacy({
	disabled = false,
	size = 'medium',
}) {
	const { accountantRoleLegacy, setAccountantRoleLegacy } = useAppFilters();

	return (
		<Filter
			name="accountant-role-filter-legacy"
			label="Rolle"
			value={accountantRoleLegacy || options[0]}
			options={options}
			onChange={setAccountantRoleLegacy}
			disabled={disabled}
			size={size}
		/>
	);
}
