import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const UnderlineLink = styled(Link)`
	color: ${p => p.theme.palette.primary.main};
	text-decoration: underline;
	transition: all 0.5s;

	&:hover {
		color: ${p => p.theme.palette.text.primary};
	}
`;

export const StyledALink = styled.a`
	color: ${p => p.theme.palette.primary.main};
	text-decoration: underline;
	transition: all 0.5s;

	&:hover {
		color: ${p => p.theme.palette.text.primary};
	}
`;

export function EmailLink({ email }) {
	return <StyledALink href={`mailto:${email}`}>{email}</StyledALink>;
}

export function PhoneLink({ phone }) {
	return <StyledALink href={`tel:${phone}`}>{phone}</StyledALink>;
}

export const StyledLink = styled(Link)`
	color: inherit;
	text-decoration: inherit;
	transition: all 0.5s;

	&:hover {
		color: ${p => p.theme.palette.primary.main};
	}
`;

export default StyledLink;
