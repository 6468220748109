import { useMutation } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { dropdownYears } from 'utils/dates';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import AutocompleteField from 'components/form/AutocompleteField';
import SubmitButton from 'components/form/SubmitButton';

import { ADD_ANNUAL_FINANCIAL_STATUS } from 'api/mutations/tenantMutations';

export default function SetCompanyAnnualFinancialStatusDialog({
	annualFinancialStatus = [],
	companyId,
	open,
	onClose,
}) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="xs"
			aria-labelledby="dialog-title"
			aria-describedby="dialog-description"
		>
			<DialogTitle id="dialog-title">
				Sett status for årsregnskap
			</DialogTitle>

			<IconButton
				aria-label="close"
				onClick={onClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: theme => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>

			<SetCompanyAnnualFinancialStatusForm
				annualFinancialStatus={annualFinancialStatus}
				companyId={companyId}
				onClose={onClose}
			/>
		</Dialog>
	);
}

function SetCompanyAnnualFinancialStatusForm({
	annualFinancialStatus,
	companyId,
	onClose,
}) {
	const { notify } = useSnackbar();
	const year = new Date().getFullYear();
	const years = dropdownYears(null, year);

	const reviewedYears =
		annualFinancialStatus?.length > 0
			? annualFinancialStatus.map(status => {
					if (status.readyForReview) return status.reviewedYear;
				})
			: null;

	const [addAnnualFinancialSatus, { loading, error }] = useMutation(
		ADD_ANNUAL_FINANCIAL_STATUS,
		{
			refetchQueries: ['findOneTenant'],
		}
	);

	return (
		<Form
			isLoading={loading}
			isDisabled={loading}
			onSubmit={async variables => {
				const year = variables.year;
				try {
					const { data } = await addAnnualFinancialSatus({
						variables: {
							_id: companyId,
							year,
						},
					});

					if (data?.added) {
						notify(`Regnskap ${year} er nå klart for gjennomgang`);
						onClose();
					}
				} catch (err) {
					console.error(err);
				}
			}}
		>
			<DialogContent>
				<ErrorMessage errors={[error]} />

				<AutocompleteField
					name="year"
					label="Velg år"
					options={years.map(year => {
						return {
							value: year.value,
							label: year.label,
							disabled: reviewedYears?.some(
								el => el === year.value
							),
						};
					})}
					required
				/>

				<div>
					<i>
						Du kan kun sette årsregnskadivet klart en gang per år.
					</i>
				</div>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Avbryt</Button>

				<SubmitButton variant="contained">Sett klar</SubmitButton>
			</DialogActions>
		</Form>
	);
}
