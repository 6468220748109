import { gql } from '@apollo/client';

export const INSERT_ONE_FILE = gql`
	mutation insertOneFile(
		$fileName: String!
		$fileType: String!
		$url: String!
		$docId: ID!
	) {
		file: insertOneFile(
			fileName: $fileName
			fileType: $fileType
			url: $url
			docId: $docId
		) {
			_id
			fileName
			fileType
			url
			docId
		}
	}
`;

export const DELETE_ONE_FILE = gql`
	mutation deleteOneFile($_id: ID!) {
		deleted: deleteOneFile(_id: $_id)
	}
`;

export const REQUEST_FILE_UPLOAD = gql`
	mutation requestFileUpload(
		$uploadContext: String!
		$fileName: String!
		$contentType: String!
	) {
		uploadUrl: requestFileUpload(
			uploadContext: $uploadContext
			fileName: $fileName
			contentType: $contentType
		)
	}
`;
