import { useMutation } from '@apollo/client';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ModalLayout from 'components/modals/ModalLayout';
import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import { UPDATE_ONE_COMPANY_RISK_QUESTION_COMMENT } from 'api/mutations/companyRiskMutations';

export default function CompanyRiskAddCommentModal({
	questionId,
	comment = '',
	onClose,
}) {
	const { notify } = useSnackbar();

	const [updateComment, { loading, error }] = useMutation(
		UPDATE_ONE_COMPANY_RISK_QUESTION_COMMENT,
		{
			refetchQueries: ['findOneCompanyRisk'],
		}
	);

	return (
		<ModalLayout title="Legg til kommentar" onClose={onClose} width="800px">
			<ErrorMessage errors={error} />

			<Form
				values={{ comment }}
				isLoading={loading}
				isDisabled={loading}
				onSubmit={async ({ comment }) => {
					try {
						try {
							const { data } = await updateComment({
								variables: {
									questionId,
									comment,
								},
							});

							if (data.updated) {
								notify('Kommentar oppdatert');

								onClose();
							}
						} catch (err) {
							console.error(err);
						}
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<TextField
					name="comment"
					type="text"
					label="Kommentar"
					maxRows="5"
					multiline={true}
					required
				/>

				<SubmitButton variant="contained">Legg til</SubmitButton>
			</Form>
		</ModalLayout>
	);
}
