import { useQuery } from '@apollo/client';

import useSortOrder from 'hooks/useSortOrder';

import FileBrowser from 'web/components/views/FileBrowser';
import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';

import { GET_COMPANY_DOCUMENTS } from 'api/queries/documentQueries';

export default function DocumentsTab({ companyId }) {
	const [sort, setNewOrder] = useSortOrder('order');
	const tenantId = companyId;

	const {
		data: { documentNode, documentNodes = { items: [], count: 0 } } = {},
		loading: documentNodesLoading,
		error: documentNodesError,
	} = useQuery(GET_COMPANY_DOCUMENTS, {
		variables: {
			tenantId,
			parentId: 'root',
			order: sort.order,
			orderBy: sort.orderBy,
		},
		errorPolicy: 'all',
	});

	if (!companyId) return null;

	return (
		<>
			{documentNodesLoading && <InlineLoading />}

			<ErrorMessage errors={[documentNodesError]} />

			<h2>Dokumenter</h2>

			{documentNodes && documentNodes.items && (
				<FileBrowser
					documents={documentNodes}
					parentId="root"
					parentName={documentNode?.name}
					tenantId={tenantId}
					linkBase={`/bedrifter/${companyId || ''}/`}
					sort={sort}
					setNewOrder={setNewOrder}
				/>
			)}
		</>
	);
}
