import { useState, useEffect, useRef } from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import { DisplayError } from 'components/ErrorMessage';

export default function ErrorAlert({ error = null }) {
	const scrollRef = useRef(null);
	const [alertErrors, setAlertErrors] = useState([]);

	const addError = error => {
		if (error) {
			// check if error already exists in alertErrors array and is open
			const errorAlreadyExists = alertErrors.some(e => {
				if (error.message && e.error.message === error.message) {
					return true;
				}

				if (error && e.error === error) {
					return true;
				}

				return false;
			});

			if (errorAlreadyExists) return;

			// add error to alertErrors array
			setAlertErrors(prev => [
				...prev,
				{
					error: error,
					open: true,
				},
			]);

			if (scrollRef.current) {
				const rect = scrollRef.current.getBoundingClientRect();
				if (rect.top < window.innerHeight && rect.bottom >= 0) {
					return; // The component is already in the viewport, no need to scroll
				}

				// scroll to the error
				scrollRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}
	};

	const handleClose = index => {
		const currentErrors = [...alertErrors];
		currentErrors[index].open = false;
		setAlertErrors(currentErrors);
	};

	useEffect(() => {
		if (!error) return;

		if (Array.isArray(error)) {
			// if error is an array, add each item to the alertErrors array
			error.forEach(e => {
				addError(e);
			});
		} else {
			addError(error);
		}
	}, [error]);

	if (alertErrors.length === 0) return null;

	return (
		<div ref={scrollRef}>
			{alertErrors.map((e, index) => {
				return (
					<Collapse in={e.open} key={`error-alert-${index}`}>
						<Alert
							severity="error"
							action={
								<IconButton
									aria-label="Lukk"
									color="inherit"
									size="small"
									onClick={() => {
										handleClose(index);
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
							sx={{ mb: 4 }}
						>
							<AlertTitle>Feilmelding:</AlertTitle>

							<DisplayError error={e.error} />
						</Alert>
					</Collapse>
				);
			})}
		</div>
	);
}
