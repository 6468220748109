import { createContext, useContext, useState } from 'react';

import ListDialog, {
	GeneralDialogContent,
	ListDialogContent,
} from 'web/components/widgets/dialogs/ListDialog';

export const StatisticsDialogContext = createContext();

export function useStatisticsDialog() {
	const context = useContext(StatisticsDialogContext);

	if (context === undefined) {
		throw new Error(
			'useStatisticsDialog must be used within a StatisticsDialogProvider'
		);
	}

	return context;
}

export default function StatisticsDialogProvider({ children }) {
	const [dialogData, setDialogData] = useState({
		open: false,
		entries: [],
		title: '',
		link: '',
		content: null,
	});

	function handleShowDialog(title, entries, link, content = null) {
		setDialogData({
			open: true,
			entries,
			title,
			link,
			content,
		});
	}

	function handleCloseDialog() {
		setDialogData({
			open: false,
			entries: [],
			title: '',
			link: '',
			content: null,
		});
	}

	return (
		<StatisticsDialogContext.Provider
			value={{
				dialogData,
				showDialog: handleShowDialog,
				closeDialog: handleCloseDialog,
			}}
		>
			{children}

			<ListDialog
				entries={dialogData.entries}
				title={dialogData.title}
				open={dialogData.open}
				onClose={handleCloseDialog}
			>
				{dialogData.content ? (
					<GeneralDialogContent>
						{dialogData.content}
					</GeneralDialogContent>
				) : (
					<ListDialogContent
						entries={dialogData.entries}
						link={dialogData.link}
					/>
				)}
			</ListDialog>
		</StatisticsDialogContext.Provider>
	);
}
