import { useState, createContext, useContext } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import omitDeep from 'omit-deep';

const FormContext = createContext({});

export function useForm() {
	const context = useContext(FormContext);

	if (context === undefined) {
		throw new Error('useForm must be used within a Form');
	}

	return context;
}

export default function Form(props) {
	const [fields, setFields] = useState({});
	const [values, setValues] = useState(props.values || {});
	const [errors, setErrors] = useState({});
	const [preSubmitHooks, setPreSubmitHooks] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	function removeProp(obj, propToDelete) {
		// Apollo InMemoryCache is deep freezing results,
		// so we need to clone the object before we can delete a property.
		return omitDeep(cloneDeep(obj), propToDelete);
	}

	function registerField(field) {
		if (!field.name) {
			throw new Error('Field must have a name');
		}

		setFields(prevFields => {
			if (field.disabled) return prevFields;
			if (prevFields[field.name]) return prevFields;

			return {
				...prevFields,
				[field.name]: field,
			};
		});

		if ((field.value || field.defaultValue) && field.required) {
			setValues(prevValues => ({
				...prevValues,
				[field.name]: field.value || field.defaultValue,
			}));
		}
	}

	function updateField(field) {
		if (!field.name) {
			return;
		}

		setValues(prevValues => ({
			...prevValues,
			[field.name]: field.value,
		}));

		if (field.error) {
			setErrors(prevErrors => ({
				...prevErrors,
				[field.name]: field.error,
			}));
		} else {
			setErrors(prevErrors => {
				const updatedErrors = { ...prevErrors };
				delete updatedErrors[field.name];
				return updatedErrors;
			});
		}
	}

	function isFieldsValid(fields, values) {
		let allFieldsValidated = Object.keys(fields).map(name => ({
			...fields[name],
			isValid: validateField(fields[name], values[name]),
		}));

		if (Object.keys(fields).length === 0) {
			return false;
		}

		let invalidFields = allFieldsValidated.filter(field => !field.isValid);

		if (invalidFields && invalidFields.length) {
			return false;
		}

		return true;
	}

	function validateFields() {
		let isValid = true;
		let allFieldsValidated = Object.keys(fields).map(name => ({
			...fields[name],
			isValid: validateField(fields[name], values[name]),
		}));

		let invalidFields = allFieldsValidated.filter(field => !field.isValid);

		let updatedErrors = {};
		if (invalidFields && invalidFields.length) {
			isValid = false;

			invalidFields.map(field => {
				updatedErrors[field.name] = field;
				return field;
			});
		}

		setErrors(updatedErrors);
		return isValid;
	}

	function validateField(field, value) {
		if (typeof value !== 'boolean' && field.required && !value) {
			return false;
		}

		if (field.pattern) {
			// If field is not required and value is empty.
			// Default value for MUITextField is undefined.
			if (!field.required && !value) {
				return true;
			}

			const regex = new RegExp(field.pattern);
			return regex.test(value);
		}

		if (field.validate) {
			return field.validate(value);
		}

		return true;
	}

	async function runPreSubmitHooks() {
		let newValues = values;

		for (let key in preSubmitHooks) {
			newValues = {
				...newValues,
				...(await preSubmitHooks[key](newValues)),
			};
		}

		return newValues;
	}

	async function onSubmit(e) {
		try {
			e.preventDefault();
			e.stopPropagation();

			setIsSubmitting(true);

			if (!e.target.reportValidity) return;

			if (props.isDisabled) {
				setIsSubmitting(false);
				return;
			}

			const valuesAfterPresubmitHook = await runPreSubmitHooks();

			setValues(valuesAfterPresubmitHook);

			setValues(prevValues => removeProp(prevValues, '__typename'));

			let isValid = validateFields();
			let isSuccess = true;

			if (isValid) {
				try {
					await props.onSubmit(values);
				} catch (err) {
					console.error(err);

					isSuccess = false;
				}
			}

			if (isValid && isSuccess && props.onSuccess) {
				props.onSuccess(values);
			}

			setIsSubmitting(false);
		} catch (err) {
			console.error(err);
		} finally {
			setIsSubmitting(false);
		}
	}

	let {
		errorMessage,
		children,
		isDisabled,
		isLoading,
		renderHiddenSubmit = true,
	} = props;

	return (
		<FormContext.Provider
			value={{
				fields,
				values,
				errors,
				isLoading: isLoading || isSubmitting,
				isDisabled: isDisabled,
				updateField: field => updateField(field),
				registerField: field => registerField(field),
				canSubmit: isFieldsValid,
				errorMessage: errorMessage || 'Dette feltet er påkrevd',
				registerPreSubmitHook: (name, callback) => {
					setPreSubmitHooks(prevPreSubmitHooks => ({
						...prevPreSubmitHooks,
						[name]: callback,
					}));
				},
				removePreSubmitHook: name => {
					setPreSubmitHooks(prevPreSubmitHooks => {
						const newPreSubmitHooks = { ...prevPreSubmitHooks };
						delete newPreSubmitHooks[name];
						return newPreSubmitHooks;
					});
				},
			}}
		>
			<form onSubmit={onSubmit} style={props.style}>
				{renderHiddenSubmit && (
					<input
						type="submit"
						style={{
							position: 'absolute',
							left: '-9999px',
							width: '1px',
							height: '1px',
							opacity: '0',
						}}
					/>
				)}

				{children}
			</form>
		</FormContext.Provider>
	);
}
