import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { companyRoles } from 'server/libs/companyRoles';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';

import ErrorAlert from 'components/ErrorAlert';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';
import CheckboxField from 'components/form/CheckboxField';
import AutocompleteField from 'components/form/AutocompleteField';

import { PAGINATE_AUDITING_COMPANIES } from 'api/queries/auditingCompanyQueries';
import { INSERT_ONE_USER, INVITE_ONE_USER } from 'api/mutations/userMutations';

export default function UsersCreateDialog({
	open,
	onClose,
	onSubmitSuccess,
	title = 'Opprett bruker',
	user = {},
	companyId = null,
	defaultRole = 'silent',
}) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="xs"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>

			<UserCreateDialogForm
				user={user}
				companyId={companyId}
				onClose={onClose}
				onSubmitSuccess={onSubmitSuccess}
				defaultRole={defaultRole}
			/>
		</Dialog>
	);
}

function UserCreateDialogForm({
	onClose,
	onSubmitSuccess,
	user = {},
	companyId = null,
	defaultRole = 'silent',
}) {
	const { verify } = useConfirmDialog();

	const [formError, setFormError] = useState(null);

	const [
		insertOneUser,
		{ loading: insertOneUserLoading, error: insertOneUserError },
	] = useMutation(INSERT_ONE_USER, {
		refetchQueries: ['findOneTenant', 'paginateUsers'],
	});

	const [
		inviteOneUser,
		{ loading: inviteOneUserLoading, error: inviteOneUserError },
	] = useMutation(INVITE_ONE_USER);

	return (
		<Form
			values={{
				name: user?.name,
				email: '',
				phone: '',
				auditingCompany: user?.auditingCompany || null,
				userRoles: user?.role ? [user.role] : [],
				companyOwner: user?.companyOwner ? true : false,
			}}
			isLoading={insertOneUserLoading || inviteOneUserLoading}
			isDisabled={insertOneUserLoading || inviteOneUserLoading}
			onSubmit={async values => {
				try {
					if (values?.sendInvite && !values?.email) {
						setFormError(
							'Brukeren må ha en gyldig e-post for å bli invitert.'
						);

						return;
					}

					let variables = {
						role: defaultRole,
						name: values.name,
						email: values.email,
						phone: values.phone,
						auditingCompanyId: values.auditingCompanyId,
					};

					if (companyId) {
						if (
							values.userRoles.length === 0 &&
							!values.companyOwner
						) {
							setFormError(
								'Du må velge en rolle eller sette brukeren som reell rettighetshaver.'
							);

							return;
						}

						variables.userRoles =
							values.userRoles.map(role => ({
								id: companyId,
								role,
							})) || [];

						if (values.companyOwner) {
							variables.companyOwner = companyId;
						}
					}

					if (
						values?.sendInvite &&
						!(await verify({
							title: 'Sende invitasjon?',
							text: `Er du sikker på at du ønsker å sende invitasjon til den nye brukeren?`,
						}))
					) {
						return;
					}

					const { data: { insertOneUser: newUser } = {} } =
						await insertOneUser({
							variables,
						});

					if (values?.sendInvite && newUser._id) {
						await inviteOneUser({
							variables: {
								_id: newUser._id,
							},
						});
					}

					onSubmitSuccess(newUser);
				} catch (e) {
					console.warn(e);
				}
			}}
		>
			<DialogContent>
				<ErrorAlert error={insertOneUserError} />
				<ErrorAlert error={inviteOneUserError} />
				<ErrorAlert error={formError} />

				<TextField
					name="name"
					label="Navn"
					disabled={!!user?.name}
					required
				/>

				<TextField name="email" label="E-post" />

				<TextField
					name="phone"
					type="tel"
					title="Skriv inn et gyldig telefonnummer."
					label="Telefon"
				/>

				{companyId && <CompanyRolesAutoCompleteField />}

				{defaultRole === 'auditor' && <AuditorAutoCompleteField />}

				<CheckboxField
					name="sendInvite"
					label="Send invitasjon"
					defaultValue={false}
				/>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Avbryt</Button>

				<SubmitButton variant="contained">Opprett bruker</SubmitButton>
			</DialogActions>
		</Form>
	);
}

function CompanyRolesAutoCompleteField() {
	return (
		<>
			<AutocompleteField
				label="Bedriftsroller"
				name="userRoles"
				options={companyRoles}
				required
				multiple
			/>

			<CheckboxField
				name="companyOwner"
				label="Personen er en reell rettighetshaver for denne bedriften"
			/>
		</>
	);
}

function AuditorAutoCompleteField() {
	const {
		data: { auditingCompanies } = { auditingCompanies: [] },
		loading: auditingCompaniesLoading,
		error: auditingCompaniesError,
	} = useQuery(PAGINATE_AUDITING_COMPANIES);

	if (auditingCompaniesError) {
		return <ErrorAlert error={auditingCompaniesError} />;
	}

	return (
		<AutocompleteField
			label="Revisorfirma"
			name="auditingCompanyId"
			disabled={auditingCompaniesLoading}
			options={
				auditingCompanies?.items?.map(auditingCompany => ({
					value: auditingCompany._id,
					label: auditingCompany.name,
				})) ?? []
			}
			required
		/>
	);
}
