import {
	InfoLineWrapper,
	InfoLine,
	InfoValue,
	SubduedValue,
	PersonIcon,
} from 'web/components/panels/PanelItems';
import { UnderlineLink } from 'web/components/Link';

export default function UserInfoItem({ user }) {
	return (
		<InfoLineWrapper>
			<InfoLine>
				<PersonIcon />

				<InfoValue>
					<div>
						<UnderlineLink to={`/brukere/${user._id}/`}>
							{user.name}
						</UnderlineLink>
					</div>

					<SubduedValue>Aktiv bruker</SubduedValue>
				</InfoValue>
			</InfoLine>
		</InfoLineWrapper>
	);
}
