import { useEffect } from 'react';
import styled from 'styled-components';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { useForm } from 'components/form/Form';

const Wrapper = styled.div`
	margin-bottom: 10px;
`;

export default function SwitchField(props) {
	const { name, disabled, label, defaultValue = false } = props;

	const { registerField, updateField, isDisabled, values, errors } =
		useForm();

	const error = errors[name];

	useEffect(() => {
		registerField(props);
	});

	return (
		<Wrapper>
			<FormControlLabel
				control={
					<Switch
						{...props}
						disabled={disabled || isDisabled}
						error={errors[name]}
						value={
							values[name] !== null && values[name] !== undefined
								? !!values[name]
								: defaultValue
						}
						name={name}
						checked={
							values[name] !== null && values[name] !== undefined
								? !!values[name]
								: defaultValue
						}
						onChange={e => {
							const checked = e.target.checked;
							updateField({
								name,
								value: checked,
							});

							props.onChange && props.onChange(checked);
						}}
					/>
				}
				label={label}
			/>

			{!!error && <span>{error}</span>}
		</Wrapper>
	);
}
