const departments = [
	{ label: 'Levanger', value: 'levanger' },
	{ label: 'Verdal', value: 'verdal' },
	{ label: 'Rådgiving', value: 'radgiving' },
	{ label: 'Steinkjer', value: 'steinkjer' },
];

export function getDepartmentLabel(value) {
	const department = departments.find(r => r.value === value);

	if (!department) return '';

	return department.label;
}

export default departments;
