import styled from 'styled-components';

export const Card = styled.div`
	background-color: ${p =>
		p.type === 'warning'
			? p.theme.palette.secondary.main
			: p.theme.palette.background.gray};
	border: 1px solid ${p => p.theme.palette.background.paper};
	min-height: 100%;
`;

export const CardHeader = styled.div`
	display: flex;
	align-items: center;
	padding: 20px 16px 4px;

	p:last-of-type {
		margin-bottom: 0;
	}
`;

export const CardHeaderContent = styled.div`
	flex: 1 1 auto;
`;

export const CardContent = styled.div`
	padding: 16px;
`;
