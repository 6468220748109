export const calculationFunctions = {
	deem8: questions => {
		// Calculate Likviditetsgrad 1
		//
		// < 0,5 Ikke tilfredsstillende
		// 0,5 - 0,99 Svak
		// 1 - 1,49 Tilfredsstillende
		// 1,5 - 2 God
		// > 2 Meget god
		// Kilde: proff.no
		const qui5h = Number(questions.find(q => q.id === 'qui5h')?.value); // Omløpsmidler
		const yoch2 = Number(questions.find(q => q.id === 'yoch2')?.value); // Kortsiktig gjeld

		if (isNaN(yoch2) || isNaN(qui5h)) {
			return 'Ugyldig beløp.';
		}

		const sum = qui5h / yoch2;

		if (sum < 0.5) {
			return 'Ikke tilfredsstillende';
		} else if (sum < 1) {
			return 'Svak';
		} else if (sum < 1.5) {
			return 'Tilfredsstillende';
		} else if (sum < 2) {
			return 'God';
		} else {
			return 'Meget god';
		}
	},
	yegh0: questions => {
		// Calculate Totalrentabilitet

		// Gir uttrykk for lønnsomhet.
		// Ikke tilfredsstillende < 1 %
		// Svak 1 - 5,9 %
		// Tilfredsstillende 6 - 9,9 %
		// God 10 - 15 %
		// Meget god > 15 %
		// Kilde: proff.no
		const vaip7 = Number(questions.find(q => q.id === 'vaip7')?.value); // Resultat før skattekostnad
		const ohgh2 = Number(questions.find(q => q.id === 'ohgh2')?.value); // Egenkapital
		const ui2oo = Number(questions.find(q => q.id === 'ui2oo')?.value); // Gjeld
		const iik3o = Number(questions.find(q => q.id === 'iik3o')?.value); // Rentekostnader
		const jaep0 = Number(questions.find(q => q.id === 'jaep0')?.value); // Egenkapital pr 1/1
		const wi2cu = Number(questions.find(q => q.id === 'wi2cu')?.value); // Gjeld pr 1/1

		if (
			isNaN(vaip7) ||
			isNaN(ohgh2) ||
			isNaN(ui2oo) ||
			isNaN(iik3o) ||
			isNaN(jaep0) ||
			isNaN(wi2cu)
		) {
			return 'Ugyldig beløp.';
		}

		const sum = (vaip7 - iik3o) / ((ohgh2 + ui2oo + jaep0 + wi2cu) / 2);

		if (sum < 0.01) {
			return 'Ikke tilfredsstillende';
		} else if (sum < 0.06) {
			return 'Svak';
		} else if (sum < 0.1) {
			return 'Tilfredsstillende';
		} else if (sum < 0.15) {
			return 'God';
		} else {
			return 'Meget god';
		}
	},
	vo3ie: questions => {
		// Calculate Soliditet

		// 	Hvis sum EK er lavere enn 100', er soliditeten uansett svak.
		// < 3 % Ikke tilfredsstillende
		// 3 - 9 % Svak
		// 10 - 17 % Tilfredsstillende
		// 18 - 40 % God
		// > 40 % Meget god
		// Kilde: proff.no
		const ohgh2 = Number(questions.find(q => q.id === 'ohgh2')?.value); // Egenkapital
		const ui2oo = Number(questions.find(q => q.id === 'ui2oo')?.value); // Gjeld

		if (isNaN(ohgh2) || isNaN(ui2oo)) {
			return 'Ugyldig beløp.';
		}

		const sum = ohgh2 / (ohgh2 + ui2oo);

		if (ohgh2 < 100000) {
			return 'Svak';
		} else if (sum < 0.03) {
			return 'Ikke tilfredsstillende';
		} else if (sum < 0.09) {
			return 'Svak';
		} else if (sum < 0.17) {
			return 'Tilfredsstillende';
		} else if (sum < 0.4) {
			return 'God';
		} else {
			return 'Meget god';
		}
	},
};

export const defaultAssignmentRisks = {
	BUSINESS_RISKS: {
		type: 'BUSINESS_RISKS',
		order: 1,
		name: 'Virksomhetsforståelse (Om virksomheten)',
		description:
			'<p>Regnskapsfører skal ha tilstrekkelig forståelse av oppdragsgivers virksomhet til å kunne identifisere og forstå hendelser, transaksjoner og andre forhold som kan ha betydning for oppdraget. Regnskapsførers forståelse av oppdragsgivers virksomhet omfatter utfylling av dette skjema. </p><p>Formålet med skjemaet er å få frem hva som gjør at medarbeidere på oppdraget kan identifisere og forstå hendelser, transaksjoner og andre forhold som kan ha vesentlig betydning for oppdraget, jf. <a href="https://www.regnskapnorge.no/faget/grfs/grfs/#5.3_Brudd_p%C3%A5_oppdragsavtale_eller_krav_gitt_i_eller_i_medhold_av_lov" target="_blank">GRFS 5.3</a>.</p>',
		categories: [
			{
				title: 'Produkter og tjenester',
				questions: [
					{
						id: 'eisu0',
						title: 'Omsettes produkter?',
						type: 'boolean',
						value: '',
					},
					{
						id: 'eise0',
						title: 'Type produkter?',
						type: 'text',
						value: '',
						dependsOn: 'eisu0',
						dependsOnValue: 'Ja',
					},
					{
						id: 'zai3j',
						title: 'Hvilke har høyest samlet omsetning og dekningsbidrag?',
						type: 'text',
						value: '',
						dependsOn: 'eisu0',
						dependsOnValue: 'Ja',
					},
					{
						id: 'phei2',
						title: 'Er noen å anse som virksomhetskritiske?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'eisu0',
						dependsOnValue: 'Ja',
					},
					{
						id: 'aeng9',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'eisu0',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ae6ia',
						title: 'Omsettes tjenester?',
						type: 'boolean',
						value: '',
					},
					{
						id: 'oeg7m',
						title: 'Type tjenester?',
						type: 'text',
						value: '',
						dependsOn: 'ae6ia',
						dependsOnValue: 'Ja',
					},
					{
						id: 'hae8y',
						title: 'Hvilke har høyest samlet omsetning og dekningsbidrag?',
						type: 'text',
						value: '',
						dependsOn: 'ae6ia',
						dependsOnValue: 'Ja',
					},
					{
						id: 'geic9',
						title: 'Er noen å anse som virksomhetskritiske?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'ae6ia',
						dependsOnValue: 'Ja',
					},
					{
						id: 'aip4f',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'ae6ia',
						dependsOnValue: 'Ja',
					},
				],
			},
			{
				title: 'Salgs- og inntektskilder',
				questions: [
					{
						id: 'to5ee',
						title: 'Hvordan foregår salget?',
						type: 'text',
						helpText:
							'<p>Nettbestilling, butikk, ordresystem, telefonisk, faste løpende avtaler, abonnement mv.</p>',
						value: '',
					},
					{
						id: 'taif7',
						title: 'Hvordan utstedes salgsdokumentasjon?',
						type: 'text',
						helpText:
							'<p>Kvitteringer fra kontantkasse, kontantfaktura, fakturasystem, abonnementsavtaler mv.</p>',
						value: '',
					},
					{
						id: 'te1al',
						title: 'Type oppgjør?',
						type: 'text',
						helpText:
							'<p>Faktura, kassasystem, kontant vs. kort, debetkort vs. kredittkort vs. Klarna etc., e-penger/krypto, bytte av varer/tjenester mv.</p>',
						value: '',
					},
				],
			},
			{
				title: 'Kundesammensetning',
				questions: [
					{
						id: 'weil4',
						title: 'Type kunder?',
						type: 'text',
						helpText:
							'<p>Faste eller tilfeldige kunder, få eller mange, lokale, nasjonale eller internasjonale, anbudskonkurranser etc.</p>',
						value: '',
					},
					{
						id: 'aisu4',
						title: 'Er noen å anse som spesielt viktige og evt. virksomhetskritiske?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'joos2',
						title: 'Har oppdragsgiver eksport?',
						type: 'describe',
						value: 'Nei',
					},
				],
			},
			{
				title: 'Leverandører',
				questions: [
					{
						id: 'a1b2c',
						title: 'Type leverandører',
						type: 'text',
						helpText:
							'<p>Faste eller varierende leverandører, få eller mange, lokale, nasjonale eller internasjonale etc.</p>',
						value: '',
					},
					{
						id: 'd3r2f',
						title: 'Hvem er sentrale leverandører, evt spesifisert på produkt-/ tjenesteområder?',
						type: 'text',
						value: '',
					},
					{
						id: 'oor0o',
						title: 'Er noen å anse som spesielt viktige og evt. virksomhetskritiske?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'c2x3v',
						title: 'Har oppdragsgiver import?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'm9n0p',
						title: 'Benyttes faste oppdragstakere som driver ENK, som har få andre oppdragsgivere?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'fee6t',
						title: 'Er det tatt stilling til lønn vs. næring?',
						type: 'negativeDescribe',
						value: 'Ja',
					},
					{
						id: 'choh3',
						title: 'Ivaretas tredjepartsrapportering på næringsdrivende uten fast kontorsted?',
						type: 'negativeDescribe',
						value: 'Ja',
					},
					{
						id: 'w5x6y',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
					},
				],
			},
			{
				title: 'Bransjekunnskap',
				questions: [
					{
						id: 'cnb2c',
						title: 'Hvordan er konkurransesituasjonen?',
						type: 'text',
						value: '',
					},
					{
						id: 'd3e4f',
						title: 'Hvem er markedsledende i bransjen?',
						type: 'text',
						value: '',
					},
					{
						id: 'kk22vs',
						title: 'Noe vi bør være oppmerksomme på, f.eks. viktigste konkurrenter?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'j7k8l',
						title: 'Hvem er de viktigste leverandørene til bransjen?',
						type: 'text',
						value: '',
					},
					{
						id: 'cc9d0',
						title: 'Tas det jevnlig sjekk av at egne leverandører tilbyr gode priser?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'ju1ee',
						title: 'Bransjeforening(er)',
						type: 'text',
						value: '',
					},
					{
						id: 'tee9g',
						title: 'Er oppdragsgiver medlem?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'ei9ut',
						title: 'I så fall, tilbyr foreningen juridisk rådgivning?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'phe9v',
						title: 'Mest relevante nettsteder for bransjen',
						type: 'text',
						value: '',
					},
					{
						id: 'uga9y',
						title: 'Annet som er nyttig å kjenne til?',
						type: 'describe',
						value: 'Nei',
					},
				],
			},
			{
				title: 'Ansatte',
				questions: [
					{
						id: 'eeng7',
						title: 'Ca. antall ansatte',
						type: 'number',
						value: '',
					},
					{
						id: 'wing9',
						title: 'Type lønninger',
						type: 'text',
						helpText:
							'<p>Fast, timebasert, provisjon, bonusordninger mv.</p>',
						value: '',
					},
					{
						id: 'eiv7d',
						title: 'Arbeidstidsordning',
						type: 'text',
						value: '',
					},
					{
						id: 'ahng2',
						title: 'Følger tariffavtale?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'iep1o',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
					},
				],
			},
			{
				title: 'Kostnadselementer',
				questions: [
					{
						id: 'bhng3',
						title: 'Hvilke vesentlige kostnadselementer har virksomheten?',
						type: 'text',
						value: '',
					},
					{
						id: 'iep1y',
						title: 'Annet verdt å merke seg?',
						type: 'text',
						value: '',
					},
				],
			},
			{
				title: 'Investeringer og formuesverdier',
				questions: [
					{
						id: 'cof2l',
						title: 'Hvilke vesentlige investeringer har virksomheten evt. gjort?',
						type: 'text',
						helpText:
							'<p>Eiendom, driftsmidler, immaterielle verdier, varelager, finansielle instrumenter mv.</p>',
						value: '',
					},
					{
						id: 'eiti7',
						title: 'Hvilke vesentlige formuesverdier har oppdragsgiver?',
						type: 'text',
						helpText:
							'<p>Eiendom, driftsmidler, immaterielle verdier, varelager, finansielle instrumenter mv.</p>',
						value: '',
					},
					{
						id: 'joh4b',
						title: 'Er disse lett omsettelige, hvis det oppstår kapitalbehov?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'quie0',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
					},
				],
			},
			{
				title: 'Finansiering',
				questions: [
					{
						id: 'ahze6',
						title: 'Hvordan er oppdragsgiver finansiert?',
						type: 'text',
						helpText:
							'<p>Lån fra finansinstitusjoner, ansvarlig lånekapital, innskutt egenkapital, opptjent egenkapital o.a.</p>',
						value: '',
					},
					{
						id: 'ji6oh',
						title: 'Hvem er evt. sentrale långivere mv.?',
						type: 'text',
						value: '',
					},
					{
						id: 'ooch4',
						title: 'Er det andre kreditorer av betydning?',
						helpText:
							'<p>Eiere, familie, privatpersoner ellers, leverandører, kunder, gråmarked mv.</p>',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'hohl5',
						title: 'Får virksomheten offentlig støtte?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'que7m',
						title: 'Innebærer dette noen plikter og begrensninger som må ivaretas?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'am0oo',
						title: 'Er det forhold rundt beløpsgrenser og vilkår etter statsstøtteregelverket som må ivaretas?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'ief4e',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
					},
				],
			},
			{
				title: 'Økonomi',
				questions: [
					{
						id: 'wwo23',
						title: 'Årstall',
						type: 'number',
						helpText:
							'<p>Skriv inn hvilket årstall tallene gjelder for.</p>',
						value: '',
					},
					{
						id: 'qui5h',
						title: 'Omløpsmidler',
						type: 'currency',
						value: '',
					},
					{
						id: 'yoch2',
						title: 'Kortsiktig gjeld',
						type: 'currency',
						value: '',
					},
					{
						id: 'deem8',
						title: 'Likviditetsgrad 1',
						type: 'calculation',
						helpText:
							'<p>Dette er et mål på hvor mye kortsiktige midler foretaket har i forhold til forpliktelsene selskapet har på samme tidshorisont. Kortsiktig gjeld er forpliktelser som forfaller innen ett år, mens omløpsmidler er midler i foretaket som kan likvideres innen ett år. (Kilde: <a href="https://proff.no" target="_blank">proff.no</a>)</p>',
					},
					{
						id: 'zu8ee',
						title: 'Kommentar til likviditeten',
						helpText:
							'<p>Likvide midler i forhold til kortsiktige forpliktelser per dags dato. Beregn likviditeten før du legger inn kommentar.</p>',
						type: 'textarea',
						value: '',
					},
					{
						id: 'vaip7',
						title: 'Resultat før skattekostnad',
						type: 'currency',
						value: '',
					},
					{
						id: 'ohgh2',
						title: 'Egenkapital',
						type: 'currency',
						value: '',
					},
					{
						id: 'ui2oo',
						title: 'Gjeld',
						type: 'currency',
						value: '',
					},
					{
						id: 'iik3o',
						title: 'Rentekostnader',
						type: 'currency',
						value: '',
					},
					{
						id: 'jaep0',
						title: 'Egenkaptial pr 31/12',
						type: 'currency',
						value: '',
					},
					{
						id: 'wi2cu',
						title: 'Gjeld pr 31/12',
						type: 'currency',
						value: '',
					},
					{
						id: 'yegh0',
						title: 'Totalrentabilitet',
						type: 'calculation',
						helpText:
							'<p>Gir uttrykk for lønnsomhet. Totalrentabiliteten i et foretak er et mål på avkastningen på selve eiendelene. Et viktig nøkkeltall ved lønnsomhetsberegning. Et rimelig nivå er 10 - 15%, men bør ligge over det selskapet betaler for sine lån. (Kilde: <a href="https://proff.no" target="_blank">proff.no</a>)</p>',
					},
					{
						id: 'xo9za',
						title: 'Kommentar til lønnsomheten',
						type: 'textarea',
						helpText:
							'<p>Avkastning i perioden, sett i forhold til kapitalinnsatsen (gjennomsnittlig egenkapital og gjeld). Legg inn din vurdering av nøkkeltallet, samt periode input er hentet fra. Forslag til kommentarer: Sammenlign nøkkeltall med bransjetall ((Kilde: <a href="https://proff.no" target="_blank">proff.no</a>)), forventning til nøkkeltall, tidligere år, sesongsvingninger.</p>',
						value: '',
					},
					{
						id: 'vo3ie',
						title: 'Soliditet',
						type: 'calculation',
						helpText:
							'<p>Hvis sum egenkapital < 100 000,- anses soliditeten uansett for å være svak. Egenkapitalandelen viser hvor stor andel av eiendelene som er finansiert med egenkapitalen. (Kilde: <a href="https://proff.no" target="_blank">proff.no</a>)</p>',
					},
					{
						id: 'pafo0',
						title: 'Kommentar til soliditeten',
						type: 'textarea',
						helpText:
							'<p>Defineres gjerne som evne til å tåle tap, hvor egenkapital måles mot totalkapitalen. Legg inn din vurdering av nøkkeltallet, samt periode input er hentet fra. Forslag til kommentarer: Sammenlign nøkkeltall med bransjetall (proff.no), forventning til nøkkeltall, tidligere år, sesongsvingninger.</p>',
						value: '',
					},
					{
						id: 'da3og',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
					},
				],
			},
			{
				title: 'Vesentlige avtaler',
				questions: [
					{
						id: 'a4b2c',
						title: 'Hvilke vesentlige avtaler har oppdragsgiver?',
						type: 'title',
					},
					{
						id: 'eeb2a',
						title: 'Leverandøravtaler',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'g5h6i',
						title: 'Kundeleveranser',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'nu3k2',
						title: 'Leieavtaler',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'ish6k',
						title: 'Pensjonsavtaler',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'q1r2s',
						title: 'Forsikringsavtaler',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 't3u4v',
						title: 'Avtaler med eiere',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'zoo3i',
						title: 'Andre avtaler',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'z7a8b',
						title: 'Kjente avtaler mellom eiere om foretaket?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'c9d0e',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
					},
				],
			},
			{
				title: 'Regelverk, konsesjoner, rapporteringsplikter',
				questions: [
					{
						id: 'wi2ui',
						title: 'Er oppdragsgiver underlagt konsesjonskrav/godkjenningsordning?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'lie8o',
						title: 'Håndterer oppdragsgiver rapporteringsplikter selv, som er relevant for oppdraget?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'kai4w',
						title: 'Underlagt særlovgivning/særskilt regelverk?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'il2go',
						title: 'Underlagt etisk regelverk e.l.?',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'esh9s',
						title: 'Allment regelverk av betydning for oppdragsutførelsen',
						type: 'describe',
						value: 'Nei',
					},
					{
						id: 'ea5ud',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
					},
				],
			},
			{
				title: 'Administrative oppgaver og systemer',
				questions: [
					{
						id: 'waec0',
						title: 'Hvem utfører administrative oppgaver relevant for oppdraget?',
						helpText: 'Oppdragsgiver selv eller tredjeparter?',
						type: 'text',
						value: '',
					},
					{
						id: 'ou6vo',
						title: 'Hvilke IT-systemer benyttes til dette, som påvirker oppdragsutførelsen?',
						type: 'text',
						value: '',
					},
					{
						id: 'xohp7',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
					},
				],
			},
			{
				title: 'Annet',
				questions: [
					{
						id: 'fii3e',
						title: 'Beskriv eventuelt andre vesentlige forhold som er relevant for oppdraget',
						type: 'textarea',
						value: '',
					},
				],
			},
		],
	},
	SOFTWARE: {
		type: 'SOFTWARE',
		order: 2,
		name: 'Systemoversikt - Systemer involvert i oppdraget',
		description:
			'<p>For å forstå og avdekke risikoelementene i oppdraget må systemene som er involvert i oppdraget kartlegges. Vi må være kjent med prosessene som ligger bak og i regnskapet, og hvordan dataen kommer inn i regnskaps- og lønnssystem (manuelt, import, integrasjoner etc.). Kartleggingen av oppdragsgivers systemer omfatter utfylling av dette skjemaet. Systeminformasjon som legges inn i kartleggingssteget i Quality, under "Data og system", flettes inn her.</p><p>Formålet med skjemaet er å skissere opp hvilke systemer og evt. systemmoduler som er involvert i oppdraget. Skjemaet benyttes kun dersom det vurderes som nyttig.</p>',
		categories: [
			{
				title: 'Regnskapssystem',
				questions: [
					{
						id: 'hw2k2',
						title: 'Aktuelt?',
						type: 'boolean',
						value: '',
					},
					{
						id: 'bxng7',
						title: 'Navn på system',
						type: 'suggestion',
						options: [
							'Unimicro',
							'Tripletex',
							'PowerOffice Go',
							'Duett',
							'Xledger',
						],
						value: '',
						dependsOn: 'hw2k2',
						dependsOnValue: 'Ja',
					},
					{
						id: 'bing9',
						title: 'Lisenstype',
						type: 'choice',
						options: [
							'Vår lisens',
							'Kundens lisens',
							'Flere systemer',
						],
						value: '',
						dependsOn: 'hw2k2',
						dependsOnValue: 'Ja',
					},
					{
						id: 'quei0',
						title: 'Fakturatolk?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'hw2k2',
						dependsOnValue: 'Ja',
					},
					{
						id: 'euy4p',
						title: 'Import fra andre systemer?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'hw2k2',
						dependsOnValue: 'Ja',
					},
					{
						id: 'qua8a',
						title: 'Automatiserte prosesser?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'hw2k2',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ohje9',
						title: 'AI-baserte prosesser?',
						helpText:
							'<p>Oppgaver hvor saksbehandling er basert på hel eller delvis bruk av kunstig intelligens.</p>',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'hw2k2',
						dependsOnValue: 'Ja',
					},
					{
						id: 'lol2g',
						title: 'Manuelle prosesser?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'hw2k2',
						dependsOnValue: 'Ja',
					},
					{
						id: 'oob4w',
						title: 'Eksport til andre systemer?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'hw2k2',
						dependsOnValue: 'Ja',
					},
				],
			},
			{
				title: 'Kassasystem',
				questions: [
					{
						id: 'eet0z',
						title: 'Aktuelt?',
						type: 'boolean',
						value: '',
					},
					{
						id: 'iwie1',
						title: 'Navn på system',
						helpText:
							'<p>Liste over <a href="https://www.skatteetaten.no/bedrift-og-organisasjon/starte-og-drive/rutiner-regnskap-og-kassasystem/kassasystem/liste/" target="_blank">godkjente kassasystemer</a> hos Skatteetaten.</p>',
						type: 'text',
						value: '',
						dependsOn: 'eet0z',
						dependsOnValue: 'Ja',
					},
					{
						id: 'e2t1z',
						title: 'Lisenstype',
						type: 'choice',
						options: [
							'Vår lisens',
							'Kundens lisens',
							'Flere systemer',
						],
						value: '',
						dependsOn: 'eet0z',
						dependsOnValue: 'Ja',
					},
					{
						id: 'io3tu',
						title: 'Dagsoppgjør overføres digitalt og automatisk?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'eet0z',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ier0i',
						title: 'Automatiserte prosesser?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'eet0z',
						dependsOnValue: 'Ja',
					},
					{
						id: 'eid6n',
						title: 'AI-baserte prosesser?',
						helpText:
							'<p>Oppgaver hvor saksbehandling er basert på hel eller delvis bruk av kunstig intelligens.</p>',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'eet0z',
						dependsOnValue: 'Ja',
					},
					{
						id: 're5me',
						title: 'Manuelle prosesser?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'eet0z',
						dependsOnValue: 'Ja',
					},
					{
						id: 'fahp3',
						title: 'Eksport til andre systemer?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'eet0z',
						dependsOnValue: 'Ja',
					},
				],
			},
			{
				title: 'Årsoppgjørssystem',
				questions: [
					{
						id: 'avai6',
						title: 'Aktuelt?',
						type: 'boolean',
						value: '',
					},
					{
						id: 'xg2o1',
						title: 'Navn på system',
						type: 'suggestion',
						options: [
							'Visma Periode & År',
							'Maestro Årsoppgjør',
							'Duett Årsoppgjør',
							'Cantor',
						],
						value: '',
						dependsOn: 'avai6',
						dependsOnValue: 'Ja',
					},
					{
						id: 'eix1z',
						title: 'Lisenstype',
						type: 'choice',
						options: [
							'Vår lisens',
							'Kundens lisens',
							'Flere systemer',
						],
						value: '',
						dependsOn: 'avai6',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ies0b',
						title: 'Import fra andre systemer?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'avai6',
						dependsOnValue: 'Ja',
					},
					{
						id: 'fee3n',
						title: 'Automatiserte prosesser?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'avai6',
						dependsOnValue: 'Ja',
					},
					{
						id: 'chee5',
						title: 'AI-baserte prosesser?',
						helpText:
							'<p>Oppgaver hvor saksbehandling er basert på hel eller delvis bruk av kunstig intelligens.</p>',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'avai6',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ohm7t',
						title: 'Manuelle prosesser?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'avai6',
						dependsOnValue: 'Ja',
					},
					{
						id: 'jae0s',
						title: 'Eksport til andre systemer?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'avai6',
						dependsOnValue: 'Ja',
					},
				],
			},
			{
				title: 'Fakturasystem',
				questions: [
					{
						id: 'esei6',
						title: 'Aktuelt?',
						type: 'boolean',
						value: '',
					},
					{
						id: 'eich0',
						title: 'Navn på system',
						type: 'text',
						value: '',
						dependsOn: 'esei6',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ool0o',
						title: 'Lisenstype',
						type: 'choice',
						options: [
							'Vår lisens',
							'Kundens lisens',
							'Flere systemer',
						],
						value: '',
						dependsOn: 'esei6',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ub2oa',
						title: 'Import fra ordresystem mv.?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'esei6',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ahhe4',
						title: 'Automatiserte prosesser?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'esei6',
						dependsOnValue: 'Ja',
					},
					{
						id: 'voov2',
						title: 'AI-baserte prosesser?',
						helpText:
							'<p>Oppgaver hvor saksbehandling er basert på hel eller delvis bruk av kunstig intelligens.</p>',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'esei6',
						dependsOnValue: 'Ja',
					},
					{
						id: 'aiqu0',
						title: 'Manuelle prosesser?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'esei6',
						dependsOnValue: 'Ja',
					},
					{
						id: 'af5ee',
						title: 'Eksport til andre systemer?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'esei6',
						dependsOnValue: 'Ja',
					},
				],
			},
			{
				title: 'Lønnssystem',
				questions: [
					{
						id: 'gee4m',
						title: 'Aktuelt?',
						type: 'boolean',
						value: '',
					},
					{
						id: 'uch9l',
						title: 'Navn på system',
						type: 'text',
						value: '',
						dependsOn: 'gee4m',
						dependsOnValue: 'Ja',
					},
					{
						id: 'xoo1i',
						title: 'Lisenstype',
						type: 'choice',
						options: [
							'Vår lisens',
							'Kundens lisens',
							'Flere systemer',
						],
						value: '',
						dependsOn: 'gee4m',
						dependsOnValue: 'Ja',
					},
					{
						id: 'aesh9',
						title: 'Import fra ordresystem mv.?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'gee4m',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ohv7i',
						title: 'Automatiserte prosesser?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'gee4m',
						dependsOnValue: 'Ja',
					},
					{
						id: 'eiw8x',
						title: 'AI-baserte prosesser?',
						helpText:
							'<p>Oppgaver hvor saksbehandling er basert på hel eller delvis bruk av kunstig intelligens.</p>',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'gee4m',
						dependsOnValue: 'Ja',
					},
					{
						id: 'uya1w',
						title: 'Manuelle prosesser?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'gee4m',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ci9ce',
						title: 'Eksport til andre systemer?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'gee4m',
						dependsOnValue: 'Ja',
					},
				],
			},
			{
				title: 'Betalingsløsning',
				questions: [
					{
						id: 'ur1ph',
						title: 'Aktuelt?',
						type: 'boolean',
						value: '',
					},
					{
						id: 'ezu4a',
						title: 'Navn på system',
						type: 'text',
						value: '',
						dependsOn: 'ur1ph',
						dependsOnValue: 'Ja',
					},
					{
						id: 'eeje4',
						title: 'Lisenstype',
						type: 'choice',
						options: [
							'Vår lisens',
							'Kundens lisens',
							'Flere systemer',
						],
						value: '',
						dependsOn: 'ur1ph',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ahj2k',
						title: 'Import fra ordresystem mv.?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'ur1ph',
						dependsOnValue: 'Ja',
					},
					{
						id: 'yeiy6',
						title: 'Automatiserte prosesser?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'ur1ph',
						dependsOnValue: 'Ja',
					},
					{
						id: 'neu8i',
						title: 'AI-baserte prosesser?',
						helpText:
							'<p>Oppgaver hvor saksbehandling er basert på hel eller delvis bruk av kunstig intelligens.</p>',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'ur1ph',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ugah5',
						title: 'Manuelle prosesser?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'ur1ph',
						dependsOnValue: 'Ja',
					},
					{
						id: 'maer9',
						title: 'Eksport til andre systemer?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'ur1ph',
						dependsOnValue: 'Ja',
					},
				],
			},
			{
				title: 'Annet',
				questions: [
					{
						id: 'dsw2a',
						title: 'Hvilke andre IT-systemer benyttes  av oppdragsgiver/tredjepart, som påvirker oppdragsutførelsen?',
						type: 'textarea',
						value: '',
					},
					{
						id: 'ubik7',
						title: 'Andre kommentarer om oppdraget',
						type: 'textarea',
						value: '',
					},
				],
			},
		],
	},
	ROUTINES: {
		type: 'ROUTINES',
		order: 3,
		name: 'Oppdragsgivers interne rutiner',
		description:
			'<p>Regnskapsfører skal vurdere de interne rutiner hos oppdragsgiver som er sentrale for utføring av oppdraget. Regnskapsfører skal ha en risikobasert tilnæring til vurderingen. Vurderingen skal oppdateres ved endringer i rutiner, eller andre forhold som påvirker rutinene eller vurderingene.</p><p>Hvis regnskapsfører i utføringen av regnskapsoppdraget avdekker svakheter ved oppdragsgivers interne rutiner, skal svakhetene rapporteres til oppdragsgiver. Vesentlige svakheter skal rapporteres skriftlig, og lagres på oppgaven "Brudd på lovkrav". Kartleggingen av oppdragsgivers rutiner omfatter utfylling av dette skjemaet.</p>',
		categories: [
			{
				title: 'Faktureringsoppdrag',
				questions: [
					{
						id: 'shoo3',
						title: 'Har vi faktureringsoppdrag?',
						type: 'boolean',
						value: '',
					},
					{
						id: 'uxu4a',
						title: 'Sikrer rutinene at alle leveranser blir fakturert?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'shoo3',
						dependsOnValue: 'Ja',
					},
					{
						id: 'be0ae',
						title: 'Blir grunnlag tilgjengelig til avtalt tid?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'shoo3',
						dependsOnValue: 'Ja',
					},
					{
						id: 'wib9u',
						title: 'Oppdaterer oppdragsgiver oss om endringer i avtaler som er grunnlag for fast fakturering?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'shoo3',
						dependsOnValue: 'Ja',
					},
					{
						id: 'kei6p',
						title: 'Oppdaterer oppdragsgiver oss om endringer i pris- eller kunderegister?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'shoo3',
						dependsOnValue: 'Ja',
					},
					{
						id: 'aeh9e',
						title: 'Grunnlag for salgsdokumentene',
						type: 'title',
						dependsOn: 'shoo3',
						dependsOnValue: 'Ja',
					},
					{
						id: 'wae7l',
						title: 'Sørger rutinene for at krav til innhold i salgsdokumenter oppfylles?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'shoo3',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ish6d',
						title: 'Gis tilstrekkelig info for rett mva-behandling?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'shoo3',
						dependsOnValue: 'Ja',
					},
					{
						id: 'rai1u',
						title: 'Ved særavgifter: Sørger rutinene for rett bruk av satser, avgiftskoder og spesifikasjon til særavgiftsregnskap?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'shoo3',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ub2oe',
						title: 'Er det noe i oppdragsavtalen som må tas særskilt hensyn til?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'shoo3',
						dependsOnValue: 'Ja',
					},
					{
						id: 'tie8p',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'shoo3',
						dependsOnValue: 'Ja',
					},
				],
			},
			{
				title: 'Lønnsoppdrag',
				questions: [
					{
						id: 'oog9o',
						title: 'Har vi lønnsoppdrag?',
						type: 'boolean',
						value: '',
					},
					{
						id: 'jeij2',
						title: 'Sikrer rutinene at lønn og godtgjørelser utbetales til avtalt tid?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'oog9o',
						dependsOnValue: 'Ja',
					},
					{
						id: 'xee4j',
						title: 'Blir lønnsgrunnlag tilgjengelig til avtalt tid?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'oog9o',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ob6zo',
						title: 'Oppdaterer oppdragsgiver oss om endringer i fast ansatte og vikarbruk?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'oog9o',
						dependsOnValue: 'Ja',
					},
					{
						id: 'wei9y',
						title: 'Oppdaterer oppdragsgiver oss om endringer i lønnsbetingelser mv.?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'oog9o',
						dependsOnValue: 'Ja',
					},
					{
						id: 'fai9f',
						title: 'Sørger rutinene for at dokumentasjonskrav for lønnsytelser, forskuddstrekk, utleggstrekk mv, reiseregninger, andre godtgjørelser og utlegg oppfylles?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'oog9o',
						dependsOnValue: 'Ja',
					},
					{
						id: 'uqu4o',
						title: 'Oppfylles krav til skattetrekkskonto/ bankgaranti for trekkansvar?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'oog9o',
						dependsOnValue: 'Ja',
					},
					{
						id: 'vbue6',
						title: 'Pensjonsordning (OTP mv) og personalforsikringer',
						type: 'title',
						dependsOn: 'oog9o',
						dependsOnValue: 'Ja',
					},
					{
						id: 'tohp5',
						title: 'Sørger rutinene for rett inn-/utmelding i pensjonsordning?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'oog9o',
						dependsOnValue: 'Ja',
					},
					{
						id: 'oe7je',
						title: 'Sørger rutinene for oppdaterte grunnlag for pensjonssparingen?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'oog9o',
						dependsOnValue: 'Ja',
					},
					{
						id: 'iqu6j',
						title: 'Oppdateres personalforsikringer i samsvar med forsikringsvilkårene?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'oog9o',
						dependsOnValue: 'Ja',
					},
					{
						id: 'xai4e',
						title: 'Sørger rutinen for at krav om refusjoner overfor NAV blir ivaretatt til rett tid?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'oog9o',
						dependsOnValue: 'Ja',
					},
					{
						id: 'too8t',
						title: 'Er det noe i oppdragsavtalen som må tas særskilt hensyn til?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'oog9o',
						dependsOnValue: 'Ja',
					},
					{
						id: 'uj6li',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'oog9o',
						dependsOnValue: 'Ja',
					},
				],
			},
			{
				title: 'Betalingsoppdrag',
				questions: [
					{
						id: 'ne8ke',
						title: 'Har vi betalingsoppdrag?',
						type: 'boolean',
						value: '',
					},
					{
						id: 'su0da',
						title: 'Sikrer rutinene at betalinger skjer til rett tid?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'ne8ke',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ud8pu',
						title: 'Blir betalingsgrunnlag tilgjengelig til avtalt tid?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'ne8ke',
						dependsOnValue: 'Ja',
					},
					{
						id: 'va8ju',
						title: 'Oppdaterer oppdragsgiver oss om endringer i kontantrabatter, akontobetalinger, fradrag for kreditnota mv.?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'ne8ke',
						dependsOnValue: 'Ja',
					},
					{
						id: 'id0hu',
						title: 'Oppdaterer oppdragsgiver oss om stopp/frigivelse av poster som avventes for utbetaling?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'ne8ke',
						dependsOnValue: 'Ja',
					},
					{
						id: 'wi3oh',
						title: 'Inneholder betalingsgrunnlag tilstrekkelig informasjon til at krav til dokumentasjon av betalingstransaksjoner kan oppfylles?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'ne8ke',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ea7ie',
						title: 'Har oppdragsgiver betryggende rutine for attestasjon/godkjenning, enten av betalingsgrunnlag eller betalingsforslag (manuelt eller elektronisk)?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'ne8ke',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ilei9',
						title: 'Sørger rutinen for at endringer i avtaler som er grunnlag for faste betalinger formidles til oss?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'ne8ke',
						dependsOnValue: 'Ja',
					},
					{
						id: 'eigh0',
						title: 'Er det noe i oppdragsavtalen som må tas særskilt hensyn til?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'ne8ke',
						dependsOnValue: 'Ja',
					},
					{
						id: 'poh7p',
						title: 'Har vi vurdert om oppdragsgiver kan ta betalingsoppdraget selv?',
						helpText:
							'<p>Det er foretrukket at oppdragsgiver håndterer betalingsoppdragene selv Dette skyldes at betalingsoppdrag innebærer en betydelig risiko. Vennligst se egen policy (Sharepoint) for betalingsoppdrag for å hjelpe deg med å få oppdragsgiver til å overta betalingsoppdraget.</p>',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'ne8ke',
						dependsOnValue: 'Ja',
					},
					{
						id: 'chae2',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'ne8ke',
						dependsOnValue: 'Ja',
					},
				],
			},
			{
				title: 'Bokføringsoppdrag',
				questions: [
					{
						id: '88ut4',
						title: 'Har vi bokføringsoppdrag?',
						type: 'boolean',
						value: '',
					},
					{
						id: 'gg7bx',
						title: 'Bilag og andre bokføringsgrunnlag',
						type: 'title',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'aith7',
						title: 'Bilag mv blir tilgjengelig for oss innen avtalte frister?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'oh0ye',
						title: 'Gis nødvendig informasjon om bokføringskontoer, prosjektkoder, avdelingskontoer mv.?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'tee4o',
						title: 'Begrunnes transaksjoner mv. når dette er nødvendig for å underbygge bokføringen?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ahy5s',
						title: 'Fremstår evt. kontantforvaltning som forsvarlig, herunder kassasystem?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ahp1o',
						title: 'Ivaretas skillet mellom privatøkonomi og foretaksøkonomi på betryggende måte?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'xn5op',
						title: 'Oppfølging rapporter mv.',
						type: 'title',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'lier8',
						title: 'Følger oppdragsgiver opp rapporter som utarbeides?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'aeno7',
						title: 'Er oppdragsgiver oppmerksom på når perioderapporter er kvalitetssikret og periodisert?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'shae5',
						title: 'Er oppdragsgiver kjent med hvilke svakheter evt. sanntids- og nøkkeltallsoversikter kan ha?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'yooz9',
						title: 'Har oppdragsgiver kunnskap om hva som bør følges opp i periodiske regnskapsrapporter og pliktig regnskapsrapportering?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'cei1a',
						title: 'Sørger oppdragsgiver for å melde fra om evt. feil og mangler som oppdragsgiver oppdager?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'on4xu',
						title: 'Virker det som oppdragsgiver sørger for betryggende oppbevaring av regnskapsmateriale?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'eiph7',
						title: 'Informerer oppdragsgiver oss om evt. dialog med revisor, off. myndigheter mv.?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'mook6',
						title: 'Er det noe i oppdragsavtalen som må tas særskilt hensyn til?',
						type: 'describe',
						value: 'Nei',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
					{
						id: 'srt3i',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
						dependsOn: '88ut4',
						dependsOnValue: 'Ja',
					},
				],
			},
			{
				title: 'Årsoppgjørsoppdrag',
				questions: [
					{
						id: 'aes7g',
						title: 'Har vi årsoppgjørsoppdrag?',
						type: 'boolean',
						value: '',
					},
					{
						id: 'aiqu1',
						title: 'Er dokumentasjon av balansen tilfredsstillende?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'aes7g',
						dependsOnValue: 'Ja',
					},
					{
						id: 'fo9th',
						title: 'Får vi nødvendig informasjon til årsregnskapet og skattemeldingen?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'aes7g',
						dependsOnValue: 'Ja',
					},
					{
						id: 'pah0o',
						title: 'Informerer oppdragsgiver oss om evt. dialog med revisor, skatteetaten og andre etater om årsregnskap/skatt?',
						type: 'negativeDescribe',
						value: 'Ja',
						dependsOn: 'aes7g',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ya6ei',
						title: 'Er det noe i oppdragsavtalen som må tas særskilt hensyn til?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'aes7g',
						dependsOnValue: 'Ja',
					},
					{
						id: 'ohm2i',
						title: 'Annet verdt å merke seg?',
						type: 'describe',
						value: 'Nei',
						dependsOn: 'aes7g',
						dependsOnValue: 'Ja',
					},
				],
			},
			{
				title: 'Andre rapporteringsoppdrag',
				questions: [
					{
						id: 'gg27x',
						title: 'Vurder hvilke av oppdragsgivers rutiner som er sentrale for rapporteringsoppdraget',
						type: 'choice',
						options: ['OK rutine', 'Følges opp'],
					},
					{
						id: '12a3b',
						title: 'Hva skal følges opp?',
						type: 'textarea',
						value: '',
						dependsOn: 'gg27x',
						dependsOnValue: 'Følges opp',
					},
				],
			},
			{
				title: 'Oppbevaring av regnskapsmateriale',
				questions: [
					{
						id: 'ohf8b',
						title: 'Regnskapsbilag',
						type: 'choice',
						options: ['OK rutine', 'Følges opp'],
					},
					{
						id: 'kaeb2',
						title: 'Hva skal følges opp?',
						type: 'textarea',
						value: '',
						dependsOn: 'ohf8b',
						dependsOnValue: 'Følges opp',
					},
					{
						id: 'eev7u',
						title: 'Dokumentasjon av balanse per årsslutt',
						type: 'choice',
						options: ['OK rutine', 'Følges opp'],
					},
					{
						id: 'heuw0',
						title: 'Hva skal følges opp?',
						type: 'textarea',
						value: '',
						dependsOn: 'eev7u',
						dependsOnValue: 'Følges opp',
					},
					{
						id: 'pha7m',
						title: 'Spesifikasjoner pliktig regnskaps-rapportering (pdf eller regnskapsdata)',
						type: 'choice',
						options: ['OK rutine', 'Følges opp'],
					},
					{
						id: 'aith6',
						title: 'Hva skal følges opp?',
						type: 'textarea',
						value: '',
						dependsOn: 'pha7m',
						dependsOnValue: 'Følges opp',
					},
					{
						id: 'eiv1f',
						title: 'Årsregnskap, årsberetning og revisjonsberetning',
						type: 'choice',
						options: ['OK rutine', 'Følges opp'],
					},
					{
						id: 'ez67e',
						title: 'Hva skal følges opp?',
						type: 'textarea',
						value: '',
						dependsOn: 'eiv1f',
						dependsOnValue: 'Følges opp',
					},
					{
						id: 'shee0',
						title: 'Skattemelding, mva-oppgave og a-meldinger',
						type: 'choice',
						options: ['OK rutine', 'Følges opp'],
					},
					{
						id: 'eo0th',
						title: 'Hva skal følges opp?',
						type: 'textarea',
						value: '',
						dependsOn: 'shee0',
						dependsOnValue: 'Følges opp',
					},
					{
						id: 'hu3oh',
						title: 'Elektronisk tilgjengelighet til bokførte opplysninger',
						type: 'choice',
						options: ['OK rutine', 'Følges opp'],
					},
					{
						id: 'eeci7',
						title: 'Hva skal følges opp?',
						type: 'textarea',
						value: '',
						dependsOn: 'hu3oh',
						dependsOnValue: 'Følges opp',
					},
					{
						id: 'yau3e',
						title: 'Annen dokumentasjon',
						type: 'choice',
						options: ['OK rutine', 'Følges opp'],
					},
					{
						id: 'oosh5',
						title: 'Hva skal følges opp?',
						type: 'textarea',
						value: '',
						dependsOn: 'yau3e',
						dependsOnValue: 'Følges opp',
					},
				],
			},
			{
				title: 'Annet',
				questions: [
					{
						id: 'aesh7',
						title: 'Andre kommentarer om oppdraget',
						type: 'textarea',
						value: '',
					},
				],
			},
		],
	},
	ASSESSMENTS: {
		type: 'ASSESSMENTS',
		order: 4,
		name: 'Grunnleggende intern oppdragsvurdering',
		description:
			'<p>Oppdragsansvarlig regnskapsførers involvering og kontroll baseres på en risikobasert tilnærming. Momenter i vurderingen om oppdragsrisiko er blant annet kartleggingen av virksomheten, systemene og oppdragsgivers rutiner. Det gjøres en egen risikovurdering tilknyttet hvitvasking.</p><p>I tillegg skal oppdragets størrelse og kompleksitet, kompetansen til medarbeiderne på oppdraget og regnskapsforetakets kvalitetsstyring inngå i vurderingen. Med risikobasert tilnærming menes en samlet vurdering av sannsynligheten, og konsekvensen for slike feil, mangler og andre uønskede hendelser</p>',
		categories: [
			{
				title: 'Vurdering av medarbeidere',
				questions: [
					{
						id: 'kkoo3',
						title: 'Kundeansvarlig',
						helpText:
							'<p>Summen av erfaring, kompetanse og kapasitet påvirker totalrisiko på oppdraget.</p>',
						type: 'title',
					},
					{
						id: 'phei7',
						title: 'Navn',
						type: 'text',
						value: '',
					},
					{
						id: 'me2et',
						title: 'Erfaring',
						helpText:
							'<p>Hjelp til å vurdere erfaring: Lang eller kort erfaring? Bransjespesifikk erfaring eller generell? Erfaring fra byrå eller fersk i bransjen?</p>',
						type: 'text',
						value: '',
					},
					{
						id: 'erei0',
						title: 'Kompetanse',
						helpText:
							'<p>Hjelp til å vurdere kompetanse: Spesialkompetanse innenfor et område? Begrensen til enkelte leveranser på oppdraget?</p>',
						type: 'text',
						value: '',
					},
					{
						id: 'aiko8',
						title: 'Kapasitet',
						type: 'text',
						helpText:
							'<p>Hjelp til å vurdere kapasitet: For mye å gjøre utgjør i seg selv en risiko (tar forhastede konklusjoner, ikke detaljorientert).</p>',
						value: '',
					},
					{
						id: 'bn2wq',
						title: 'Regnskapsmedarbeider',
						helpText:
							'<p>Summen av erfaring, kompetanse og kapasitet påvirker totalrisiko på oppdraget.</p>',
						type: 'title',
					},
					{
						id: 'paim5',
						title: 'Navn',
						type: 'text',
						value: '',
					},
					{
						id: 'tee8e',
						title: 'Erfaring',
						helpText:
							'<p>Hjelp til å vurdere erfaring: Lang eller kort erfaring? Bransjespesifikk erfaring eller generell? Erfaring fra byrå eller fersk i bransjen?</p>',
						type: 'text',
						value: '',
					},
					{
						id: 'iv2ei',
						title: 'Kompetanse',
						helpText:
							'<p>Hjelp til å vurdere kompetanse: Spesialkompetanse innenfor et område? Begrensen til enkelte leveranser på oppdraget?</p>',
						type: 'text',
						value: '',
					},
					{
						id: 'dei1o',
						title: 'Kapasitet',
						type: 'text',
						helpText:
							'<p>Hjelp til å vurdere kapasitet: For mye å gjøre utgjør i seg selv en risiko (tar forhastede konklusjoner, ikke detaljorientert).</p>',
						value: '',
					},
					{
						id: 'cv321',
						title: 'Lønnsansvarlig',
						helpText:
							'<p>Summen av erfaring, kompetanse og kapasitet påvirker totalrisiko på oppdraget.</p>',
						type: 'title',
					},
					{
						id: 'doo7n',
						title: 'Navn',
						type: 'text',
						value: '',
					},
					{
						id: 'eit3v',
						title: 'Erfaring',
						helpText:
							'<p>Hjelp til å vurdere erfaring: Lang eller kort erfaring? Bransjespesifikk erfaring eller generell? Erfaring fra byrå eller fersk i bransjen?</p>',
						type: 'text',
						value: '',
					},
					{
						id: 'za7ru',
						title: 'Kompetanse',
						helpText:
							'<p>Hjelp til å vurdere kompetanse: Spesialkompetanse innenfor et område? Begrensen til enkelte leveranser på oppdraget?</p>',
						type: 'text',
						value: '',
					},
					{
						id: 'ek0th',
						title: 'Kapasitet',
						type: 'text',
						helpText:
							'<p>Hjelp til å vurdere kapasitet: For mye å gjøre utgjør i seg selv en risiko (tar forhastede konklusjoner, ikke detaljorientert).</p>',
						value: '',
					},
					{
						id: 'lop3a',
						title: 'Andre medarbeidere',
						helpText:
							'<p>Summen av erfaring, kompetanse og kapasitet påvirker totalrisiko på oppdraget.</p>',
						type: 'title',
					},
					{
						id: 'ti5op',
						title: 'Navn',
						type: 'text',
						value: '',
					},
					{
						id: 'iib4i',
						title: 'Erfaring',
						helpText:
							'<p>Hjelp til å vurdere erfaring: Lang eller kort erfaring? Bransjespesifikk erfaring eller generell? Erfaring fra byrå eller fersk i bransjen?</p>',
						type: 'text',
						value: '',
					},
					{
						id: 'tah9o',
						title: 'Kompetanse',
						helpText:
							'<p>Hjelp til å vurdere kompetanse: Spesialkompetanse innenfor et område? Begrensen til enkelte leveranser på oppdraget?</p>',
						type: 'text',
						value: '',
					},
					{
						id: 'eeph1',
						title: 'Kapasitet',
						type: 'text',
						helpText:
							'<p>Hjelp til å vurdere kapasitet: For mye å gjøre utgjør i seg selv en risiko (tar forhastede konklusjoner, ikke detaljorientert).</p>',
						value: '',
					},
				],
			},
			{
				title: 'Overordnet beskrivelse av kundens virksomhet, oppdragets kompleksistet og risiko',
				questions: [
					{
						id: 'fg7bx',
						title: 'Gi en kortfattet oppsummering av de vurderingene du har foretatt i (1) Om virksomheten, (2) Systemoversikt og (3) Oppdragsgivers rutiner',
						type: 'textarea',
						value: '',
					},
				],
			},
			{
				title: 'Risiko- og fokusområder',
				questions: [
					{
						id: 'b2h7x',
						title: 'Inntekter',
						type: 'risk',
						value: '',
					},
					{
						id: 'b7bxe',
						title: 'Begrunnelse',
						type: 'text',
						value: '',
					},
					{
						id: '3s7bx',
						title: 'Varelager',
						type: 'risk',
						value: '',
					},
					{
						id: 'hs7bx',
						title: 'Begrunnelse',
						type: 'text',
						value: '',
					},
					{
						id: '6y7bx',
						title: 'Aksjer',
						type: 'risk',
						value: '',
					},
					{
						id: 'n7bx2',
						title: 'Begrunnelse',
						type: 'text',
						value: '',
					},
					{
						id: 'm7bx',
						title: 'Driftsmidler',
						type: 'risk',
						value: '',
					},
					{
						id: 'po2bx',
						title: 'Begrunnelse',
						type: 'text',
						value: '',
					},
					{
						id: 'ml7bx',
						title: 'Egenkapital',
						type: 'risk',
						value: '',
					},
					{
						id: 'vb7bx',
						title: 'Begrunnelse',
						type: 'text',
						value: '',
					},
					{
						id: 're7bx',
						title: 'Skatteberegning',
						type: 'risk',
						value: '',
					},
					{
						id: 'ui2bd',
						title: 'Begrunnelse',
						type: 'text',
						value: '',
					},
					{
						id: 'b7bx2',
						title: 'Gjeld',
						type: 'risk',
						value: '',
					},
					{
						id: '3sbx2',
						title: 'Begrunnelse',
						type: 'text',
						value: '',
					},
					{
						id: '6yu7b',
						title: 'Prosjektregnskap',
						type: 'risk',
						value: '',
					},
					{
						id: 'kl9bx',
						title: 'Begrunnelse',
						type: 'text',
						value: '',
					},
					{
						id: 'ce11x',
						title: 'Bilagsbehandling',
						type: 'risk',
						value: '',
					},
					{
						id: '23x0k',
						title: 'Begrunnelse',
						type: 'text',
						value: '',
					},
					{
						id: 'lk7bx',
						title: 'MVA koder',
						type: 'risk',
						value: '',
					},
					{
						id: 'ci92a',
						title: 'Begrunnelse',
						type: 'text',
						value: '',
					},
				],
			},
			{
				title: 'Konklusjon risikovurdering (totalvurdering)',
				questions: [
					{
						id: 'nesh7',
						title: '',
						value: '<p><i>Legg inn frekvens og omfang av kontroll av medarbeiders arbeid i feltene. Konklusjonen på dette påvirker hva som anses å være tilstrekkelig kvalitetssikring av rapporteringsgrunnlaget i seksjonen under.</i></p>',
						type: 'html',
					},

					{
						id: 'ch7bx',
						title: 'Frekvens',
						type: 'choice',
						options: [
							'Månedlig',
							'2-månedlig',
							'Kvartalsvis',
							'Tertialvis',
							'Halvårlig',
							'Årlig',
						],
						value: '',
					},
					{
						id: 're2bx',
						title: 'Omfang',
						type: 'choice',
						options: ['Lite', 'Middels', 'Stort'],
						value: '',
					},
					{
						id: 'vsd7x',
						title: 'Totalvurdering',
						type: 'choice',
						options: ['Høy', 'Middels', 'Lav'],
						value: '',
					},
					{
						id: '29dxa',
						title: 'Begrunnelse',
						helpText:
							'<p>Vurderingene er ikke uttømmende.</p><p><ol><li>Ny medarbeider på oppdraget indikerer behov for hyppigere kontroller.</li><li>Erfaren medarbeider indikerer færre kontroller igjennom året.</li><li>Egen kjennskap til oppdragsgiver og bransjen må vurderes. Nytt oppdrag for oppdragsansvarlig, og lite kjennskap til bransjen indikerer hyppigere kontroller.</li><li>Risikoelementer i oppdrag avgjør behov for hvor hyppig kontroller bør tas.</li><li>Helt nytt oppdrag bør kontrolleres hyppigere i oppstarten.</li><li>Ved endringer hos oppdragsgiver (ny regnskapsmedarbeider/daglig leder), og større systemendringer indikerer behov for hyppigere kontroller.</li></ol></p>',
						type: 'textarea',
						value: '',
					},
				],
			},
			{
				title: 'Vurdering av tilstrekkelig kvalitetssikring av rapporteringsgrunnlag iht. risikovurdering',
				questions: [
					{
						id: 'k2h7x',
						title: 'Vurdering',
						helpText:
							'<h4><i>GRFS 9.3 Kvalitetssikring av rapporteringsgrunnlag</i></h4><p><i>Regnskapsfører skal sørge for tilstrekkelig kvalitetssikring av grunnlaget for pliktig regnskapsrapportering og periodisk regnskapsrapportering til oppdragsgiver. Kvalitetssikringen skal underbygge at rapporteringsgrunnlaget i det vesentligste er utarbeidet i samsvar med lovkrav. Regnskapsfører skal ha en risikobasert tilnærming til kvalitetssikringen. Avvik skal følges opp. Kvalitetssikringen kan skje løpende eller periodisk. Kvalitetssikringen kan utføres manuelt eller automatisk. Kvalitetssikringen kan omfatte både kontroller og tiltak som forhindrer feil eller mangler i regnskapet og avdekkende og korrigerende kontroller slik at feil og mangler rettes opp i ettertid. Risikobasert tilnærming kan for eksempel innebære at store, uvanlige og/eller kompliserte regnskapsposter kvalitetssikres grundigere enn små, vanlige og enkle regnskapsposter. Videre kan det innebære at poster som er underlagt spesielle regnskaps-, skatte- eller avgiftsregler kvalitetssikres grundigere enn andre regnskapsposter.</i></p><p><i>Frekvens på avstemminger og dokumentasjon av balanseposter dokumenteres i vurderingen. Dersom avstemminger og dokumentasjon av vesentlige balanseposter avviker fra avtalt frekvens for periodisk rapportering og/eller pliktig regnskapsrapportering skal dette dokumenteres i vurderingen.</i></p><p>Kilde: <a href="https://www.regnskapnorge.no/contentassets/7d4de2f522c7435d9dba5a6117f62def/grfs---fom.-2023---masterfil.pdf" target="_blank">God Regnskapsføringsskikk (GRFS)</a></p>',
						type: 'textarea',
						value: '',
					},
				],
			},
		],
	},
};

//
// Note: You can use the following terminal program to generate a random id with 5 alphanumeric characters:
//
// $ brew install pwgen
// $ pwgen 5 --no-capitalize
//

// Uncomment this function to check if some of the question IDs are duplicated. Throws an error if a collision is found.

// (function checkForDuplicateIds(data) {
// 	const duplicateId = Object.values(data)
// 		.flatMap(risk =>
// 			risk.categories.flatMap(cat => cat.questions.map(q => q.id))
// 		)
// 		.find((id, idx, arr) => arr.indexOf(id) !== idx);

// 	duplicateId
// 		? console.error(`Collision detected with question ID: ${duplicateId}`)
// 		: console.info('No collisions found');
// })(defaultAssignmentRisks);
