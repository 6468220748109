import theme from 'components/theme';

import {
	WidgetList,
	WidgetListLabel,
} from 'web/components/widgets/components/WidgetList';
import WidgetListData from 'web/components/widgets/components/WidgetListData';

import Widget from 'web/components/widgets/Widget';

import {
	ACTIVE_COMPANIES,
	COMPANIES_MISSING_ASSIGNMENT,
	COMPANIES_MISSING_FIELD,
} from 'api/queries/widgetQueries';

export default function CompaniesWidget() {
	return (
		<Widget title="Bedrifter" titleIcon="building">
			<WidgetList>
				<WidgetListData
					label={
						<WidgetListLabel
							label="Aktive bedrifter"
							chipColor={theme.palette.status.success}
						/>
					}
					query={ACTIVE_COMPANIES}
					link={id => `/bedrifter/${id}/rediger/`}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler planlegging"
							chipColor={theme.palette.status.error}
						/>
					}
					query={COMPANIES_MISSING_ASSIGNMENT}
					link={id => `/bedrifter/${id}/rediger/?tab=planlegging`}
				/>
			</WidgetList>

			<WidgetList>
				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler org.nr."
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_MISSING_FIELD}
					variables={{
						field: 'orgNo',
						accountTypes: ['accounting'],
					}}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty={true}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler kundenr."
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_MISSING_FIELD}
					variables={{
						field: 'clientNo',
						accountTypes: ['accounting', 'counseling'],
					}}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty={true}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler avdeling"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_MISSING_FIELD}
					variables={{
						field: 'departmentId',
						accountTypes: ['accounting', 'counseling'],
					}}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty={true}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler kategori"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_MISSING_FIELD}
					variables={{
						field: 'categoryIds',
						accountTypes: ['accounting'],
					}}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty={true}
				/>
			</WidgetList>

			<WidgetList>
				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler regnskapsfører"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_MISSING_FIELD}
					variables={{
						field: 'accountantIds',
						accountTypes: ['accounting'],
					}}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty={true}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler kundeansvarlig"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_MISSING_FIELD}
					variables={{
						field: 'accountManagerIds',
						accountTypes: ['accounting'],
					}}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty={true}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler oppdragskontrollør"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_MISSING_FIELD}
					variables={{
						field: 'controllerIds',
						accountTypes: ['accounting'],
					}}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty={true}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler rådgiver"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_MISSING_FIELD}
					variables={{
						field: 'advisorIds',
						accountTypes: ['counseling'],
					}}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty={true}
				/>
			</WidgetList>
		</Widget>
	);
}
