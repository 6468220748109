import { useQuery } from '@apollo/client';

import ErrorAlert from 'components/ErrorAlert';

import Widget from 'web/components/widgets/Widget';
import SignatureRequestsList from 'web/components/widgets/components/lists/SignatureRequestsList';
import MyContractsList from 'web/components/widgets/components/lists/MyContractsList';

import {
	MY_DOCUMENTS_SIGNATURE_REQUESTS,
	MY_UNSIGNED_CONTRACTS,
} from 'api/queries/widgetQueries';

export default function MyUnsignedDocumentsWidget() {
	const {
		data: { signatureRequests } = { signatureRequests: [] },
		error: signatureRequestsError,
		loading: signatureRequestsLoading,
	} = useQuery(MY_DOCUMENTS_SIGNATURE_REQUESTS);

	const {
		data: { contracts } = { contracts: [] },
		error: contractsError,
		loading: contractsLoading,
	} = useQuery(MY_UNSIGNED_CONTRACTS);

	return (
		<Widget
			title="Dokumenter som avventer min signering"
			titleIcon="signature"
			loading={contractsLoading || signatureRequestsLoading}
		>
			<ErrorAlert error={signatureRequestsError} />
			<SignatureRequestsList signatureRequests={signatureRequests} />

			<ErrorAlert error={contractsError} />
			<MyContractsList contracts={contracts} />
		</Widget>
	);
}
