import { useQuery } from '@apollo/client';

import theme from 'components/theme';

import { useStatisticsDialog } from 'web/contexts/StatisticsDialogContext';

import {
	WidgetList,
	WidgetListItem,
	WidgetListLabel,
} from 'web/components/widgets/components/WidgetList';

import ErrorAlert from 'components/ErrorAlert';

import Widget from 'web/components/widgets/Widget';

import { GET_USERS_INFO } from 'api/queries/widgetQueries';

export default function UsersWidget() {
	const { showDialog } = useStatisticsDialog();

	const {
		data: { activeUsersCount, notInvitedUsers, unauthorizedUsers } = {
			activeUsersCount: 0,
			notInvitedUsers: [],
			unauthorizedUsers: [],
		},
		loading,
		error,
	} = useQuery(GET_USERS_INFO);

	return (
		<Widget title="Brukere" titleIcon="users">
			<ErrorAlert error={error} />

			<WidgetList>
				<WidgetListItem
					label={
						<WidgetListLabel
							label="Aktive brukere"
							chipColor={theme.palette.status.success}
						/>
					}
					value={activeUsersCount}
					loading={loading}
				/>

				<WidgetListItem
					label={
						<WidgetListLabel
							label="Ikke invitert"
							chipColor={theme.palette.status.error}
						/>
					}
					value={notInvitedUsers.length}
					loading={loading}
					onClick={() =>
						showDialog(
							'Brukere som ikke er invitert',
							notInvitedUsers,
							id => `/brukere/${id}/`
						)
					}
				/>

				<WidgetListItem
					label={
						<WidgetListLabel
							label="Ikke verifisert"
							chipColor={theme.palette.status.warning}
						/>
					}
					value={unauthorizedUsers.length}
					loading={loading}
					onClick={() =>
						showDialog(
							'Ikke verifisert',
							unauthorizedUsers,
							id => `/brukere/${id}/`
						)
					}
				/>
			</WidgetList>
		</Widget>
	);
}
