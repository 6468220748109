import styled from 'styled-components';

export const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 32px;
	padding: 0 0 32px;

	@media (min-width: 1200px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const GridItem = styled.div``;
