import Widget from 'web/components/widgets/Widget';
import DocumentItem from 'web/components/widgets/components/DocumentItem';

export default function DocumentsWidget({ title, action = null, documents }) {
	if (!documents || documents.length === 0) {
		return null;
	}

	return (
		<Widget title={title} action={action}>
			{documents.map(document => (
				<DocumentItem
					key={document._id}
					doc={document}
					to={`/dokumenter/${document._id}/`}
				/>
			))}
		</Widget>
	);
}
