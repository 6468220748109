import { useState } from 'react';
import styled from 'styled-components';
import { useDrag } from 'react-dnd';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useMoveDocumentNodeModal } from 'components/contexts/MoveDocumentNodeModalContext';

import Icon from 'components/Icon';

import { TableRow, TableCell } from 'web/components/Table';
import Link from 'web/components/Link';

import {
	DELETE_DOCUMENT_NODE,
	UPDATE_ONE_DOCUMENT_NODE,
} from 'api/mutations/documentMutations';

const DocumentFileRowWrapper = styled.div`
	background-color: ${p => p.theme.palette.background.gray};
	opacity: ${p => (p.$isDragging ? 0.2 : p.$isHidden ? 0.5 : 1)};
	position: relative;
	padding-right: ${p => (p.$showMenu ? '64px' : '0')};
	margin-bottom: 4px;
	transition: all 0.5s;

	&:hover {
		background-color: ${p => p.theme.palette.common.gray};
	}

	&& > * {
		grid-template-columns: repeat(5, minmax(50px, 1fr));
		grid-column: 1 / -1;
		display: grid;
	}
`;

const StyledIcon = styled(Icon)`
	margin-right: 5px;
	width: 20px;
	text-align: center;
	color: ${p => p.theme.palette.status.subdued};

	&.fa-file-check {
		color: ${p => p.theme.palette.status.success};
	}

	&.fa-file-signature {
		color: ${p => p.theme.palette.status.error};
	}
`;

const DocumentRowMenuWrapper = styled.div`
	position: absolute;
	right: 0;
	top: 0;
`;

const StyledTableRow = styled(TableRow)`
	& > * {
		font-weight: ${p => (p.$bold ? '600' : '400')};
	}
`;

const DocumentFileRowButton = styled(Button)`
	&& {
		position: absolute;
		right: 0;
		top: 0;
		height: 48px;
	}

	&:hover {
		.fa-ellipsis-v {
			color: black;
		}
	}
`;

const InfoWrapper = styled.div`
	position: relative;
`;

const InfoItems = styled.div`
	display: none;
	opacity: 0;
	position: absolute;
	top: calc(100% + 20px);
	left: 50%;
	transform: translateX(-50%);
	max-width: 300px;
	background-color: white;
	padding: 10px 15px;
	box-sizing: border-box;
	z-index: 10;
	box-shadow: 3px 0 10px rgba(98, 15, 35, 0.06);
	color: black;
	border-radius: 5px;
`;

const InfoItem = styled.div`
	padding: 5px 0;
`;

const InfoHover = styled.span`
	display: block;
	padding: 0 10px;
	box-sizing: border-box;
	&:hover {
		${InfoItems} {
			display: block;
			opacity: 1;
		}
	}
`;

export default function DocumentFileRow(props) {
	const {
		_id,
		name,
		to,
		tenantId,
		isSigning,
		isSigningComplete,
		signeesSigned,
		signeesWaiting,
		seenByCurrentUser,
		showMenu = false,
		canDelete = false,
		isHidden = false,
	} = props;

	const [anchorEl, setAnchorEl] = useState(null);

	const { move } = useMoveDocumentNodeModal();
	const { verify } = useConfirmDialog();

	const [{ isDragging }, drag] = useDrag(() => ({
		type: 'file',
		item: { _id, name },
		options: {
			dropEffect: 'copy',
		},
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
	}));

	const [deleteDocumentNode] = useMutation(DELETE_DOCUMENT_NODE, {
		refetchQueries: ['paginateDocumentNodes'],
	});

	const [updateDocumentNode] = useMutation(UPDATE_ONE_DOCUMENT_NODE, {
		refetchQueries: ['paginateDocumentNodes'],
	});

	function handleClick(event) {
		setAnchorEl(event.currentTarget);
	}

	function handleClose() {
		setAnchorEl(null);
	}

	function getIcon() {
		if (isSigningComplete) return 'file-check';
		if (isSigning) return 'file-signature';

		return 'file';
	}

	return (
		<DocumentFileRowWrapper
			$isHidden={isHidden}
			ref={drag}
			$isDragging={isDragging}
		>
			<Link to={to}>
				<StyledTableRow $bold={!seenByCurrentUser} interact transparent>
					<TableCell start="1" end="4">
						<StyledIcon icon={getIcon()} />

						<span>{name}</span>
					</TableCell>

					<TableCell center>
						<InfoHover>
							{signeesWaiting?.length ?? 0}

							{(signeesWaiting && signeesWaiting.length && (
								<InfoWrapper>
									<InfoItems>
										{signeesWaiting &&
											signeesWaiting.map((item, i) => (
												<InfoItem key={i}>
													{item}
												</InfoItem>
											))}
									</InfoItems>
								</InfoWrapper>
							)) || <></>}
						</InfoHover>
					</TableCell>

					<TableCell center>
						<InfoHover>
							{signeesSigned?.length ?? 0}

							{(signeesSigned && signeesSigned.length && (
								<InfoWrapper>
									<InfoItems>
										{signeesSigned &&
											signeesSigned.map((item, i) => (
												<InfoItem key={i}>
													{item}
												</InfoItem>
											))}
									</InfoItems>
								</InfoWrapper>
							)) || <></>}
						</InfoHover>
					</TableCell>
				</StyledTableRow>
			</Link>

			{showMenu && (
				<DocumentRowMenuWrapper>
					<DocumentFileRowButton onClick={handleClick}>
						<Icon icon="ellipsis-v" />
					</DocumentFileRowButton>

					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleClose}
						anchorOrigin={{
							horizontal: 'right',
							vertical: 'top',
						}}
					>
						<MenuItem
							onClick={() => {
								move({
									_id,
									tenantId,
									name,
								});

								handleClose();
							}}
						>
							Flytt
						</MenuItem>

						{!isHidden && (
							<MenuItem
								onClick={async () => {
									if (
										await verify({
											title: 'Arkiver?',
											text: `Sikker på at du ønsker å arkivere ${name}?`,
										})
									) {
										await updateDocumentNode({
											variables: {
												_id,
												isHidden: true,
											},
										});
									}
								}}
							>
								Arkiver
							</MenuItem>
						)}

						{canDelete && (
							<MenuItem
								onClick={async () => {
									if (
										await verify({
											title: 'Slette?',
											text: `Sikker på at du ønsker å slette ${name}?`,
										})
									) {
										await deleteDocumentNode({
											variables: {
												_id,
												tenantId,
												isArchived: true,
											},
										});
									}
								}}
							>
								Slett
							</MenuItem>
						)}
					</Menu>
				</DocumentRowMenuWrapper>
			)}
		</DocumentFileRowWrapper>
	);
}
