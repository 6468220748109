import { useMutation } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorAlert from 'components/ErrorAlert';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import { ADD_COMPANY_CONTROL_QUESTION_DEVIATION_COMMENT } from 'api/mutations/companyControlMutations';

export default function DeviationCommentDialog({
	questionId,
	deviationId,
	open,
	onClose,
}) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="xs"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Legg inn kommentar
			</DialogTitle>

			<DeviationCommentForm
				questionId={questionId}
				deviationId={deviationId}
				onClose={onClose}
			/>
		</Dialog>
	);
}

function DeviationCommentForm({ questionId, deviationId, onClose }) {
	const { notify } = useSnackbar();

	const [addComment, { loading, error }] = useMutation(
		ADD_COMPANY_CONTROL_QUESTION_DEVIATION_COMMENT,
		{
			refetchQueries: [
				'getCategoryQuestions',
				'getQuestionsWithCommentsOrDeviations',
				'getCustomQuestions',
			],
		}
	);

	return (
		<Form
			isLoading={loading}
			isDisabled={loading}
			onSubmit={async values => {
				try {
					const { data } = await addComment({
						variables: {
							questionId,
							deviationId,
							message: values.message.trim(),
						},
					});

					if (data.updated) {
						notify('Kommentar ble lagt til!');

						onClose();
					}
				} catch (err) {
					console.error(err);
				}
			}}
		>
			<DialogContent>
				<ErrorAlert error={error} />

				<TextField name="message" label="Kommentar" required />
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Avbryt</Button>

				<SubmitButton variant="contained">Kommenter</SubmitButton>
			</DialogActions>
		</Form>
	);
}
