import { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import { TaskDeadlineEntryStatusPill } from 'components/Pill';

import EditAssignmentEntryDialog from 'web/screens/dialogs/assignmentTaskEntry/EditAssignmentEntryDialog';

import { TaskDeadlineEntryTooltip } from 'web/components/AssignmentTasksCalendarTimeline';

import { GET_TASK_DEADLINE_ENTRY_BY_TYPE } from 'api/queries/companyAssignmentTaskQueries';

const StatusIcon = styled.div`
	font-size: 20px;
	line-height: 1;
	position: absolute;
	top: -7px;
	right: -7px;
`;

export default function TaskDeadlineEntryByType({ companyId, type, year }) {
	const [showEntryDialog, setShowEntryDialog] = useState(false);

	const {
		data: { deadlineEntry } = { deadlineEntry: null },
		loading,
		error,
	} = useQuery(GET_TASK_DEADLINE_ENTRY_BY_TYPE, {
		variables: {
			companyId,
			type,
			year,
		},
	});

	if (error) return <div>Feil ved lasting</div>;

	if (loading || !deadlineEntry) {
		return <div />;
	}

	return (
		<>
			<TaskDeadlineEntryStatusPill
				$statusCode={deadlineEntry.status}
				onClick={() => setShowEntryDialog(true)}
			>
				<TaskDeadlineEntryTooltip
					comments={deadlineEntry?.comments}
					files={deadlineEntry?.files}
				>
					<div>{deadlineEntry?.status ?? 'Ikke startet'}</div>

					{deadlineEntry.postponedStatus && (
						<StatusIcon title="Frist søkt utsatt">
							<AccessTimeFilledIcon fontSize="inherit" />
						</StatusIcon>
					)}
				</TaskDeadlineEntryTooltip>
			</TaskDeadlineEntryStatusPill>

			<EditAssignmentEntryDialog
				deadlineEntry={deadlineEntry}
				open={showEntryDialog}
				onClose={() => setShowEntryDialog(false)}
			/>
		</>
	);
}
