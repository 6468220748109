import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { countries } from 'utils/countries';

import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import AutocompleteField from 'components/form/AutocompleteField';
import SubmitButton from 'components/form/SubmitButton';

import { VERIFY_ONE_USER_ADDRESS } from 'api/mutations/userMutations';

const Row = styled.div`
	display: grid;
	grid-template-columns: 1fr 3fr;
	gap: 20px;
`;

export default function AddressVerifyModal({ _id, verificationAddress }) {
	const [verifyUserAddress, { loading, error }] = useMutation(
		VERIFY_ONE_USER_ADDRESS,
		{
			refetchQueries: ['currentUser'],
		}
	);

	return (
		<>
			<DialogTitle id="user-verification-dialog-title">
				Vennligst bekreft adressen din
			</DialogTitle>

			<ErrorMessage errors={[error]} />

			<Form
				values={{ ...verificationAddress }}
				isLoading={loading}
				onSubmit={async ({
					street_address,
					postal_code,
					locality,
					country,
				}) => {
					try {
						await verifyUserAddress({
							variables: {
								_id: _id,
								address: {
									street_address,
									postal_code,
									locality: locality.toUpperCase(),
									country,
								},
							},
						});
					} catch (err) {
						console.warn(err);
					}
				}}
			>
				<DialogContent>
					<TextField name="street_address" label="Adresse" required />

					<Row>
						<TextField
							name="postal_code"
							label="Postnummer"
							required
							fullWidth
						/>

						<TextField
							name="locality"
							label="Sted"
							required
							fullWidth
						/>
					</Row>

					<AutocompleteField
						label="Land"
						name="country"
						options={countries.map(country => ({
							label: country,
							value: country,
						}))}
					/>
				</DialogContent>

				<DialogActions>
					<SubmitButton variant="contained" color="primary">
						Bekreft adresse
					</SubmitButton>
				</DialogActions>
			</Form>
		</>
	);
}
