import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';

import NotFound from 'web/screens/NotFound';
import UsersCreateDialog from 'web/screens/dialogs/users/UsersCreateDialog';

import DesktopLayout from 'web/components/Layout';
import { Content } from 'components/DesktopLayout';

import ErrorMessage from 'components/ErrorMessage';
import Message from 'components/messages/Message';

import DetailsTab from 'web/components/company/DetailsTab';
import ProffTab from 'web/components/company/ProffTab';
import AssignmentsTab from 'web/components/company/AssignmentsTab';
import RiskFormsTab from 'web/components/company/RiskFormsTab';
import AssignmentTasksTab from 'web/components/company/AssignmentTasksTab';
import FormsTab from 'web/components/company/FormsTab';
import DocumentsTab from 'web/components/company/DocumentsTab';

import { GET_ONE_COMPANY } from 'api/queries/tenantQueries';

export default function CompaniesEdit() {
	const { companyId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const [tabValue, setTabValue] = useState(
		new URLSearchParams(location.search).get('tab') ?? 'detaljer'
	);
	const [createUserModal, setCreateUserModal] = useState(null);

	const {
		data: { company } = { company: null },
		loading: companyLoading,
		error: companyError,
		refetch,
	} = useQuery(GET_ONE_COMPANY, {
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		variables: {
			_id: companyId,
		},
	});

	if (!companyLoading && !company) {
		return (
			<NotFound
				label="Bedrift ikke funnet"
				title="Denne bedriften ble ikke funnet!"
			/>
		);
	}

	return (
		<DesktopLayout
			loading={companyLoading}
			breadcrumbs={[
				{
					to: '/bedrifter/',
					label: 'Bedrifter',
				},
				{
					to: `/bedrifter/${company?._id}/`,
					label: company?.name || '...',
				},
				{
					label: 'Oppdater bedrift',
				},
			]}
		>
			{company && (
				<Content>
					<ErrorMessage errors={[companyError]} />

					{company?.isInactive && (
						<Message title="Obs!" type="warning">
							Denne bedriften er inaktiv. Den vil ikke være synlig
							for vanlige brukere.
						</Message>
					)}

					<TabContext value={tabValue} textColor="inherit">
						<Box
							sx={{
								borderBottom: 1,
								borderColor: 'divider',
							}}
						>
							<TabList
								onChange={(_event, newValue) => {
									setTabValue(newValue);

									navigate(
										`/bedrifter/${companyId}/rediger/?tab=${newValue}`
									);
								}}
								aria-label="Oppdater bedrift"
							>
								<Tab
									label="Bedriftsdetaljer"
									value="detaljer"
								/>

								<Tab label="Proff.no" value="proff" />

								<Tab label="Oppdrag" value="oppdrag" />

								<Tab label="Hvitvasking" value="hvitvasking" />

								<Tab label="Planlegging" value="planlegging" />

								<Tab label="Skjema" value="skjema" />

								<Tab label="Dokumenter" value="dokumenter" />
							</TabList>
						</Box>

						<TabPanel value="detaljer">
							<DetailsTab
								company={company}
								companyLoading={companyLoading}
								setCreateUserModal={setCreateUserModal}
							/>
						</TabPanel>

						<TabPanel value="proff">
							<ProffTab
								companyId={companyId}
								company={company}
								companyLoading={companyLoading}
								setCreateUserModal={setCreateUserModal}
								refetch={refetch}
							/>
						</TabPanel>

						<TabPanel value="oppdrag">
							<AssignmentsTab company={company} />
						</TabPanel>

						<TabPanel value="hvitvasking">
							<RiskFormsTab company={company} />
						</TabPanel>

						<TabPanel value="planlegging">
							<AssignmentTasksTab company={company} />
						</TabPanel>

						<TabPanel value="skjema">
							<FormsTab company={company} />
						</TabPanel>

						<TabPanel value="dokumenter">
							<DocumentsTab companyId={companyId} />
						</TabPanel>
					</TabContext>
				</Content>
			)}

			<UsersCreateDialog
				companyId={companyId}
				user={createUserModal}
				open={!!createUserModal}
				onClose={() => setCreateUserModal(false)}
				onSubmitSuccess={async user => {
					if (user) {
						await refetch();
					}

					setCreateUserModal(null);
				}}
			/>
		</DesktopLayout>
	);
}
