import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import { useForm } from 'components/form/Form';

const Wrapper = styled.div`
	margin-bottom: 16px;
	position: relative;
`;

const StyledOption = styled.li`
	span {
		font-style: ${p => p.$subdued && 'italic'};
		color: ${p => p.$subdued && p.theme.palette.status.subdued};
	}
	&:hover span {
		color: ${p => p.$subdued && p.theme.palette.common.black};
	}
`;

export default function AutocompleteField(props) {
	let {
		name,
		placeholder = '',
		options,
		value,
		label,
		update = null,
		multiple = false,
		disabled = false,
		clearable = true,
		helperText = null,
		noOptionsText = 'Ingen alternativer',
		required = false,
		...rest
	} = props;

	const { registerField, updateField, isDisabled, values } = useForm();

	const [registered, setRegistered] = useState(false);
	const [option, setOption] = useState(
		value
			? multiple
				? options.filter(o => o.value === value)
				: options.find(o => o.value === value)
			: multiple
				? []
				: null
	);

	useEffect(() => {
		if (update || !options) return;

		registerField(props);

		setRegistered(true);
	});

	useEffect(() => {
		if (!options) return;

		if (update) {
			let current = multiple
				? options.filter(o => value.includes(o.value)) || []
				: options.find(o => o.value === value) || null;

			setOption(current);
		} else {
			if (!registered || !values) return;

			let current = multiple ? [] : null;
			if (multiple && values[name]) {
				current = options.filter(o => values[name].includes(o.value));
			} else if (!multiple && values[name]) {
				current = options.find(o => o.value === values[name]) || null;
			}

			setOption(current);
		}
	}, [registered, values, options]);

	return (
		<Wrapper>
			<Autocomplete
				multiple={multiple}
				disabled={isDisabled || disabled}
				value={option}
				options={options}
				isOptionEqualToValue={(option, value) => {
					return option.value === value.value;
				}}
				renderOption={(props, option) => {
					return (
						<StyledOption
							{...props}
							key={`${name}-${option.value}`}
							$subdued={option.subdued}
							aria-disabled={option.disabled}
						>
							{option.tooltip ? (
								<OptionTooltip
									title={option.label}
									option={option}
								>
									<span>{option.label}</span>
								</OptionTooltip>
							) : (
								<span>{option.label}</span>
							)}
						</StyledOption>
					);
				}}
				onChange={(_event, data) => {
					setOption(data || '');

					let value = multiple ? [] : '';

					if (data !== null) {
						value = multiple
							? data.map(value => value.value)
							: data.value;
					}

					if (update) {
						update({
							name,
							value,
						});
						return;
					}

					updateField({
						name,
						value,
					});
				}}
				placeholder={placeholder || label || null}
				renderInput={params => (
					<TextField
						{...params}
						label={label}
						helperText={helperText}
						inputProps={{
							...params.inputProps,
							required: required && option?.length === 0,
						}}
						required={required}
					/>
				)}
				disableClearable={!clearable}
				noOptionsText={noOptionsText}
				{...rest}
			/>
		</Wrapper>
	);
}

const StyledTooltipLabel = styled.span`
	display: block;
	width: 100%;
`;
const StyledTooltipContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

function OptionTooltip({ children, option }) {
	return (
		<Tooltip
			title={
				<StyledTooltipContent>
					{option.tooltip?.map((t, i) => (
						<div key={i}>{t}</div>
					))}
				</StyledTooltipContent>
			}
		>
			<StyledTooltipLabel>{children}</StyledTooltipLabel>
		</Tooltip>
	);
}
