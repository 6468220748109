import { useState } from 'react';

export default function useSortOrder(defaultOrderBy, defaultOrder = 1) {
	const [sort, setSort] = useState({
		orderBy: defaultOrderBy,
		order: defaultOrder,
	});

	function setNewOrder(orderBy) {
		if (sort.orderBy === orderBy) {
			setSort(prev => ({ ...prev, order: prev.order * -1 }));
			return;
		}

		setSort({ orderBy, order: defaultOrder });
	}

	return [sort, setNewOrder];
}
