import { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';
import RestoreIcon from '@mui/icons-material/Restore';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import CalendarIcon from 'web/components/widgets/components/CalendarIcon';

import EditTodoDialog from 'web/components/widgets/dialogs/EditTodoDialog';

import { UPDATE_ONE_TODO } from 'api/mutations/todoMutations';

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 10px;
`;

const Content = styled.div`
	flex: 1 1 0%;
`;

const TitleButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	color: ${p => p.theme.palette.primary.main};
	font-size: 1rem;
	margin-bottom: 5px;
	padding: 0;
	text-decoration: underline;
	text-align: left;
`;

const Description = styled.div`
	color: ${p => p.theme.palette.common.darkGray};
	font-size: 14px;
`;

export default function Todo({ todo }) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Wrapper>
			<CheckMarkCalendarIconButton todo={todo} />

			<Content>
				<TitleButton onClick={() => setIsOpen(true)}>
					{todo.title}
				</TitleButton>

				{todo.company && <Description>{todo.company.name}</Description>}
			</Content>

			<EditTodoDialog
				todo={todo}
				isOpen={isOpen}
				close={() => setIsOpen(false)}
			/>
		</Wrapper>
	);
}

const TransparentButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	position: relative;

	&:hover {
		.button-overlay-action {
			opacity: 0.9;
		}
	}
`;

const ButtonOverlay = styled.div`
	background: ${p => p.theme.palette.background.default};
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: opacity 0.2s ease-in-out;
`;

const Error = styled.div`
	color: ${p => p.theme.palette.status.error};
	flex: 1;
	flex-basis: 0;
`;

function CheckMarkCalendarIconButton({ todo }) {
	const { notify } = useSnackbar();

	const [updateTodo, { loading, error }] = useMutation(UPDATE_ONE_TODO, {
		refetchQueries: ['getTodos'],
	});

	if (error) {
		return <Error>Det oppstod en feil ved oppdatering av oppgaven!</Error>;
	}

	return (
		<TransparentButton
			disabled={loading}
			title={todo.completed ? 'Gjenopprett oppgave' : 'Fullfør oppgave'}
			onClick={async () => {
				try {
					const { data } = await updateTodo({
						variables: {
							_id: todo._id,
							completed: !todo.completed,
						},
					});

					if (data?.todo?._id) {
						notify(
							todo.completed
								? 'Oppgave gjenopprettet!'
								: 'Oppgave fullført!'
						);
					}
				} catch (err) {
					console.error(err);
				}
			}}
		>
			<CalendarIcon date={todo.date} />

			<ButtonOverlay className="button-overlay-action">
				{todo.completed ? <RestoreIcon /> : <CheckIcon />}
			</ButtonOverlay>
		</TransparentButton>
	);
}
