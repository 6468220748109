import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';

import { countries } from 'utils/countries';

import ErrorMessage from 'components/ErrorMessage';

import Layout from 'app/components/Layout';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';

import { VERIFY_ONE_USER_ADDRESS } from 'api/mutations/userMutations';
import AutocompleteField from 'components/form/AutocompleteField';

const Title = styled.h3`
	margin: 0 0 20px 0;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;

	& > *:not(:last-child) {
		margin-right: 50px;
		flex: 1 1 auto;
	}

	& > *:last-child {
		width: 75%;
		flex: 1 1 75%;
	}
`;

const ButtonWrapper = styled.div`
	margin-top: 20px;
	text-align: right;
`;

export default function UserVerifyAddress({ _id, verificationAddress }) {
	const [verifyUserAddress, { error }] = useMutation(
		VERIFY_ONE_USER_ADDRESS,
		{
			refetchQueries: ['currentUser'],
			errorPolicy: 'all',
		}
	);

	return (
		<Layout pageTitle="Verifiser adresse">
			<Title>Vennligst bekreft adressen din</Title>

			<ErrorMessage errors={error} />

			<Form
				values={{ ...verificationAddress }}
				onSubmit={async ({
					street_address,
					postal_code,
					locality,
					country,
				}) => {
					try {
						await verifyUserAddress({
							variables: {
								_id: _id,
								address: {
									street_address,
									postal_code,
									locality: locality.toUpperCase(),
									country,
								},
							},
						});
					} catch (err) {
						console.warn(err);
					}
				}}
			>
				<div>
					<TextField name="street_address" label="Adresse" required />

					<Row>
						<TextField
							name="postal_code"
							label="Postnummer"
							required
							fullWidth
						/>

						<TextField
							name="locality"
							label="Sted"
							required
							fullWidth
						/>
					</Row>
					<AutocompleteField
						label="Land"
						name="country"
						options={countries.map(country => ({
							label: country,
							value: country,
						}))}
						clearable={false}
					/>

					<ButtonWrapper>
						<Button
							type="submit"
							variant="contained"
							color="primary"
						>
							Bekreft adresse
						</Button>
					</ButtonWrapper>
				</div>
			</Form>
		</Layout>
	);
}
