import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import MUITextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ErrorMessage from 'components/ErrorMessage';

import { useForm } from 'components/form/Form';

import { GET_TENANT_USERS } from 'api/queries/tenantQueries';

function populateFieldsWithExistingData(fields) {
	const values = {};

	fields.forEach(field => {
		values[field.name] = field.value || '';
	});

	return values;
}

export default function CompanyRoutineFields({ fields, companyId }) {
	const [values, setValues] = useState(() =>
		populateFieldsWithExistingData(fields)
	);

	const { registerField, updateField } = useForm();

	if (fields.length === 0) {
		return null;
	}

	useEffect(() => {
		registerField({
			name: 'values',
			value: values,
			required: true,
		});
	}, []);

	useEffect(() => {
		updateField({ name: 'values', value: values });
	}, [values]);

	async function handleFieldChange(name, value) {
		setValues(prev => ({
			...prev,
			[name]: value,
		}));
	}

	return (
		<div>
			{fields.map(field => (
				<div key={field.name}>
					{renderRoutineField(
						field,
						companyId,
						values[field.name],
						handleFieldChange
					)}
				</div>
			))}
		</div>
	);
}

function renderRoutineField(field, companyId, value, handleFieldChange) {
	switch (field.type) {
		case 'text':
			return (
				<RoutineTextField
					field={field}
					value={value}
					handleFieldChange={handleFieldChange}
				/>
			);
		case 'user':
			return (
				<RoutineUserField
					field={field}
					companyId={companyId}
					value={value}
					handleFieldChange={handleFieldChange}
				/>
			);
		default:
			return 'Dette feltet er ikke støttet!';
	}
}

const TextFieldWrapper = styled.div`
	margin-top: 20px;

	> div {
		width: 100%;
	}
`;

const UserFieldWrapper = styled.div`
	margin-top: 30px;
`;

const FieldDescription = styled.div`
	color: ${p => p.theme.palette.text.secondary};
	font-size: 0.9rem;
	font-style: italic;
	margin: 5px 0 0 0;
`;

function RoutineTextField({ field, value, handleFieldChange }) {
	return (
		<TextFieldWrapper>
			<MUITextField
				name={field.name}
				type="text"
				label={field.label}
				value={value}
				maxRows={5}
				multiline={true}
				onChange={e => handleFieldChange(field.name, e.target.value)}
			/>

			{field.description && (
				<FieldDescription>{field.description}</FieldDescription>
			)}
		</TextFieldWrapper>
	);
}

function RoutineUserField({ field, companyId, value, handleFieldChange }) {
	const { data, loading, error } = useQuery(GET_TENANT_USERS, {
		variables: { tenantId: companyId },
	});

	if (loading || !data.users) {
		return null;
	}

	const selectedUser = data.users.find(user => user._id === value);

	return (
		<UserFieldWrapper>
			<ErrorMessage errors={error} />

			<FormControl fullWidth>
				<InputLabel id="selected-user-select-label">
					{field.label}
				</InputLabel>
				<Select
					labelId="selected-user-select-label"
					label={field.label}
					name={field.name}
					value={selectedUser ? selectedUser._id : ''}
					onChange={data => {
						handleFieldChange(field.name, data.target.value);
					}}
					fullWidth
				>
					{!data?.users?.length && (
						<MenuItem value="">
							<em>Ingen valg</em>
						</MenuItem>
					)}
					{data?.users?.length &&
						data.users.map(user => (
							<MenuItem key={user._id} value={user._id}>
								{user.name}
							</MenuItem>
						))}
				</Select>
				{field.description && (
					<FieldDescription>{field.description}</FieldDescription>
				)}
			</FormControl>
		</UserFieldWrapper>
	);
}
