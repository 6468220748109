import Message from 'components/messages/Message';

/**
 * The error can be a string, a single object or an array of error objects/strings.
 */
export default function ErrorMessage({
	title = 'Oisann, noe gikk galt!',
	errors = null,
}) {
	let displayErrors = null;

	if (!errors) return null;

	// Remove null, undefined and false values from the error array.
	if (Array.isArray(errors)) {
		displayErrors = errors.filter(error => error);

		if (displayErrors.length == 0) return null;
	}
	// Convert a single object to an array (Also handles strings).
	else if (typeof errors === 'object' || typeof errors === 'string') {
		displayErrors = [errors];
	} else {
		return null;
	}

	return (
		<Message title={title} type="error">
			{displayErrors.length === 1 ? (
				<DisplayError error={displayErrors[0]} />
			) : (
				<ul>
					{displayErrors.map((error, i) => {
						return (
							<li key={i}>
								<DisplayError error={error} />
							</li>
						);
					})}
				</ul>
			)}
		</Message>
	);
}

export function DisplayError({ error }) {
	switch (true) {
		case typeof error === 'string':
			return <StringError error={error} />;
		case error.constructor.name === 'ApolloError':
			return <GraphQLError error={error} />;
		case error.message !== undefined:
			return <GeneralError error={error} />;
		default:
			return <DefaultError />;
	}
}

function GraphQLError({ error }) {
	if (error.graphQLErrors.length === 0) return <GeneralError error={error} />;

	return error.graphQLErrors.map(({ message }) => (
		<div key={message}>{message}</div>
	));
}

function StringError({ error }) {
	return <div>{error}</div>;
}

function GeneralError({ error }) {
	return <div>{error.message}</div>;
}

function DefaultError() {
	return <div>En uventet feil oppstod!</div>;
}
