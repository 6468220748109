import { Fragment, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';

import { companyRoles, getRoleLabel } from 'server/libs/companyRoles';
import Icon from 'components/Icon';

import { useForm } from 'components/form/Form';
import AutocompleteField from 'components/form/AutocompleteField';

import Panel from 'web/components/panels/Panel';

const StyledList = styled(List)`
	background: white;
	padding: 0;
	margin-bottom: 40px;
`;

const StyledListItemText = styled(ListItemText)`
	${p => p.onClick && `cursor:pointer;text`}

	&:hover {
		color: ${p => (p.onClick ? p.theme.palette.primary.main : 'inherit')};
	}

	span {
		text-decoration: ${p => (p.onClick ? 'underline' : 'inherit')};
		&:hover {
			color: ${p => p.theme.palette.primary.main};
		}
	}
`;

const AddButton = styled.button`
	background: transparent;
	border: none;
	position: absolute;
	right: -42px;
	height: 56px;
	display: flex;
	width: 44px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all 0.5s;

	&:hover:not(:disabled) {
		color: ${p => p.theme.palette.primary.main};
	}
`;

const Row = styled.div`
	display: flex;
	position: relative;
	margin: 0;
	width: calc(100% - 40px);
	padding: 0 -2px;
`;

const Column = styled.div`
	flex: 1;
	width: 100%;
	padding: 0 2px;
`;

function getRenderValue(value, options) {
	let labels = [];

	for (let i = 0; i < options.length; i++) {
		if (value.indexOf(options[i].value) > -1) {
			labels.push(options[i].label);
		}
	}
	return labels.join(', ');
}

const usePrev = value => {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

export default function DualFields({
	title,
	leftSideLabel,
	leftSideOptions,
	name,
	handleCreateUser = null,
	...props
}) {
	const { verify } = useConfirmDialog();
	const { registerField, updateField, values } = useForm();
	const prevRoles = usePrev(userRoles);
	const [newItem, setNewItem] = useState({ id: '', role: '' });
	const [userRoles, setUserRoles] = useState([]);

	useEffect(() => {
		registerField({ name, value: props.userRoles });

		if (!values.userRoles) return;

		const ur = values?.userRoles
			?.filter(o => !!leftSideOptions.find(item => item.value === o.id))
			.map(o => ({ ...o }));

		setUserRoles(ur);
	}, [values?.userRoles, leftSideOptions]);

	useEffect(
		() => () => {
			if (prevRoles && userRoles !== prevRoles && props.onChange) {
				props.onChange(userRoles);
			}
		},
		[userRoles]
	);

	function handleAddUserRole() {
		const updatedUserRoles = [...userRoles, newItem];
		updateField({ name, value: updatedUserRoles });
		setUserRoles(updatedUserRoles);
		setNewItem({ id: '', role: '' });
	}

	function handleDeleteUserRole(index) {
		const updatedUserRoles = userRoles.filter((_, i) => i !== index);
		updateField({ name, value: updatedUserRoles });
		setUserRoles(updatedUserRoles);
	}

	return (
		<Panel title={title}>
			<StyledList>
				{userRoles &&
					userRoles.map((ur, index) => {
						const option = leftSideOptions.find(
							item => item.value === ur.id
						);

						return (
							<Fragment key={index}>
								<ListItem
									secondaryAction={
										<Tooltip
											title="Fjern bruker fra bedriften"
											placement="left"
										>
											<IconButton
												edge="end"
												aria-label="Slett"
												onClick={async () => {
													if (
														!(await verify({
															title: 'Fjern bruker fra bedrift',
															text: 'Er du sikker på at du ønsker å fjerne brukeren fra bedriften? Husk å lagre endringene etterpå. (Brukeren blir ikke slettet).',
														}))
													) {
														return;
													}

													handleDeleteUserRole(index);
												}}
											>
												<Icon
													icon="trash"
													size=""
													className="button-icon"
													solid
												/>
											</IconButton>
										</Tooltip>
									}
								>
									<StyledListItemText
										primary={option?.label}
										secondary={getRoleLabel(ur.role)}
										onClick={() =>
											option?.action && option.action()
										}
										role={
											option?.action
												? 'button'
												: undefined
										}
									/>
								</ListItem>
								<Divider component="li" />
							</Fragment>
						);
					})}
			</StyledList>

			<Row>
				<Column>
					<AutocompleteField
						required
						name="id"
						key={newItem.id}
						value={newItem.id}
						label={leftSideLabel || 'Bruker'}
						options={leftSideOptions}
						update={({ value }) => {
							setNewItem({ ...newItem, id: value });
						}}
					/>
				</Column>

				<Column>
					{newItem.id && newItem.role ? (
						<AddButton onClick={handleAddUserRole}>
							<Icon icon="plus" solid className="button-icon" />
						</AddButton>
					) : handleCreateUser ? (
						<AddButton
							onClick={e => {
								e.preventDefault();
								handleCreateUser();
							}}
						>
							<Icon
								icon="user-plus"
								solid
								className="button-icon"
							/>
						</AddButton>
					) : (
						<AddButton disabled>
							<Icon icon="plus" solid className="button-icon" />
						</AddButton>
					)}

					<FormControl fullWidth={true} variant={'outlined'}>
						<InputLabel>Rolle</InputLabel>

						<Select
							value={newItem.role}
							input={<OutlinedInput label="Rolle" name="role" />}
							onChange={e => {
								setNewItem({
									...newItem,
									role: e.target.value,
								});
							}}
							renderValue={selected =>
								getRenderValue(selected, companyRoles)
							}
						>
							{companyRoles.map(({ value: val, label }) => (
								<MenuItem key={val} value={val}>
									<ListItemText primary={label} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Column>
			</Row>
		</Panel>
	);
}
