import TokenVerifyModal from 'web/components/verifications/TokenVerifyModal';

export default function EmailVerifyModal({ _id, verificationEmail }) {
	return (
		<TokenVerifyModal
			type="email"
			userId={_id}
			verificationValue={verificationEmail}
			title="Du må verifisere e-postadressen din"
			firstStepContent="For å kunne motta meldinger fra oss, må du registrere en e-postadresse du har tilgang til. Sjekk at e-postadressen stemmer, så sender vi en verifiseringskode til den oppgitte adressen."
			lastStepContent="Fyll ut verifiseringskoden du har fått tilsendt på e-post."
			changeValueText="Endre e-postadresse"
		/>
	);
}
