import { useEffect } from 'react';
import MUITextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';

import { useForm } from 'components/form/Form';
import Icon from 'components/Icon';

const Wrapper = styled.div`
	margin-bottom: 16px;
	position: relative;

	> div {
		width: 100%;
	}
	.MuiTooltip-popper {
		font-size: 16px;
	}
`;

const TooltipIconWrapper = styled.span`
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 0;
	height: 56px;
	width: 56px;
	text-align: center;
	line-height: 56px;
`;

const Descripiton = styled.div`
	color: ${p => p.theme.palette.text.secondary};
	font-size: 0.9rem;
	font-style: italic;
	margin: 10px 0;
`;

export default function TextField(props) {
	const { name, description, disabled, tooltip, defaultValue, style } = props;

	const { registerField, updateField, isDisabled, values, errors } =
		useForm();

	const error = errors[name];

	useEffect(() => {
		registerField(props);
	});

	return (
		<Wrapper style={style}>
			<MUITextField
				{...props}
				disabled={disabled || isDisabled}
				error={!!errors[name]}
				defaultValue={defaultValue || values[name]}
				InputLabelProps={{ shrink: !!values[name] }}
				onChange={e => {
					updateField({
						name,
						value: e.target.value.trim(),
					});
					props.onChange && props.onChange(e);
				}}
			/>

			{tooltip && (
				<Tooltip title={tooltip} placement="left">
					<TooltipIconWrapper>
						<Icon icon="question-circle" />
					</TooltipIconWrapper>
				</Tooltip>
			)}

			{description && <Descripiton>{description}</Descripiton>}

			{!!error && <span>{error}</span>}
		</Wrapper>
	);
}
