import { useAppFilters } from 'web/contexts/AppFiltersContext';

import UsersFilterSelect from 'components/filters/components/UsersFilterSelect';

export default function AccountantFilter({
	disabled = false,
	size = 'medium',
}) {
	const { accountant, setAccountant } = useAppFilters();

	return (
		<UsersFilterSelect
			title="Vis regnskapsfører"
			roles={['admin', 'accountant']}
			value={accountant}
			onChange={setAccountant}
			disabled={disabled}
			size={size}
		/>
	);
}
