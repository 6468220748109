import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import EmailIcon from '@mui/icons-material/Email';

import { useWebApp } from 'web/contexts/WebContexts';
import { parseJwt } from 'web/contexts/WebContexts';

import LoginLayout from 'web/components/LoginLayout';
import { UnderlineLink } from 'web/components/Link';

export default function Login() {
	const LOGIN_URL = `${window.location.origin}/bankid/login`;
	const {
		set,
		data: { apiToken, invitedUser },
	} = useWebApp();

	const [error, setError] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				const tokenData = parseJwt(apiToken);

				if (tokenData?.exp && Date.now() / 1000 < tokenData?.exp) {
					return;
				}

				if (window.location.search.includes('code=')) {
					const urlParams = new URLSearchParams(
						window.location.search
					);

					const code = urlParams.get('code');

					await handleRedirectCallback(code);
				}
			} catch (error) {
				console.error(error);
				setError(error);
				//set({ apiToken: null, user: null }); // TODO: Causing infinite reloads.
			}
		})();
	}, []);

	async function handleRedirectCallback(code) {
		try {
			const tokenUrl = `${window.location.origin}/bankid/token`;

			const response = await fetch(tokenUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					code,
					invitedUser,
				}),
			});

			const { error, token, user } = await response.json();

			if (error) {
				throw new Error(error);
			}

			const updateObj = { apiToken: token, user };

			if (invitedUser) {
				updateObj.invitedUser = undefined;
			}

			set(updateObj);
		} catch (error) {
			console.error(error);
			setError(error);
		}
	}

	return (
		<LoginLayout
			header={
				<UnderlineLink to="/admin-innlogging">
					Innlogging admin
				</UnderlineLink>
			}
			errors={error}
		>
			<Button
				variant="contained"
				color="primary"
				size="large"
				fullWidth={true}
				href={LOGIN_URL}
				startIcon={<FingerprintIcon />}
			>
				Logg inn med BankId
			</Button>

			{!invitedUser && (
				<Button
					to="/invitasjon"
					component={RouterLink}
					color="primary"
					size="large"
					variant="outlined"
					fullWidth={true}
					startIcon={<EmailIcon />}
				>
					Jeg har invitasjon
				</Button>
			)}

			{invitedUser && (
				<>
					<div>Invitasjon registrert. Vennligst logg inn!</div>

					<Button
						variant="text"
						onClick={() => set({ invitedUser: null })}
					>
						(Nullstill invitasjon)
					</Button>
				</>
			)}
		</LoginLayout>
	);
}
