import { useAppFilters } from 'web/contexts/AppFiltersContext';

import UsersFilterSelect from 'components/filters/components/UsersFilterSelect';

export default function AdminsAndAccountantsFilter({
	disabled = false,
	size = 'medium',
}) {
	const { accountantLegacy, setAccountantLegacy } = useAppFilters();

	return (
		<UsersFilterSelect
			title="Vis regnskapsfører"
			roles={['admin', 'accountant']}
			value={accountantLegacy}
			onChange={setAccountantLegacy}
			disabled={disabled}
			size={size}
		/>
	);
}
