import styled from 'styled-components';
import Icon from 'components/Icon';

const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(41, 20, 25, 0.15);
	z-index: 1290;
`;
const Backdrop = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
`;
const Content = styled.div`
	background: ${p => p.theme.palette.background.default};
	max-height: ${props => props.$maxHeight};
	max-width: ${props => props.$maxWidth};
	min-width: 400px;
	height: ${props => props.$height};
	width: ${props => props.$width};
	pointer-events: auto;
	padding: 24px;
	position: relative;
`;
const ContentWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	pointer-events: none;
`;
const Title = styled.h2`
	margin: 0 0 24px;
	text-align: center;
`;
const SmallHeaderWrapper = styled.div`
	position: relative;
`;
const SmallTitle = styled.h2`
	color: #7b7b7b;
	font-size: 15px;
	font-weight: 600;
	margin: 5px 0 28px;
`;
const SmallCloseButton = styled.button`
	background: none;
	border: none;
	position: absolute;
	right: 0;
	top: 0;
	color: #401d69;
`;

export function SmallHeader({ title, onClose }) {
	return (
		<SmallHeaderWrapper>
			<SmallTitle>{title}</SmallTitle>
			{onClose && (
				<SmallCloseButton onClick={onClose}>
					<Icon icon="times" />
				</SmallCloseButton>
			)}
		</SmallHeaderWrapper>
	);
}

export default function ModalLayout(props) {
	const {
		onClose,
		children,
		width = 'auto',
		height = 'auto',
		maxWidth = 'calc(100% - 30px)',
		maxHeight = 'calc(100% - 30px)',
		title,
		style,
		className,
		...rest
	} = props;

	return (
		<ModalWrapper {...rest}>
			<Backdrop onClick={() => onClose()} />
			<ContentWrapper>
				<Content
					$width={width}
					$height={height}
					$maxWidth={maxWidth}
					$maxHeight={maxHeight}
					style={style}
					className={className}
				>
					{title && <Title>{title}</Title>}

					{children}
				</Content>
			</ContentWrapper>
		</ModalWrapper>
	);
}
