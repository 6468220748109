import styled from 'styled-components';

import DesktopLayout, { Content } from 'web/components/Layout';
import WelcomeWidget from 'web/components/widgets/WelcomeWidget';

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
`;

export default function AuditorHome() {
	return (
		<DesktopLayout
			title="Hjem"
			breadcrumbs={[
				{
					to: '/',
					label: 'Hjem',
				},
			]}
		>
			<Content>
				<Grid>
					<WelcomeWidget />
				</Grid>
			</Content>
		</DesktopLayout>
	);
}
