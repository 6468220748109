import { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';

import { useUser } from 'web/contexts/UserContext';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';

import Table, { TableRow, TableCell } from 'web/components/Table';
import DocumentFolderRow from 'web/components/DocumentFolderRow';
import DocumentFileRow from 'web/components/DocumentFileRow';

import { GET_DOCUMENT_SEARCH_RESULT } from 'api/queries/documentQueries';

const Wrapper = styled.div`
	margin-top: 10px;
`;

const SearchFeedback = styled.div`
	color: inherit;
	width: 100%;
	display: flex;
`;

const FilterWrapper = styled.div`
	display: flex;
`;

const FilterHeadline = styled.div`
	font-size: 0.9rem;
	line-height: 1;
	margin-right: 5px;
	padding: 8px 0;
`;

const FILTERS = [
	{
		label: 'Signerte dokumenter',
		filter: { var: 'isSigningComplete', value: true, type: 'file' },
	},
	{
		label: 'Usignerte dokumenter',
		filter: { var: 'isSigningComplete', value: false, type: 'file' },
	},
];

export default function DocumentSearch({ search, tenantId }) {
	const { user, userHasMinRole } = useUser();
	const [filter, setFilter] = useState();

	const {
		data: { searchResult = { items: [], count: 0 } } = {},
		loading,
		error,
	} = useQuery(GET_DOCUMENT_SEARCH_RESULT, {
		variables: { tenantId, search },
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
	});

	const handleSetFilter = filterPicked => {
		if (filter === filterPicked) {
			setFilter();
			return;
		}

		setFilter(filterPicked);
	};

	const getFilteredItems = itemType => {
		let filteredResult = searchResult.items.filter(
			f => f.type === itemType
		);
		if (filter) {
			if (filter.filter.type !== itemType) {
				return [];
			}

			let result = [];
			const variable = filter.filter.var;
			filteredResult.map(r => {
				if (r[variable] && r[variable] === filter.filter.value) {
					result.push(r);
				} else if (!r[variable] && filter.filter.value === false) {
					result.push(r);
				}
			});

			filteredResult = result;
		}

		return filteredResult;
	};

	return (
		<Wrapper>
			<ErrorMessage errors={[error]} />

			<FilterWrapper>
				{!filter && (
					<>
						<FilterHeadline>Filter:</FilterHeadline>

						{FILTERS.map(filt => (
							<Button
								key={filt.label}
								size="small"
								onClick={() => handleSetFilter(filt)}
							>
								{filt.label}
							</Button>
						))}
					</>
				)}

				{filter && (
					<Button
						size="small"
						startIcon={<ClearIcon />}
						onClick={() => handleSetFilter(filter)}
					>
						{filter.label}
					</Button>
				)}
			</FilterWrapper>

			<h2>Mapper</h2>

			<Table columns="5" style={{ marginBottom: '30px' }}>
				{!loading &&
					searchResult &&
					searchResult.items &&
					getFilteredItems('folder').length === 0 && (
						<SearchFeedback>
							{`Fant ingen mapper som matchet "${search}".`}
						</SearchFeedback>
					)}

				{loading && (
					<TableRow transparent>
						<TableCell start="1" end="-1">
							<InlineLoading />
						</TableCell>
					</TableRow>
				)}

				{searchResult &&
					searchResult.items &&
					getFilteredItems('folder').length > 0 && (
						<TableRow header>
							<TableCell start="1" end="3">
								Mappe
							</TableCell>

							<TableCell>Mangler signering</TableCell>

							<TableCell>Ferdig signert</TableCell>

							<TableCell>Filer</TableCell>
						</TableRow>
					)}

				{getFilteredItems('folder').map((folder, i) => (
					<DocumentFolderRow
						key={i}
						_id={folder._id}
						name={folder.name}
						linkBase={`/bedrifter/${tenantId}/`}
						tenantId={tenantId}
						signedDocuments={folder.signedDocuments}
						unsignedDocuments={folder.unsignedDocuments}
						filesCount={folder.filesCount || 0}
						seenByCurrentUser={folder.seenByCurrentUser}
					/>
				))}
			</Table>

			<h2>Dokumenter</h2>

			<Table columns="5" style={{ marginBottom: '30px' }}>
				{!loading &&
					searchResult &&
					searchResult.items &&
					getFilteredItems('file').length === 0 && (
						<SearchFeedback>
							{`Fant ingen filer som matchet "${search}".`}
						</SearchFeedback>
					)}

				{loading && (
					<TableRow transparent>
						<TableCell start="1" end="-1">
							<InlineLoading />
						</TableCell>
					</TableRow>
				)}

				{searchResult &&
					searchResult.items &&
					getFilteredItems('file').length > 0 && (
						<TableRow header>
							<TableCell start="1" end="4">
								Filnavn
							</TableCell>

							<TableCell>Avventer signering</TableCell>

							<TableCell>Utført signering</TableCell>
						</TableRow>
					)}

				{getFilteredItems('file').map((file, i) => (
					<DocumentFileRow
						key={i}
						_id={file._id}
						name={file.name}
						to={`/bedrifter/${tenantId}/${file._id}/`}
						tenantId={tenantId}
						isSigning={file.isSigning}
						isSigningComplete={file.isSigningComplete}
						signeesSigned={file.signeesSigned}
						signeesWaiting={file.signeesWaiting}
						seenByCurrentUser={file.seenByCurrentUser}
						showMenu={!file.locked}
						canDelete={
							file.createdBy === user._id ||
							userHasMinRole('accountant')
						}
						isHidden={file.isHidden}
					/>
				))}
			</Table>
		</Wrapper>
	);
}
