import { useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import styled from 'styled-components';

import Icon from 'components/Icon';

import { Link } from 'app/components/Navigator';

import AddButton from 'app/components/AddButton';

export const MenuWrapper = styled.div`
	width: 100%;
	min-height: 200px;
	padding-bottom: 32px;
`;
export const MenuHeader = styled.div`
	width: 100%;
	height: 24px;
	position: relative;
	padding: 35px 15px;
	color: #7b7b7b;
`;
export const CloseButton = styled.button`
	background: none;
	border: none;
	outline: none;
	color: #9d0028;
	position: absolute;
	top: 35px;
	right: 15px;
	height: 30px;
	width: 30px;
	padding: 0;
`;
export const MenuLink = styled(Link)`
	border: none;
	outline: none;
	background: none;
	width: 100%;
	text-align: left;
	padding: 15px;
	display: block;
	text-decoration: none;
	color: inherit;
`;
const MenuButton = styled.button`
	border: none;
	outline: none;
	background: none;
	width: 100%;
	text-align: left;
	padding: 15px;
	display: block;
	color: inherit;
	cursor: pointer;
`;

export function BottomMenuItem({ children, to, onClick, ...props }) {
	if (to) {
		return (
			<MenuLink to={to} {...props}>
				{children}
			</MenuLink>
		);
	}

	return (
		<MenuButton onClick={onClick} type="button" {...props}>
			{children}
		</MenuButton>
	);
}

export default function BottomMenu({ children, title }) {
	let [isMenuOpen, setIsMenuOpen] = useState(false);

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

	return (
		<div>
			<AddButton onClick={() => setIsMenuOpen(true)} />

			<SwipeableDrawer
				anchor="bottom"
				open={isMenuOpen}
				onClose={() => setIsMenuOpen(false)}
				onOpen={() => setIsMenuOpen(true)}
				disableDiscovery={iOS}
			>
				<MenuWrapper onClick={() => setIsMenuOpen(false)}>
					<MenuHeader>
						{title}
						<CloseButton onClick={() => setIsMenuOpen(false)}>
							<Icon icon="xmark" />
						</CloseButton>
					</MenuHeader>
					{children}
				</MenuWrapper>
			</SwipeableDrawer>
		</div>
	);
}
