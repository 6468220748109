import { formatDate } from 'utils/dates';

export default function CreatedInfo({ createdAt, createdBy }) {
	if (!createdAt && !createdBy) {
		return null;
	}

	return (
		<div>
			<strong>Opprettet</strong>:{' '}
			{createdAt && formatDate(createdAt, 'DD.MM.YY [kl] HH:mm')}
			{createdBy && ` av ${createdBy}`}
		</div>
	);
}
