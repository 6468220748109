import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Outlet, NavLink } from 'react-router-dom';

import { InlineLoading } from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';

import DesktopLayout, { WideContent } from 'web/components/Layout';

import { GET_HELP_PAGES } from 'api/queries/helpPageQueries';

const Layout = styled.div`
	display: grid;
	grid-template-columns: 100%;
	gap: 30px;
	@media (min-width: 768px) {
		grid-template-columns: 280px 1fr;
	}
`;

const SidebarNav = styled.nav`
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
`;

const SidebarLink = styled(NavLink)`
	background-color: ${p => p.theme.palette.background.default};
	border: 1px solid ${p => p.theme.palette.background.paper};
	color: ${p => p.theme.palette.text.primary};
	display: block;
	margin-bottom: 2px;
	width: 100%;
	padding: 10px 20px;
	white-space: nowrap;
	transition: all 0.5s;
	text-decoration: none;
	text-overflow: ellipsis;
	overflow: hidden;

	&:hover {
		background-color: ${p => p.theme.palette.background.gray};
	}

	&.active {
		background-color: ${p => p.theme.palette.background.gray};
		font-weight: bold;
	}
`;

export default function Help() {
	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Hjelpesider',
				},
			]}
		>
			<WideContent>
				<HelpContent />
			</WideContent>
		</DesktopLayout>
	);
}

function HelpContent() {
	const {
		data: { helpPages } = {
			helpPages: [],
		},
		loading,
		error,
	} = useQuery(GET_HELP_PAGES);

	if (loading) {
		return <InlineLoading />;
	}

	if (error) {
		return <ErrorMessage errors={[error]} />;
	}

	return (
		<Layout>
			<SidebarNav aria-label="Navigasjon for hjelpesider">
				<ul>
					<SidebarLink to={`/hjelp`}>Innhold</SidebarLink>

					{helpPages?.map(page => (
						<li key={page._id}>
							<SidebarLink to={`/hjelp/${page._id}/`}>
								{page.name}
							</SidebarLink>
						</li>
					))}

					<li>
						<SidebarLink to={`/hjelp/brukerveiledning`} end>
							Brukerveiledning
						</SidebarLink>
					</li>
				</ul>
			</SidebarNav>

			<div>
				<Outlet />
			</div>
		</Layout>
	);
}
