import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorAlert from 'components/ErrorAlert';
import DeleteButton from 'components/buttons/DeleteButton';

import DesktopLayout, { NarrowContent } from 'web/components/Layout';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import { GET_ONE_TENANT_CATEGORY } from 'api/queries/tenantCategoryQueries';
import {
	ARCHIVE_TENANT_CATEGORY,
	UPDATE_TENANT_CATEGORY,
} from 'api/mutations/tenantCategoryMutations';

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	justify-content: center;

	> div {
		width: auto;
	}
`;

export default function CompanyCategoriesEdit() {
	const navigate = useNavigate();
	const { categoryId } = useParams();
	const { notify } = useSnackbar();

	const {
		data: { category = null } = { category: null },
		loading,
		error,
	} = useQuery(GET_ONE_TENANT_CATEGORY, {
		variables: {
			_id: categoryId,
		},
		errorPolicy: 'all',
	});

	const [
		updateTenantCategory,
		{ error: updateError, loading: updateLoading },
	] = useMutation(UPDATE_TENANT_CATEGORY);

	const [
		archiveTenantCategory,
		{ error: archiveError, loading: archiveLoading },
	] = useMutation(ARCHIVE_TENANT_CATEGORY);

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					to: '/bedriftskategorier/',
					label: 'Bedriftskategorier',
				},
				{
					label: 'Oppdater kategori',
				},
			]}
		>
			<NarrowContent>
				<Form
					key={category && category._id}
					values={category}
					isLoading={loading || updateLoading || archiveLoading}
					isDisabled={loading}
					errorMessage={error && error.toString()}
					onSubmit={async variables => {
						try {
							const { data } = await updateTenantCategory({
								variables,
							});

							if (data?.category?._id) {
								notify('Bedriftskategori oppdatert');

								navigate('/bedriftskategorier/');
							}
						} catch (err) {
							console.error(err);
						}
					}}
				>
					<ErrorAlert error={error} />
					<ErrorAlert error={updateError} />
					<ErrorAlert error={archiveError} />

					<TextField name="name" label="Navn" />

					<ButtonWrapper>
						<DeleteButton
							onClick={async () => {
								const { data } = await archiveTenantCategory({
									variables: {
										_id: category._id,
									},
								});

								if (!data.deleted) {
									throw new Error(
										'Det oppstod en feil ved sletting!'
									);
								}
							}}
							redirect="/bedriftskategorier/"
						/>

						<SubmitButton
							variant="contained"
							size="large"
							icon={<SaveIcon />}
						>
							Oppdater kategori
						</SubmitButton>
					</ButtonWrapper>
				</Form>
			</NarrowContent>
		</DesktopLayout>
	);
}
