import { useQuery, useMutation } from '@apollo/client';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorAlert from 'components/ErrorAlert';
import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';

import Form, {
	FormGroup,
	TextField,
	DatePicker,
	AutoComplete,
} from 'web/form/Form';

import { PAGINATE_MY_TENANTS } from 'api/queries/tenantQueries';

import { INSERT_ONE_TODO } from 'api/mutations/todoMutations';

export default function CreateTodoDialog({ isOpen, close }) {
	return (
		<Dialog
			open={isOpen}
			onClose={close}
			fullWidth={true}
			maxWidth="sm"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Opprett oppgave</DialogTitle>

			<DialogCloseButton onClick={close} />

			<AddTodoForm close={close} />
		</Dialog>
	);
}

function AddTodoForm({ close }) {
	const { notify } = useSnackbar();

	const {
		data: { companies = { count: 0, items: [] } } = {
			companies: { count: 0, items: [] },
		},
		loading: companiesLoading,
		error: companiesError,
	} = useQuery(PAGINATE_MY_TENANTS, {
		variables: {
			orderBy: 'name',
			order: 1,
		},
	});

	const [insertTodo, { loading: insertTodoLoading, error: insertTodoError }] =
		useMutation(INSERT_ONE_TODO, { refetchQueries: ['getTodos'] });

	return (
		<Form
			onSubmit={async values => {
				try {
					const { data } = await insertTodo({
						variables: {
							...values,
						},
					});

					if (data?.todo?._id) {
						notify('Oppgave opprettet!');

						close();
					}
				} catch (err) {
					console.error(err);
				}
			}}
		>
			<DialogContent>
				<ErrorAlert error={companiesError} />
				<ErrorAlert error={insertTodoError} />

				<FormGroup>
					<TextField name="title" label="Tittel" fullWidth required />
				</FormGroup>

				<FormGroup>
					<DatePicker name="date" label="Dato" />
				</FormGroup>

				<FormGroup>
					<AutoComplete
						name="companyId"
						label="Bedrift"
						loading={companiesLoading}
						options={companies?.items?.map(company => ({
							value: company._id,
							label: company.name,
						}))}
					/>
				</FormGroup>

				<FormGroup>
					<TextField
						name="description"
						label="Beskrivelse"
						rows={4}
						multiline
						fullWidth
					/>
				</FormGroup>
			</DialogContent>

			<DialogActions>
				<Button onClick={close}>Avbryt</Button>

				<Button
					type="submit"
					variant="contained"
					disabled={insertTodoLoading}
				>
					Opprett
				</Button>
			</DialogActions>
		</Form>
	);
}
