import theme from 'components/theme';
import styled from 'styled-components';

import { useAppFilters } from 'web/contexts/AppFiltersContext';

import useChildTotal from 'hooks/useChildTotal';

import {
	WidgetList,
	WidgetListItem,
	WidgetListLabel,
} from 'web/components/widgets/components/WidgetList';
import WidgetListData from 'web/components/widgets/components/WidgetListData';

import Widget from 'web/components/widgets/Widget';

import {
	COMPANIES_WITH_OVERDUE_DEADLINES_IN_PERIOD,
	COMPANIES_WITH_DUE_DEADLINES_IN_PERIOD,
	COMPANIES_WITH_DONE_DEADLINES_IN_PERIOD,
} from 'api/queries/widgetQueries';

const Message = styled.div`
	font-style: italic;
`;

export default function CompanyTasksWidget() {
	const [total, updateTotal] = useChildTotal();
	const { task } = useAppFilters();

	return (
		<Widget title="Oppgaver i periode" titleIcon="clock">
			{task?.value ? (
				<>
					<WidgetList>
						<WidgetListData
							name="overdue"
							label={
								<WidgetListLabel
									label="Bedrifter med oppgaver som har forfalt"
									chipColor={theme.palette.status.error}
								/>
							}
							query={COMPANIES_WITH_OVERDUE_DEADLINES_IN_PERIOD}
							link={id =>
								`/bedrifter/${id}/rediger/?tab=planlegging`
							}
							total={total}
							onLoaded={updateTotal}
						/>

						<WidgetListData
							name="due"
							label={
								<WidgetListLabel
									label="Bedrifter med oppgaver som skal utføres"
									chipColor={theme.palette.status.warning}
								/>
							}
							query={COMPANIES_WITH_DUE_DEADLINES_IN_PERIOD}
							link={id =>
								`/bedrifter/${id}/rediger/?tab=planlegging`
							}
							total={total}
							onLoaded={updateTotal}
						/>

						<WidgetListData
							name="done"
							label={
								<WidgetListLabel
									label="Bedrifter med oppgaver som er utført"
									chipColor={theme.palette.status.success}
								/>
							}
							query={COMPANIES_WITH_DONE_DEADLINES_IN_PERIOD}
							link={id =>
								`/bedrifter/${id}/rediger/?tab=planlegging`
							}
							total={total}
							onLoaded={updateTotal}
						/>
					</WidgetList>

					<WidgetList>
						<WidgetListItem label="Totalt" value={total} />
					</WidgetList>
				</>
			) : (
				<Message>Velg en oppgave for å vise statistikken.</Message>
			)}
		</Widget>
	);
}
