import { Grid, GridItem } from 'web/components/company/GridLayout';

import CompanyRisksPanel from 'web/components/panels/CompanyRisksPanel';
import CompanyOwners from 'web/components/CompanyOwners';

export default function RiskFormsTab({ company }) {
	if (!company) return null;

	return (
		<Grid>
			<GridItem>
				<CompanyOwners
					companyId={company._id}
					owners={company?.owners}
				/>
			</GridItem>

			<GridItem>
				<CompanyRisksPanel companyId={company._id} />
			</GridItem>
		</Grid>
	);
}
