export function orgNoFormat(orgNo) {
	if (orgNo.length !== 9) {
		return orgNo;
	}

	return orgNo.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
}

/**
 * Format a number as NOK (Norwegian Krone) currency.
 *
 * This function uses the `Intl.NumberFormat` API to format a given number
 * as currency in NOK with specified minimum and maximum decimal places.
 *
 * @param {number} number - The number to format as NOK currency.
 * @param {number} [minDecimals=2] - The minimum number of decimal places to display.
 * @param {number} [maxDecimals=2] - The maximum number of decimal places to display.
 * @returns {string} - The formatted currency string in NOK.
 *
 * @example
 * formatNOKCurrency(1234.567); // "kr 1 234,57"
 * formatNOKCurrency(1234.5, 1, 3); // "kr 1 234,5"
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat}
 */
export function formatNOKCurrency(number, minDecimals = 2, maxDecimals = 2) {
	const formatter = new Intl.NumberFormat('nb-NB', {
		style: 'currency',
		currency: 'NOK',
		currencyDisplay: 'narrowSymbol',
		minimumFractionDigits: minDecimals,
		maximumFractionDigits: maxDecimals,
	});

	return formatter.format(number);
}

/**
 * Calculates the percentage of a value with respect to a total.
 *
 * @param {number} value - The part value whose percentage is to be calculated.
 * @param {number} total - The total or whole value relative to which percentage is calculated.
 * @param {number} minDeciamals - The number of minimum decimal places to round the percentage to.
 * @param {number} maxDecimals - The number of maximum decimal places to round the percentage to.
 * @returns {string} - The percentage represented as a string rounded to the specified number of decimal places.
 *                      Returns '0' if the total is 0 to prevent division by zero.
 */
export function calculatePercentage(
	value,
	total,
	minDecimals = 0,
	maxDeciamals = 2
) {
	if (total === 0) {
		return '0%';
	}

	const percentage = (value / total) * 100;

	return (
		percentage.toLocaleString('nb-NO', {
			minimumFractionDigits: minDecimals,
			maximumFractionDigits: maxDeciamals,
		}) + '%'
	);
}
