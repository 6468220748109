import styled, { css } from 'styled-components';

import { useAppFilters } from 'web/contexts/AppFiltersContext';
import { useUser } from 'web/contexts/UserContext';

import TaskFilter from 'components/filters/TaskFilter';
import MonthFilter from 'components/filters/MonthFilter';
import YearFilter from 'components/filters/YearFilter';
import DepartmentFilter from 'components/filters/DepartmentFilter';
import AccountantFilter from 'components/filters/AccountantFilter';
import AccountManagerFilter from 'components/filters/AccountManagerFilter';
import PayrollManagerFilter from 'components/filters/PayrollManagerFilter';
import AnnualAccountManagerFilter from 'components/filters/AnnualAccountManagerFilter';
import ControllerFilter from 'components/filters/ControllerFilter';
import AuditorFilter from 'components/filters/AuditorFilter';
import AdminsAndAccountantsFilter from 'components/filters/AdminsAndAccountantsFilter';
import AccountantRoleFilter from 'components/filters/AccountantRoleFilter';
import AccountantRoleFilterLegacy from 'components/filters/AccountantRoleFilterLegacy';
import CompanyFilter from 'components/filters/CompanyFilter';
import UserRolesFilter from 'components/filters/UserRolesFilter';
import AccountTypeFilter from 'components/filters/AccountTypeFilter';
import AuditingCompanyFilter from 'components/filters/AuditingCompanyFilter';

const Filters = styled.div`
	display: flex;
	flex-direction: ${p => p.direction};
	align-items: ${p => (p.direction === 'row' ? 'center' : 'flex-start')};
	gap: 20px;

	// Make sure the filters are always full width when in column mode
	${p =>
		p.direction === 'column' &&
		css`
			div[class*='Filter__FilterWrapper'],
			> div {
				min-width: 100%;
				max-width: 100%;
			}
		`}
`;

export default function AppFilters({
	displayFilters = [],
	size = 'medium',
	direction = 'row',
}) {
	const { userHasMinRole } = useUser();
	const { accountantLegacy } = useAppFilters();

	const allFilters = [
		{
			id: 'year',
			display: true,
			disabled: false,
			component: YearFilter,
		},
		{
			id: 'month',
			display: true,
			disabled: false,
			component: MonthFilter,
		},
		{
			id: 'task',
			display: true,
			disabled: false,
			component: TaskFilter,
		},
		{
			id: 'department',
			display: userHasMinRole('admin'),
			disabled: false,
			component: DepartmentFilter,
		},
		{
			id: 'accountant',
			display: true,
			disabled: false,
			component: AccountantFilter,
		},
		{
			id: 'accountManager',
			display: true,
			disabled: false,
			component: AccountManagerFilter,
		},
		{
			id: 'payrollManager',
			display: true,
			disabled: false,
			component: PayrollManagerFilter,
		},
		{
			id: 'annualAccountsManager',
			display: true,
			disabled: false,
			component: AnnualAccountManagerFilter,
		},
		{
			id: 'controller',
			display: true,
			disabled: false,
			component: ControllerFilter,
		},
		{
			id: 'auditor',
			display: true,
			disabled: false,
			component: AuditorFilter,
		},
		{
			id: 'adminsAndAccountants',
			display: userHasMinRole('admin'),
			disabled: false,
			component: AdminsAndAccountantsFilter,
		},
		{
			id: 'accountantRole',
			display: true,
			disabled:
				userHasMinRole('admin') &&
				(!accountantLegacy || !accountantLegacy.value),
			component: AccountantRoleFilter,
		},
		{
			id: 'accountantRoleLegacy',
			display: true,
			disabled: false,
			component: AccountantRoleFilterLegacy,
		},
		{
			id: 'userRole',
			display: true,
			disabled: false,
			component: UserRolesFilter,
		},
		{
			id: 'company',
			display: true,
			disabled: false,
			component: CompanyFilter,
		},
		{
			id: 'accountType',
			display: true,
			disabled: false,
			component: AccountTypeFilter,
		},
		{
			id: 'auditingCompany',
			display: true,
			disabled: false,
			component: AuditingCompanyFilter,
		},
	];

	// We want to keep the order of the filters as they are defined in the allFilters array.
	const activeFilters = displayFilters
		.map(id => allFilters.find(filter => filter.id === id))
		.filter(filter => filter && filter.display);

	if (activeFilters.length === 0) {
		throw new Error('At least one displayFilter is required!');
	}

	return (
		<Filters direction={direction}>
			{activeFilters.map(filter => {
				const FilterComponent = filter.component;

				return (
					<FilterComponent
						key={filter.id}
						size={size}
						disabled={filter.disabled}
					/>
				);
			})}
		</Filters>
	);
}
