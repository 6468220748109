import { gql } from '@apollo/client';

export const PAGINATE_COMPANY_CONTRACTS = gql`
	query paginateCompanyContracts(
		$companyId: ID!
		$isSentToSigning: Boolean
		$order: Int
		$orderBy: String
		$limit: Int
		$offset: Int
		$search: String
	) {
		contracts: paginateCompanyContracts(
			companyId: $companyId
			isSentToSigning: $isSentToSigning
			order: $order
			orderBy: $orderBy
			limit: $limit
			skip: $offset
			search: $search
		) {
			count
			items {
				_id
				companyId
				typeLabel
				isSentToSigning
				isFullySigned
				createdAt
				createdByDisplayName
				updatedAt
				updatedByDisplayName
			}
		}
	}
`;

export const FIND_ONE_COMPANY_CONTRACT = gql`
	query findOneCompanyContract($_id: ID!) {
		contract: findOneCompanyContract(_id: $_id) {
			_id
			typeLabel
			company {
				_id
				name
				users(includeAdmins: true) {
					_id
					name
				}
				boardMembers(
					roles: [
						"innehaver"
						"dagligleder"
						"nestleder"
						"styreleder"
						"styremedlem"
						"kontaktperson"
					]
				) {
					_id
					name
				}
			}
			startDate
			introText
			clientContactId
			signingIds
			signingUsers {
				_id
				name
				signed
				signedAt
			}
			services {
				name
				billings
				fixedPrice
			}
			assignments {
				name
				comment
				tasks {
					name
					frequency
					clientDeadline
					contractorDeadline
					active
				}
			}
			attestationRoutines {
				name
				active
			}
			software {
				name
				vendor
				license
				adminRights
				modules {
					name
					unit
					price
					active
				}
			}
			hourlyRates {
				name
				value
				active
			}
			fixedRates {
				name
				value
				active
			}
			isSentToSigning
			createdAt
			createdByDisplayName
			updatedAt
			updatedByDisplayName
		}
	}
`;
