import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';

import { countries } from 'utils/countries';

import ErrorMessage from 'components/ErrorMessage';

import Layout from 'app/components/Layout';

import Form from 'components/form/Form';
import AutocompleteField from 'components/form/AutocompleteField';

import { VERIFY_ONE_USER_CITIZENSHIP } from 'api/mutations/userMutations';

const Title = styled.h3`
	margin: 0 0 20px 0;
`;

const Content = styled.div`
	margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
	margin-top: 20px;
	text-align: right;
`;

export default function UserVerifyCitizenship({
	_id,
	verificationCitizenship,
}) {
	const [verifyUserCitizenship, { error }] = useMutation(
		VERIFY_ONE_USER_CITIZENSHIP,
		{
			refetchQueries: ['currentUser'],
			errorPolicy: 'all',
		}
	);

	return (
		<Layout pageTitle="Bekreft statsborgerskap">
			<Title>Vennligst bekreft ditt statsborgerskap</Title>

			<ErrorMessage errors={error} />

			<Form
				values={{ citizenship: verificationCitizenship }}
				onSubmit={async ({ citizenship }) => {
					try {
						verifyUserCitizenship({
							variables: {
								_id,
								citizenship,
							},
						});
					} catch (err) {
						console.warn(err);
					}
				}}
			>
				<Content>
					<AutocompleteField
						label="Statsborger i land"
						name="citizenship"
						options={countries.map(country => ({
							label: country,
							value: country,
						}))}
						clearable={false}
					/>

					<ButtonWrapper>
						<Button
							type="submit"
							variant="contained"
							color="primary"
						>
							Bekreft statsborgerskap
						</Button>
					</ButtonWrapper>
				</Content>
			</Form>
		</Layout>
	);
}
