import { useState } from 'react';
import { useMutation } from '@apollo/client';

import useSearchParams from 'hooks/useSearchParams';

import LoginLayout from 'web/components/LoginLayout';
import { UnderlineLink } from 'web/components/Link';

import PasswordStrength from 'web/components/PasswordStrength';
import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import { SET_NEW_PASSWORD } from 'api/mutations/userMutations';

export default function SetNewPassword() {
	const params = useSearchParams();
	const [message, setMessage] = useState('');

	const [password, setPassword] = useState('');
	const [repeatPasswordError, setRepeatPasswordError] = useState('');

	const [setNewPassword, { loading, error }] = useMutation(SET_NEW_PASSWORD);

	return (
		<LoginLayout errors={error}>
			{!message && (
				<>
					<p>
						Bruk det midlertidige passordet du har mottatt per
						e-post.
					</p>

					<Form
						values={{ token: params.get('token') || '' }}
						isLoading={loading}
						isDisabled={loading}
						onSubmit={async ({
							token,
							newPassword,
							repeatNewPassword,
						}) => {
							try {
								const {
									data: { ok },
								} = await setNewPassword({
									variables: {
										token,
										newPassword,
										repeatNewPassword,
									},
								});

								if (ok) {
									setMessage(
										'Passord oppdatert! Du kan nå logge inn med ditt nye passord.'
									);
								}
							} catch (err) {
								console.warn(err);
							}
						}}
					>
						<TextField
							name="token"
							type="text"
							label="Midlertidig passord"
							required
						/>

						<TextField
							name="newPassword"
							type="password"
							label="Nytt passord"
							onChange={e => setPassword(e.target.value)}
							value={password}
							helperText="Må innheholde minst 8 tegn, minimum en stor bokstav og ett tall. Spesialtegn gjør det ekstra sterkt."
							required
						/>

						<PasswordStrength password={password} />

						<TextField
							name="repeatNewPassword"
							type="password"
							label="Gjenta passord"
							onChange={e => {
								setRepeatPasswordError(
									e.target.value !== password
								);
							}}
							helperText={
								repeatPasswordError ? 'Passord ikke likt' : null
							}
							required
						/>

						<SubmitButton variant="contained" size="large">
							Sett nytt passord
						</SubmitButton>
					</Form>

					<UnderlineLink to="/admin-innlogging">
						Gå tilbake
					</UnderlineLink>
				</>
			)}

			{message && (
				<>
					<p>{message}</p>

					<UnderlineLink to="/admin-innlogging">
						Logg inn
					</UnderlineLink>
				</>
			)}
		</LoginLayout>
	);
}
