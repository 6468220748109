import { gql } from '@apollo/client';

export const HOME_DASHBOARD_QUERY = gql`
	query homeDashboardData(
		$tenantId: ID!
		$createdAt: DateQueryInput
		$type: String
	) {
		company: findOneTenant(_id: $tenantId) {
			_id
			name
			orgNo
			clientNo
			departmentId
			accountants {
				name
				email
				phone
			}
			auditors {
				name
				email
				phone
			}
		}
		mySignatureRequests {
			_id
			signUrl
			documentNode {
				_id
				name
				type
				parentId
				createdAt
				isSigning
				isSigningComplete
				createdBy: createdByDisplayName
				tenantId
				isArchived
				seenByCurrentUser
			}
		}
		unSignedDocuments: paginateDocumentNodes(
			isSigning: true
			isSigningComplete: false
			tenantId: $tenantId
			orderBy: "createdAt"
			order: -1
		) {
			count
			items {
				_id
				name
				type
				parentId
				createdAt
				isSigning
				isSigningComplete
				createdBy: createdByDisplayName
				tenantId
				isArchived
				seenByCurrentUser
			}
		}
		recentDocuments: paginateDocumentNodes(
			tenantId: $tenantId
			createdAt: $createdAt
			seenByCurrentUser: false
			type: $type
			orderBy: "createdAt"
			order: -1
		) {
			count
			items {
				_id
				name
				type
				parentId
				createdAt
				isSigning
				isSigningComplete
				createdBy: createdByDisplayName
				tenantId
				isArchived
				seenByCurrentUser
			}
		}
	}
`;
