import { useState } from 'react';
import styled from 'styled-components';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';

import { formatDate } from 'utils/dates';

import { pluralize } from 'utils/strings';

import Panel from 'web/components/panels/Panel';
import ProffDataModal from 'web/screens/modals/ProffDataModal';

import { CardContent } from 'components/GridCardLayout';

const Row = styled.div`
	display: flex;
	align-items: flex-start;
`;

const Divider = styled.hr`
	border-top: 1px solid #d1d1d1;
	margin: 5px 0 15px;
`;

const Label = styled.div`
	flex: 1;
	font-weight: 600;
	padding: 0 5px 5px;
`;

const Value = styled.div`
	flex: 4;
	background: ${p => (p.bg ? '#e1e1e1' : 'transparent')};
	padding: 0 5px 5px;
`;

const PropListWrap = styled.div`
	background: ${p =>
		p.$bg ? p.theme.palette.background.paper : 'transparent'};
	padding: 5px 5px 0;
`;

const ShowMoreWrapper = styled.div`
	margin-top: 10px;
	text-align: center;
`;

export default function ProffDataPanel({ data }) {
	const [showMoreInfo, setShowMoreInfo] = useState(false);

	return (
		<Panel title="Informasjon fra proff.no">
			<CardContent>
				<DataRow
					label="Sist oppdatert"
					value={
						data.updatedAt
							? formatDate(
									data?.updatedAt,
									'DD.MM.YYYY [kl] HH:mm'
							  )
							: 'Ugyldig dato'
					}
					divider
				/>

				<DataRow
					label={pluralize(
						'Næringskode',
						'Næringskoder',
						data.naceCategories
					)}
					value={data?.naceCategories?.map((c, i) => (
						<div key={i}>{c}</div>
					))}
					divider
				/>

				<DataRow
					label="Forretningsadresse"
					value={<Address {...data.visitorAddress} />}
					divider
				/>

				<DataRow
					label="Postadresse"
					value={<Address {...data.postalAddress} />}
					divider
				/>

				<DataRow
					label="Signatur"
					value={data?.signatories?.map((s, i) => (
						<div key={i}>{s}</div>
					))}
					divider
				/>

				<DataRow
					label="Prokura"
					value={data?.procuration?.map((s, i) => (
						<div key={i}>{s}</div>
					))}
					divider
				/>

				<DataRow
					label="Revisor/Regnskap"
					value={data?.companyRoles?.map((p, i) => (
						<PropList
							key={i}
							bg={i % 2 === 0}
							labels={companyRoleLabels}
							{...p}
						/>
					))}
					divider
				/>

				<DataRow
					label="Aksjonærer"
					value={data?.shareholders?.map((p, i) => (
						<PropList
							key={i}
							bg={i % 2 === 0}
							labels={shareholderLabels}
							allowQueries={true}
							{...p}
						/>
					))}
					divider={showMoreInfo}
				/>

				<Collapse in={showMoreInfo}>
					<DataRow
						label="Organisasjonsnummer"
						value={data.companyId}
						divider
					/>

					<DataRow
						label="Navn/foretaksnavn"
						value={data.name}
						divider
					/>

					<DataRow
						label="Organisasjonsform"
						value={data.companyTypeName}
						divider
					/>

					<DataRow
						label="Registrert i MVA"
						value={data.registeredForVat ? 'Ja' : 'Nei'}
						divider
					/>

					<DataRow
						label="Registrert i Aa-registeret"
						value={data.registeredForNav ? 'Ja' : 'Nei'}
						divider
					/>

					{data.phoneNumbers && (
						<DataRow
							label="Kontaktinfo"
							value={Object.keys(data.phoneNumbers)
								.filter(
									key =>
										data.phoneNumbers[key] &&
										phoneLabels[key]
								)
								.map((key, i) => (
									<Row key={i}>
										<Label>{phoneLabels[key]}</Label>

										<Value>{data.phoneNumbers[key]}</Value>
									</Row>
								))}
							show={
								Object.keys(data.phoneNumbers).filter(
									key => data.phoneNumbers[key]
								).length > 0
							}
							divider
						/>
					)}

					<DataRow
						label="Status"
						value={
							<>
								{data.status?.statusFlag}
								{data.status?.description && (
									<>
										<br />
										{data.status?.description}
									</>
								)}
							</>
						}
						divider
					/>

					{data.corporateStructure && (
						<DataRow
							label="Struktur"
							value={Object.keys(data.corporateStructure)
								.filter(
									key =>
										data.corporateStructure[key] &&
										corporateStructureLabels[key]
								)
								.map((key, i) => (
									<Row key={i}>
										<Label>
											{corporateStructureLabels[key]}
										</Label>

										<Value>
											{data.corporateStructure[key]}
										</Value>
									</Row>
								))}
							show={
								Object.keys(data.corporateStructure).filter(
									key => data.corporateStructure[key]
								).length > 0
							}
							divider
						/>
					)}

					<DataRow
						label="Styre"
						value={data?.personRoles?.map((p, i) => (
							<PropList
								key={i}
								bg={i % 2 === 0}
								labels={personLabels}
								{...p}
							/>
						))}
						divider
					/>

					<DataRow
						label="Reelle rettighetshavere"
						value={data?.owners?.map((p, i) => (
							<PropList
								key={i}
								bg={i % 2 === 0}
								labels={ownerLabels}
								{...p}
							/>
						))}
					/>
				</Collapse>
			</CardContent>

			<ShowMoreWrapper>
				<Button onClick={() => setShowMoreInfo(current => !current)}>
					{showMoreInfo ? 'Skjul mer info' : 'Vis mer info'}
				</Button>
			</ShowMoreWrapper>
		</Panel>
	);
}

function DataRow({ label, value, show = true, divider = false }) {
	if (!value || !show) return null;

	return (
		<>
			<Row>
				<Label>{label}</Label>

				<Value>{value}</Value>
			</Row>

			{divider && <Divider />}
		</>
	);
}

function Address(props) {
	return (
		<div>
			{Object.keys(props)
				.filter(key => props[key] && addressLabels[key])
				.map((key, i) => (
					<Row key={i}>
						<Label>{addressLabels[key]}</Label>

						<Value>{props[key]}</Value>
					</Row>
				))}
		</div>
	);
}

function PropList({ bg = false, labels, allowQueries, ...props }) {
	return (
		<PropListWrap $bg={bg}>
			{Object.keys(props)
				.filter(key => props[key] != null && labels[key])
				.map((key, i) => {
					let value = props[key];
					let label = labels[key];

					if (typeof value === 'number') {
						value = Math.round(value * 1000) / 1000; // max 3 decimals
					} else if (key === 'companyId' && allowQueries) {
						value = <ProffDataModal label={label} value={value} />;
					} else if (
						key === 'name' &&
						props?.details?.href &&
						!props?.details?.href.includes('api.proff.no')
					) {
						value = (
							<a
								href={props.details.href}
								target="_blank"
								title="Les mer på proff.no"
								rel="noreferrer"
							>
								{value}
							</a>
						);
					} else if (new RegExp('date', 'i').test(key)) {
						value = formatDate(props[key], 'DD.MM.YY', 'DDMMYYYY');
					}

					return (
						<Row key={i}>
							<Label>{label}</Label>

							<Value>{value}</Value>
						</Row>
					);
				})}
		</PropListWrap>
	);
}

const addressLabels = {
	addressLine: 'Adresse',
	boxAddressLine: 'Postboks',
	postPlace: 'Poststed',
	zipCode: 'Postnr',
};

const companyRoleLabels = {
	companyId: 'Orgnr',
	details: null,
	elector: null,
	name: 'Navn',
	responsibility: null,
	title: 'Rolle',
};

const shareholderLabels = {
	companyId: 'Orgnr',
	details: null,
	firstName: 'Fornavn',
	lastName: 'Etternavn',
	name: 'Navn',
	numberOfShares: 'Antall aksjer',
	share: 'Andel',
};

const phoneLabels = {
	faxNumber: 'Fax',
	mobilePhone: 'Mobil',
	mobilePhone2: 'Mobil',
	telephoneNumber: 'Telefon',
	telephoneNumber2: 'Telefon',
};

const personLabels = {
	birthDate: 'Født',
	details: null,
	elector: null,
	name: 'Navn',
	personId: null,
	responsibility: null,
	title: 'Rolle',
};

const corporateStructureLabels = {
	details: null,
	numberOfCompanies: 'Ant. selskap',
	numberOfSubsidiaries: 'Ant. underenheter',
	parentCompanyLink: null,
	parentCompanyName: 'Foreldernavn',
	parentCompanyOrganisationNumber: 'Forelders orgnr',
};

const ownerLabels = {
	birthDate: 'Født',
	name: 'Navn',
	share: 'Andel',
};
