import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { getFolderNameBySlug } from 'server/libs/defaultFolders';
import { pluralize } from 'utils/strings';
import { conjunctionFormat } from 'utils/arrays';

import CompanyRoutinesCreateDialog from 'web/screens/dialogs/companies/CompanyRoutinesCreateDialog';

import { RevisionText } from 'components/Revision';
import { InlineLoading } from 'components/Loading';
import { UnderlineLink } from 'web/components/Link';
import ErrorMessage from 'components/ErrorMessage';
import ErrorAlert from 'components/ErrorAlert';

import Panel, { EmptyPanel } from 'web/components/panels/Panel';

import { GET_COMPANY_ROUTINES } from 'api/queries/companyRoutineQueries';
import { DELETE_COMPANY_ROUTINE } from 'api/mutations/companyRoutineMutations';
import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';

const Text = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-size: 0.9rem;
	margin-top: 5px;
`;

export default function CompanyRoutinesPanel({ companyId }) {
	const [showDialog, setShowDialog] = useState(false);

	const {
		data: { routines } = { routines: [] },
		loading,
		error,
	} = useQuery(GET_COMPANY_ROUTINES, {
		variables: {
			companyId,
		},
	});

	return (
		<>
			<Panel
				title="Bedriftsrutiner"
				action={
					<Button
						size="medium"
						variant="contained"
						color="primary"
						endIcon={<AddIcon size="small" />}
						onClick={() => setShowDialog(true)}
					>
						Opprett ny
					</Button>
				}
			>
				<ErrorMessage errors={error} />

				{loading ? (
					<InlineLoading />
				) : (
					<CompanyRoutinesList routines={routines} />
				)}
			</Panel>

			<CompanyRoutinesCreateDialog
				companyId={companyId}
				activeRoutines={routines}
				open={showDialog}
				onClose={() => setShowDialog(false)}
			/>
		</>
	);
}

function CompanyRoutinesList({ routines }) {
	if (!routines || routines.length === 0) {
		return <EmptyPanel>Bedriften har ingen rutiner.</EmptyPanel>;
	}

	return (
		<List>
			{routines.map(routine => (
				<CompanyRoutineItem key={routine._id} routine={routine} />
			))}
		</List>
	);
}

function CompanyRoutineItem({ routine }) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [deleteRoutine, { loading, error }] = useMutation(
		DELETE_COMPANY_ROUTINE,
		{
			refetchQueries: ['getCompanyRoutines'],
		}
	);

	return (
		<>
			<ErrorAlert error={error} />

			<ListItem
				secondaryAction={
					<IconButton
						edge="end"
						aria-label="Slett"
						disabled={loading}
						onClick={async () => {
							try {
								if (
									!(await verify({
										title: `Slett bedriftsrutine`,
										text: `Er du sikker på at du vil slette ${routine.name}?`,
									}))
								) {
									return;
								}

								const { data } = await deleteRoutine({
									variables: {
										_id: routine._id,
									},
								});

								if (data.deleted) {
									notify(`${routine.name} ble slettet`);
								}
							} catch (err) {
								console.error(err);
							}
						}}
					>
						<DeleteIcon />
					</IconButton>
				}
			>
				<ListItemText>
					<UnderlineLink
						to={`/bedrifter/${routine.companyId}/rutiner/${routine._id}/rediger/`}
					>
						{routine.name}
					</UnderlineLink>

					{routine.folderPlacements ? (
						<Text>
							<strong>
								{pluralize(
									'Mappeplassering: ',
									'Mappeplasseringer: ',
									Text
								)}
							</strong>

							<span>
								{conjunctionFormat(
									routine.folderPlacements.map(slug =>
										getFolderNameBySlug(slug)
									)
								)}
							</span>
						</Text>
					) : (
						<Text>Vises ikke i noen mappe</Text>
					)}

					<RevisionText
						needsRevision={routine?.needsRevision}
						date={routine?.revision?.date}
						by={routine?.revision?.byDisplayName}
					/>
				</ListItemText>
			</ListItem>

			<Divider component="li" />
		</>
	);
}
