import { useQuery, useMutation } from '@apollo/client';

import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import FileListWithUploadDialog from 'web/screens/dialogs/users/FileListWithUploadDialog';

import ErrorAlert from 'components/ErrorAlert';
import DeleteButton from 'components/buttons/DeleteButton';

import Form, {
	FormGroup,
	TextField,
	DatePicker,
	AutoComplete,
} from 'web/form/Form';

import { PAGINATE_MY_TENANTS } from 'api/queries/tenantQueries';

import { UPDATE_ONE_TODO, DELETE_ONE_TODO } from 'api/mutations/todoMutations';

export default function EditTodoDialog({ todo, isOpen, close }) {
	return (
		<Dialog
			open={isOpen}
			onClose={close}
			fullWidth={true}
			maxWidth="sm"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Rediger oppgave</DialogTitle>

			<DialogCloseButton onClick={close} />

			<EditTodoForm todo={todo} close={close} />
		</Dialog>
	);
}

function EditTodoForm({ todo, close }) {
	const { notify } = useSnackbar();

	const {
		data: { companies = { count: 0, items: [] } } = {
			companies: { count: 0, items: [] },
		},
		loading: companiesLoading,
		error: companiesError,
	} = useQuery(PAGINATE_MY_TENANTS, {
		variables: {
			orderBy: 'name',
			order: 1,
		},
	});

	const [updateTodo, { loading: updateTodoLoading, error: updateTodoError }] =
		useMutation(UPDATE_ONE_TODO, { refetchQueries: ['getTodos'] });

	const [deleteTodo, { loading: deleteTodoLoading, error: deleteTodoError }] =
		useMutation(DELETE_ONE_TODO, { refetchQueries: ['getTodos'] });

	return (
		<Form
			values={todo}
			onSubmit={async values => {
				try {
					const { data } = await updateTodo({
						variables: {
							id: todo._id,
							...values,
						},
					});

					if (data?.todo?._id) {
						notify('Oppgave oppdatert!');

						close();
					}
				} catch (err) {
					console.error(err);
				}
			}}
		>
			<DialogContent>
				<ErrorAlert error={companiesError} />
				<ErrorAlert error={updateTodoError} />
				<ErrorAlert error={deleteTodoError} />

				<FormGroup>
					<TextField name="title" label="Tittel" fullWidth required />
				</FormGroup>

				<FormGroup>
					<DatePicker name="date" label="Dato" />
				</FormGroup>

				<FormGroup>
					<AutoComplete
						name="companyId"
						label="Bedrift"
						loading={companiesLoading}
						options={companies?.items?.map(company => ({
							value: company._id,
							label: company.name,
						}))}
					/>
				</FormGroup>

				<FormGroup>
					<TextField
						name="description"
						label="Beskrivelse"
						rows={4}
						multiline
						fullWidth
					/>
				</FormGroup>

				<FileListWithUploadDialog
					docId={todo._id}
					uploadContext="todo"
				/>
			</DialogContent>

			<DialogActions>
				<DeleteButton
					disabled={deleteTodoLoading}
					onClick={async () => {
						const { data } = await deleteTodo({
							variables: {
								_id: todo._id,
							},
						});

						if (!data.deleted) {
							throw new Error(
								'Det oppstod en feil ved sletting!'
							);
						}
					}}
					redirect="/"
				/>

				<Button
					type="submit"
					variant="contained"
					disabled={updateTodoLoading}
				>
					Oppdater
				</Button>
			</DialogActions>
		</Form>
	);
}
