import { gql } from '@apollo/client';

export const PAGINATE_TENANT_CATEGORIES = gql`
	query paginateTenantCategories(
		$order: Int
		$orderBy: String
		$limit: Int
		$offset: Int
		$search: String
	) {
		categories: paginateTenantCategories(
			order: $order
			orderBy: $orderBy
			limit: $limit
			skip: $offset
			search: $search
		) {
			count
			items {
				_id
				name
			}
		}
	}
`;

export const GET_ONE_TENANT_CATEGORY = gql`
	query getOneTenantCategory($_id: ID!) {
		category: getOneTenantCategory(_id: $_id) {
			_id
			name
		}
	}
`;
