import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const TableWrapper = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(${p => p.$columns}, minmax(50px, 1fr));

	.row,
	> * {
		grid-template-columns: repeat(${p => p.$columns}, minmax(50px, 1fr));
		grid-column: 1 / -1;
		display: grid;

		> .cell {
			padding: ${p => (p.$header ? '10px 15px' : '15px')};
		}

		> .cell:first-child {
			padding: ${p => (p.$header ? '10px 0' : '15px')};
		}
	}
`;

const TableRowWrapper = styled.div`
	border-bottom: 1px solid ${p => p.theme.palette.background.paper};

	&:hover {
		background-color: rgba(240, 238, 235, 0.5);
	}

	${P =>
		P.$header &&
		css`
			background-color: ${p => p.theme.palette.background.default};
			border: 0;
			position: sticky;
			top: 0;
			z-index: 1;
			padding-bottom: 10px;

			&:hover {
				background: none;
			}
		`}

	${p =>
		p.$footer &&
		css`
			border: none;
			&:hover {
				background: none;
			}
		`}

	${p =>
		p.$transparent &&
		css`
			border: none;
			background: transparent;
			&:hover {
				background: none;
			}
		`}

	${p =>
		p.$interact &&
		css`
			cursor: pointer;
		`}

	${p =>
		p.$subdued &&
		css`
			font-style: italic;
			color: #919191;

			i,
			svg {
				opacity: 0.3;
			}
		`}

		${p =>
		p.$hover &&
		css`
			transition: all 0.2s;

			&:hover {
				background: ${p => p.theme.palette.common.light};
			}
		`}
`;

const SharedStyles = css`
	${p => p.$start && `grid-column-start: ${p.$start}`};
	${p => p.$end && `grid-column-end: ${p.$end}`};
	${p => (p.onClick ? 'cursor: pointer;' : '')};
	${p => (p.$right ? 'justify-content: flex-end' : '')};
	${p => (p.$center ? 'justify-content: center' : '')};
`;

const TableCellWrapper = styled.div`
	${SharedStyles}
	display: flex;
	align-items: center;
	gap: 10px;
`;

const TableCellLinkWrapper = styled(Link)`
	color: inherit;
	text-decoration: inherit;
	transition: all 0.5s;
	display: flex;
	align-items: center;
	gap: 10px;

	&:hover {
		color: ${p => p.theme.palette.primary.main};
	}

	${SharedStyles}
`;

export default function Table({
	children,
	columns = 3,
	className,
	header,
	...props
}) {
	return (
		<TableWrapper
			className={classNames(className, 'table')}
			$columns={columns}
			$header={header}
			{...props}
		>
			{children}
		</TableWrapper>
	);
}

export function TableRow({
	children,
	className,
	header,
	footer,
	transparent,
	subdued,
	hover,
	interact,
	...props
}) {
	return (
		<TableRowWrapper
			className={classNames(className, 'row')}
			$header={header}
			$footer={footer}
			$transparent={transparent}
			$subdued={subdued}
			$hover={hover}
			$interact={interact}
			{...props}
		>
			{children}
		</TableRowWrapper>
	);
}

export function TableCell({
	children,
	start,
	end,
	className,
	to,
	right = false,
	center = false,
	...props
}) {
	if (to) {
		return (
			<TableCellLinkWrapper
				className={classNames(className, 'cell')}
				$start={start}
				$end={end}
				to={to}
				$right={right}
				$center={center}
				{...props}
			>
				{children}
			</TableCellLinkWrapper>
		);
	}

	return (
		<TableCellWrapper
			className={classNames(className, 'cell')}
			$start={start}
			$end={end}
			$right={right}
			$center={center}
			{...props}
		>
			{children}
		</TableCellWrapper>
	);
}
