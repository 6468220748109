export const types = [
	{ value: 'TAX_NOTICE', label: 'Skattemelding' },
	{ value: 'ANNUAL_ACCOUNTS', label: 'Årsregnskap' },
];

export const postponedStatuses = [
	{ value: 'Vi søker utsettelse', label: 'Vi søker utsettelse' },
	{ value: 'Kunden søker utsettelse', label: 'Kunden søker utsettelse' },
	{ value: 'Revisor søker utsettelse', label: 'Revisor søker utsettelse' },
];

export function createData(entry) {
	return {
		company: {
			_id: entry.company._id,
			name: entry.company.name,
			type: entry.company?.proffData?.companyType,
			identifier: getCompanyId(entry.company),
		},
		deadlineEntry: {
			_id: entry.deadlineEntry._id,
			date: entry.deadlineEntry.date,
			status: entry.deadlineEntry?.status,
			postponedStatus: {
				status: entry.deadlineEntry?.postponedStatus?.status,
				exported:
					entry.deadlineEntry?.postponedStatus?.exported ?? false,
			},
		},
	};
}

function getCompanyId(company) {
	if (!company.orgNo) {
		return null;
	}

	const proffData = company?.proffData;

	if (!proffData) {
		return company.orgNo;
	}

	const companyType = proffData?.companyType?.trim();

	if (companyType !== 'ENK') {
		return proffData?.companyId ?? company.orgNo;
	}

	const companyOwners = company?.owners ?? [];

	if (companyOwners.length !== 1) {
		console.error(
			`We cannot decide which social security number should be used, if the company does not have exactly one owner. We have ${companyOwners.length} owners for the company ${company.name} with orgNo: ${company.orgNo}!`
		);

		return null;
	}

	const owner = companyOwners[0];

	if (!owner.socialno) {
		console.error(
			`The owner ${owner.name} of the company ${company.name} with orgNo: ${company.orgNo} does not have a social security number!`
		);

		return null;
	}

	return owner.socialno;
}
