import { gql } from '@apollo/client';

export const INSERT_ONE_USER = gql`
	mutation insertOneUser(
		$name: String!
		$role: String
		$auditingCompanyId: ID
		$email: String
		$phone: String
		$dateofbirth: String
		$userRoles: [UserTenantRoleInput]
		$companyOwner: String
		$address: UserAddressInput
		$socialno: String
	) {
		insertOneUser(
			name: $name
			role: $role
			auditingCompanyId: $auditingCompanyId
			email: $email
			phone: $phone
			dateofbirth: $dateofbirth
			userRoles: $userRoles
			companyOwner: $companyOwner
			address: $address
			socialno: $socialno
		) {
			_id
			name
		}
	}
`;

export const UPDATE_ONE_USER = gql`
	mutation updateOneUser(
		$_id: ID!
		$name: String
		$role: String
		$position: String
		$auditingCompanyId: ID
		$isPoliticallyExposedPerson: Boolean
		$email: String
		$phone: String
		$userRoles: [UserTenantRoleInput]
		$address: UserAddressInput
		$citizenship: String
		$socialno: String
		$dNumber: String
		$userPoliticallyExposedPersonRoles: [UserPoliticallyExposedPersonRoleInput]
		$forceUserVerificationDate: DateTime
		$userLastVerificationDate: DateTime
	) {
		user: updateOneUser(
			_id: $_id
			name: $name
			role: $role
			position: $position
			auditingCompanyId: $auditingCompanyId
			isPoliticallyExposedPerson: $isPoliticallyExposedPerson
			email: $email
			phone: $phone
			userRoles: $userRoles
			address: $address
			citizenship: $citizenship
			socialno: $socialno
			dNumber: $dNumber
			userPoliticallyExposedPersonRoles: $userPoliticallyExposedPersonRoles
			forceUserVerificationDate: $forceUserVerificationDate
			userLastVerificationDate: $userLastVerificationDate
		) {
			_id
			name
			role
			position
			auditingCompanyId
			email
			phone
			isPoliticallyExposedPerson
			updatedAt
			address {
				street_address
				postal_code
				locality
				country
			}
			citizenship
			socialno
			dNumber
			userRoles {
				id
				role
			}
			userVerifications {
				type
				value
				isVerified
				lastVerified
			}
			forceUserVerificationDate
			userLastVerificationDate
			userPoliticallyExposedPersonRoles {
				type
				name
				address
			}
		}
	}
`;

export const UPDATE_ONE_USER_INPUT_WITH_VERIFICATION = gql`
	mutation updateOneUserInputWithVerification(
		$_id: ID!
		$field: String!
		$value: String!
	) {
		updatedValue: updateOneUserInputWithVerification(
			_id: $_id
			field: $field
			value: $value
		)
	}
`;

export const VERIFY_ONE_USER_INPUT_WITH_TOKEN = gql`
	mutation verifyOneUserInputWithToken(
		$_id: ID!
		$type: String!
		$value: String!
		$token: String!
	) {
		updated: verifyOneUserInputWithToken(
			_id: $_id
			type: $type
			value: $value
			token: $token
		)
	}
`;

export const VERIFY_ONE_USER_ADDRESS = gql`
	mutation verifyOneUserAddress($_id: ID!, $address: UserAddressInput!) {
		address: verifyOneUserAddress(_id: $_id, address: $address) {
			street_address
			postal_code
			locality
			country
		}
	}
`;

export const VERIFY_ONE_USER_PEP = gql`
	mutation verifyOneUserPEP(
		$_id: ID!
		$isPEP: Boolean!
		$roles: [UserPoliticallyExposedPersonRoleInput]
	) {
		updatedValue: verifyOneUserPEP(_id: $_id, isPEP: $isPEP, roles: $roles)
	}
`;

export const VERIFY_ONE_USER_CITIZENSHIP = gql`
	mutation verifyOneUserCitizenship($_id: ID!, $citizenship: String!) {
		updatedValue: verifyOneUserCitizenship(
			_id: $_id
			citizenship: $citizenship
		)
	}
`;

export const UPDATE_ONE_USER_FOLDER_ACCESS = gql`
	mutation updateOneUserFolderAccess(
		$_id: ID!
		$tenantId: ID!
		$folder: String!
	) {
		user: updateOneUserFolderAccess(
			_id: $_id
			tenantId: $tenantId
			folder: $folder
		) {
			_id
			folderAccess {
				tenantId
				folders
			}
		}
	}
`;

export const INVITE_ONE_USER = gql`
	mutation inviteOneUser($_id: ID!) {
		inviteOneUser(_id: $_id)
	}
`;

export const DELETE_ONE_USER = gql`
	mutation deleteOneUser($_id: ID!) {
		deleted: deleteOneUser(_id: $_id)
	}
`;

export const RESET_FAILED_LOGIN_ATTEMPTS = gql`
	mutation resetFailedLoginAttempts($_id: ID!) {
		resetFailedLoginAttempts(_id: $_id)
	}
`;

export const REGISTER_USER_DEVICE = gql`
	mutation registerUserDevice(
		$deviceUUID: ID!
		$registrationId: ID!
		$registrationType: String!
		$name: String!
	) {
		registerUserDevice(
			deviceUUID: $deviceUUID
			registrationId: $registrationId
			registrationType: $registrationType
			name: $name
		)
	}
`;

export const SEND_USER_CONTACT_INFO_UPDATE_NOTIFICATION = gql`
	mutation sendUserContactInfoUpdateNotification($userId: ID!) {
		sent: sendUserContactInfoUpdateNotification(userId: $userId)
	}
`;

export const REQUEST_ID_DOCUMENT_UPLOAD = gql`
	mutation requestIDDocumentUpload(
		$userId: ID!
		$fileName: String!
		$contentType: String!
	) {
		uploadUrl: requestIDDocumentUpload(
			userId: $userId
			fileName: $fileName
			contentType: $contentType
		)
	}
`;

export const REQUEST_ID_DOCUMENT_DOWNLOAD_URL = gql`
	mutation requestIDDocumentDownloadUrl($userId: ID!) {
		downloadUrl: requestIDDocumentDownloadUrl(userId: $userId)
	}
`;

export const DELETE_ID_DOCUMENT = gql`
	mutation deleteIdDocument($userId: ID!) {
		deleted: deleteIdDocument(userId: $userId)
	}
`;

export const VERIFY_USER_INVITE = gql`
	mutation verifyUserInvite($code: String!) {
		id: verifyUserInvite(code: $code)
	}
`;

export const EMAIL_AND_PASSWORD_LOGIN = gql`
	mutation emailAndPasswordLogin($email: String!, $password: String!) {
		token: emailAndPasswordLogin(email: $email, password: $password)
	}
`;

export const REQUEST_PASSWORD_RESET = gql`
	mutation requestPasswordReset($email: String!) {
		ok: requestPasswordReset(email: $email)
	}
`;

export const SET_NEW_PASSWORD = gql`
	mutation setNewPassword(
		$token: String!
		$newPassword: String!
		$repeatNewPassword: String!
	) {
		ok: setNewPassword(
			token: $token
			newPassword: $newPassword
			repeatNewPassword: $repeatNewPassword
		)
	}
`;

export const SET_NEW_USER_PASSWORD = gql`
	mutation setNewUserPassword(
		$userId: ID!
		$newPassword: String!
		$repeatNewPassword: String!
	) {
		ok: setNewUserPassword(
			userId: $userId
			newPassword: $newPassword
			repeatNewPassword: $repeatNewPassword
		)
	}
`;

/**
 * User profile mutations for roles: user and auditor
 */

export const UPDATE_USER_PROFILE = gql`
	mutation updateUserProfile(
		$_id: ID!
		$name: String
		$email: String
		$phone: String
	) {
		user: updateUserProfile(
			_id: $_id
			name: $name
			email: $email
			phone: $phone
		) {
			_id
		}
	}
`;
