import { gql } from '@apollo/client';

export const INSERT_TENANT_CATEGORY = gql`
	mutation insertOneTenantCategory($name: String!) {
		category: insertOneTenantCategory(name: $name) {
			_id
			name
		}
	}
`;

export const UPDATE_TENANT_CATEGORY = gql`
	mutation updateOneTenantCategory($_id: ID!, $name: String!) {
		category: updateOneTenantCategory(_id: $_id, name: $name) {
			_id
			name
		}
	}
`;

export const ARCHIVE_TENANT_CATEGORY = gql`
	mutation archiveOneTenantCategory($_id: ID!) {
		deleted: archiveOneTenantCategory(_id: $_id)
	}
`;
