import styled from 'styled-components';

const StyledPanel = styled.div`
	/* margin-bottom: 30px; */
	min-width: fit-content(500px);
	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100%;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	margin: 0 0 20px 0;
`;

const Title = styled.h3`
	margin: 0;
	flex: 1 1 0%;
`;

const ContentWrapper = styled.div`
	${p =>
		p.$background &&
		`background-color: white;
		border: 1px solid rgba(0, 0, 0, 0.12);
		border-radius: 4px;
		padding: 16px 8px;
		flex: 1;`}
	display: flex;
	flex-direction: column;
`;

const Content = styled.div`
	margin: 0;
`;

const ActionWrapper = styled.div`
	padding-top: 20px;
	justify-self: flex-end;
	align-self: flex-end;
	margin-top: auto;
`;

export const EmptyPanel = styled.div`
	font-style: italic;
	text-align: center;
	margin: 20px;
`;

export default function Panel({
	title = null,
	action = null,
	background = true,
	children,
}) {
	return (
		<StyledPanel>
			{title && (
				<Header>
					<Title>{title}</Title>
				</Header>
			)}

			<ContentWrapper $background={background}>
				<Content>{children}</Content>

				{action && <ActionWrapper>{action}</ActionWrapper>}
			</ContentWrapper>
		</StyledPanel>
	);
}
