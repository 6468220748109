import { StyledALink } from 'web/components/Link';

import {
	Wrapper,
	Document,
	StyledIcon,
} from 'web/components/widgets/components/lists/common/ListComponents';

export default function MyContractsList({ contracts }) {
	if (contracts.length === 0) {
		return null;
	}

	return (
		<Wrapper>
			<div>
				{contracts.map(contract => (
					<Document key={contract._id}>
						<StyledIcon icon="building" />

						<StyledALink
							href={contract.mySignatureUrl}
							target="_blank"
							rel="noopener"
						>
							{contract.company.name}
						</StyledALink>
					</Document>
				))}
			</div>
		</Wrapper>
	);
}
