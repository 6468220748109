import { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';

import AddOwnerDialog from 'web/components/panels/dialogs/AddOwnerDialog';

import ErrorMessage from 'components/ErrorMessage';

import Panel, { EmptyPanel } from 'web/components/panels/Panel';
import { UnderlineLink } from 'web/components/Link';

import {
	REMOVE_OWNER_FROM_COMPANY,
	UPDATE_OWNER_ON_COMPANY,
} from 'api/mutations/tenantMutations';

const OwnerNote = styled.div`
	font-size: 0.9rem;
	color: ${p => p.theme.palette.common.darkGray};
	margin: 5px 10px 0 0;
	white-space: pre-wrap;
`;

const NoteEditingWrapper = styled.div`
	margin-top: 5px;
`;

const NoteEditingActions = styled.div`
	margin-top: 5px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
`;

export default function CompanyOwners({ companyId, owners }) {
	const [showDialog, setShowDialog] = useState(false);

	return (
		<Panel
			title="Reelle rettighetshavere"
			action={
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						setShowDialog(true);
					}}
					endIcon={<AddIcon />}
				>
					Legg til
				</Button>
			}
		>
			<OwnersList owners={owners} companyId={companyId} />

			<AddOwnerDialog
				companyId={companyId}
				open={showDialog}
				onClose={() => setShowDialog(false)}
			/>
		</Panel>
	);
}

function OwnersList({ owners = [], companyId }) {
	if (!owners || owners.length === 0) {
		return (
			<EmptyPanel>
				Bedriften har ingen reelle rettighetshavere.
			</EmptyPanel>
		);
	}

	return owners.map(owner => (
		<OwnerDetails
			key={owner.user._id}
			owner={owner}
			companyId={companyId}
		/>
	));
}

function OwnerDetails({ owner, companyId }) {
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();
	const [isEditing, setIsEditing] = useState(false);
	const [noteValue, setNoteValue] = useState(owner?.note || '');

	const [
		updateOwner,
		{ loading: updateOwnerLoading, error: updateOwnerError },
	] = useMutation(UPDATE_OWNER_ON_COMPANY, {
		refetchQueries: ['findOneTenant'],
	});

	const [
		removeOwner,
		{ loading: removeOwnerLoading, error: removeOwnerError },
	] = useMutation(REMOVE_OWNER_FROM_COMPANY, {
		refetchQueries: ['findOneTenant'],
	});

	if (!owner?.user) return null;

	return (
		<>
			<ErrorMessage errors={[updateOwnerError, removeOwnerError]} />

			<ListItem
				secondaryAction={
					!isEditing && (
						<IconButton
							aria-label="Fjern reell rettighetshaver"
							disabled={removeOwnerLoading}
							onClick={async e => {
								try {
									e.preventDefault();

									if (
										!(await verify({
											title: 'Fjern bruker fra bedrift',
											text: 'Er du sikker på at du ønsker å fjerne brukeren fra bedriften? (Brukeren blir ikke slettet).',
										}))
									) {
										return;
									}

									const { data } = await removeOwner({
										variables: {
											_id: companyId,
											userId: owner.user._id,
										},
									});

									if (data && data.removed) {
										notify('Rettighetshaver ble fjernet');
									}
								} catch (err) {
									console.error(err);
								}
							}}
						>
							<DeleteIcon />
						</IconButton>
					)
				}
			>
				{!isEditing && (
					<ListItemIcon>
						<IconButton
							aria-label="Rediger notat"
							onClick={() => setIsEditing(true)}
						>
							<EditIcon fontSize="small" />
						</IconButton>
					</ListItemIcon>
				)}

				<ListItemText
					disableTypography
					primary={
						<UnderlineLink to={`/brukere/${owner.user._id}`}>
							{owner.user.name}
						</UnderlineLink>
					}
					secondary={
						isEditing ? (
							<>
								<NoteEditingWrapper>
									<TextField
										size="small"
										maxRows="5"
										minRows="2"
										multiline={true}
										value={noteValue}
										onChange={e => {
											setNoteValue(e.target.value);
										}}
										onFocus={event => {
											event.target.select();
										}}
										fullWidth
										autoFocus
									/>

									<NoteEditingActions>
										<Button
											size="small"
											onClick={() => setIsEditing(false)}
										>
											Avbryt
										</Button>

										<Button
											size="small"
											variant="contained"
											disabled={updateOwnerLoading}
											onClick={async () => {
												try {
													const { data } =
														await updateOwner({
															variables: {
																_id: companyId,
																userId: owner
																	.user._id,
																note: noteValue,
															},
														});

													if (data?.updated) {
														notify(
															'Rettighetshaver ble oppdatert'
														);

														setIsEditing(false);
													}
												} catch (err) {
													console.error(err);
												}
											}}
										>
											Lagre
										</Button>
									</NoteEditingActions>
								</NoteEditingWrapper>
							</>
						) : (
							<OwnerNote>{owner.note}</OwnerNote>
						)
					}
				/>
			</ListItem>

			<Divider />
		</>
	);
}
