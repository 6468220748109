import styled from 'styled-components';

import { formatDate } from 'utils/dates';

import Message from 'components/messages/Message';

export default function Revision({
	needsRevision = false,
	date = null,
	by = null,
}) {
	if (!date || !by) {
		return (
			<Message type="error" title="Mangler revisjon!">
				Vennligst revider skjema.
			</Message>
		);
	}

	return (
		<Message
			type={needsRevision ? 'error' : 'success'}
			title={needsRevision ? 'Trenger revisjon' : 'Revisjon OK'}
		>
			Sist revidert: {formatDate(date, 'DD.MM.YY [kl] HH:mm')} av {by}
		</Message>
	);
}

const StyledRevisionText = styled.div`
	color: ${p =>
		p.$needsRevision
			? p.theme.palette.status.error
			: p.theme.palette.status.success};
	font-size: 0.9rem;
	margin-top: 5px;
`;

export function RevisionText({
	needsRevision = false,
	date = null,
	by = null,
}) {
	if (!date || !by) {
		return (
			<StyledRevisionText $needsRevision={true}>
				Mangler revisjon
			</StyledRevisionText>
		);
	}

	return (
		<StyledRevisionText $needsRevision={needsRevision}>
			Sist revidert:
			{formatDate(date, 'DD.MM.YY [kl] HH:mm')} av {by}
		</StyledRevisionText>
	);
}
