import styled from 'styled-components';

import { InlineLoading } from 'components/Loading';
import Icon from 'components/Icon';

const Wrapper = styled.aside`
	border: 1px solid ${p => p.theme.palette.background.paper};
	height: 100%;
`;

const Header = styled.header`
	background-color: ${p => p.theme.palette.background.gray};
	padding: 15px 20px;
	display: flex;
	align-items: center;
`;

const Title = styled.h2`
	font-size: 1rem;
	line-height: 1.1;
	flex: 1;
	margin: 0;
	display: flex;
	align-items: center;
	gap: 10px;
`;

const Content = styled.div`
	padding: 20px;
`;

export default function Widget({
	title,
	titleIcon = null,
	children,
	loading = false,
	action = null,
}) {
	return (
		<Wrapper>
			<Header>
				<Title>
					{titleIcon && <Icon icon={titleIcon} duotone />}

					<div>{title}</div>
				</Title>

				{action && <div>{action}</div>}
			</Header>

			<Content>{loading ? <InlineLoading /> : children}</Content>
		</Wrapper>
	);
}
