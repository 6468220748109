import { useUser } from 'web/contexts/UserContext';

import Widget from 'web/components/widgets/Widget';

export default function WelcomeWidget() {
	const { user } = useUser();

	return (
		<Widget title={`Velkommen ${user.name}!`}>
			Du finner bedriftene dine under «Bedrifter» i menyen.
		</Widget>
	);
}
