import { gql } from '@apollo/client';

export const INSERT_ONE_COMPANY_RISK = gql`
	mutation insertOneCompanyRisk($companyId: ID!) {
		risk: insertOneCompanyRisk(companyId: $companyId) {
			_id
		}
	}
`;

export const UPDATE_ONE_COMPANY_RISK_QUESTION = gql`
	mutation updateOneCompanyRiskQuestion(
		$questionId: ID!
		$value: Int!
		$comment: String
	) {
		updated: updateOneCompanyRiskQuestion(
			questionId: $questionId
			value: $value
			comment: $comment
		)
	}
`;

export const UPDATE_ONE_COMPANY_RISK_QUESTION_COMMENT = gql`
	mutation updateOneCompanyRiskQuestionComment(
		$questionId: ID!
		$comment: String!
	) {
		updated: updateOneCompanyRiskQuestionComment(
			questionId: $questionId
			comment: $comment
		)
	}
`;

export const REVISE_ONE_COMPANY_RISK = gql`
	mutation reviseOneCompanyRisk(
		$_id: ID!
		$totalEvaluation: String
		$totalScore: String!
	) {
		revised: reviseOneCompanyRisk(
			_id: $_id
			totalEvaluation: $totalEvaluation
			totalScore: $totalScore
		)
	}
`;

export const DELETE_ONE_COMPANY_RISK = gql`
	mutation deleteOneCompanyRisk($_id: ID!) {
		deleted: deleteOneCompanyRisk(_id: $_id)
	}
`;
