import { gql } from '@apollo/client';

export const GET_TODOS = gql`
	query getTodos($completed: Boolean) {
		todos: getTodos(completed: $completed) {
			_id
			title
			date
			completed
			companyId
			company {
				_id
				name
			}
			description
		}
	}
`;
