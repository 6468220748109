import { useEffect } from 'react';
import styled from 'styled-components';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';

import { useForm } from 'components/form/Form';

const Wrapper = styled.div`
	margin-bottom: 16px;
`;

function getRenderValue(value, options, multiple) {
	let labels = [];

	for (let i = 0; i < options.length; i++) {
		if (!multiple && options[i].value === value) {
			return options[i].label;
		}

		if (value.indexOf(options[i].value) > -1) {
			labels.push(options[i].label);
		}
	}

	return labels.join(', ');
}

function isValueSelected(value, selected, multiple) {
	if (!multiple) {
		return value === selected;
	}

	if (!selected) {
		return false;
	}

	return selected.indexOf(value) > -1;
}

function labelToId(label) {
	return label.replace(/[^A-Za-z0-9_'-]/gi, '');
}

export default function SelectField(props) {
	const {
		name,
		disabled,
		label,
		options,
		multiple = false,
		fullWidth = true,
		variant = 'outlined',
		onChange = () => {},
	} = props;

	const { registerField, updateField, isDisabled, values, errors } =
		useForm();

	const error = errors[name];

	useEffect(() => {
		registerField(props);
	});

	useEffect(() => {
		if (value) {
			updateField({ name, value: value });
		}
	}, []);

	let value = values[name];

	if (multiple && !value) {
		value = [];
	}

	let fieldId = 'select-' + labelToId(label);

	return (
		<Wrapper>
			<FormControl fullWidth={fullWidth} variant={variant}>
				<InputLabel
					id={fieldId + '-label'}
					disabled={disabled || isDisabled}
				>
					{label}
				</InputLabel>

				<Select
					labelId={fieldId + '-label'}
					id={fieldId}
					label={label}
					disabled={disabled || isDisabled}
					multiple={multiple}
					value={value || ''}
					variant={variant}
					onChange={e => {
						updateField({ name, value: e.target.value });
						if (onChange) {
							onChange({
								name,
								value: e.target.value,
							});
						}
					}}
					renderValue={selected =>
						getRenderValue(selected, options, multiple)
					}
				>
					{options.map(({ value, label }) => (
						<MenuItem key={value} value={value}>
							{multiple && (
								<Checkbox
									checked={isValueSelected(
										value,
										values[name],
										multiple
									)}
								/>
							)}
							<ListItemText primary={label} />
						</MenuItem>
					))}
				</Select>
			</FormControl>

			{!!error && <span>{error}</span>}
		</Wrapper>
	);
}
