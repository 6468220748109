import { useQuery } from '@apollo/client';

import useSortOrder from 'hooks/useSortOrder';

import { useUser } from 'web/contexts/UserContext';
import { useAppFilters } from 'web/contexts/AppFiltersContext';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';

import SearchFilter from 'components/filters/SearchFilter';

import DesktopLayout, {
	ArchiveHeader,
	ArchiveHeaderRow,
	Content,
} from 'web/components/Layout';
import SortIcon from 'web/components/SortIcon';
import Table, { TableRow, TableCell } from 'web/components/Table';
import Link from 'web/components/Link';
import CreateFabButton from 'web/components/CreateFabButton';

import { PAGINATE_TENANT_CATEGORIES } from 'api/queries/tenantCategoryQueries';

export default function CompanyCategoriesArchive() {
	const { user } = useUser();
	const [{ orderBy, order }, setNewOrder] = useSortOrder('name');

	const { search } = useAppFilters();

	const {
		data: { categories = { count: 0, items: [] } } = {
			categories: { count: 0, items: [] },
		},
		loading,
		error,
	} = useQuery(PAGINATE_TENANT_CATEGORIES, {
		variables: {
			orderBy,
			order,
			search,
		},
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		notifyOnNetworkStatusChange: true,
	});

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Bedriftskategorier',
				},
			]}
		>
			<Content>
				<ErrorMessage errors={error} />

				<ArchiveHeader>
					<ArchiveHeaderRow>
						<SearchFilter label="Søk etter kategori" />
					</ArchiveHeaderRow>
				</ArchiveHeader>

				<Table columns="1">
					<TableRow header>
						<TableCell
							start="1"
							end="1"
							onClick={() => setNewOrder('name')}
						>
							<span>Navn</span>

							<SortIcon
								field="name"
								orderBy={orderBy}
								order={order}
							/>
						</TableCell>
					</TableRow>

					{!loading && categories?.items?.length === 0 && (
						<TableRow transparent>
							<TableCell start="1" end="-1" center>
								Ingen kategorier ble funnet
							</TableCell>
						</TableRow>
					)}

					{!error &&
						categories?.items.map(c => (
							<Link to={c._id + '/rediger/'} key={c._id}>
								<TableRow hover>
									<TableCell start="1" end="1">
										{c.name}
									</TableCell>
								</TableRow>
							</Link>
						))}

					{loading && (
						<TableRow transparent>
							<TableCell start="1" end="1">
								<InlineLoading />
							</TableCell>
						</TableRow>
					)}

					{!error && !loading && categories?.items?.length !== 0 && (
						<TableRow footer>
							<TableCell start="1" end="1" right>
								Viser {categories.items.length} av{' '}
								{categories.count} kategorier
							</TableCell>
						</TableRow>
					)}
				</Table>
			</Content>

			{user && user.role == 'admin' && <CreateFabButton to="ny/" />}
		</DesktopLayout>
	);
}
