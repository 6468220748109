import { useAppFilters } from 'web/contexts/AppFiltersContext';

import UsersFilterSelect from 'components/filters/components/UsersFilterSelect';

export default function PayrollManagerFilter({
	disabled = false,
	size = 'medium',
}) {
	const { payrollManager, setPayrollManager } = useAppFilters();

	return (
		<UsersFilterSelect
			title="Lønnsansvarlig"
			roles={['admin', 'accountant']}
			value={payrollManager}
			onChange={setPayrollManager}
			disabled={disabled}
			size={size}
		/>
	);
}
