import { gql } from '@apollo/client';

export const INSERT_ONE_COMMENT = gql`
	mutation insertOneComment($docId: ID!, $message: String!) {
		comment: insertOneComment(docId: $docId, message: $message) {
			_id
		}
	}
`;

export const DELETE_ONE_COMMENT = gql`
	mutation deleteOneComment($_id: ID!) {
		deleted: deleteOneComment(_id: $_id)
	}
`;
