import { gql } from '@apollo/client';

/**
 * TaskWeekCalendarWidget queries
 */
export const MY_COMPANIES = gql`
	query myCompanies {
		companies: myCompanies {
			_id
			name
		}
	}
`;

export const MY_COMPANIES_WITH_DUE_TASK_DEADLINE_ENTRIES = gql`
	query myCompaniesWitDueTaskDeadlineEntries(
		$fromDate: DateTime!
		$toDate: DateTime!
		$filter: AppFilterInput
	) {
		companies: myCompaniesWitDueTaskDeadlineEntries(
			fromDate: $fromDate
			toDate: $toDate
			filter: $filter
		) {
			_id
			name
		}
	}
`;

export const TASK_DEADLINE_ENTRIES_ON_DATE = gql`
	query taskDeadlineEntriesOnDate(
		$companyId: ID!
		$date: DateTime!
		$filter: AppFilterInput
	) {
		deadlineEntries: taskDeadlineEntriesOnDate(
			companyId: $companyId
			date: $date
			filter: $filter
		) {
			_id
			taskId
			type
			date
			notifications
			isOverdue
			status
			postponedStatus {
				status
				exported
			}
			comments {
				_id
			}
			files {
				_id
			}
			subTasks {
				name
				completed
				updatedAt
				updatedBy
				updatedByDisplayName
			}
			hasCompletedSubTasks
			history {
				_id
				event
				createdAt
				userId
				userDisplayName
			}
		}
	}
`;

/**
 * CompaniesWidget queries
 */
export const ACTIVE_COMPANIES = gql`
	query activeCompanies($filter: AppFilterInput) {
		items: activeCompanies(filter: $filter) {
			_id
			name
		}
	}
`;

export const COMPANIES_MISSING_ASSIGNMENT = gql`
	query companiesMissingAssignment($filter: AppFilterInput) {
		items: companiesMissingAssignment(filter: $filter) {
			_id
			name
		}
	}
`;

export const COMPANIES_MISSING_FIELD = gql`
	query companiesMissingField(
		$field: String!
		$accountTypes: [AccountType!]!
		$filter: AppFilterInput
	) {
		items: companiesMissingField(
			field: $field
			accountTypes: $accountTypes
			filter: $filter
		) {
			_id
			name
		}
	}
`;

/**
 * CompanyTasksWidget queries
 */
export const COMPANIES_WITH_OVERDUE_DEADLINES_IN_PERIOD = gql`
	query companiesWithOverdueDeadlinesInPeriod($filter: AppFilterInput) {
		items: companiesWithOverDueDeadlinesInPeriod(filter: $filter) {
			_id
			name
		}
	}
`;

export const COMPANIES_WITH_DUE_DEADLINES_IN_PERIOD = gql`
	query companiesWithDueDeadlinesInPeriod($filter: AppFilterInput) {
		items: companiesWithDueDeadlinesInPeriod(filter: $filter) {
			_id
			name
		}
	}
`;

export const COMPANIES_WITH_DONE_DEADLINES_IN_PERIOD = gql`
	query companiesWithDoneDeadlinesInPeriod($filter: AppFilterInput) {
		items: companiesWithDoneDeadlinesInPeriod(filter: $filter) {
			_id
			name
		}
	}
`;

/**
 * UsersWidget queries
 */
export const GET_USERS_INFO = gql`
	query getUsersInfo {
		activeUsersCount: usersCount
		notInvitedUsers {
			_id
			name
		}
		unauthorizedUsers {
			_id
			name
		}
	}
`;

/**
 * CompanyRoutinesWidget queries
 */
export const COMPANIES_MISSING_ROUTINES = gql`
	query companiesMissingRoutines($filter: AppFilterInput) {
		items: companiesMissingRoutines(filter: $filter) {
			_id
			name
		}
	}
`;

export const COMPANIES_NEED_ROUTINES_REVISION = gql`
	query companiesNeedRoutinesRevision($filter: AppFilterInput) {
		items: companiesNeedRoutinesRevision(filter: $filter) {
			_id
			name
		}
	}
`;

/**
 * AssignmentRisksWidget queries
 */
export const COMPANIES_MISSING_ASSIGNMENT_RISKS = gql`
	query companiesMissingAssignmentRisks(
		$type: AssignmentRiskType!
		$filter: AppFilterInput
	) {
		items: companiesMissingAssignmentRisks(type: $type, filter: $filter) {
			_id
			name
		}
	}
`;

export const COMPANIES_NEED_ASSIGNMENT_RISKS_REVISION = gql`
	query companiesNeedAssignmentRisksRevision(
		$type: AssignmentRiskType!
		$filter: AppFilterInput
	) {
		items: companiesNeedAssignmentRisksRevision(
			type: $type
			filter: $filter
		) {
			_id
			name
		}
	}
`;

export const COMPANIES_WITH_COMPLETED_ASSIGNMENT_RISKS = gql`
	query companiesWithCompletedAssignmentRisks(
		$type: AssignmentRiskType!
		$filter: AppFilterInput
	) {
		items: companiesWithCompletedAssignmentRisks(
			type: $type
			filter: $filter
		) {
			_id
			name
		}
	}
`;

/**
 * CompanyRisksWidget queries
 */
export const COMPANIES_MISSING_RISKS = gql`
	query companiesMissingRisks($filter: AppFilterInput) {
		items: companiesMissingRisks(filter: $filter) {
			_id
			name
		}
	}
`;

export const COMPANIES_NEED_RISKS_REVISION = gql`
	query companiesNeedRisksRevision($filter: AppFilterInput) {
		items: companiesNeedRisksRevision(filter: $filter) {
			_id
			name
		}
	}
`;

export const COMPANIES_WITH_LOW_RISK_SCORE = gql`
	query companiesWithLowRiskScore($filter: AppFilterInput) {
		items: companiesWithRiskScore(score: "Lav", filter: $filter) {
			_id
			name
		}
	}
`;

export const COMPANIES_WITH_NORMAL_RISK_SCORE = gql`
	query companiesWithNormalRiskScore($filter: AppFilterInput) {
		items: companiesWithRiskScore(score: "Normal", filter: $filter) {
			_id
			name
		}
	}
`;

export const COMPANIES_WITH_HIGH_RISK_SCORE = gql`
	query companiesWithHighRiskScore($filter: AppFilterInput) {
		items: companiesWithRiskScore(score: "Høy", filter: $filter) {
			_id
			name
		}
	}
`;

/**
 * CompanyControlsWidget queries
 */
export const COMPANIES_ANNUAL_SETTLEMENT_CONTROL_STATUS_READY = gql`
	query companiesAnnualSettlementControlStatusReady($filter: AppFilterInput) {
		items: companiesAnnualSettlementControlStatusReady(filter: $filter) {
			_id
			name
			annualFinancialStatus {
				readyForReview
				reviewedYear
				reviewedBy
				reviewedByDisplayName
				reviewedAt
				notRelevant
			}
		}
	}
`;

export const COMPANIES_CONTROLLED = gql`
	query companiesControlled(
		$type: String!
		$statusCode: CompanyControlStatusCodes!
		$filter: AppFilterInput
	) {
		items: companiesControlled(
			type: $type
			statusCode: $statusCode
			filter: $filter
		) {
			_id
			name
		}
	}
`;

export const COMPANIES_ANNUAL_ACCOUNT_CONTROL_REVIEW = gql`
	query companiesAnnualAccountControlStatusReview($filter: AppFilterInput) {
		items: companiesControlled(
			type: "arsregnskap-skattemelding"
			statusCode: REVIEW
			filter: $filter
		) {
			_id
			name
		}
	}
`;

/**
 * CompanyDocumentsWidget queries
 */
export const MY_DOCUMENTS_SIGNATURE_REQUESTS = gql`
	query myDocumentsSignatureRequests {
		signatureRequests: mySignatureRequests {
			_id
			signUrl
			documentNode {
				_id
				name
				tenant {
					_id
					name
				}
			}
		}
	}
`;

export const COMPANY_DOCUMENTS_WITH_ACTIVE_SIGNING_REQUESTS = gql`
	query companyDocumentsWithActiveSigningRequests(
		$filter: AppFilterInput
		$showOnlyMyRequests: Boolean
	) {
		documents: companyDocumentsWithActiveSigningRequests(
			filter: $filter
			showOnlyMyRequests: $showOnlyMyRequests
		) {
			_id
			name
			tenant {
				_id
				name
			}
		}
	}
`;

/**
 * CompanyContractsWidget queries
 */
export const COMPANY_CONTRACTS_MISSING_SIGNATURES = gql`
	query companyContractsMissingSignatures(
		$filter: AppFilterInput
		$showOnlyMyRequests: Boolean
	) {
		items: companyContractsMissingSignatures(
			filter: $filter
			showOnlyMyRequests: $showOnlyMyRequests
		) {
			_id
			company {
				_id
				name
			}
			signingUsers {
				_id
				name
				signed
			}
		}
	}
`;

export const COMPANIES_WITHOUT_CONTRACTS = gql`
	query companiesWithoutContracts($filter: AppFilterInput) {
		items: companiesWithoutContracts(filter: $filter) {
			_id
			name
		}
	}
`;

export const COMPANIES_WITH_INCOMPLETE_CONTRACTS = gql`
	query companiesWithIncompleteContracts($filter: AppFilterInput) {
		items: companiesWithIncompleteContracts(filter: $filter) {
			_id
			name
		}
	}
`;

export const MY_UNSIGNED_CONTRACTS = gql`
	query myUnsignedContracts {
		contracts: myUnsignedContracts {
			_id
			company {
				name
			}
			mySignatureUrl
		}
	}
`;

/**
 * CompanyCategoryWidget queries
 */
export const COMPANIES_WITH_CATEGORY = gql`
	query companiesWithCategory($categoryId: ID!, $filter: AppFilterInput) {
		items: companiesWithCategory(categoryId: $categoryId, filter: $filter) {
			_id
			name
		}
	}
`;

/**
 * TaskDeadlineWidget queries
 */
export const COMPANIES_WITH_TASK_DEADLINE_ENTRIES = gql`
	query companiesWithTaskDeadlineEntries(
		$type: AssignmentType!
		$status: String
		$excludeCompleted: Boolean
		$excludePostponed: Boolean
		$isPostponed: Boolean
		$filter: AppFilterInput
	) {
		items: companiesWithTaskDeadlineEntries(
			type: $type
			status: $status
			excludeCompleted: $excludeCompleted
			excludePostponed: $excludePostponed
			isPostponed: $isPostponed
			filter: $filter
		) {
			_id
			name
		}
	}
`;

export const COMPANIES_WITH_OVERDUE_TASK_DEADLINE_ENTRIES = gql`
	query companiesWithOverdueTaskDeadlineEntries(
		$type: AssignmentType!
		$filter: AppFilterInput
	) {
		items: companiesWithOverdueTaskDeadlineEntries(
			type: $type
			filter: $filter
		) {
			_id
			name
		}
	}
`;
