import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { UnderlineLink } from 'web/components/Link';

export default function ListDialog({ title, open, onClose, children }) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="sm"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>

			{children}

			<DialogActions>
				<Button onClick={onClose} variant="cotained">
					Lukk
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export function GeneralDialogContent({ children }) {
	return <DialogContent>{children}</DialogContent>;
}

export function ListDialogContent({ entries, link }) {
	return (
		<DialogContent>
			{entries?.length > 0 ? (
				<List>
					{entries.map(entry => (
						<ListItem key={entry._id}>
							<UnderlineLink to={link(entry._id)}>
								{entry.name}
							</UnderlineLink>
						</ListItem>
					))}
				</List>
			) : (
				<div>Ingen oppføringer ble funnet</div>
			)}
		</DialogContent>
	);
}
