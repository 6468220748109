import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { useQuery } from '@apollo/client';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import { formatDate } from 'utils/dates';

import CompanyControlsCreateModal from 'web/screens/modals/CompanyControlsCreateModal';

import { CompanyControlStatusPill } from 'components/Pill';
import ErrorMessage from 'components/ErrorMessage';

import { UnderlineLink } from 'web/components/Link';
import Panel, { EmptyPanel } from 'web/components/panels/Panel';

import { GET_COMPANY_CONTROLS } from 'api/queries/companyControlQueries';
import { InlineLoading } from 'components/Loading';

const TextWrapper = styled.div`
	text-align: center;
`;

const Status = styled(CompanyControlStatusPill)`
	margin-top: 5px;
	min-width: 110px;
`;

const SubText = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-size: 0.9rem;
	margin-top: 5px;
`;

export default function CompanyControlsPanel({ companyId }) {
	const [showCreateControlModal, setShowCreateControlModal] = useState(false);

	const {
		data: { controls } = { controls: [] },
		loading,
		error,
	} = useQuery(GET_COMPANY_CONTROLS, {
		variables: {
			companyId,
		},
	});

	return (
		<>
			<Panel
				title="Bedriftskontroller"
				action={
					<Button
						size="medium"
						variant="contained"
						color="primary"
						onClick={() => setShowCreateControlModal(true)}
						endIcon={<AddIcon size="small" />}
					>
						Opprett ny
					</Button>
				}
			>
				<ErrorMessage errors={error} />

				{loading ? (
					<InlineLoading />
				) : (
					<CompanyControlsList controls={controls} />
				)}
			</Panel>

			{showCreateControlModal &&
				createPortal(
					<CompanyControlsCreateModal
						companyId={companyId}
						onClose={() => setShowCreateControlModal(false)}
					/>,
					document.getElementById('modal-root')
				)}
		</>
	);
}

function CompanyControlsList({ controls = [] }) {
	if (!controls || controls.length === 0) {
		return <EmptyPanel>Bedriften har ingen kontrollskjema.</EmptyPanel>;
	}

	return (
		<List>
			{controls.map(control => (
				<CompanyControlsListItem key={control._id} control={control} />
			))}
		</List>
	);
}

function CompanyControlsListItem({ control }) {
	return (
		<>
			<ListItem
				secondaryAction={
					<TextWrapper>
						<div>{control.year}</div>

						<Status $statusCode={control.status.code}>
							{control.status.label}
						</Status>
					</TextWrapper>
				}
			>
				<ListItemText>
					<UnderlineLink
						to={`/bedrifter/${control.companyId}/kontroller/${control._id}/rediger/`}
					>
						{control.name}
					</UnderlineLink>

					<SubText>
						Sist oppdatert{' '}
						{formatDate(
							control.status.updatedAt,
							'DD.MM.YY [kl] HH:mm'
						)}{' '}
						av {control.status.updatedByDisplayName}
					</SubText>
				</ListItemText>
			</ListItem>

			<Divider component="li" />
		</>
	);
}
