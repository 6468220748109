import { useState } from 'react';
import { useMutation } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorAlert from 'components/ErrorAlert';

import PasswordStrength from 'web/components/PasswordStrength';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';

import { SET_NEW_USER_PASSWORD } from 'api/mutations/userMutations';

export default function NewPasswordDialog({ userId, open, onClose }) {
	const { notify } = useSnackbar();
	const [password, setPassword] = useState('');
	const [repeatPasswordError, setRepeatPasswordError] = useState('');

	const [setNewPassword, { loading, error }] = useMutation(
		SET_NEW_USER_PASSWORD
	);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="xs"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Endre passord</DialogTitle>

			<Form
				isLoading={loading}
				isDisabled={loading}
				onSubmit={async ({ newPassword, repeatNewPassword }) => {
					try {
						const {
							data: { ok },
						} = await setNewPassword({
							variables: {
								userId,
								newPassword,
								repeatNewPassword,
							},
						});

						if (ok) {
							notify('Ditt passord ble oppdatert!');
							onClose();
						}
					} catch (err) {
						console.warn(err);
					}
				}}
			>
				<DialogContent>
					<ErrorAlert error={error} />

					<TextField
						name="newPassword"
						type="password"
						label="Nytt passord"
						onChange={e => setPassword(e.target.value)}
						value={password}
						required
						helperText="Må innheholde minst 8 tegn, minimum en stor bokstav og ett tall. Spesialtegn gjør det ekstra sterkt."
					/>

					<PasswordStrength password={password} />

					<TextField
						name="repeatNewPassword"
						type="password"
						label="Gjenta passord"
						onChange={e => {
							setRepeatPasswordError(e.target.value !== password);
						}}
						helperText={
							repeatPasswordError ? 'Passord ikke likt' : null
						}
						required
					/>
				</DialogContent>

				<DialogActions>
					<Button onClick={onClose}>Avbryt</Button>

					<Button
						type="submit"
						variant="contained"
						disabled={loading}
						autoFocus
					>
						Sett nytt passord
					</Button>
				</DialogActions>
			</Form>
		</Dialog>
	);
}
