import { gql } from '@apollo/client';

export const INSERT_ONE_ASSIGNMENT_RISK = gql`
	mutation insertOneAssignmentRisk($type: String!, $companyId: ID!) {
		risk: insertOneAssignmentRisk(type: $type, companyId: $companyId) {
			_id
			name
		}
	}
`;

export const UPDATE_ONE_ASSIGNMENT_RISK = gql`
	mutation updateOneAssignmentRisk($_id: ID!) {
		updatedRisk: updateOneAssignmentRisk(_id: $_id) {
			_id
			name
		}
	}
`;

export const UPDATE_ONE_ASSIGNMENT_RISK_QUESTION_VALUE = gql`
	mutation updateOneAssignmentRiskQuestionValue(
		$assignmentRiskId: ID!
		$questionId: ID!
		$value: String!
	) {
		updated: updateOneAssignmentRiskQuestionValue(
			assignmentRiskId: $assignmentRiskId
			questionId: $questionId
			value: $value
		)
	}
`;

export const DELETE_ONE_ASSIGNMENT_RISK = gql`
	mutation deleteOneAssignmentRisk($_id: ID!) {
		deleted: deleteOneAssignmentRisk(_id: $_id)
	}
`;
