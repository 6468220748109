import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';

import Todo from 'web/components/widgets/components/Todo';

import ErrorAlert from 'components/ErrorAlert';

import { GET_TODOS } from 'api/queries/todoQueries';

export default function CompletedTodosDialog({ isOpen, close }) {
	return (
		<Dialog
			open={isOpen}
			onClose={close}
			fullWidth={true}
			maxWidth="sm"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Utførte oppgaver</DialogTitle>

			<DialogCloseButton onClick={close} />

			<CompletedTodos close={close} />
		</Dialog>
	);
}

const NotFoundMessage = styled.div`
	font-style: italic;
	font-weight: 500;
	text-align: center;
	margin-top: 20px;
`;

function CompletedTodos({ close }) {
	const { data: { todos = [] } = { todos: [] }, error } = useQuery(
		GET_TODOS,
		{
			variables: {
				completed: true,
			},
		}
	);

	return (
		<>
			<DialogContent>
				<ErrorAlert error={error} />

				{todos.length > 0 ? (
					<div>
						{todos.map(todo => (
							<Todo key={todo._id} todo={todo} />
						))}
					</div>
				) : (
					<NotFoundMessage>
						Du har ingen utførte oppgaver
					</NotFoundMessage>
				)}
			</DialogContent>

			<DialogActions>
				<Button variant="contained" onClick={close}>
					Lukk
				</Button>
			</DialogActions>
		</>
	);
}
