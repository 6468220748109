import { useState } from 'react';

import CompanyContractsDialog from 'web/components/widgets/dialogs/CompanyContractsDialog';

import {
	Wrapper,
	Document,
	StyledIcon,
	StyledButton,
} from 'web/components/widgets/components/lists/common/ListComponents';

export default function ContractsList({ contracts }) {
	const [activeContracts, setActiveContracts] = useState(null);

	if (contracts.length === 0) {
		return null;
	}

	return (
		<Wrapper>
			<div>
				{contracts.map(contract => (
					<Document key={contract._id}>
						<StyledIcon icon="building" />

						<StyledButton
							onClick={() => setActiveContracts([contract])}
						>
							{contract.company.name}
						</StyledButton>
					</Document>
				))}
			</div>

			<CompanyContractsDialog
				contracts={activeContracts}
				open={!!activeContracts}
				onClose={() => setActiveContracts(null)}
			/>
		</Wrapper>
	);
}
