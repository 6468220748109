import { useState } from 'react';
import styled from 'styled-components';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const toolbarSettings = {
	options: ['blockType', 'inline'],
	blockType: {
		inDropdown: true,
		options: ['Normal', 'H1', 'H2', 'H3', 'H4'],
	},
	inline: {
		options: ['bold', 'italic', 'underline'],
	},
};

const MarkdownEditorWrapper = styled.div`
	min-width: 300px;

	h1,
	h2,
	h3,
	h4 {
		margin: 0 0 15px 0;
		line-height: 1;
	}

	h1 {
		font-size: 1.8rem;
	}

	h2 {
		font-size: 1.4rem;
	}

	h3 {
		font-size: 1.2rem;
	}

	h4 {
		font-size: 1rem;
	}

	.rdw-editor-toolbar {
		border: 1px solid ${p => p.theme.palette.borderColor.default};
		border-radius: 4px;
	}

	.rdw-editor-main {
		background: ${p => p.theme.palette.background.default};
		border: 1px solid ${p => p.theme.palette.borderColor.default};
		border-radius: 4px;
		padding: 14px;
		min-height: 300px;
	}

	.public-DraftStyleDefault-block {
		margin: 0 0 15px 0;
	}

	&:hover {
		.rdw-editor-main {
			border: 1px solid ${p => p.theme.palette.borderColor.hover};
		}
	}
`;

export function MarkdownEditor({ value, onChange }) {
	const [editorState, setEditorState] = useState(() => {
		const rawData = markdownToDraft(value || '');
		const contentState = convertFromRaw(rawData);

		return EditorState.createWithContent(contentState);
	});

	const onEditorStateChange = editorState => {
		setEditorState(editorState);

		return onChange(
			draftToMarkdown(convertToRaw(editorState.getCurrentContent()))
		);
	};

	return (
		<MarkdownEditorWrapper>
			<Editor
				toolbar={toolbarSettings}
				editorState={editorState}
				onEditorStateChange={onEditorStateChange}
			/>
		</MarkdownEditorWrapper>
	);
}
