import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import map from 'lodash/map';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ModalLayout from 'components/modals/ModalLayout';
import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import SelectField from 'components/form/SelectField';
import SubmitButton from 'components/form/SubmitButton';

import { INSERT_ONE_COMPANY_CONTRACT } from 'api/mutations/companyContractMutations';

import { contractTypes } from 'server/schema/CompanyContracts.module';

const contractTypeChoices = map(contractTypes, (label, type) => {
	return { value: type, label: label };
});

export default function CompanyContractCreateModal({ companyId, onClose }) {
	const navigate = useNavigate();
	const { notify } = useSnackbar();

	const [insertContract, { loading, error }] = useMutation(
		INSERT_ONE_COMPANY_CONTRACT,
		{
			refetchQueries: ['paginateCompanyContracts'],
		}
	);

	return (
		<ModalLayout
			title="Opprett ny avtale"
			onClose={onClose}
			maxWidth="800px"
		>
			<ErrorMessage errors={[error]} />

			<Form
				values={{ type: 'AGREEMENT' }}
				isLoading={loading}
				isDisabled={loading}
				onSubmit={async ({ type }) => {
					try {
						const { data } = await insertContract({
							variables: {
								companyId,
								startDate: new Date(),
								type,
							},
						});
						if (data?.contract?._id) {
							notify(`${contractTypes[type]} opprettet!`);

							navigate(
								`/bedrifter/${companyId}/avtaler/${data.contract._id}/rediger/`
							);
						}
					} catch (err) {
						console.error(err);
						return null;
					}
				}}
			>
				<SelectField
					name="type"
					label="Velg type avtale"
					options={contractTypeChoices}
					required
				/>

				<SubmitButton variant="contained">Opprett avtale</SubmitButton>
			</Form>
		</ModalLayout>
	);
}
