import { useMutation } from '@apollo/client';
import pick from 'lodash/pick';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import FrequencyFields from 'web/screens/dialogs/assignmentTask/FrequencyFields';
import SubTasksField from 'web/screens/dialogs/assignmentTask/SubTasksField';

import ErrorMessage from 'components/ErrorMessage';

import Form, {
	FormGroup,
	MarkdownField,
	TextField,
	DatePicker,
	Switch,
} from 'web/form/Form';

import { UPDATE_ONE_COMPANY_ASSIGNMENT_TASK } from 'api/mutations/companyAssignmentTaskMutations';

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
`;

export default function EditAssignmentTaskDialog({ task, open, onClose }) {
	const { notify } = useSnackbar();

	const [updateTask, { loading, error }] = useMutation(
		UPDATE_ONE_COMPANY_ASSIGNMENT_TASK,
		{
			refetchQueries: ['getTasks'],
		}
	);

	// TODO: Maybe handle this in the Form component?
	// Only pick the values we need for the form/graphql mutation
	const formValues = pick(task, [
		'name',
		'startDate',
		'routine',
		'notifications',
		'frequency',
		'deadline.weekday',
		'deadline.day',
		'deadline.relativeMonth',
		'deadline.month',
		'deadline.dates',
		'subTasks',
	]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullWidth={true}
			maxWidth="md"
			scroll="body"
			PaperProps={{
				sx: {
					minHeight: 400,
				},
			}}
		>
			<DialogTitle id="alert-dialog-title">Rediger oppgave</DialogTitle>

			<Form
				values={formValues}
				onSubmit={async values => {
					try {
						//TODO: Cleanup the deadline property before save?
						// The form doesn't remove the deadline property if the frequency is changed
						// Should this be done on the client or on the server?
						const { data } = await updateTask({
							variables: {
								_id: task._id,
								input: {
									...values,
								},
							},
						});

						if (data?.task?._id) {
							notify('Oppgave endret!');

							onClose();
						}
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<DialogContent>
					<ErrorMessage errors={[error]} />

					<Grid>
						<div>
							<FormGroup>
								<TextField
									name="name"
									label="Oppgavenavn"
									fullWidth
									required
								/>
							</FormGroup>

							<FormGroup>
								<DatePicker
									name="startDate"
									label="Startdato"
									required
								/>
							</FormGroup>

							<FrequencyFields />

							<FormGroup>
								<Switch
									name="notifications"
									label="Send en påminnelse på e-post til regnskapsfører og kundeansvarlig ved utløp av tidsfrist."
								/>
							</FormGroup>

							<SubTasksField />
						</div>

						<div>
							<FormGroup>
								<MarkdownField
									name="routine"
									label="Oppgaverutine"
								/>
							</FormGroup>
						</div>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Button onClick={onClose}>Avbryt</Button>

					<Button
						type="submit"
						variant="contained"
						disabled={loading}
						autoFocus
					>
						Oppdater
					</Button>
				</DialogActions>
			</Form>
		</Dialog>
	);
}
