import { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import ErrorAlert from 'components/ErrorAlert';

import Todo from 'web/components/widgets/components/Todo';

import Widget from 'web/components/widgets/Widget';

import CreateTodoDialog from 'web/components/widgets/dialogs/CreateTodoDialog';
import CompletedTodosDialog from 'web/components/widgets/dialogs/CompletedTodosDialog';

import { GET_TODOS } from 'api/queries/todoQueries';

const AllDoneMessage = styled.div`
	font-style: italic;
	font-weight: 500;
	text-align: center;
	margin-top: 20px;
`;

const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
`;

export default function TodosWidget() {
	const [createTodoModalIsOpen, setCreateTodoModalIsOpen] = useState(false);
	const [completedTodosModalIsOpen, setCompletedTodosModalIsOpen] =
		useState(false);

	const { data: { todos = [] } = { todos: [] }, error } = useQuery(GET_TODOS);

	return (
		<Widget
			title="Mine oppgaver"
			titleIcon="clipboard-list-check"
			action={
				<IconButton
					title="Legg til oppgave"
					size="small"
					onClick={() => setCreateTodoModalIsOpen(true)}
				>
					<AddIcon fontSize="small" />
				</IconButton>
			}
		>
			<ErrorAlert error={error} />

			{todos.length > 0 ? (
				<div>
					{todos.map(todo => (
						<Todo key={todo._id} todo={todo} />
					))}
				</div>
			) : (
				<AllDoneMessage>Alle dine oppaver er utført</AllDoneMessage>
			)}

			<Actions>
				<Button onClick={() => setCompletedTodosModalIsOpen(true)}>
					Se utførte
				</Button>
			</Actions>

			<CreateTodoDialog
				isOpen={createTodoModalIsOpen}
				close={() => setCreateTodoModalIsOpen(false)}
			/>

			<CompletedTodosDialog
				isOpen={completedTodosModalIsOpen}
				close={() => setCompletedTodosModalIsOpen(false)}
			/>
		</Widget>
	);
}
