import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const CenterWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const InlineLoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const LoadingText = styled.p`
	margin: 20px 0 0;
	font-size: 20px;
	font-weight: bold;
`;

export function Loading({ text = null }) {
	return (
		<CenterWrapper>
			<CircularProgress />

			{text && <LoadingText>{text}</LoadingText>}
		</CenterWrapper>
	);
}

export function InlineLoading({ text = null }) {
	return (
		<InlineLoadingWrapper>
			<CircularProgress />

			{text && <LoadingText>{text}</LoadingText>}
		</InlineLoadingWrapper>
	);
}

export default Loading;
