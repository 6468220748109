import styled from 'styled-components';
import { Alert, AlertTitle } from '@mui/material';

const StyledErrorMessage = styled.div`
	margin-bottom: 20px;
	text-align: left;
`;

export default function Message({ title = null, type, children }) {
	return (
		<StyledErrorMessage>
			<Alert severity={type}>
				{title && <AlertTitle>{title}</AlertTitle>}

				<div>{children}</div>
			</Alert>
		</StyledErrorMessage>
	);
}
