import styled from 'styled-components';

import { formatDate, isDateBeforeToday } from 'utils/dates';

const CalendarIconWrapper = styled.div`
	border: 1px solid
		${p =>
			p.$isOverdue
				? p.theme.palette.status.error
				: p.theme.palette.status.success};
	border-radius: 3px;
	color: ${p => p.theme.palette.text.primary};
	font-size: 10px;
	min-width: 35px;
`;

const Month = styled.div`
	background-color: ${p =>
		p.$isOverdue
			? p.theme.palette.status.error
			: p.theme.palette.status.success};
	line-height: 1;
	text-align: center;
	text-transform: uppercase;
	padding: 4px 3px;
`;

const Day = styled.div`
	line-height: 1;
	text-align: center;
	padding: 5px 3px;
`;

export default function CalendarIcon({ date }) {
	const day = formatDate(date, 'DD');
	const month = formatDate(date, 'MMM');
	const isOverdue = isDateBeforeToday(date);

	return (
		<CalendarIconWrapper $isOverdue={isOverdue}>
			<Month $isOverdue={isOverdue}>{month}</Month>

			<Day>{day}</Day>
		</CalendarIconWrapper>
	);
}
