import styled from 'styled-components';

const StyledIcon = styled.i`
	min-width: 30px;
	text-align: center;
	line-height: 1em;

	&.button-icon {
		line-height: 24px;
		font-size: 18px;
		width: 24px;
		min-width: 0;
	}

	&.fa-duotone {
		color: ${p => p.theme.palette.common.darkGray};
	}
`;

export default function Icon({
	icon,
	solid,
	light,
	className = '',
	spin,
	pulse,
	duotone,
	size = 'lg',
	...props
}) {
	const iconStyleClass =
		(solid && 'fa-solid') ||
		(light && 'fa-light') ||
		(duotone && 'fa-duotone') ||
		'fa-regular';

	const animationStyleClass = `${(spin && `fa-spin`) || ''} ${
		(pulse && `fa-pulse`) || ''
	}`;

	const sizeStyleClass = (size && 'fa-' + size) || '';

	return (
		<StyledIcon
			className={`${iconStyleClass} fa-${icon} ${animationStyleClass} ${sizeStyleClass} ${className}`}
			{...props}
		/>
	);
}

/**
 * Sizes:
 * 2xs
 * xs
 * sm
 *
 * lg
 * xl
 * 2xl
 */
