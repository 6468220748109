import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';

import { formatDate } from 'utils/dates';

import CompanyContractCreateModal from 'web/screens/modals/CompanyContractCreateModal';

import ErrorMessage from 'components/ErrorMessage';
import { CompanyContractsStatusPill } from 'components/Pill';
import { UpdatedCreatedAtText } from 'components/UpdatedCreatedAtAlert';

import Panel, { EmptyPanel } from 'web/components/panels/Panel';
import { UnderlineLink } from 'web/components/Link';

import { PAGINATE_COMPANY_CONTRACTS } from 'api/queries/companyContractQueries';
import { InlineLoading } from 'components/Loading';

const Text = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-size: 0.9rem;
	margin-top: 5px;
`;

const TextWrapper = styled.div`
	font-size: 14px;
	text-align: center;
`;

export default function CompanyContractsPanel({ companyId }) {
	const [showCreateContractModal, setShowCreateContractModal] =
		useState(false);

	const {
		data: { contracts = { count: 0, items: [] } } = {
			contracts: { count: 0, items: [] },
		},
		loading,
		error,
	} = useQuery(PAGINATE_COMPANY_CONTRACTS, {
		variables: {
			companyId,
			orderBy: 'createdAt',
			order: -1,
		},
	});

	return (
		<>
			<Panel
				title="Oppdragsavtaler"
				action={
					<Button
						endIcon={<AddIcon size="small" />}
						size="medium"
						variant="contained"
						color="primary"
						disabled={showCreateContractModal}
						onClick={() => {
							setShowCreateContractModal(true);
						}}
					>
						Opprett ny
					</Button>
				}
			>
				<ErrorMessage errors={error} />

				{loading ? (
					<InlineLoading />
				) : (
					<CompanyContractsList contracts={contracts?.items} />
				)}
			</Panel>

			{showCreateContractModal &&
				createPortal(
					<CompanyContractCreateModal
						companyId={companyId}
						onClose={() => setShowCreateContractModal(false)}
					/>,
					document.getElementById('modal-root')
				)}
		</>
	);
}

function CompanyContractsList({ contracts = [] }) {
	if (!contracts || contracts.length === 0) {
		return <EmptyPanel>Bedriften har ingen oppdragsavtaler.</EmptyPanel>;
	}

	return (
		<List>
			{contracts.map(contract => (
				<CompanyContractsListItem
					key={contract._id}
					contract={contract}
				/>
			))}
		</List>
	);
}

function CompanyContractsListItem({ contract }) {
	return (
		<>
			<ListItem
				secondaryAction={
					<TextWrapper>
						<div>{formatDate(contract.createdAt, 'DD.MM.YY')}</div>

						<ContractStatus
							isSentToSigning={contract.isSentToSigning}
							isFullySigned={contract.isFullySigned}
						/>
					</TextWrapper>
				}
			>
				<ListItemText>
					<UnderlineLink
						to={`/bedrifter/${contract.companyId}/avtaler/${contract._id}/rediger/`}
					>
						{contract.typeLabel}
					</UnderlineLink>

					<Text>
						<UpdatedCreatedAtText
							createdAt={contract.createdAt}
							createdBy={contract.createdByDisplayName}
							updatedAt={contract.updatedAt}
							updatedBy={contract.updatedByDisplayName}
						/>
					</Text>
				</ListItemText>
			</ListItem>
			<Divider component="li" />
		</>
	);
}

export function ContractStatus({ isSentToSigning, isFullySigned }) {
	let statusText = 'Opprettet';
	let statusCode = 'CREATED';

	if (isSentToSigning && isFullySigned) {
		statusText = 'Signert';
		statusCode = 'SIGNED';
	} else if (isSentToSigning && !isFullySigned) {
		statusText = 'Til signering';
		statusCode = 'SIGNING';
	}

	return (
		<CompanyContractsStatusPill $statusCode={statusCode}>
			{statusText}
		</CompanyContractsStatusPill>
	);
}
